import * as type from './types'


export function createOrganisation(organisation) {
    return {
        type : type.CREATE_ORGANISATION,
        payload : organisation,
        txn : 'initiated',
    };
}


export function savedOrganisation(organisation) {
    return {
        type : type.SAVED_ORGANISATION,
        payload : organisation,
        txn : 'success',
        diff : true
    };
}


export function editOrganisation(organisation) {
    return {
        type : type.EDIT_ORGANISATION,
        payload : organisation,
        txn : 'initiated',
    };
}


export function fetchOrganisation() {
    return {
        type : type.FETCH_ORGANISATION,
    };
}


export function fetchedOrganisation(organisation) {
    return {
        type : type.FETCHED_ORGANISATION,
        payload : organisation
    };
}


export function searchOrganisation(organisation) {
    return {
        type : type.SEARCH_ORGANISATION,
        payload : organisation
    };
}


export function storeSearchOrganisationResult(organisation) {
    return {
        type : type.STORE_SEARCH_ORGANISATION_RESULT,
        payload : organisation
    };
}


export function deleteOrganisation(organisation) {
    return {
        type : type.DELETE_ORGANISATION,
        payload: organisation
    };
}


export function deletedOrganisation(msg) {
    return {
        type : type.DELETED_ORGANISATION,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_ORGANISATION,
        payload: props
    };
}