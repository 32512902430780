import { createSelector } from "reselect";
import { chain, cloneDeep,difference } from "lodash";
import { role,permissionOptionsArray } from "./model";

export const getRole = (state, props) => {
  if (props.match.path === "/roles/create") {
    return role();
  }
  if (props.match.path === "/roles/edit/:id") {
    let id = props.match.params.id;
    let obj = cloneDeep(state.roles.byId[id]);
    return obj;
  }
};

export const getRoleList = (state) => state.roles.byId;
export const getPermissionList = (state) => state.roles.permissionById;

export const getRoleArray = createSelector(getRoleList, (role) => {
  return chain(Object.values(role))
    .sortBy((item) => item.roleName)
    .value();
});


export const getPermissionInRole = (state, roleId) => {
  const permissions = state.roles.permissionById;
  const permissionsArray = Object.values(permissions);
  return permissionsArray.filter((p) => p.roleId === roleId);
};

export const getPermissionOptions=(state,roleId)=>{
  let permissionList = getPermissionInRole(state,roleId)
  let permissionListArray = permissionList.map((p) => p.moduleName);
  let opt = difference(permissionOptionsArray, permissionListArray);
  let permissionOptions = opt.map((o) => ({ key: o, text: o, value: o }));
  return permissionOptions
} 
