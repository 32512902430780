import React,{useEffect,useState} from 'react'
import { Header, Button, Modal, Grid, Message, Container, Form } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikPasswordComponent } from '../../../utilities/formUtils';
import { changingPassword,changePassword } from '../../login/data/actions'
import * as select from '../../login/data/selectors'
import * as Yup from 'yup';


const schema = Yup.object({
    password: Yup
      .string()
      .required('Please Enter your password')
      .min(8, 'Must be minimum 8 characters'),
    confirmPassword: Yup
      .string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  });

const PasswordChangeModal = ({userId,pageFrom}) => {
  const [open, setOpen] = useState(false)


  // const job = createJob()
  const dispatch = useDispatch()
// const listOfUsers = (workroom)
const data = {id : userId, password: '', confirmPassword:''}
const getchangedPwMsg = useSelector(state => select.getchangedPwMsg(state));
const ischangingPassword = useSelector(state => select.changingPassword(state));
  const savePassword = (values, resetForm) => {
    const obj =
      {
        'id' : values.id,
        'password' :values.password
      }
      console.log(' Editing Password  is ', obj)
      dispatch(changingPassword(true,values.changedPassword))
       dispatch(changePassword(obj))
        setTimeout(function(){ 
          dispatch(changingPassword(false,values.changedPassword))
          setOpen(false)
        }, 1200);
     /*  setTimeout(function () {
        setOpen(false)
      }, 2000); */

  }


  return (
    <Modal
      closeIcon
      size='mini'
      dimmer = 'inverted'
      open={ischangingPassword ? true : open}
      /* trigger = {
        <div className="paddingLeft15 paddingBottom10 paddingTop10"> 
        <Button color="blue" size="large" className="FontHindBold"> Create New Ticket </Button>
        </div>
      } */
      trigger={pageFrom == "userList" ? <span style={{cursor: 'pointer', color: 'green',"margin-left":"15px"}} >Reset Password</span> : <span className="fontSize18 pointer titleHead" color='blue'>Change Password</span>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      {/* <Header content='Create New Ticket' /> */}
      <Modal.Content>
      <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column >
                    <Formik id="passwordForm" 
                        initialValues={data}
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => savePassword(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                                    <Field  name="password" label = "New Password (Minimum 8 characters)" component={FormikPasswordComponent}></Field>
                                    <Field  name="confirmPassword" label = "Confirm Password" component={FormikPasswordComponent}></Field>
                                    {/* <Field  name="description" label = "Description" component={FormikTextAreaComponent}></Field> */}
                                
                               <Button type="submit" floated="right" color="blue" size="medium" className="CustomeBTN"> Submit </Button> 
                               <Button floated="right" color="grey" size="medium" className="CustomeBTN" onClick={() => setOpen(false)}> Cancel </Button> 
                            </Form>
                        )}
                    />
                    
                </Grid.Column>
                      
            </Grid>
            {(getchangedPwMsg == "success") ?
                        // <Notification id={savedActivityId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                        <Message positive style = {{ zIndex: 100}}>
                        <Message.Header>Password Change Form submitted successfully</Message.Header>
                      </Message>
                        : 
                    null}  
        </Container>
      </Modal.Content>
    </Modal>
  )
}
export default PasswordChangeModal