import { combineReducers } from 'redux';
import { merge, deepClone } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a taskdetailss schema
const taskdetailsSchema = new schema.Entity('taskdetails',{}, { idAttribute: 'antId' });
const taskdetailsListSchema = [taskdetailsSchema];



const defaultTaskdetailsParams = {
  taskdetailsFetched: false,
  searchFetched: false,
  isDelFetched: false,
  createTitle: 'Create New Taskdetails',
  createSubmitButtonText: 'Create Taskdetails',
  editTitle: 'Edit Taskdetails',
  editSubmitButtonText: 'Edit Taskdetails' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_TASKDETAILS') {
      const jobId = action.payload.jobId;
      const antId = action.payload.antId
      let existingarray = (state[jobId]) ? state[jobId] : []

      // this could already be there in the selector. Hence filtering out old object and putting in new
     /*  existingarray = existingarray.filter(function( obj ) {
        return obj.antId !== antId;
    }); */

    return {...state, 
      [jobId]: {...existingarray, [antId] : action.payload}
    } 
      
    }
    if (action.type === 'FETCHED_TASKDETAILS_BY_JOB') { 
      let data = action.payload
      if (data.length > 0) {
          const jobId = data[0].jobId
          const normalizedComment = normalize(action.payload, taskdetailsListSchema);
          let newobj = merge({},state[jobId],normalizedComment.entities.taskdetails)

        return {...state, 
          [jobId]: newobj
        }
      } else return state
    }     
    if (action.type === 'DELETED_TASKDETAILS') {
      if(action.payload.msg === "Success"){
        const taskdetailsID = action.payload.id;
        return {...state, 
          [taskdetailsID]: { ...state[taskdetailsID],
            status :0
          }
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_TASKDETAILS_RESULT') { 
    const normalizedTaskdetails = normalize(action.payload, taskdetailsListSchema);
    return merge({},normalizedTaskdetails.entities.taskdetailss)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_TASKDETAILS') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_TASKDETAILS'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultTaskdetailsParams, action) {
  if (action.type === 'FETCHED_TASKDETAILS') { 
      return {...state, 
        taskdetailsFetched: true,
        isFetching:false
      }
  }   if (action.type === 'SEARCH_TASKDETAILS') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_TASKDETAILS_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_TASKDETAILS') { 
    return {...state, 
      isDelFetched:true
    }
  }if(action.type === 'SET_TASKDETAILS_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

const taskdetails = combineReducers({
    byId,
    notifications,
    searchResults,
    params
});

export default taskdetails;

