import * as type from './types'
import { URL } from '../../../store/path';


export function createComments(comments) {
    return {
        type : type.CREATE_COMMENTS,
        payload : comments,
        txn : 'initiated',
    };
}


export function savedComments(comments) {
    // console.log ( ' In save comments the comment returned from saga is ', comments)
    return {
        type : type.SAVED_COMMENTS,
        payload : comments,
        txn : 'success',
        diff : true
    };
}


export function editComments(comments) {
    return {
        type : type.EDIT_COMMENTS,
        payload : comments,
        txn : 'initiated',
    };
}


export function fetchComments(workroomId) {
    return {
        type : type.FETCH_COMMENTS,
        payload: workroomId
    };
}

export function fetchCommentsByJob(jobId) {
    return {
        type : type.FETCH_COMMENTS_BY_JOB,
        payload: jobId
    };
}


export function fetchedComments(comments) {
    return {
        type : type.FETCHED_COMMENTS,
        payload : comments
    };
}

export function fetchSignedUrl(fileid) {
    return {
        type : type.FETCH_SIGNED_URL,
        payload: fileid
    };
}


export function fetchedSignedUrl(signedurl) {
    return {
        type : type.FETCHED_SIGNED_URL,
        payload : signedurl
    };
}


export function uploadFile(filedata) {
    return {
        type : type.UPLOAD_FILE,
        payload: filedata
    };
}


export function uploadedFile(filedata) {
    return {
        type : type.UPLOADED_FILE,
        payload : filedata
    };
}


export function searchComments(comments) {
    return {
        type : type.SEARCH_COMMENTS,
        payload : comments
    };
}


export function storeSearchCommentsResult(comments) {
    return {
        type : type.STORE_SEARCH_COMMENTS_RESULT,
        payload : comments
    };
}


export function deleteComments(comments) {
    return {
        type : type.DELETE_COMMENTS,
        payload: comments
    };
}


export function deletedComments(msg) {
    return {
        type : type.DELETED_COMMENTS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_COMMENTS,
        payload: props
    };
}