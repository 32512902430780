import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'
import { workroom } from '../../workRoom/data/model'
import { process, processSchema } from '../../process/data/model'


export const jobs = () => ({
    id: v4(),
    workroomId: "",
    processId: "",
    processKey: null,
    wfProcessDefinitionId: "",
    wfProcessInstanceId: "",
    wfCurrentActivityId: "",
    wfCurrentActivityName: "",
    wfCurrentTransitionId: "",
    wfActivityInstanceId: null,
    jobKey: "",
    jobName: "",
    teamId: "",
    userId :"",
    queueId: null,
    priority: null,
    deadline: null,
    initialDeadline: null,
    dtStart: "",
    dtFinish: null,
    jobStatus: 1,
    achivement: null,
    resolution: null,
    description: "",
    status: 1,
    dtCreate: "",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const jobsSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),
    jobName: Yup.string()
                .required('This is a Required Field')
                .max(50, 'Must be less than 50 characters'),
    workroomId: Yup.string()
                .required('This is a Required Field'),
    processKey: Yup.string()
                .required('This is a Required Field'),
    userId: Yup.string()
                .required('This is a Required Field'),
    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });


  export const jobsCreateSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),
    workroomId: Yup.object(workroom)
                .required('This is a Required Field'),
    processId: Yup.object(process)
                .required('This is a Required Field'),
    userId: Yup.string()
                .required('This is a Required Field'),
    jobName: Yup.string()
                .required('This is a Required Field')
                .max(50, 'Must be less than 50 characters'),
    
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });


  export const jobNameCheckSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),
    jobName: Yup.string()
                .required('This is a Required Field')
                .max(50, 'Must be less than 50 characters'),
  });


export const duplicateCheckJobsCode = debounce(checkJobsCode, 500);
function checkJobsCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/jobs/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckJobsName = debounce(checkJobsName, 500);
function checkJobsName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/jobs/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  