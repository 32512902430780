import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsScheduledprocessFetched } from './selectors'
import {savedScheduledprocess,fetchedScheduledprocess, storeSearchScheduledprocessResult, deletedScheduledprocess } from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import apiContainer, {getHeaderJWT, URL} from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import { withSuccess } from 'antd/lib/modal/confirm';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Scheduledprocess 

function* createScheduledprocess() {
    yield takeEvery('CREATE_SCHEDULEDPROCESS',saveScheduledprocess)
  }

function* saveScheduledprocess(action){
  const { response, error } = yield call(saveScheduledprocessAPI, action.payload)
  console.log("Saga",action.payload)
  if (response) 
  try {
    yield put (savedScheduledprocess(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveScheduledprocessAPI(data) {
    return axios.post(URL+'/scheduled-job/create', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Scheduledprocess 

function* editScheduledprocess() {
  yield takeEvery('EDIT_SCHEDULEDPROCESS',saveEditedScheduledprocess)
}

function* saveEditedScheduledprocess(action){
  const { response, error } = yield call(editScheduledprocessAPI, action.payload)  
  if (response) 
  try {
    yield put (savedScheduledprocess(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editScheduledprocessAPI(data) {
  let user_id = data._id
  return axios.post(URL+'/scheduled-job/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Scheduledprocess 

function* deleteScheduledprocess() {
  yield takeEvery('DELETE_SCHEDULEDPROCESS',saveDeleteScheduledprocess)
}

function* saveDeleteScheduledprocess(action){
  const { response, error } = yield call(deleteScheduledprocessAPI, action.payload)
  if (response) 
  try {
    // console.log("after delete data: ==",response.data,"=====payloadid====",action.payload.id)
    yield put (deletedScheduledprocess({id: action.payload.id, msg : response.data}))

  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteScheduledprocessAPI(data) {
  return axios.post(URL+'/scheduled-job/delete/', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Scheduledprocess 

function* fetchScheduledprocess() {
  yield takeEvery('FETCH_SCHEDULEDPROCESS',requestScheduledprocess)
}
  

function* requestScheduledprocess(action){
  const isScheduledprocessFetched = yield select(getIsScheduledprocessFetched);
  if(!isScheduledprocessFetched){
    let { data } = yield call(requestScheduledprocessAPI)
    try {
      // console.log("fetch New List==============",data)
        yield put (fetchedScheduledprocess(data))
      } catch(error) {
        sagaErrorMessage(error, action)
      }
  }
}


function requestScheduledprocessAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.get(URL+'/scheduled-job/list', getHeaderJWT())
}

//#endregion


//#region Saga for Search Scheduledprocess 

function* searchScheduledprocess() {

  yield takeLatest('SEARCH_SCHEDULEDPROCESS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchScheduledprocessResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/scheduledprocess/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* scheduledprocessSaga() {
    yield all([
        createScheduledprocess(),
        fetchScheduledprocess(),
        editScheduledprocess(),
        deleteScheduledprocess(),
        searchScheduledprocess()
    ])
  }  