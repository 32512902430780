import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import { useSelector, useDispatch } from "react-redux";
import { Grid, Accordion, Label } from "semantic-ui-react";
import StepsListForLeftPanel from "./stepsListForLeftPanel";
import StepsListForWorkroomPanel from "./stepsListForWorkroomPanel";
import { getMyListWorkroom } from "../../workRoom/data/selectors";
import { getIsJobsFetched } from "../../jobs/data/selectors";
import { fetchMyListWorkroom } from "../../workRoom/data/actions";
import JobsCreateModal from "../../jobs/components/jobsCreateModal";
import JumpToJobTextBox from "../../jobs/components/jumpToJobTextBox";
import { fetchQueueJobs, fetchMyCreatedJobs } from "../../jobs/data/actions";
import { parseJwt } from "../../../utilities/parseJWT";
import { getTeamsLedByUser } from "../../users/data/selectors";
import { getTotalUnreadMessages } from "../../app/data/selectors";

const MenuDefault = (props) => {
  const dispatch = useDispatch();

  const myListWorkroom = useSelector((state) => getMyListWorkroom(state));
  const myListWorkroomFetched = useSelector(
    (state) => state.workroom.myListWorkroomFetched
  );
  const token = localStorage.getItem("user");
  const userid = token ? parseJwt(token).identity : null;
  const myqueueFetched = useSelector((state) => getIsJobsFetched(state, props));
  const teamLed = useSelector((state) => getTeamsLedByUser(state, userid));
  const totalUnreadMessages = useSelector((state) =>
    getTotalUnreadMessages(state, myListWorkroom)
  );
  const accordionHeight = props.accordionHeight;

  useEffect(() => {
    if (!myqueueFetched && userid) {
      dispatch(fetchQueueJobs({ userId: userid }));
      dispatch(fetchMyCreatedJobs({ userId: userid }));
    }
  }, [userid, myqueueFetched]);

  useEffect(() => {
    if (!myListWorkroomFetched) {
      dispatch(fetchMyListWorkroom());
    }
  }, [myListWorkroomFetched]);

  const menuPanels = (props) => {
    let teamPanel = [];
    let mytaskspanel = [
      {
        key: "menu1",
        title: {
          content: (
            <>
              <Link
                className="titleHead colorMenu"
                to={{ pathname: "/app/landing" }}
              >
                My Tasks
              </Link>
            </>
          ),
        },
      },
    ];
    let wpanel = [
      {
        key: "menu2",
        title: {
          content: (
            <>
              <span className="titleHead">
                Workrooms &nbsp;
                {totalUnreadMessages && totalUnreadMessages != 0 ? (
                  <Label circular color="red" size="tiny" float="right">
                    {totalUnreadMessages}
                  </Label>
                ) : null}
              </span>
            </>
          ),
        },
        content: {
          content: (
            <div className="paddingLeft24">
              <StepsListForWorkroomPanel isEdit="show" data={myListWorkroom} />
            </div>
          ),
        },
      },
    ];
    // let myjobpanel = createJobListPanel()

    if (teamLed && teamLed.length > 0) {
      teamPanel = [
        {
          key: "menu3",
          title: { content: <span className="titleHead">Manage Team</span> },
          content: {
            content: (
              <div className="paddingLeft24">
                {/* <div > */}
                <StepsListForLeftPanel
                  isEdit="show"
                  data={teamLed}
                  pathToLink="/team/view/"
                  displayField="teamName"
                />
              </div>
            ),
          },
        },
      ];
    }
    let newpanel = mytaskspanel.concat(wpanel).concat(teamPanel);

    return newpanel;
  };

  // if (!myqueue) return null

  return (
    <>
      <Grid className=" paddingLeft15 paddingBottom10 paddingTop10 paddingRight15">
        <Grid.Column width={10} className="paddingright0">
          <JobsCreateModal path="/workroom/create" />
        </Grid.Column>
        <Grid.Column width={6} className="paddingLeft0">
          <JumpToJobTextBox />
        </Grid.Column>
      </Grid>
      <div
        className="outerDivClassForForm"
        style={{ height: accordionHeight + "px" }}
      >
        <Accordion
          exclusive={false}
          className="boxNone menuCell"
          styled
          panels={menuPanels()}
        />
      </div>
    </>
  );
};

export default withRouter(MenuDefault);
