import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedClient } from '../data/actions'
import * as select from '../data/selectors'
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'


const ClientSelect = ({ name, isTxn, required, isLabel, label, isSelection, multiple, getValue : callback }, props) => {

    const client = useSelector(state => state.client.byId)
    const clientFetched = useSelector(state => select.getIsClientFetched(state, props))
    const options = useSelector(state => select.selectClient(state, props))
    const dispatch = useDispatch()
    // console.log(client, "==client");
    // console.log(options, "==options");
    
    useEffect(()=>{
        if (!clientFetched)
            dispatch(fetchedClient(client));
    },[]);  
 

    const getValue = (values) => {
        // console.log("Client Selected", values, name)
    }


    if (!clientFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Field name={name} 
                isLabel={isLabel}
                required = {required} 
                isTxn={isTxn} 
                label={label}
                multiple={multiple}
                component={FormikSelectComponent} 
                userProps={{ options, getValue }}> 
            </Field>
        )
    }

}


export default ClientSelect;
