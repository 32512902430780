import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a commentss schema
const commentsSchema = new schema.Entity('comments',{}, { idAttribute: 'id' });
const commentsListSchema = [commentsSchema];

const defaultCommentsParams = {
  commentsFetched: false,
  searchFetched: false,
  isDelFetched: false,
  signedUrl : "",
  createTitle: 'Create New Comments',
  createSubmitButtonText: 'Create Comments',
  editTitle: 'Edit Comments',
  editSubmitButtonText: 'Edit Comments' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_COMMENTS') {
        const workroomId = action.payload.workroomId;
        let id = action.payload.id
        let existingarray = (state[workroomId]) ? state[workroomId] : []
        // existingarray = 
        // console.log (' Existing arays is:', existingarray)
      return {...state, 
        [workroomId]: {...existingarray, [id] : action.payload}
      } 
      
    }
    if (action.type === 'FETCHED_COMMENTS') { 
      let responsedata = action.payload.responseData
      let requestdata = action.payload.requestData
     
          const workroomId = requestdata.workroomId
          const normalizedComment = normalize(responsedata, commentsListSchema);
          // console.log(' Normalise comment is ', normalizedComment)
          let newobj = merge({},state[workroomId],normalizedComment.entities.comments)
   
        return {...state, 
          [workroomId]: newobj
        }
     
    }     
    if (action.type === 'DELETED_COMMENTS') {
      if(action.payload.msg === "Success"){
        const commentsID = action.payload.id;
        return {...state, 
          [commentsID]: { ...state[commentsID],
            status :0
          }
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_COMMENTS_RESULT') { 
    const normalizedComments = normalize(action.payload, commentsListSchema);
    return merge({},normalizedComments.entities.commentss)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_COMMENTS') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_COMMENTS'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultCommentsParams, action) {
  if (action.type === 'FETCHED_COMMENTS') { 
      return {...state, 
        commentsFetched: true,
        isFetching:false
      }
  }   
  if (action.type === 'FETCHED_SIGNED_URL') { 
    return {...state, 
      signedUrl: action.payload,
      // isFetching:false
    }
} 
  if (action.type === 'SEARCH_COMMENTS') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_COMMENTS_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_COMMENTS') { 
    return {...state, 
      isDelFetched:true
    }
  }if(action.type === 'SET_COMMENTS_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

const comments = combineReducers({
    byId,
    notifications,
    searchResults,
    params
});

export default comments;

