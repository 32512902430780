import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {fetchOrganisation, deleteOrganisation, setNotifyDone } from '../data/actions'
import * as select from '../data/selectors'
import { Container, Table, TableHeader, TableBody, Header } from 'semantic-ui-react'
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
    

const Organisation = props => {
    const dispatch = useDispatch()
    const isNegative = props.organisation.status === 1 ? false : true;
    
    const [deleteOrganisationId, setDeleteOrganisationId] = useState(false);
    
    const deleteOrganisationFromList = (values) => {
        const getValue = {id: values}
        userACL.atUpdate(getValue)
        dispatch(deleteOrganisation(getValue))
        setDeleteOrganisationId(values);
    }
    return (

        <Row>
            <Cell style={{width: '30%'}} className='borderNoneObj borderBottomObj'>
                {props.organisation.name}
            </Cell>
            <Cell style={{width: '30%'}} className='borderNoneObj borderBottomObj'>
                {props.organisation.location}
            </Cell>
            <Cell style={{width: '15%'}} className='borderNoneObj borderBottomObj'>
                {props.organisation.mobile}
            </Cell>
            <Cell style={{width: '15%'}} className='borderNoneObj borderBottomObj'>
                {props.organisation.email}
            </Cell>
            <Cell style={{width: '10%'}} className='borderNoneObj borderBottomObj'>
                {isNegative === false ? 
                    <>
                        <Link to={{pathname: "/organisation/edit/"+props.organisation.id}}>Edit</Link><span>&nbsp;&nbsp;</span>
                        <span style={{cursor: 'pointer', color: 'red'}} onClick={() => deleteOrganisationFromList(props.organisation.id)}>Delete</span>
                    </>
                :
                    <span>Deleted</span>
                }
                <>
                {
                    deleteOrganisationId ? 
                    <Notification id={deleteOrganisationId} notifySelector={select.getNotification} notifyDoneAction={setNotifyDone} type='delete'/>
                    :
                    null
                }
                </>
            </Cell>
        </Row>
)}

const OrganisationList = (props) => {
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }) 

    const organisation = useSelector(state => state.organisation.byId) 
    const organisationFetched = useSelector(state => select.getIsOrganisationFetched(state, props))
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchOrganisation());
    });        

    // Loading Logic if tasks not fetched
    return (
        <Container>
            <Header as='h1' className="padding55" id="headDiv" align='left'>Organisation List</Header>
            {organisationFetched === false ? 
            <p>Loading ... </p> 
            :
                <div id="outerDiv" className='paddingRight20 outerDivClass'  style={{width: widths+"px"}}>
                    <div style={{height: (heights-20)+'px', width: "100%"}}>
                        <StickyTable className="tableLayOut padding55" stickyHeaderCount={1}>
                            <Row style={{widths: widths+"px"}}>
                                <Cell style={{width: '30%'}} className='borderNoneObj head borderBottomObj'>
                                    Name
                                </Cell>
                                <Cell style={{width: '30%'}} className='borderNoneObj head borderBottomObj'>
                                    Location
                                </Cell>
                                <Cell style={{width: '15%'}} className='borderNoneObj head borderBottomObj'>
                                    Mobile
                                </Cell>
                                <Cell style={{width: '15%'}} className='borderNoneObj head borderBottomObj'>
                                    Email
                                </Cell>
                                <Cell style={{width: '10%'}} className='borderNoneObj head borderBottomObj'>
                                    Action
                                </Cell>
                            </Row>
                            { Object.keys(organisation).map((key) => 
                                <Organisation organisation={organisation[key]} key={organisation[key].id} /> )}
                        </StickyTable>
                    </div>
                </div>
            }   
        </Container>
    )
}

export default OrganisationList;
