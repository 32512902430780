import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "semantic-ui-react";
import * as select from "../data/selectors";
import { TableList } from "../../../utilities/tableUtils";
import { searchColumns } from "../data/model";
import { fetchRole } from "../data/action";
import { updateHeaderTitle } from "../../app/data/actions";
import { heightSet } from "../../../utilities/heightForListing";

function RoleList(props) {
  const dispatch = useDispatch();
  const [heights, setHeight] = useState("");
  const role = useSelector((state) => select.getRoleArray(state));

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Role List" }));
  }, []);

  useEffect(() => {
    heightSet(setHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
      },
      false
    );
  }, []);

  const RoleActions = ({ object: role }) => {
    return (
      <>
        <Link to={"/roles/edit/" + role.id}>Edit</Link>
      </>
    );
  };

  return (
    <Container>
      <div id="outerDiv" className="outerDivClass paddingRight20">
        <div style={{ height: heights - 20 + "px", width: "100%" }}>
          <TableList
            columns={searchColumns}
            data={role}
            Actions={RoleActions}
          ></TableList>
        </div>
      </div>
    </Container>
  );
}

export default RoleList;
