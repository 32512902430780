import React from 'react';
import { Route, withRouter } from 'react-router';
import ProcessList from "./components/processList";
import ProcessFormCreate from "./components/processFormCreate";
import ProcessFormEdit from "./components/processFormEdit";
import ProcessDispContainer from './components/processDispContainer'
import ProcessfileForm from "../processfile/components/processfileForm";
import StateForm from "./components/state";
// import StepForm from "../steps/components/stepsForm";
import { Container } from 'semantic-ui-react'


const Process = () => {
    return (
        <Container>
            <Route path="/process/list" component={ProcessList} />
            <Route path="/process/summary" component={ProcessDispContainer} />
            <Route path="/process/edit/:id" component={ProcessDispContainer} />
            <Route path="/process/activity/:id" component={ProcessDispContainer} />
            <Route path="/process/processfile/create" component={ProcessDispContainer} />
            <Route path="/process/processfile/:id" component={ProcessDispContainer} />
            <Route path="/process/activity/list" component={ProcessDispContainer} />
            <Route path="/process/create" component={ProcessFormCreate} />
            {/* <Route path="/processfile/create" component={ProcessfileForm} /> */}
            <Route path="/process/state" component={StateForm} />
            {/* <Route path="/process/step" component={ProcessForm} /> */}
        </Container>
    )
};

export default Process;

