import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Comment, Label, Container } from 'semantic-ui-react'
import {fetchComments} from '../../comments/data/actions'
import { components } from 'react-select';
import { getAttachmentsByWorkroomId, getAttachmentsByJobAndWorkroomId} from '../data/selectors'
import { heightSet, widthSet, innerHeightSet, setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { selectUsers } from '../../users/data/selectors'
import { handleFileDownload } from './commentsUtils'


const getReadableDate = (longdate) => {
  let jdate = new Date(longdate / 1000000)
  let str = jdate.toLocaleString()
  return str
}

const getUserName = (userList, userid) => {
  if (userList == undefined || userList.length == 0) return null
  let userobj = userList.find((item) => item.value == userid)
  if (userobj == undefined) return null
  return userobj.text
}

const AttachmentsDisplayWorkroom = (props) => {

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [innerCommentsHeights, setInnerCommentHeight] = useState('')
  const [widths, setWidth] = useState('')

  // const jobId = props.job ? props.job.id : undefined
  const workroomId = props.workroomId ? props.workroomId : undefined
  const attachmentsRecd = useSelector(state => getAttachmentsByWorkroomId(state, workroomId)) 
  const userList = useSelector(state => selectUsers(state, props))


  useEffect(() => {
    setMenuHeight(setHeight)
    widthSet(setWidth)
    menuAccordionHeight(setInnerHeight)


    // setMainContainerHeight(setMainComtainerHeight)
    window.addEventListener('resize', function(){
        setMenuHeight(setHeight)
        widthSet(setWidth)
        menuAccordionHeight(setInnerHeight)
        // heightSetFotTaskForm(setInnerCommentHeight)
    }, false);
}) 


const dispatch = useDispatch()
    
    
    useEffect(() => {
       
        if(!attachmentsRecd){
            // console.log ( ' comments not recd is ', commentsRecd)
            dispatch(fetchComments({'workroomId': workroomId, 'dtCreated': 0}));
        }
    },[attachmentsRecd]);


    function renderAttachment(comment, i) {
      let attachIds = (comment.attachmentId && comment.attachmentId !='') ? comment.attachmentId.split('||') : undefined
      let attachNames = (comment.attachmentName && comment.attachmentName !='') ? comment.attachmentName.split('||') : undefined
      let commentString = (attachIds && attachIds.length > 1 ? 'Files Uploaded: ' : 'File Uploaded: ')

      return (
        <Comment key={comment.dtCreate} className='paddingBottom10'>
          <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
          <Comment.Content>
            <Comment.Author>{getUserName(userList, comment.userId)}
              <Comment.Metadata>
                {getReadableDate(comment.dtCreate)}
              </Comment.Metadata>
            </Comment.Author>
              <Comment.Text>
                {comment.comment == '' ? commentString : <div dangerouslySetInnerHTML={{__html: comment.comment}} />}
                { attachIds.length > 1 ? <br /> : null}
                
              {attachIds.map(( attachmentid, i ) => 
             <>
              <Label as ='a' basic color='blue' className='borderNoneObj' id={comment.id} onClick= {()=>handleFileDownload(attachmentid)} >
                {attachNames[i]}
                
              </Label>
              <br />
              </>
              )}  

              </Comment.Text>
          </Comment.Content>
        </Comment>

      )
    }


  if (!attachmentsRecd) return null
  let data = attachmentsRecd
  return (
    <Container>
    {/* <div id="mainDiv" className='borderNoneObj'  style={{height: (innerHeights- 130)+'px', widths: widths+"px"}}> */}
        <div  id = 'comments' className="outerDivClass hideScroll paddingLeft20" style={{height: (innerHeights + 80)+'px', widths: widths+"px"}}>
    <Comment.Group >
      {data.map((comment) => (
          renderAttachment(comment)
        
      ))
      }

    </Comment.Group>
    </div>
    {/* </div> */}
    </Container>
  )
}



export default AttachmentsDisplayWorkroom;
