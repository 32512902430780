import React from 'react';
import { Route, withRouter } from 'react-router';
import OrganisationList from "./components/organisationList";
import OrganisationForm from "./components/organisationForm";
import { Container } from 'semantic-ui-react'


const Organisation = () => {
    return (
        <Container>
            <Route path="/organisation/list" component={withRouter(OrganisationList)} />
            <Route path="/organisation/edit/:id" component={OrganisationForm} />
            <Route path="/organisation/create" component={OrganisationForm} />
        </Container>
    )
};

export default Organisation;

