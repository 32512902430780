import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Table, TableBody, } from 'semantic-ui-react';
import moment from 'moment'
import {getProcessNameFromId} from '../../process/data/selectors'
import { getActiveTask } from '../data/selectors'
import { selectUsers } from '../../users/data/selectors'

const getUserName = (userList, userid) => {
    if (userList == undefined || userList.length == 0) return null
    let userobj = userList.find((item) => item.value == userid)
    if (userobj == undefined) return null
    return userobj.text
  }


const JobDetailsForTaskRightPanel = (props) => {

    let job = props.job
    let processid = job ? job.processId :null
    const userList = useSelector(state => selectUsers(state, props))
    const processname = useSelector(state => getProcessNameFromId(state, processid))
    const currActiveTask = useSelector (state => getActiveTask(state, job))

   if (!job) return null
    return (
        <Container>
            <Table selectable basic='very'>
                <TableBody>
                         <Table.Row  >
                         <Table.Cell >
                             Assigned to:&nbsp; 
                            <span className="FontHindBold">
                              {currActiveTask.userId ? getUserName(userList, currActiveTask.userId ): null}
                            </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell >
                             Owned by:&nbsp; 
                            <span className="FontHindBold">
                                {job.ownerId ? getUserName(userList, job.ownerId ): "-"}
                            </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell >
                            Priority:&nbsp;
                            <span className="FontHindBold">
                             {job.priority}
                             </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                                Deadline:&nbsp;
                            <span className="FontHindBold">
                             {job.deadline ? moment(job.deadline).format('DD-MMM-YY') : null}
                             </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Original Deadline:&nbsp;
                            <span className="FontHindBold">
                              {job.initialDeadline ? moment(job.initialDeadline).format('DD-MMM-YY') : null}
                            </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                                Process:&nbsp;
                            <span className="FontHindBold">
                             {processname}
                             </span> 
                         </Table.Cell>
                        </Table.Row>
                </TableBody>
            </Table>
        </Container>  
    )
}


export default JobDetailsForTaskRightPanel;