import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { Formik, Form as FormikForm, Field } from "formik";
import RoleSelect from "./RoleSelect";
import {
  createPermission,
  fetchPermission,
  removePermission,
} from "../data/action";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { BasicTableList } from "../../../utilities/tableUtils";
import { FormikCheckBoxComponent } from "../../../utilities/formUtils";
import { permissionModel, permissionSchema } from "../data/model";
import * as select from "../data/selectors";

function RolesPermission(props) {
  const { roleId } = props;
  const [permission, setPermission] = useState({
    moduleName: "",
    roleId: roleId,
    canCreate: 0,
    canUpdate: 0,
    canDelete: 0,
    canExport: 0,
    id: v4(),
  });

  const dispatch = useDispatch();
  const permissionList = useSelector((state) =>select.getPermissionInRole(state, roleId));
  console.log(permissionList);
  const permissionOptions = useSelector((state) =>select.getPermissionOptions(state, roleId));

  useEffect(() => {
    dispatch(fetchPermission());
    setPermission(permissionModel(roleId));
  }, []);

  const savePermission = (values) => {
    values.canCreate = values.canCreate ? 1 : 0;
    values.canUpdate = values.canUpdate ? 1 : 0;
    values.canDelete = values.canDelete ? 1 : 0;
    values.id = v4();
    dispatch(createPermission(values));
  };

  const removePermissionFromList = (permissionList) => {
    dispatch(removePermission(permissionList));
  };

  return (
    <>
      <div>
        <br />
        <br />
        <h3>Add a New Permission</h3>
        <Grid>
          <Grid.Column style={{ width: "80%" }}>
            <Formik
              initialValues={permission}
              validationSchema={permissionSchema}
              onSubmit={(values, { resetForm }) =>
                savePermission(values, resetForm)
              }
              render={({ handleSubmit }) => (
                <Form as={FormikForm} onSubmit={handleSubmit}>
                  <Form.Group widths={3}>
                    <RoleSelect
                      name="moduleName"
                      isLabel="false"
                      label="Permission"
                      options={permissionOptions}
                    />
                    <Field
                      name="canCreate"
                      label="Create"
                      component={FormikCheckBoxComponent}
                    />
                    <Field
                      name="canUpdate"
                      label="Update"
                      component={FormikCheckBoxComponent}
                    ></Field>
                    <Field
                      name="canDelete"
                      label="Delete"
                      component={FormikCheckBoxComponent}
                    ></Field>
                    <Button
                      type="submit"
                      floated="left"
                      color="blue"
                      size="small"
                    >
                      Add Permission
                    </Button>
                  </Form.Group>
                </Form>
              )}
            />
          </Grid.Column>
        </Grid>
        <br />
        <br />
      </div>
      <div style={{ height: "40vh", width: "95%", overflowY: "auto" }}>
        <BasicTableList
          columns={[
            { Header: "ModuleName", accessor: "moduleName" },
            {
              Header: "Create",
              accessor: "canCreate",
              disableFilters: true,
            },
            {
              Header: "Update",
              accessor: "canUpdate",
              disableFilters: true,
            },
            {
              Header: "Delete",
              accessor: "canDelete",
              disableFilters: true,
            },
          ]}
          data={permissionList}
          Actions={removePermissions}
          fn={{ removePermissionFromList }}
        />
      </div>
    </>
  );
}

const removePermissions = ({ object: permissionList, fn }) => {
  console.log(permissionList);
  return (
    <>
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={() => fn.removePermissionFromList(permissionList)}
      >
        Remove
      </span>
      <span>&nbsp;&nbsp;</span>
    </>
  );
};

export default RolesPermission;
