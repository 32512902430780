import { call, takeEvery, all, put, select } from "redux-saga/effects";
import { handlError, parseError } from "../../app/serverError";
import {
  fetchedRole,
  savedPermission,
  savedRole,
  fetchedPermission,
  removedPermission,
} from "./action";
import axios from "axios";
import { getHeaderJWT, URL } from "../../../store/path";
import { toast } from "react-toastify"

function* createRole() {
  yield takeEvery("CREATE_ROLE", saveRole);
}

function* saveRole(action) {
  const { response, error } = yield call(saveRoleAPI, action.payload);
  if (response) {
    try {
      yield put(savedRole(action.payload));
      toast.success("Role Created")
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Role Creation Failed")
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function saveRoleAPI(data) {
  return axios
    .post(URL + "/roles/create", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* fetchRole() {
  yield takeEvery("FETCH_ROLE", requestRole);
}

function* requestRole(action) {
  const { response, error } = yield call(requestRoleAPI);
  if (response) {
    try {
      yield put(fetchedRole(response.data));
      
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function requestRoleAPI() {
  return axios
    .get(URL + "/roles/list", getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* editRole() {
  yield takeEvery("EDIT_ROLE", saveEditedRole);
}

function* saveEditedRole(action) {
  const { response, error } = yield call(editRoleAPI, action.payload);
  if (response) {
    try {
      yield put(savedRole(action.payload));
      toast.success("Role Updated Successfully")
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Role Update Failed")
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function editRoleAPI(data) {
  return axios
    .post(URL + "/roles/update", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* createPermission() {
  yield takeEvery("CREATE_PERMISSION", savePermission);
}

function* savePermission(action) {
  const { response, error } = yield call(savePermissionAPI, action.payload);
  if (response) {
    try {
      yield put(savedPermission(action.payload));
      toast.success("Permission Created")
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Permission Creation Failed")
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function savePermissionAPI(data) {
  return axios
    .post(URL + "/permissions/create", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* fetchPermission() {
  yield takeEvery("FETCH_PERMISSION", requestPermission);
}

function* requestPermission(action) {
  const { response, error } = yield call(requestPermissionAPI);
  if (response) {
    try {
      yield put(fetchedPermission(response.data));
  
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function requestPermissionAPI() {
  return axios
    .get(URL + "/permissions/list", getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* removePermission() {
  yield takeEvery("REMOVE_PERMISSION", deletePermission);
}

function* deletePermission(action) {
  const { response, error } = yield call(deletePermissionAPI, action.payload);
  if (response) {
    try {
      yield put(removedPermission(action.payload));
      toast.success("Permission Deleted")
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Permission Delete Failed")
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function deletePermissionAPI(data) {
  return axios
    .post(URL + "/permissions/delete", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

const sagaErrorMessage = (error, action) => {
  console.group("Saga Error:" + action.type);
  console.log(error.response);
  console.groupEnd();
};

export default function* roleSaga() {
  yield all([
    createRole(),
    fetchRole(),
    editRole(),
    createPermission(),
    fetchPermission(),
    removePermission(),
  ]);
}
