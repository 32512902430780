export const getHeaderJWT = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("user")}`,
      "Content-Type": "application/json",
    },
  };
};

// export const URL_NOTIFICATION = 'http://3.7.148.233:8888/notification'
// export const URL_TASKS = 'http://3.7.148.233/tasks'
// export const URL = 'http://3.7.148.233'

export const URL_NOTIFICATION = "https://tasks.realbooks.in/notification";
export const URL_TASKS = "https://tasks.realbooks.in/rest-api/tasks";
export const URL = "https://tasks.realbooks.in/rest-api";

// export const URL_NOTIFICATION = 'http://dev.localhost:8888/notification'
// export const URL_TASKS = 'http://dev.localhost/tasks'
// export const URL = 'http://dev.localhost'
