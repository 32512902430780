import * as type from "./types";

export function createModule(module) {
  return {
    type: type.CREATE_MODULE,
    payload: module,
    txn: "initiated",
  };
}

export function savedModule(module) {
  return {
    type: type.SAVED_MODULE,
    payload: module,
    txn: "success",
    diff: true,
  };
}

export function fetchModule() {
  return {
    type: type.FETCH_MODULE,
  };
}

export function fetchedModule(module) {
  return {
    type: type.FETCHED_MODULE,
    payload: module,
  };
}

export function editModule(module) {
  return {
    type: type.EDIT_MODULE,
    payload: module,
    txn: "initiated",
  };
}

export function createProcess(process) {
  return {
    type: type.CREATE_PROCESS,
    payload: process,
    txn: "initiated",
  };
}

export function savedProcess(process) {
  return {
    type: type.SAVED_PROCESS,
    payload: process,
    txn: "success",
    diff: true,
  };
}

export function fetchProcess() {
  return {
    type: type.FETCH_PROCESS,
  };
}

export function fetchedProcess(process) {
  return {
    type: type.FETCHED_PROCESS,
    payload: process,
  };
}

export function removeProcess(process) {
  return {
    type: type.REMOVE_PROCESS,
    payload: process,
    txn: "initiated",
  };
}

export function removedProcess(process) {
  return {
    type: type.REMOVED_PROCESS,
    payload: process,
    txn: "success",
  };
}
