import { call, takeEvery,  all, put } from 'redux-saga/effects'
import { successfulLogin,failedLogin, successfulLogout, changedPassword } from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import { URL, getHeaderJWT } from '../../../store/path'
import { parseJwt, parseJwtHeader } from '../../../utilities/parseJWT'

//#region Saga for Request Login 
function* login() {
    yield takeEvery('LOGIN_REQUEST',requestLogin)
  }

function* requestLogin(action){
  const { response, error } = yield call(requestLoginAPI, action.payload)
  if (response) {
    let token_data = parseJwt(response.data.token)
    // let token_header = parseJwtHeader(response.data.token)
    console.log (' Success while login : ', token_data)
    // console.log (' token_header : ', token_header)
    
    yield localStorage.setItem('user', response.data.token);
    yield localStorage.setItem('exp', token_data.exp);
    yield localStorage.setItem('header', "Home");
    yield put (successfulLogin({
      username: action.payload.user,
      uid: token_data.uid
    }))
  }
  else {
    console.log (' Error while login : ', error)
    yield put (failedLogin({
      username: action.payload.user,
      error_code: error.response.status,
      http_error: error.message,
      error_message: error.response.data
    }))
  }
}

function requestLoginAPI(data) {
    console.log('login request sent', data);
    return axios.post(URL+'/users/login', {}, {
      crossDomain: true,
      // withCredentials: true,  
      auth: {
        username: data.user,
        password: data.password
      }})
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion



//#region Saga for Changing Password

function* changePassword() {
  yield takeEvery('CHANGE_PASSWORD',requestChangePassword)
}

function* requestChangePassword(action){
  const { response, error } = yield call(requestChangePasswordAPI, action.payload)  
  console.log(' response.data is ', response.data)
  if (response) 
  try{
    yield put (changedPassword("success"))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function requestChangePasswordAPI(data) {
  // let user_id = data.id
  // console.log (' ^^^^^ Sending team update data is ^^^^^', data)
  return axios.post(URL+'/user/update-password', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



//#region Saga for Request Login 
function* logout() {
  yield takeEvery('LOGOUT_REQUEST',requestLogout)
}

function* requestLogout(action) {
  
  yield localStorage.removeItem('user');
  yield localStorage.removeItem('exp');
  yield put (successfulLogout({
    msg: 'Log out Successful'
  }))
}


//#endregion



const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* loginSaga() {
    yield all([
      login(),
      logout(),
      changePassword()
    ])
  }  