export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD'
export const CHANGEING_PASSWORD = 'CHANGEING_PASSWORD'
