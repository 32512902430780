import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Icon } from "semantic-ui-react";

const AccordioneMenu = (props) => {
  document.title = "Settings";

  return (
    <div
      style={{
        height: props.accordionHeight + 60 + "px",
        overflow: "hidden scroll",
      }}
    >
      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/workroom/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="home"
                />
                Workroom
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/workroom/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>

      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/scheduledprocess/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="retweet"
                />
                Scheduled Job
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/scheduledprocess/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>

      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/process/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="sitemap"
                />
                Process
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/process/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>

      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/team/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="users"
                />
                Teams
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/team/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>

      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/client/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="handshake"
                />
                Client
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/client/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>

      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                className="paddingleft30 fontSize18 colorMenu"
                to={{ pathname: "/users/list" }}
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="user"
                />
                User
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/users/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>
      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                to={{ pathname: "/roles/list" }}
                className="paddingleft30 fontSize18 colorMenu"
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="key"
                />
                Roles and Permissions
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/roles/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>
      <Menu.Item className="noBackColor paddingTop15" style={{ width: "100%" }}>
        <Grid divided="vertically" columns={2}>
          <Grid.Row className="minHeight40">
            <Grid.Column className="top5" width={13}>
              <Link
                to={{ pathname: "/module/list" }}
                className="paddingleft30 fontSize18 colorMenu"
                style={{ float: "left" }}
              >
                <Icon
                  size="small"
                  className="iconCstmCss"
                  color="black"
                  name="folder"
                />
                Module
              </Link>
            </Grid.Column>
            <Grid.Column className="top5" width={3}>
              <Link
                to={{ pathname: "/module/create" }}
                style={{ float: "right" }}
              >
                <Icon className="iconCstmCss color686868" name="plus circle" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Menu.Item>
    </div>
  );
};

export default AccordioneMenu;
