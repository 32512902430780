import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getScheduledprocess, getNotification, getScheduledprocessParams, getScheduledprocessList } from '../data/selectors';
import { createScheduledprocess, editScheduledprocess, setNotifyDone } from '../data/actions';
import { scheduledprocessSchema } from '../data/model'
import { Container, Header, Form, Button, Grid, Message, Checkbox, Label, Divider} from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikDateComponent } from '../../../utilities/formUtils';

import UserSelect from '../../users/components/usersSelect' 
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'
import { updateHeaderTitle } from '../../app/data/actions'
import WorkroomSelect from '../../workRoom/components/workroomSelect'
import ProcessSelect from '../../process/components/processSelect'
import * as lfns from '../utilities/lineFunctions'
import cronstrue from 'cronstrue';


// import UserDropdown from '../../users/components/userDropdown'


const ScheduledProcessForm = (props) => {

    const [heights, setHeight] = useState('')
    const [savedScheduleProcessId, setSavedScheduleProcessId] = useState(false)
    const [isError, setIsError] = useState(false)

    const scheduledprocess = useSelector(state => getScheduledprocess(state, props))
    const allscheduledprocess = useSelector(state => getScheduledprocessList(state, props))
    const params = useSelector(state => getScheduledprocessParams(state, props))
    const dispatch = useDispatch()

    let scheduleProcessName = "";
    let scheduleProcessId = scheduledprocess.id
    

    useEffect(() => {
        if (props.match.path === '/scheduledprocess/edit/:id') {
            dispatch(updateHeaderTitle({ 'title': 'Update Scheduled Process'}))
        } else
            dispatch(updateHeaderTitle({ 'title': 'Create Scheduled Process'}))
    }, [])

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    const saveScheduledprocess = (values, resetForm) => {
        if (props.match.path === '/scheduledprocess/create') {
            // userACL.atCreate(values)
            dispatch(createScheduledprocess(values))
            setTimeout(function(){ 
                props.history.push({pathname: "/scheduledprocess/list"})    
            }, 2000);  
        }
        if (props.match.path === '/scheduledprocess/edit/:id') {
            // userACL.atUpdate(values)
            dispatch(editScheduledprocess(values))
            props.history.push(`/scheduledprocess/list`)
        }
    }
    
    const [workroomId, setWorkroomId] = useState()
    const getUsersList =(value)=> {
        console.log (' ****** In workroom select get value si ', value)
       if (value) {
         setWorkroomId (value.id)
        //  let users = useSelector(state => getListOfUsersFromWorkroom(state, value))
        //  console.log (' ****** In workroom select USERS IS si ', users)
        //  setListOfUsers (users)
       }
  
    }
    const [scheduledOption, setScheduledOption] = useState(true)
    const setScheduledJobOption = () => {
        if(scheduledOption == true){
            setScheduledOption(false)
        }else{
            setScheduledOption(true)
        }
    }
    
    const process = useSelector(state => state.process.byId) 
    const setProcessKey = (value, setFieldValue, values) =>{
        lfns.setFromProcessId(value, setFieldValue, values, process)
    }

    const cronDescriptionColor = (cronExpression) =>{
        if(cronstrue.toString(cronExpression, { verbose: true, throwExceptionOnParseError : false }).startsWith('An error') )
            return 'red'
        return 'green'
    }
    return (

        <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column width={13} style={{ height: (heights) + "px" }} className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                    <Formik id="scheduledprocessForm" size="large" width={5}
                        initialValues={scheduledprocess}
                        validationSchema={scheduledprocessSchema}
                        onSubmit={(values, { resetForm }) => saveScheduledprocess(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="large" className="CustomeForm" width={5} onSubmit={handleSubmit}>
                                <Form.Group widths={3}>
                                    <WorkroomSelect required={true} name="workroomId" label="Workroom" getWorkroomValue = {getUsersList}/>
                                    <ProcessSelect required={true} name="processId" label="Process" callback={setProcessKey}/>
                                    <UserSelect name="userId" label="Assign to User" filterFromWorkroom= {workroomId}/>
                                    {/* <Field name="processKey" label= 'Process Key' component={FormikInputComponent}></Field> */}
                                </Form.Group>
                                <Field name="jobName" label= 'Job Name' component={FormikInputComponent}></Field>
                                <Form.Group widths={3} right aligned>
                                    <UserSelect width={3} right aligned name="ownerId" label="Job Owner" filterFromWorkroom= {workroomId}/>
                                    <Field name="deadline" label= 'Deadline(in days)' component={FormikInputComponent}></Field>
                                    <Field name="copyFromJobKey" label= 'Copy ToDos from Job' component={FormikInputComponent}></Field>
                                </Form.Group>
                                <br/>
                                <br/>
                                <h3>Schedule the Job</h3>
                                <Divider/>
                                <Checkbox toggle onClick={() => setScheduledJobOption()} label="Toggle to make a one time future job"/>
                                <br/>
                                <br/>
                                <br/>
                                {scheduledOption == true
                                    ?<>
                                    {values.cronExpression != undefined && values.cronExpression != ""?
                                        <>
                                            <br/><div style={{color:cronDescriptionColor(values.cronExpression)}}>{cronstrue.toString(values.cronExpression, { verbose: true, throwExceptionOnParseError : false })}</div><br/>
                                        </>
                                    : <></>}
                                    <Form.Group widths={2}>
                                    <Field name="cronExpression" label= 'Cron Expression' component={FormikInputComponent}></Field>
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Field name="startDate" label= 'Start Date' component={FormikDateComponent}></Field>
                                        <Field name="endDate" label= 'End Date' component={FormikDateComponent}></Field>
                                    </Form.Group></>
                                    :
                                    <Form.Group widths={2}>
                                        <Field name="scheduledAt" label= 'Scheduled At' component={FormikDateComponent}></Field>
                                    </Form.Group>
                                    
                                }
                                
                                <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                        
                                
                                {savedScheduleProcessId ?
                                    <Notification id={savedScheduleProcessId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save' />
                                    :
                                    null}
                            </Form>
                            
                        )}
                        
                    />
                    { isError &&
                    <Message
                    style = {{marginTop: '100px'}}
                    size='mini'
                        error
                        header='There was some errors with your submission'
                        list={[
                            'Workroom and Process have already been used to create a process',
                        ]}
                        />
                    }
                    
                </Grid.Column>
                
            </Grid>
            
        
        </Container>
    )
}

export default ScheduledProcessForm;