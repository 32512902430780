import axios from 'axios'
// import React, {useState} from 'react'
import {URL_NOTIFICATION} from './path'
import {getStore} from '../index.js'
import {savedComments} from '../modules/comments/data/actions'
import {savedTask, updatedTaskReport, insertTaskReport} from '../modules/tasks/data/actions'
import {savedJobs, deletedJobs, fetchQueueJobs} from '../modules/jobs/data/actions'
import { editedLastSeen, updatedUserOnline, editedUnreadCount } from '../modules/app/data/actions'
import { delay } from 'lodash'

const SockJS = require('sockjs-client') ;
const Stomp = require('stompjs') ;
let stompClient = null
let isConnected = true
let globalUserid;
let globalChannel;

export const getStompClient = () =>{
    return stompClient
} 

function registerUserToSocket (userid) {
    axios.get( URL_NOTIFICATION + "/register-user/" + userid)
            .then((res) => { 
            console.log("--------Received data from socket after registration-----------", userid)    
            //  console.log(res.data)            
        }, (error) => {
            console.log("--------ERROR from socket while registering----------")    
            // console.log(error);
        });
}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const checkWebSocketStatus = () => {
    window.setInterval(function(){
                console.log("Connection Status: ", isConnected)
                if (!isConnected)
                    delay(configureWebSocket, 10000, globalUserid, globalChannel)
            }, 30000);
}
checkWebSocketStatus()


export default function configureWebSocket(userid, channel){
    const socket = new SockJS(URL_NOTIFICATION);
    const stClient = Stomp.over(socket);
    stompClient = stClient
    globalUserid = userid
    globalChannel = channel

    stClient.connect({'userId' : userid}, function (frame) {
        startWebSocket(stClient, userid, channel)
        registerUserToSocket(userid)
        isConnected = true
    }, function(frame) {
        console.log("Socket Closed, lost connection !")
        isConnected = false
    });
}

const startWebSocket = (stClient, userid, channel) => {

    stClient.subscribe(channel, function (greeting) {
        let body = IsJsonString(greeting.body)? JSON.parse (greeting.body): greeting.body
        // console.log('------------ BODY IS --------------', body)
        let resVal = body.value
        // console.log('------------ NOW THE ACTUAL VALUE --------------', resVal)
        let actionType = resVal.actionType
        let payloadVal = IsJsonString(resVal.payload) ? JSON.parse (resVal.payload): resVal.payload 
        // let payloadVal = resVal.payload
        // console.log(' ActionType is : ', actionType)
        // const actionT = "savedComments"
        // console.log('Payload is : ',payloadVal);
        const store = getStore()

        switch(actionType) {
            case 'SAVE_COMMENT' :
                store.dispatch(savedComments(payloadVal))
                break ;
            case 'SAVE_TASK' : // task user assign change
                store.dispatch(savedTask(payloadVal))
                break;
            case 'INSERT_TASK' :
                store.dispatch(savedTask(payloadVal))
                break;
            case 'R001_INSERT_TASK' :
                store.dispatch(updatedTaskReport(payloadVal))
                store.dispatch(insertTaskReport(payloadVal))
                break;
            case 'COMPLETE_TASK' :
                store.dispatch(savedTask(payloadVal))
                break ;
            case 'R001_COMPLETE_TASK' :
                store.dispatch(updatedTaskReport(payloadVal))
                break;
            case 'SET_TASK_STATUS' :
                store.dispatch(savedTask(payloadVal))
                break ;
            case 'R001_SET_TASK_STATUS' :
                store.dispatch(updatedTaskReport(payloadVal))
                break;
            case 'INSERT_JOB' : 
                store.dispatch(savedJobs(payloadVal)) 
                store.dispatch(fetchQueueJobs( { 'userId' : userid}));
                break;

            case 'SAVE_JOB' : 
                store.dispatch(savedJobs(payloadVal)) 
                break;

            case 'UPDATE_JOB' : 
                store.dispatch(savedJobs(payloadVal)) 
                break;
            case 'DELETE_JOB' :
                store.dispatch(deletedJobs(payloadVal)) 
                break;
            case 'SET_PRIORITY_JOB' : // job priority change
                store.dispatch(savedJobs(payloadVal)) 
                break;
            case 'SET_DEADLINE' : // job deadline change
                store.dispatch(savedJobs(payloadVal)) 
                break;
            case 'R001_SET_PRIORITY_JOB' :
                store.dispatch(updatedTaskReport(payloadVal))
                break;
            case 'COMPLETE_JOB' : // job status change
                store.dispatch(savedJobs(payloadVal)) 
                break;

            case 'ASSIGN_TASK' :
                store.dispatch(savedTask(payloadVal))
                store.dispatch(fetchQueueJobs( { 'userId' : userid}));
                break;
            case 'R001_ASSIGN_TASK' :
                store.dispatch(updatedTaskReport(payloadVal))
                break;
            case 'lastSeen' :
                store.dispatch(editedLastSeen(payloadVal))
                break;
            case 'user-online' :
                store.dispatch(updatedUserOnline(payloadVal))
                break;
            case 'unread-count' :
                store.dispatch(editedUnreadCount(payloadVal))
                break;
            case 'TEST' :
                console.log('--- actionType is ---', actionType)
            
        }       
        
        if (body.id && body.id != '0') {
            console.log(' Sending ack :', body.id)
            stClient.send("/app/ack", {}, JSON.stringify({
            'userId' : userid,
            'messageId' : body.id
        })); }
    });
}