import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash';
import { Container, Header, Button, Form, Grid, List, Table, Label, Icon, Message, Input } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent, FormikTextAreaComponent, FormikRichText } from '../../../utilities/formUtils';
import { heightSet } from '../../../utilities/heightForListing'
import {getTaskDetailsByJobStartForm} from '../data/selectors'
import v4 from 'uuid';
import moment  from 'moment'
import ToDoAdd from './todoAdd'
import {todoTaskDetails} from '../../taskdetails/data/model'
import {editTaskdetails, fetchTaskdetailsByJob} from '../data/actions'
import { STARTFORM } from '../data/templateMapping'
import CopyTaskStepsFromJobTextBox from '../../jobs/components/copyTaskStepsFromJobTextBox'
import PopupTaskCreate from '../../tasks/components/popUpTaskCreate'


/** 
 *  This template refers to the Start Form for the Tasks . 
 *  There is one description field and a set of todos related to tasks
 *  This start form template is the basis on which the other Todos are captured 
 *  Sync, updating of todos are all calculated based on this form
*/

const TemplateSummary = (props) => {
    const [heights, setHeight] = useState('')
    const [todoTasks, setTodoTasks] = useState(todoTaskDetails())
    const [originalTodoTasks, setOriginalTodoTasks] = useState()
    const [editMode, setEditMode] = useState(false)
    const [noTasksSetFlag, setNoTasksSetFlag] = useState (true)
    const [savedSuccess, setSavedSuccess] = useState(false)
    const [jobIdToCopyFrom, setJobIdToCopyFrom] = useState (null)

    const dispatch = useDispatch()

    const job = props.job ? props.job : undefined
    const jobId = props.job ? props.job.id : undefined
    const jobArchived = props.job ? props.job.jobStatus == 0 : false
    const taskdetails = useSelector(state => getTaskDetailsByJobStartForm (state, jobId))
    const taskdetailsToCopyFrom = useSelector(state => getTaskDetailsByJobStartForm (state, jobIdToCopyFrom))


    useEffect(() => {
        if (!taskdetails) {
            let newtasks = todoTaskDetails()
            newtasks.description = props.job ? props.job.description : ""
            setTodoTasks (newtasks)
            setOriginalTodoTasks(newtasks)
            setNoTasksSetFlag (true)
            
        } else {
            let obj = cloneDeep(taskdetails)
            obj.todos = JSON.parse(taskdetails.todos)
            setTodoTasks(obj)
            setOriginalTodoTasks(obj)
            setNoTasksSetFlag (false)
        }
    }, [taskdetails, props.job])


    const keyPressed = (event)=> {
        if (event.key === "Enter") {
            let jobkey = event.target.value
            console.log(' the task number to copy from is :', jobkey)
            setJobIdToCopyFrom(jobkey)
            // dispatch (fetchTaskdetailsByJob ({'jobId': jobid}))
        }
      }

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })


    const copyFromTask = () => {
        let obj = cloneDeep(taskdetailsToCopyFrom)
            obj.todos = JSON.parse(taskdetailsToCopyFrom.todos)
            setTodoTasks(obj)
            setNoTasksSetFlag (false)
            setEditMode (true)
    }

    useEffect(() => {
        console.log(' taskdetails to copy from ', taskdetailsToCopyFrom)
        console.log(' jobIdToCopyFrom to copy from ', jobIdToCopyFrom)
        if (taskdetailsToCopyFrom) {
            let obj = cloneDeep(taskdetailsToCopyFrom)
            obj.description = todoTasks.description
            obj.todos = JSON.parse(taskdetailsToCopyFrom.todos)
            setTodoTasks(obj)
            // setNoTasksSetFlag (false)
            setEditMode (true)
        }
    }, [taskdetailsToCopyFrom, jobIdToCopyFrom])

/* when not in editMode this summary is shown */
    const renderSummaryDisplay = () => {
        return(
            <div>
            <Grid.Row className="borderNoneObj marginTopTen">
            <Message className=" minHeight40 messageTransparent marginBottom15 ">
                    <Message.Header className='paddingBottom5 borderBottomObj'>
                        Describe the Task / Issue:                
                    </Message.Header>
                    <Message.Content className=' marginTop5 '>
                    <div dangerouslySetInnerHTML={{__html: todoTasks.description}} />
                    </Message.Content>
            </Message>
            <Message className=' marginTopTen messageTransparent paddingright0'>
                {
                    noTasksSetFlag && 
                    <Message.Header className=' marginTop5' >No To-Dos Set</Message.Header>
                }
                {  !noTasksSetFlag &&
                <div>
                    <Message.Header className=' marginTop5 paddingBottom5 borderBottomObj'>To Do for Completing the Task / Solving the Issue : </Message.Header>
                    {/* { (todoTasks.todos[0].text !='') &&  */}
                    {/* <Message.List bulleted='true' className=' marginTopTen'> */}
                    <Table basic  className='borderNoneObj paddingZeroRow'>
                    <Table.Header>
                        <Table.Row className='borderNoneObj'>
                        <Table.HeaderCell textAlign='left' width = {10}  className='borderNoneObj paddingZeroRow paddingBottom5'>To Do</Table.HeaderCell>
                            <Table.HeaderCell textAlign='left' width = {2} className='borderNoneObj paddingZeroRow paddingBottom5' > 
                
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                            <Table.Body>
                   {Array.isArray(todoTasks.todos) && 
                       todoTasks.todos.map((item, index) => 
                            <Table.Row key={index}>   
                                <Table.Cell textAlign='left' verticalAlign="top" className='borderNoneObj paddingZeroRow paddingTop10' >

                                    {(item.text != '') &&
                                       `${index +1}. ${item.text}`
                                    }
                                    
                                </Table.Cell>
                                <Table.Cell textAlign='left' verticalAlign="top" className='borderNoneObj paddingZeroRow paddingTop10'>
                                <Label basic color='grey' className=' borderNoneObj paddingZero labelNormal' size='small'>
                                    {item.createDate ? `Created: ${moment(item.createDate,"YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}` : ''}
                                    { item.createDate && <br /> }
                                    {item.targetDate ? `Target:  ${moment(item.targetDate,"YYYY-MM-DD").format("DD-MM-YYYY")}` : ''}
                                    
                                </Label>
                                </Table.Cell>
                                
                            </Table.Row>
                         ) 
                   } 
                            </Table.Body>
                        </Table>
                </div>
                }  
                 
                </Message> 
                </Grid.Row> 
                 <Grid.Row className="borderNoneObj marginLeftTwenty">
                 {!jobArchived &&
                    <>
                     <Button color="blue" size="medium" className="CustomeBTN marginTopTen " onClick={() => setEditMode(true) }>
                         Update To Dos
                     </Button>
                    <PopupTaskCreate job={job}/>
                    </>
                }
                 </Grid.Row>
                 <Grid.Row className="borderNoneObj marginLeftTwenty">
                    {!jobArchived && noTasksSetFlag &&
                    <CopyTaskStepsFromJobTextBox callbackfn = {(id)=> setJobIdToCopyFrom(id)} />
                    }

                 </Grid.Row>   
             </div>
        )

    }

    const cancelSubmission= () =>{
        setTodoTasks(originalTodoTasks)
        setEditMode(!editMode)
        
    }

        function findMaxUpdateDate (tdetails) {
            let maxdatestr = tdetails.maxUpdateDateForTodos
            let maxdate = maxdatestr ? moment (maxdatestr) :null
            let currtodos = tdetails.todos
            let update_date
            currtodos.map ((item) => {
                update_date = item.updateDate ? moment(item.updateDate) :null
                if (update_date && update_date > maxdate)
                    maxdate = update_date
            })
            console.log (' maxdate is now ', maxdate)
            return (maxdate ? moment(maxdate).format('YYYY-MM-DD HH:mm:ss'): '')
        }

    const saveTaskTodos = (values, actions) => {
        // console.log(' User touched values todos: ', touched)
        let maxdate = findMaxUpdateDate (values) 
        let newarray = values.todos
        /* the following is done to handle conditions when no todos have been set*/
            if (newarray.length ==1 && newarray[0].text =="") 
                newarray=[]
        let newval = cloneDeep (values)
        newval.lastSyncDate = maxdate
        newval.maxUpdateDateForTodos = maxdate
        let str = JSON.stringify(newarray)
        // console.log (' User filled values todos !!!!', newarray)
        newval.todos = str
        let obj = {
            jobId : jobId,
            activityId : STARTFORM,
            antId :STARTFORM + '#' + jobId,
            taskDetails : newval
        }
        console.log(' Start form todos: ', obj)
        dispatch (editTaskdetails(obj))
        setSavedSuccess (true)
       /*  setTimeout(function(){ 
            setSavedSuccess (false)
        }, 3000); */
        setEditMode (false)
    }


    return (
     
        <>
            <Grid columns={1}>
                <Grid.Column className="borderNoneObj paddingTopZero marginTopTen">
                    {!editMode && renderSummaryDisplay()}
                    {
                        editMode && 
                        <Grid.Row>
                        <Formik id="activityForm" size="large" width={5}
                            enableReinitialize
                            initialValues={todoTasks}
                            onSubmit={(values,{ resetForm }) => saveTaskTodos(values, resetForm)}
                            render={({ handleSubmit, onChange, values, handleChange, handleBlur, setFieldValue }) => (
                                <Form as={FormikForm} size="small" className="CustomeForm borderNoneObj marginTopZero" width={5} onSubmit={handleSubmit}>
                                    <Field name="description"  className= 'desc' userProps= {{'height' :5}} placeHolder="Please type here..." label="Description"  component={FormikRichText} ></Field>
                                   
                                    {/*  this margin is to offset the rich text coming down */}
                                    <div style = {{'margin-top': '220px'}} > 
                                        <FieldArray name='todos' label='To do' component={ToDoAdd} disabledComp = {!editMode}  className="borderNoneObj" />
                                    </div>
                                { !jobArchived && editMode &&
                                    <>
                                    <Button type="submit" size="medium" color="blue" className="CustomeBTN"> Save </Button>
                                    <Button type="button" size="medium" color= "grey" className="CustomeBTN" onClick={cancelSubmission}> Cancel </Button>
                                    </>
                                }
                                </Form>
                            )}
                        />
                    </Grid.Row>
                    }
                    
                </Grid.Column>
            </Grid>
        </>
               
         
    )
}

export default TemplateSummary;
