import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedWorkroom } from '../data/actions'
import * as select from '../data/selectors'
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'
import { valuesIn } from 'lodash';
import { Collapse } from 'bootstrap';

/**
 * @desc Returns a dropdown of Workroom values with name shown
 * @param {filterFromMyList : boolean} : when true the mylist workroom is used instead of the master workroom 
 * @param {returnFullObject : boolean} : when true the full workroom object is returned as value instead of just the ID. 
 */
const WorkroomSelect = ({ name, isTxn, required, isLabel, label, isSelection, filterFromMyList = false, multiple, getWorkroomValue, returnFullObject=false }, props) => {

    const workroom = useSelector(state => state.workroom.byId)
    const myListWorkroom = useSelector(state => select.getMyListWorkroom(state))
    const workroomFetched = useSelector(state => select.getIsWorkroomFetched(state, props))

    let actualWList = filterFromMyList ? myListWorkroom : workroom

    const options = returnFullObject ? useSelector(state => select.selectWorkroomObjFromList( actualWList)) : useSelector(state => select.selectWorkroomFromList( actualWList))
    const dispatch = useDispatch()
    // console.log(workroom, "==workroom");
    // console.log(options, "==options");
    
    useEffect(()=>{
        if (!workroomFetched)
            dispatch(fetchedWorkroom(workroom));
    },[]);  
 

    const getValue = (values) => {
        // console.log("Client Selected", values, name)
        // getValue(values)
        // console.log (' @@@@@ In workroom select get value si ', values)
        if (getWorkroomValue){
            console.log("getWorkroomValue ", values);
            getWorkroomValue(values)

        }

    }


    if (!workroomFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Field name={name} 
                isLabel={isLabel}
                required = {required} 
                isTxn={isTxn} 
                label={label}
                multiple={multiple}
                component={FormikSelectComponent} 
                userProps={{ options, getValue }}> 
            </Field>
        )
    }

}


export default WorkroomSelect;
