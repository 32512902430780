import React, { useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTaskDetailsByJobActivityTask, getTaskDetailsByJobStartForm } from '../data/selectors'
import { Container, Icon, Button, Form, Checkbox, Message, Label, Table } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent, FormikCheckBoxComponent } from '../../../utilities/formUtils';
import { heightSet } from '../../../utilities/heightForListing'
import { editTaskdetails } from '../data/actions'
import { cloneDeep } from 'lodash';
import { updateTaskComplete, updateTaskStatus } from '../../tasks/data/actions'
import { parseJwt } from '../../../utilities/parseJWT'
import { getActiveTask } from '../../tasks/data/selectors'
import { getJobPathLink } from '../../../utilities/pathUtils'
import { getFormFields, getValidationSchema } from '../../../utilities/yupUtils'
import { getStateMachine, getActions , enumStates } from '../data/state';

const TemplateTasksDynamicForm = (props) => {
    const [heights, setHeight] = useState('')
    const [todoTasks, setTodoTasks] = useState('')
    const [currTaskStatus, setCurrTaskStatus] = useState()

    const dispatch = useDispatch()

    const token = localStorage.getItem('user')
    const userId = token? parseJwt(token).identity : null

    const job = props.job ? props.job : undefined
    const jobId = props.job ? props.job.id : undefined
    const activityId = props.activityId
    const activity = props.activity
    const currActiveTask = useSelector (state => getActiveTask (state, jobId))
    const taskId = currActiveTask ? currActiveTask.id : undefined
    const currTaskUserId = currActiveTask.userId
    const isPrimary = (currActiveTask["secondary"] == null ? true : false)
    const [stateMachine, setStateMachine] = useState(null)
    const [actions, setActions] = useState(null)

    const taskdetails = useSelector(state => getTaskDetailsByJobActivityTask(state, jobId, activityId, taskId))
    const startFormTaskDetails = useSelector(state => getTaskDetailsByJobStartForm(state, jobId))

    const formUI = JSON.parse(activity["formMeta"])
    console.log(activity)
    const formvalidator = getValidationSchema(formUI["fields"].concat(formUI["fieldArray"]))
    const nestedFields = getFormFields(formUI["fields"])
    let arrToPush = {}

    function getParsedTaskDetailsObject(tobj) {
        let arr = taskdetails[formUI["fieldArray"][0]["name"].split(".")[0]]
        if (!tobj || !arr) return undefined
        let obj = cloneDeep(tobj)
        obj[formUI["fieldArray"][0]["name"].split(".")[0]] = JSON.parse(arr)
        return (obj)
    }
    
    useEffect(() => {
        let obj = {}
        if (taskdetails != undefined && formUI["fieldArray"].length > 0) {
            obj = getParsedTaskDetailsObject(taskdetails)
        }
        setTodoTasks(obj)
    }, [taskdetails, startFormTaskDetails])


    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function () {
            heightSet(setHeight)
        }, false);
    })


    useEffect(() => {
        setCurrTaskStatus(currActiveTask.taskStatus)
        setActions(getActions(currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
    }, [currActiveTask])
    
    useEffect(() => {
        setActions(getActions(currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
        if(job && currActiveTask)
            if (job.ownerId == userId || currTaskUserId == userId)
                setStateMachine(getStateMachine(job.jobKey, currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
    }, [job, currActiveTask])



    const saveTaskDetail = (newTodos) => {
        let newarray = ''
        if (formUI["fieldArray"].length > 0) {
            newarray = newTodos[formUI["fieldArray"][0]["name"].split(".")[0]]
            let str = JSON.stringify(newarray)
            alert(str)
            newTodos[formUI["fieldArray"][0]["name"].split(".")[0]] = str
        }

        let obj = {
            jobId: jobId,
            activityId: activityId,
            processId: job.processId,
            workroomId: job.workroomId,
            antId: activityId + '#' + taskId,
            taskDetails: newTodos            // taskDetails : {'description': newTodos.description, 'todos' : newTodos.todonewTodos{'description': newTodos.description, 'todos' : newTodos.todos}
        }
        console.log(' Start form todos: ', obj)
        dispatch(editTaskdetails(obj))
    }

    const saveActivity = (values, actions) => {
        saveTaskDetail(cloneDeep(values))
        // callBackParent()
    }

    const completeTask = () => {
        let obj = {
            'id': taskId,
            'processId': props.job.processId,
            'variables': []
        }
        dispatch(updateTaskComplete(obj))
        setTimeout(function () {
            props.history.push({ pathname: getJobPathLink(props.job) })
        }, 2000);
    }

    const triggerAction = (action) => {
        let nextState = stateMachine.send(action.action)
        console.log("nextState", nextState.context.stateId)
        if (action.targetState == 0) 
            completeTask()
        else {
            let obj = {
                'id': taskId,
                'targetStateId': action.id
            }
            dispatch(updateTaskStatus(obj))
            setCurrTaskStatus(action.targetState)
        }
    }

    if (todoTasks == '') 
    return (<></>)
    else 
    return (
        <>
            <Formik id="activityForm" size="large" width={5}
                // enableReinitialize
                initialValues={todoTasks}
                // keepDirtyOnReinitialize
                validationSchema={formvalidator}
                onSubmit={(values, { resetForm }) => saveActivity(values, resetForm)}
                render={({ handleSubmit, onChange, values, handleChange, setFieldValue, errors }) => (
                    <Form as={FormikForm} size="small" className="CustomeForm marginTopZero" width={5} onSubmit={handleSubmit}>
                        {Array(formUI["meta"]["maxRows"]).fill(0).map((_, i) => (
                            <Form.Group widths={3}>
                                {console.log(errors)}
                                {Object.keys(nestedFields[i+1]).map(k=>{
                                    let field = nestedFields[i+1][k]
                                    switch(field["component"]){
                                     case "input": 
                                        return <Field required={field["required"]} name={field["name"]} label={field["label"]} component={FormikInputComponent}></Field>  
                                     case "checkbox": 
                                        return <Field required={field["required"]} name={field["name"]} label={field["label"]} component={FormikCheckBoxComponent}></Field>  
                                    }
                                })}
                            </Form.Group>
                        ))}
                        <br/>
                        {formUI["fieldArray"].length > 0 ? 
                            <FieldArray name={formUI["fieldArray"][0]["name"].split(".")[0]}>
                                {({ insert, remove, push }) => (
                                <>
                                    <Table basic >
                                        <Table.Header>
                                            <Table.Row key="header">
                                                {formUI["fieldArray"].map(row =>  (
                                                    <Table.HeaderCell textAlign="left">{row["label"]}</Table.HeaderCell>
                                                ))}
                                                    <Table.HeaderCell textAlign="left">Action</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {values && values[formUI["fieldArray"][0]["name"].split(".")[0]] && values[formUI["fieldArray"][0]["name"].split(".")[0]].length > 0 &&
                                                values[formUI["fieldArray"][0]["name"].split(".")[0]].map((record, index) => (
                                                <>
                                                <Table.Row>
                                                    {formUI["fieldArray"].map((field, index1) =>  {
                                                        arrToPush = { ...arrToPush, [field["name"].split(".")[2]] : "" }
                                                     return (
                                                        <Table.Cell>
                                                            <Field name={`${field["name"].split(".")[0]}.${index}.${field["name"].split(".")[2]}`} isLabel={true} isTxn={true} placeholder={field["name"].split(".")[2]} component={FormikInputComponent}></Field>                          
                                                        </Table.Cell>
                                                    )})}
                                                        <Table.Cell>
                                                            <Button onClick={() => remove(index)}> X </Button>
                                                        </Table.Cell>
                                                </Table.Row>
                                                </>
                                                ))}
                                                <Table.Row>
                                                    {formUI["fieldArray"].map((field, index1) =>  (<Table.Cell></Table.Cell>))}
                                                    <Table.Cell>
                                                        <Button type="button" float={'right'} onClick={() => push(arrToPush)}>Add a row</Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                        </Table.Body>
                                    </Table>                                    
                                </>
                                )}
                            </FieldArray>
                            : null}
                            <br/>
                        <Button type="submit"  size="medium" color="blue" className="CustomeBTN"> Submit </Button>
                    </Form>
                )} 
            />
        </>
    )
}

export default TemplateTasksDynamicForm;
