import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';
import { useSelector, useDispatch } from 'react-redux'
import { workroom } from '../../workRoom/data/model';
import { getWorkroomNameFromId } from '../../workRoom/data/selectors'

// Define a scheduledprocesss schema
const scheduledprocessSchema = new schema.Entity('scheduledprocesss',{}, { idAttribute: 'id' });
const scheduledprocessListSchema = [scheduledprocessSchema];

const defaultScheduledprocessParams = {
  scheduledprocessFetched: false,
  searchFetched: false,
  isDelFetched: false,
  createTitle: 'Create New Scheduledprocess',
  createSubmitButtonText: 'Create Scheduled Process',
  editTitle: 'Edit Scheduledprocess',
  editSubmitButtonText: 'Edit Scheduled Process' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_SCHEDULEDPROCESS') {
        const scheduledprocessID = action.payload.id;
      return {...state, 
        [scheduledprocessID]: action.payload
      }
    }
    if (action.type === 'FETCHED_SCHEDULEDPROCESS') { 
        const normalizedScheduledprocess = normalize(action.payload, scheduledprocessListSchema);
        return merge({},state,normalizedScheduledprocess.entities.scheduledprocesss)
    }     
    if (action.type === 'DELETED_SCHEDULEDPROCESS') {
      if(action.payload.msg !== "error"){
        if(action.payload.msg.id !== undefined){
          const scheduledprocessID = action.payload.id;
          return {...state, 
            [scheduledprocessID]: { ...state[scheduledprocessID],
              status :0
            }
          }
        }else {
          return state
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_SCHEDULEDPROCESS_RESULT') { 
    const normalizedScheduledprocess = normalize(action.payload, scheduledprocessListSchema);
    return merge({},normalizedScheduledprocess.entities.scheduledprocesss)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_SCHEDULEDPROCESS') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg.id !== undefined ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_SCHEDULEDPROCESS'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}

function filterResults(state = {}, action) {
  if (action.type === 'FILTER_ITEMS') { 
      const col = action.payload.col;
      return {...state,
        [col]: action.payload
      }
  } else {
    return state
  }
}


function params(state = defaultScheduledprocessParams, action) {
  if (action.type === 'FETCHED_SCHEDULEDPROCESS') { 
      return {...state, 
        scheduledprocessFetched: true,
        isFetching:false
      }
  }   if (action.type === 'SEARCH_SCHEDULEDPROCESS') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_SCHEDULEDPROCESS_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_SCHEDULEDPROCESS') { 
    return {...state, 
      isDelFetched: action.payload.msg === "error" ? false : true,
      scheduledprocessFetched: action.payload.msg === "error" ? false : true,
      workRoomFetched: action.payload.msg === "error" ?  false : true
    }
  }if(action.type === 'SET_SCHEDULEDPROCESS_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

const scheduledprocess = combineReducers({
    byId,
    notifications,
    searchResults,
    params,
    filterResults
});

export default scheduledprocess;

