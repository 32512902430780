import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Container, Grid } from "semantic-ui-react";
import { Formik, Field, Form as FormikForm } from "formik";
import { FormikInputComponent } from "../../../utilities/formUtils";
import RolesPermission from "./RolesPermission";
import { editRole } from "../data/action";
import { getRole } from "../data/selectors";
import { roleSchema } from "../data/model";
import { updateHeaderTitle } from "../../app/data/actions";

function RolesFormEdit(props) {
  const dispatch = useDispatch();
  const role = useSelector((state) => getRole(state, props));

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Role: " + role.roleName }));
  }, [role.roleName]);
  const saveRole = (values, { resetForm }) => {
    console.log(values);
    dispatch(editRole(values));
  };

  return (
    <Container>
      <Grid>
        <Grid.Column className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
          <Formik
            id="roleFormEdit"
            size="large"
            initialValues={role}
            validationSchema={roleSchema}
            onSubmit={(values, { resetForm }) => saveRole(values, resetForm)}
            render={({ handleSubmit }) => (
              <Form
                as={FormikForm}
                onSubmit={handleSubmit}
                className="CustomeForm"
              >
                <Form.Group widths={3}>
                  <Field
                    name="roleName"
                    label="Role"
                    component={FormikInputComponent}
                  ></Field>
                  <Button
                    type="submit"
                    floated="left"
                    color="blue"
                    size="medium"
                    className="CustomeBTN"
                  >
                    Update
                  </Button>
                </Form.Group>
              </Form>
            )}
          />
          <RolesPermission roleId={role.id} />
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default RolesFormEdit;
