import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Container, Grid } from "semantic-ui-react";
import { Formik, Field, Form as FormikForm } from "formik";
import { FormikInputComponent } from "../../../utilities/formUtils";
import { createRole } from "../data/action";
import { getRole } from "../data/selectors";
import { roleSchema } from "../data/model";
import { v4 } from "uuid";
import { updateHeaderTitle } from "../../app/data/actions";
function RolesForm(props) {
  const dispatch = useDispatch();
  const role = useSelector((state) => getRole(state, props));

  useEffect(()=>{
    dispatch(updateHeaderTitle({ title: "Create Role" }));
  },[])
  const saveRole = (values, resetForm) => {
    values.id = v4();
    dispatch(createRole(values));
    resetForm();
  };

  return (
    <Container>
      <Grid>
        <Grid.Column
          className="paddingLeftRight70 paddingTopZero outerDivClassForForm"
          style={{ height: "40vh", width: "95%", overflowY: "auto" }}
        >
          <Formik
            id="roleForm"
            size="large"
            initialValues={role}
            validationSchema={roleSchema}
            onSubmit={(values, { resetForm }) => saveRole(values, resetForm)}
            render={({ handleSubmit, onChange, values, handleChange }) => (
              <Form
                as={FormikForm}
                onSubmit={handleSubmit}
                className="CustomeForm"
              >
                <Form.Group widths={3}>
                  <Field
                    name="roleName"
                    label="Role"
                    component={FormikInputComponent}
                  ></Field>
                  <Button
                    type="submit"
                    floated="left"
                    color="blue"
                    size="medium"
                    className="CustomeBTN"
                  >
                    Save
                  </Button>
                </Form.Group>
              </Form>
            )}
          ></Formik>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default RolesForm;
