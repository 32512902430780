import React, { useEffect } from "react";
import { Container, Grid, Form, Button } from "semantic-ui-react";
import { Formik, Field, Form as FormikForm } from "formik";
import { FormikInputComponent } from "../../../utilities/formUtils";
import { v4 } from "uuid";
import { getModule, getModuleArray } from "../data/selectors";
import { moduleSchema } from "../data/model";
import { useSelector, useDispatch } from "react-redux";
import { createModule } from "../data/actions";
import WorkroomSelect from "../../workRoom/components/workroomSelect";
import { updateHeaderTitle } from "../../app/data/actions";

function ModuleForm(props) {
  const dispatch = useDispatch();
  const module = useSelector((state) => getModule(state, props));

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Create Module" }));
  }, []);

  const saveModule = (values, resetForm) => {
    values.id = v4();

    dispatch(createModule(values));
    resetForm();
  };

  return (
    <Container>
      <Grid>
        <Grid.Column
          className="paddingLeftRight70 paddingTopZero outerDivClassForForm"
          style={{ height: "40vh", width: "95%", overflowY: "auto" }}
        >
          <Formik
            id="moduleForm"
            size="large"
            initialValues={module}
            validationSchema={moduleSchema}
            onSubmit={(values, { resetForm }) => saveModule(values, resetForm)}
            render={({ handleSubmit, onChange, values, handleChange }) => (
              <Form
                as={FormikForm}
                onSubmit={handleSubmit}
                className="CustomeForm"
              >
                <Form.Group widths={3}>
                  <Field
                    name="moduleName"
                    label="Module Name"
                    component={FormikInputComponent}
                  ></Field>
                  <Field
                    name="moduleUrl"
                    label="ModuleUrl"
                    component={FormikInputComponent}
                  ></Field>
                  <WorkroomSelect
                    name="workroomId"
                    label="Workroom"
                  ></WorkroomSelect>
                  <Button
                    type="submit"
                    floated="left"
                    color="blue"
                    size="medium"
                    className="CustomeBTN"
                  >
                    Save
                  </Button>
                </Form.Group>
              </Form>
            )}
          ></Formik>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default ModuleForm;
