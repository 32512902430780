import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash';
import { heightSet } from '../../../utilities/heightForListing'
import { getTaskDetailsByJobActivityTask } from '../data/selectors'
import { editTaskdetails } from '../data/actions'
import { updateTaskComplete} from '../../tasks/data/actions'
import { getJobPathLink} from '../../../utilities/pathUtils'
import Iframe from 'react-iframe'
import axios from 'axios';

const TemplateNextIFrame = (props) => {
    const [heights, setHeight] = useState('')
    const [content, setContent] = useState('')

    const dispatch = useDispatch()
    const editMode = props.editMode 
    const callBackParent = props.callBack // function

    const jobId = props.job ? props.job.id : undefined
    const taskId = props.taskId
    const activityId = props.activityId
    const activity = props.activity

    const transport = axios.create({
        withCredentials: true
      })
      
    // const currTask = (props.currActiveTask.id == taskId ? true : false)

    const taskdetails = useSelector(state => getTaskDetailsByJobActivityTask (state, jobId, activityId, taskId))
    console.log ('In template #### taskdetails  is ', taskdetails)

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    // data to be saved in guidelines field
    // baseUrl::localhost:3000/;reqPath::configuration;u::prasenjit@realbooks.in;p::Ams@1234

    const baseUrl = activity["guidelines"].split(";")[0].split("::")[1]
    const reqPath = activity["guidelines"].split(";")[1].split("::")[1]
    const usr = activity["guidelines"].split(";")[2].split("::")[1]
    const pswd = activity["guidelines"].split(";")[3].split("::")[1]
    const fetchUrl = `http://${baseUrl}${reqPath}/${taskId}`

    useEffect(() => {
            const authObj = transport.post("http://"+baseUrl+"api/login", {
            email: usr,
            password: pswd,})
            .then((res) => {
                console.log(res.data)
                console.log(fetchUrl)
                setContent("yes")
                return res.data})
            .catch(error => {
                console.log("gggg - error")
                console.log(error)
                return error
            });
    }, [])



    const completeTask = () => {
        let obj = {
            'id' : taskId,
            'variables' : []
        }
        console.log(' Complete  form task: ', obj)
        dispatch (updateTaskComplete(obj))
        setTimeout(function(){ 
            props.history.push({pathname: getJobPathLink (props.job) })    
        }, 1000); 
    }

    const saveActivity = (values, actions) => {
        let obj = {
            jobId : jobId,
            activityId : activityId,
            antId :activityId + '#' + taskId,
            taskDetails : cloneDeep (values)
        }
        console.log(' Start form todos: ', obj)
        dispatch (editTaskdetails(obj))
        callBackParent ()
    }

    if (content == '' && fetchUrl == undefined && fetchUrl == '')
        return null
    else
        return (     
                <>  
                    <Iframe 
                            url={fetchUrl}
                            // srcdoc={content}
                            width="100%"
                            height="600px"
                            id="myId"
                            // className="myClassname"
                            display="initial"
                            position="relative"
                            style={{ "border-width": 0, "background-color": "#ffffff"}}
                    />
                </>
        )
}

export default TemplateNextIFrame;

