import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsClientFetched } from './selectors'
import {savedClient,fetchedClient, storeSearchClientResult, deletedClient, archivedClient, restoredClient } from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL } from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import axios from 'axios'
import { toast } from 'react-toastify'


//#region Saga for Create Client 

function* createClient() {
    yield takeEvery('CREATE_CLIENT',saveClient)
  }

function* saveClient(action){
  try {
      const { response, error } = yield call(saveClientAPI, action.payload)
      // //console.log("Saga",action.payload)
      if (response) 
      
        yield put (savedClient(action.payload))
      
      else {
        yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
}

function saveClientAPI(data) {
    return axios.post(URL+'/client/create', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Client 

function* editClient() {
  yield takeEvery('EDIT_CLIENT',saveEditedClient)
}

function* saveEditedClient(action){
  try {
    const { response, error } = yield call(editClientAPI, action.payload)  
    if (response) 
  
      yield put (savedClient(action.payload))
    
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
}

function editClientAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/client/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Client 

function* deleteClient() {
  yield takeEvery('DELETE_CLIENT',saveDeleteClient)
}

function* saveDeleteClient(action){
  const { response, error } = yield call(deleteClientAPI, action.payload)
  if (response) yield put (deletedClient({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteClientAPI(data) {
  let clientId = data.id
  return axios.post(URL+'/clients/delete/'+ clientId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Client 

function* fetchClient() {
  yield takeEvery('FETCH_CLIENT',requestClient)
}
  

function* requestClient(action){
  const isClientFetched = yield select(getIsClientFetched);
  if(!isClientFetched){
    try {
        let { response, error } = yield call(requestClientAPI)
        if ( response ) {
          yield put (fetchedClient(response.data))
        } 
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
  }
}


function requestClientAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.get(URL+'/client/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Search Client 

function* searchClient() {

  yield takeLatest('SEARCH_CLIENT',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchClientResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/clients/search/'+ data, data, getHeaderJWT())
}

//#endregion


//#region Saga for Archive Client 

function* archiveClient() {
  yield takeEvery('ARCHIVE_CLIENT',saveArchiveClient)
}

function* saveArchiveClient(action){
  const { response, error } = yield call(archiveClientAPI, action.payload)
  if (response) {
    yield put (archivedClient({id: action.payload.id, msg : response.data}))
    toast.success("Archived successfully")
  }
  else {
    let err = parseError(error)
    yield put (handlError(action, err))
    sagaErrorMessage(error,action) 
    toast.error(err.data.message)
  }
}

function archiveClientAPI(data) {
  return axios.post(URL+'/client/archive', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for Archive Client 

function* restoreClient() {
  yield takeEvery('RESTORE_CLIENT',saveRestoreClient)
}

function* saveRestoreClient(action){
  const { response, error } = yield call(restoreClientAPI, action.payload)
  if (response) {
    yield put (restoredClient({id: action.payload.id, msg : response.data}))
    toast.success("Restored successfully")
  }
  else {
    let err = parseError(error)
    yield put (handlError(action, err))
    sagaErrorMessage(error,action)  
    toast.error(err.data.message)
  }
}

function restoreClientAPI(data) {
  return axios.post(URL+'/client/restore', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* clientSaga() {
    yield all([
        createClient(),
        fetchClient(),
        editClient(),
        deleteClient(),
        searchClient(),
        archiveClient(),
        restoreClient()
    ])
  }  