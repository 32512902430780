import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getOrganisation, getOrganisationParams } from '../data/selectors';
import { createOrganisation, editOrganisation } from '../data/actions';
import { organisationSchema, duplicateCheckOrganisationName } from '../data/model'
import { Container, Header, Form, Button, Grid } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'  
import { heightSet } from '../../../utilities/heightForListing'


const OrganisationForm = (props) => {

    const [heights, setHeight] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    const organisation = useSelector(state => getOrganisation(state, props))
    const params = useSelector(state => getOrganisationParams(state, props))
    const dispatch = useDispatch()

    const saveOrganisation = (values, resetForm) => {
        if (props.match.path === '/organisation/create') {
            userACL.atCreate(values)
            dispatch(createOrganisation(values))
            resetForm()
        }
        if (props.match.path === '/organisation/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editOrganisation(values))
            props.history.push(`/organisation/list`)
        }
    }

    return (
        <Container >
            <Header id="headDiv" className="padding55" as='h1'>{params.title}</Header>
                <Grid.Column style={{height: (heights)+"px"}} className=" paddingTopZero outerDivClassForForm">
                    <Formik id="organisationForm" size="large" width={5}
                        initialValues={organisation}
                        validationSchema={organisationSchema}
                        onSubmit={(values, {resetForm}) => saveOrganisation(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="small" className="CustomeForm" width={5} onSubmit={handleSubmit}>
                                <Form.Group widths={3}>
                                    <Field name="name" component={FormikInputComponent}></Field>
                                    <Field name="short_name" component={FormikInputComponent}></Field>  
                                    <Field name="mobile" component={FormikInputComponent}></Field>
                                </Form.Group> 

                                <Form.Group widths={3}> 
                                    <Field name="location" component={FormikInputComponent}></Field>
                                    <Field name="email" component={FormikInputComponent}></Field>
                                </Form.Group>

                                <Button type="submit" size="medium blue" className="CustomeBTN"> {params.submitButtonText} </Button>
                            </Form>                
                        )}
                    />
                </Grid.Column>
        </Container>
    )
}

export default OrganisationForm;