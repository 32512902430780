export const CREATE_APP = 'CREATE_APP'
export const SAVED_APP = 'SAVED_APP'
export const EDIT_APP = 'EDIT_APP'
export const EDITED_APP = 'EDITED_APP'

export const FETCH_LAST_SEEN = 'FETCH_LAST_SEEN'
export const FETCHED_LAST_SEEN = 'FETCHED_LAST_SEEN'
export const EDIT_LAST_SEEN = 'EDIT_LAST_SEEN'
export const EDITED_LAST_SEEN = 'EDITED_LAST_SEEN'
export const FETCH_UNREAD_COUNT = 'FETCH_UNREAD_COUNT'
export const FETCHED_UNREAD_COUNT = 'FETCHED_UNREAD_COUNT'
export const EDITED_UNREAD_COUNT = 'EDITED_UNREAD_COUNT'

export const UPDATE_USER_ONLINE = 'UPDATE_USER_ONLINE'
export const UPDATED_USER_ONLINE = 'UPDATED_USER_ONLINE'

export const NOTIFICATION_DONE_APP = 'NOTIFICATION_DONE_APP'

export const UPDATE_HEADER_TITLE = 'UPDATE_HEADER_TITLE'

export const SET_CURR_USER = 'SET_CURR_USER'
