import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsWorkroomFetched } from './selectors'
import {savedWorkroom,fetchedWorkroom, fetchedUsersInWorkroom, fetchedMyListWorkroom, storeSearchWorkroomResult, deletedWorkroom, archivedWorkroom, restoredWorkroom } from './actions'
import { handlError, parseError } from '../../app/serverError'
import  {getHeaderJWT, URL } from '../../../store/path'
import axios from 'axios'
import { toast } from 'react-toastify'

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//#region Saga for Create Workroom 
// setApi()
//const axios = apiContainer.axios;

function* createWorkroom() {
    yield takeEvery('CREATE_WORKROOM',saveWorkroom)
  }

function* saveWorkroom(action){
  const { response, error } = yield call(saveWorkroomAPI, action.payload)
  // //console.log("Saga",action.payload)
  if (response) 
  try {
    yield put (savedWorkroom(action.payload))
  }
    catch(error) {
      sagaErrorMessage(error,action)
    }

  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveWorkroomAPI(data) {
    return axios.post(URL+'/workroom/create', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Workroom 

function* editWorkroom() {
  yield takeEvery('EDIT_WORKROOM',saveEditedWorkroom)
}

function* saveEditedWorkroom(action){
  const { response, error } = yield call(editWorkroomAPI, action.payload) 
  console.log(' In edit workroom response is, ', response.data) 
  if (response) 
  try {
    yield put (savedWorkroom(action.payload))
  }
  catch(error) {
    sagaErrorMessage(error,action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editWorkroomAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/workroom/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Workroom 

function* deleteWorkroom() {
  yield takeEvery('DELETE_WORKROOM',saveDeleteWorkroom)
}

function* saveDeleteWorkroom(action){
  const { response, error } = yield call(deleteWorkroomAPI, action.payload)
  if (response) yield put (deletedWorkroom({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteWorkroomAPI(data) {
  let workroomId = data.id
  return axios.post(URL+'/workrooms/delete/'+ workroomId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Archive Workroom 

function* archiveWorkroom() {
  yield takeEvery('ARCHIVE_WORKROOM',saveArchiveWorkroom)
}

function* saveArchiveWorkroom(action){
  const { response, error } = yield call(archiveWorkroomAPI, action.payload)
  if (response) {
    yield put (archivedWorkroom({id: action.payload.id, msg : response.data}))
    toast.success("Archived Successfully")
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
    toast.error("Archiving failed")
  }
}

function archiveWorkroomAPI(data) {
  return axios.post(URL+'/workroom/archive', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for Archive Workroom 

function* restoreWorkroom() {
  yield takeEvery('RESTORE_WORKROOM',saveRestoreWorkroom)
}

function* saveRestoreWorkroom(action){
  const { response, error } = yield call(restoreWorkroomAPI, action.payload)
  if (response) {
    yield put (restoredWorkroom({id: action.payload.id, msg : response.data}))
    toast.success("Restored Successfully")
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
    toast.error("Restoring Failed")
  }
}

function restoreWorkroomAPI(data) {
  return axios.post(URL+'/workroom/restore', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for List Workroom 

function* fetchWorkroom() {
  yield takeEvery('FETCH_WORKROOM',requestWorkroom)
}
  

function* requestWorkroom(action){
  const isWorkroomFetched = yield select(getIsWorkroomFetched);
  if(!isWorkroomFetched){
    try {
      let { response, error } = yield call(requestWorkroomAPI)
    

    // console.log (' The Data in fetcehdworkroom is ', response.data)
    if ( response) {
    // try {
     yield put (fetchedWorkroom(response.data))
    }
    else {
      // yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }}
    catch(error) {
      sagaErrorMessage(error, action)
    }
  }
  
}


function requestWorkroomAPI(data) {
  return axios.get(URL+'/workroom/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Users In workroom

function* fetchUsersInWorkroom() {
  yield takeEvery('FETCH_USERS_IN_WORKROOM',requestUsersInWorkroom)
}
  

function* requestUsersInWorkroom(action){
 
    try {
        let { response, error } = yield call(requestUsersInWorkroomAPI, action.payload)
        if (response) 
        
          yield put (fetchedUsersInWorkroom({responseData: response.data, requestData: action.payload}))
        
        else {
          yield put (handlError("FETCH_USERS_IN_WORKROOM", parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    // yield put (fetchedUsers(JSON.parse(data)))
  
}


function requestUsersInWorkroomAPI(data) {
  let wroomId = data.workroomId
  let requesturl = URL+'/workroom/users-in-workroom/' + wroomId
  console.log (' URL in saga is :', requesturl)
  return axios.get(requesturl, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for My List Workroom 

function* fetchMyListWorkroom() {
  yield takeEvery('FETCH_MY_LIST_WORKROOM',requestMyListWorkroom)
}
  

function* requestMyListWorkroom(action){
 
    try {
      let { response, error } = yield call(requestMyListWorkroomAPI)
    

    // console.log (' The Data in fetcehdworkroom is ', response.data)
    if ( response) {
    // try {
     yield put (fetchedMyListWorkroom(response.data))
    }
    else {
      // yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }}
    catch(error) {
      sagaErrorMessage(error, action)
    }
  
  
}


function requestMyListWorkroomAPI(data) {
  return axios.get(URL+'/workroom/mylist', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion




//#region Saga for Search Workroom 

function* searchWorkroom() {

  yield takeLatest('SEARCH_WORKROOM',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchWorkroomResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/workrooms/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* workroomSaga() {
    yield all([
        createWorkroom(),
        fetchWorkroom(),
        fetchMyListWorkroom(),
        fetchUsersInWorkroom(),
        editWorkroom(),
        deleteWorkroom(),
        searchWorkroom(),
        archiveWorkroom(),
        restoreWorkroom()
    ])
  }  