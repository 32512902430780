import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsJobsFetched,isJobsFetchedByWorkroomId } from './selectors'
import { savedJobs,createdJobs, fetchedJobsByJobKey, fetchedQueueJobs, fetchedMyCreatedJobs, updatedJobName,updatedJobPriority } from './actions'
import { storeSearchJobsResult, deletedJobs, fetchedJobsByWorkroomId, fetchedJobsByTeamId,updatedJobDeadline, removedPriority } from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL }  from '../../../store/path'
import axios from 'axios';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Jobs 

function* createJobs() {
    yield takeEvery('CREATE_JOBS',saveJobs)
  }

function* saveJobs(action){
  try {
      const { response, error } = yield call(saveJobsAPI, action.payload)
      console.log("Saga response in Saved Jobs:::::",response.data)
      if (response) 
      
        yield put (createdJobs(response.data))
      
      else {
        yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
}

function saveJobsAPI(data) {
    return axios.post(URL+'/job/start', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Jobs 

function* editJobs() {
  yield takeEvery('EDIT_JOBS',saveEditedJobs)
}

function* saveEditedJobs(action){
  try {
    const { response, error } = yield call(editJobsAPI, action.payload)  
    if (response) 
    
      yield put (savedJobs(action.payload))
    
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
}

function editJobsAPI(data) {
  let user_id = data._id
  return axios.post(URL+'/jobs/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Updating Jobs Priority

function* updateJobsPriority() {
  yield takeEvery('UPDATE_JOB_PRIORITY',saveJobsPriority)
}

function* saveJobsPriority(action){
  try {
    const { response, error } = yield call(saveJobsPriorityAPI, action.payload)  
    if (response) 
    
      yield put (updatedJobPriority(response.data))
    
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
} catch(error) {
  sagaErrorMessage(error, action)
}
}

function saveJobsPriorityAPI(data) {
  // let user_id = data._id
  return axios.post(URL+'/jobs/set-priority', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Updating Jobs Deadline

function* updateJobsDeadline() {
  yield takeEvery('UPDATE_JOB_DEADLINE',saveJobsDeadline)
}

function* saveJobsDeadline(action){
  try {
  const { response, error } = yield call(saveJobsDeadlineAPI, action.payload)  
  // console.log(' Response received from update deadline is ', response.data)
    if (response) 
    
      yield put (updatedJobDeadline(response.data))
    
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
} catch(error) {
  sagaErrorMessage(error, action)
}
}

function saveJobsDeadlineAPI(data) {
  // let user_id = data._id
  return axios.post(URL+'/jobs/set-deadline', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Updating Jobs Name

function* updateJobsName() {
  yield takeEvery('UPDATE_JOB_NAME',saveJobsName)
}

function* saveJobsName(action){
  try {
  const { response, error } = yield call(saveJobsNameAPI, action.payload)  
  // console.log(' Response received from update deadline is ', response.data)
    if (response) 
    
      yield put (updatedJobName(response.data))
    
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
} catch(error) {
  sagaErrorMessage(error, action)
}
}

function saveJobsNameAPI(data) {
  // let user_id = data._id
  return axios.post(URL+'/jobs/update-name', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



//#region Saga for Delete Jobs 

function* deleteJobs() {
  yield takeEvery('DELETE_JOBS',saveDeleteJobs)
}

function* saveDeleteJobs(action){
  const { response, error } = yield call(deleteJobsAPI, action.payload)
  if (response) 
  try {
    yield put (deletedJobs(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteJobsAPI(data) {
  let jobsId = data.id
  return axios.post(URL+'/jobs/delete', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for Delete Jobs 

function* removePriorityfromJob() {
  yield takeEvery('REMOVE_PRIORITY',saveRemovePriority)
}

function* saveRemovePriority(action){
  const { response, error } = yield call(saveRemovePriorityAPI, action.payload)
  if (response) 
  try {
    yield put (removedPriority(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveRemovePriorityAPI(data) {
  console.log('data------------------------------------', data)
  return axios.post(URL+'/jobs/remove-priority', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Queue Jobs 

function* fetchQueueJobs() {
  yield takeEvery('FETCH_QUEUE_JOBS',requestJobs)
}
  

function* requestJobs(action){

    try {
        let { response, error } = yield call(requestJobsAPI, action.payload)
        console.log (' @@@@ in jobs saga, queue response is ', response.data)
        if ( response ) 
      
          yield put (fetchedQueueJobs(response.data))
      
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  // }
}


function requestJobsAPI(data) {
  let userId = data.userId
  let requrl = URL+'/jobs/list/myjobs/' + userId
  console.log ('@@@@ Request URL in jobs Queue is ', requrl)
  
  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Queue Jobs 

function* fetchMyCreatedJobs() {
  yield takeEvery('FETCH_MY_CREATED_JOBS',requestCreatedJobs)
}
  

function* requestCreatedJobs(action){

    try {
        let { response, error } = yield call(requestCreatedJobsAPI, action.payload)
        console.log (' @@@@ in jobs saga, queue response is ', response.data)
        if ( response ) 
      
          yield put (fetchedMyCreatedJobs(response.data))
      
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  // }
}


function requestCreatedJobsAPI(data) {
  let requrl = URL+'/reports/task/created-by-me'  
  return axios.get(requrl, getHeaderJWT())
              .then(response => ({ response }))
              .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Jobs By Workroom

function* fetchJobsByWorkroomId() {
  yield takeEvery('FETCH_JOBS_BY_WORKROOM_ID',requestJobsByWorkroomId)
}
  

function* requestJobsByWorkroomId(action){
  console.log(' !!!! Came to saga requestJobsByWorkroomId: ',action.payload.workroomId )
  //  const isJobsFetched = yield select(isJobsFetchedByWorkroomId(action.payload.workroomId));
  //  if(!isJobsFetched){
    let { response, error } = yield call(requestJobsByWorkroomIdAPI, action.payload)
    // console.log (' @@@@ in jobs saga, workroo response is ', response.data)
    if ( response ) 
    try {
      yield put (fetchedJobsByWorkroomId(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  //  }
}


function requestJobsByWorkroomIdAPI(data) {
  let workroomId = data.workroomId
  let requrl = URL+'/jobs/list/workroom/' + workroomId
  console.log ('@@@@ Request URL is ', requrl)
  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Jobs By Team

function* fetchJobsByTeamId() {
  yield takeEvery('FETCH_JOBS_BY_TEAM_ID',requestJobsByTeamId)
}
  

function* requestJobsByTeamId(action){
  console.log(' !!!! Came to saga requestJobsByTeamId: ',action.payload.teamId )
  //  const isJobsFetched = yield select(isJobsFetchedByWorkroomId(action.payload.workroomId));
  //  if(!isJobsFetched){
    let { response, error } = yield call(requestJobsByTeamIdAPI, action.payload)
    // console.log (' @@@@ in jobs saga, workroo response is ', response.data)
    if ( response ) 
    try {
      yield put (fetchedJobsByTeamId(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  //  }
}


function requestJobsByTeamIdAPI(data) {
  let teamId = data.teamId
  let requrl = URL+'/jobs/list/team/' + teamId
  console.log ('@@@@ Request URL in jobs by team is ', requrl)
  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetch Jobs By JobKey

function* fetchJobsByJobkey() {
  yield takeEvery('FETCH_JOBS_BY_JOBKEY',requestJobsByJobkey)
}
  

function* requestJobsByJobkey(action){
  // console.log(' !!!! Came to saga requestJobsByJobkey: ',action.payload.jobKey )
    let { response, error } = yield call(requestJobsByJobkeyAPI, action.payload)
    if ( response ) 
    try {
      yield put (fetchedJobsByJobKey({'responseData': response.data, 'requestData' : action.payload}))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
}


function requestJobsByJobkeyAPI(data) {
  let jobKey = data.jobKey
  let requrl = URL+'/jobs/list/' + jobKey
  console.log ('@@@@ Request URL in requestJobsByJobkeyAPI is ', requrl)
  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion




//#region Saga for Search Jobs 

function* searchJobs() {

  yield takeLatest('SEARCH_JOBS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchJobsResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/jobs/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* jobsSaga() {
    yield all([
        createJobs(),
        fetchMyCreatedJobs(),
        fetchQueueJobs(),
        editJobs(),
        deleteJobs(),
        searchJobs(),
        fetchJobsByWorkroomId(),
        fetchJobsByTeamId(),
        fetchJobsByJobkey(),
        updateJobsPriority(),
        updateJobsDeadline(),
        updateJobsName(),
        removePriorityfromJob()
    ])
  }  