import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Table,
  TableHeader,
  TableBody,
  Button,
  TableRow,
  TableCell,
  Ref,
  Grid,
  Message,
} from "semantic-ui-react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import {
  getListOfTeamUserObjsForUser,
  getTeamNameFromId,
} from "../../team/data/selectors";
import { updateUserTeamPriority } from "../../team/data/actions";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";

const TeamUser = ({ tuser, index, widths }) => {
  // const dispatch = useDispatch();
  // const isNegative = job.status === 1 ? false : true;
  const teamname = useSelector((state) =>
    getTeamNameFromId(state, tuser.teamId)
  );

  return (
    <Draggable key={tuser.id} draggableId={tuser.id} index={index}>
      {(provided) => (
        <Ref innerRef={provided.innerRef}>
          <TableRow
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="borderBottomObj padding15"
          >
            <TableCell style={{ width: "5%" }}>{tuser.priority}</TableCell>
            <TableCell style={{ width: "75%" }}>{teamname}</TableCell>
          </TableRow>
        </Ref>
      )}
    </Draggable>
  );
};

const UsersTeamPriority = (props) => {
  const data = teamUsers;

  // if(props.match.path==='/users/teampriority/:id'){
  const userid = props.match.params.id;

  const teamUsers = useSelector((state) =>
    getListOfTeamUserObjsForUser(state, userid)
  );
  const [listItems, setListItems] = useState([]);
  const [savedSuccess, setSavedSuccess] = useState(false);

  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  // const redirectPath = '/team/display' + teamId
  // const userId = "327e6339-cce5-4afc-8e9e-7f75f2e56ca4"

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (teamUsers.length > 0) {
      setListItems(cloneDeep(teamUsers));
    }
  }, []);

  const handleSubmit = () => {
    // let newarray = listitems.filet
    let newarray = listItems.filter(function (obj) {
      return obj.priority !== null;
    });


    dispatch(updateUserTeamPriority(newarray));
    setSavedSuccess(true);
    setTimeout(function () {
      setSavedSuccess(false);
      props.history.push(`/users/list`);
    }, 1000);
  };

  const handleCancel = () => {
    setListItems([]);
    props.history.push(`/users/list`);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;
    if (
      destination.droppableId == source.droppableId &&
      destination.index == source.index
    )
      return;

    const items = Object.assign([], listItems);
    let curritem = listItems[source.index];
    let previtem = null;

    if (destination.index > 0) {
      if (destination.index < source.index)
        previtem = listItems[destination.index - 1];
      else previtem = listItems[destination.index];
    }

    if (previtem != null)
    if (previtem == null) curritem.priority = 1;
    else {
      if (previtem.priority == undefined || previtem.priority == null)
        curritem.priority = null;
      if (
        previtem.priority != undefined &&
        previtem.priority != null &&
        destination.index < source.index
      )
        curritem.priority = parseInt(previtem.priority + 1, 10);
      if (
        previtem.priority != undefined &&
        previtem.priority != null &&
        destination.index > source.index
      )
        curritem.priority = parseInt(previtem.priority - 1, 10);
    }

    items.splice(source.index, 1);
    items.splice(destination.index, 0, curritem);

    items.map((item, index) => {
      if (index == 0 && item.priority != null) item.priority = 1;
      if (index > 0) {
        previtem = items[index - 1];
        if (previtem.priority != null && item.priority != null)
          item.priority = previtem.priority + 1;
        if (index == items.length - 1 && previtem.priority != null)
          item.priority = previtem.priority + 1;
      }
    });
    setListItems(items);
  };

  // Loading Logic if tasks not fetched
  if (data && data.length == 0) {
    return <p>Loading ... </p>;
  } else {
    return (
      <Container>
        <div
          id="outerDiv"
          className="outerDivClass paddingLeft20 paddingRight20"
        >
          {/* <Grid.Column width={5} className="paddingZero paddingRight30 borderLeft"> */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Table className="borderNoneObj paddingLeft20 paddingRight20">
              <TableHeader style={{ width: "80%" }}>
                <TableCell
                  style={{ width: "5%" }}
                  className="borderNoneObj head borderBottomObj"
                >
                  Priority
                </TableCell>
                <TableCell
                  style={{ width: "75%" }}
                  className="borderNoneObj head borderBottomObj"
                >
                  Team
                </TableCell>
              </TableHeader>

              <Droppable droppableId={userid}>
                {(provided) => (
                  <Ref innerRef={provided.innerRef}>
                    <TableBody {...provided.droppableProps}>
                      {listItems.map((tuser, i) => (
                        <TeamUser
                          tuser={tuser}
                          key={tuser.id}
                          index={i}
                          widths={widths}
                        />
                      ))}

                      {provided.placeholder}
                    </TableBody>
                  </Ref>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
          <Button
            type="submit"
            size="medium"
            color="blue"
            className="CustomeBTN"
            onClick={handleSubmit}
          >
            {" "}
            Submit{" "}
          </Button>
          <Button
            type="cancel"
            size="medium grey"
            className="CustomeBTN"
            onClick={handleCancel}
          >
            {" "}
            Cancel{" "}
          </Button>
          {savedSuccess ? (
            // <Notification id={savedActivityId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
            <Message positive style={{ width: "50%" }}>
              <Message.Header>Form submitted successfully</Message.Header>
            </Message>
          ) : null}

          {/* </Grid.Column> */}
        </div>
      </Container>
    );
  }
};

export default UsersTeamPriority;
