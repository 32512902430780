
export const CREATE_TEAM = 'CREATE_TEAM'
export const SAVED_TEAM = 'SAVED_TEAM'
export const EDIT_TEAM = 'EDIT_TEAM'
export const EDITED_TEAM = 'EDITED_TEAM'
export const FETCH_TEAM = 'FETCH_TEAM'
export const FETCHED_TEAM = 'FETCHED_TEAM'
export const SEARCH_TEAM = 'SEARCH_TEAM'
export const STORE_SEARCH_TEAM_RESULT = 'STORE_SEARCH_TEAM_RESULT'
export const DELETE_TEAM = 'DELETE_TEAM'
export const DELETED_TEAM = 'DELETED_TEAM'
export const NOTIFICATION_DONE_TEAM = 'NOTIFICATION_DONE_TEAM'
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER'
export const ADDED_TEAM_MEMBER = 'ADDED_TEAM_MEMBER'
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER'
export const REMOVED_TEAM_MEMBER = 'REMOVED_TEAM_MEMBER'
export const UPDATE_USER_TEAM_PRIORITY = 'UPDATE_USER_TEAM_PRIORITY'
export const UPDATED_USER_TEAM_PRIORITY = 'UPDATED_USER_TEAM_PRIORITY'
export const FILTER_ITEMS = 'FILTER_ITEMS'
export const ARCHIVE_TEAM = 'ARCHIVE_TEAM'
export const ARCHIVED_TEAM = 'ARCHIVED_TEAM'
export const RESTORE_TEAM = 'RESTORE_TEAM'
export const RESTORED_TEAM = 'RESTORED_TEAM'
