import * as Yup from 'yup';


export const login = () => ({
    user: "",
    password: ""
})


export const loginSchema = Yup.object().shape({

    user: Yup.string()
                .required('This is a Required Field'),

    password: Yup.string()
                .required('This is a Required Field'),

  });
