// import { createSelector } from 'reselect'
import { startsWith, mapValues, orderBy } from 'lodash';
import { v4} from 'uuid';
import { getJobById } from '../../jobs/data/selectors'


export const getIsFetchingTasks = (state, props) => state.tasks.tasksParams.isFetching;
export const getIsTasksFetched = (state, props) => state.tasks.tasksParams.tasksFetched;
export const getIsTeamTasksFetched = (state, props) => state.tasks.tasksParams.tasksFetchedOfTeam;
export const getAllTasks = (state, props) => state.tasks.byId;
export const getActiveTask = (state, props) => state.tasks.tasksParams.activeTask;

const orgSelected = (state) => state.org.params.selected;
const projectSelected = (state) => state.project.params.selected;

export const getTask = (state, props) => {
    if(props.match.path==='/tasks/create'){
        let tasks = state.tasks.tasksById;
        let model = mapValues(tasks[Object.keys(tasks)[0]],()=>'')
        model.steps=[{rank:1, action: "TODO", notes:""}];
        // model.cid = orgSelected(state);
        // model.project = projectSelected(state);
        
        model.collaborators = [{no:"", name: "", role : ""}]
        model.uuid=v4();
        model.task_completed = false;
        delete model.id;
        return model
    }
    if(props.match.path==='/tasks/edit/:id'){
        let task_id = props.match.params.id
        let task = state.tasks.byId[task_id];
        return task
    }
}


export const getTasksByJobId=(state, jobId) =>{ 
    if (!jobId) return undefined
    const alltasks = state.tasks.byId

    // if (!alltasks) return undefined
    let tasks = alltasks[jobId]
    if (!tasks) return undefined

    // console.log (' Activities are ', activities[processId])
    return (orderBy(Object.values(tasks), ['dtCreate'],  ['asc'])
    )
}


export const getCurrentTaskByJobId=(state, jobId) =>{ 
    const tasks = getTasksByJobId( state, jobId)
    if (!tasks) return undefined

    let obj = tasks.filter((value) => {
        if (value.taskStatus != 0) {
          return value
        }
      })
      if (obj.length > 0) // found an active task
        return (obj[0])
      else  // not found - we will just send the first task of this job then
      {
        
       let job =  getJobById (state, jobId)
       let currActivityId = job.wfCurrentActivityId
       if (currActivityId) {
            let currTask = tasks.find((item) => item.activityId == currActivityId)
            return currTask
       }
       return tasks[0]
      }      
}


export const getTaskParams = (state, props) => {

    const params =  {}

    if(startsWith(state.router.location.pathname,'/tasks/create')){ 
        params.title = state.tasks.tasksParams.createTitle
        params.submitButtonText = state.tasks.tasksParams.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/tasks/edit/')){ 
        params.title = state.tasks.tasksParams.editTitle
        params.submitButtonText = state.tasks.tasksParams.editSubmitButtonText        
    };

    return params
}