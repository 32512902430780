import React, {useState, useEffect} from 'react';
import { Field, FieldArray } from 'formik';
import { Button, Checkbox, Form, Table, Label, Grid } from 'semantic-ui-react';

import { FormikInputComponent, FormikCheckBoxComponent, FormikRichText } from './formUtils';

export const DynamicFormComponent = ({formUI, nestedFields, errors, values, arrToPush}) => (
    <>
    {Array(formUI["meta"]["maxRows"]).fill(0).map((_, i) => (
        <Form.Group widths={3}>
            {console.log(errors)}
            {Object.keys(nestedFields[i+1]).map(k=>{
                let field = nestedFields[i+1][k]
                switch(field["component"]){
                case "input": 
                    return <Field required={field["required"]} name={field["name"]} label={field["label"]} component={FormikInputComponent}></Field>  
                case "checkbox": 
                    return <Field required={field["required"]} name={field["name"]} label={field["label"]} component={FormikCheckBoxComponent}></Field>  
                }
            })}
        </Form.Group>
    ))}
    <br/>
    {formUI["fieldArray"].length > 0 ? 
        <FieldArray name={formUI["fieldArray"][0]["name"].split(".")[0]}>
            {({ insert, remove, push }) => (
            <>
                <Table basic >
                    <Table.Header>
                        <Table.Row key="header">
                            {formUI["fieldArray"].map(row =>  (
                                <Table.HeaderCell textAlign="left">{row["label"]}</Table.HeaderCell>
                            ))}
                                <Table.HeaderCell textAlign="left">Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {values && values[formUI["fieldArray"][0]["name"].split(".")[0]] && values[formUI["fieldArray"][0]["name"].split(".")[0]].length > 0 &&
                            values[formUI["fieldArray"][0]["name"].split(".")[0]].map((record, index) => (
                            <>
                            <Table.Row>
                                {formUI["fieldArray"].map((field, index1) =>  {
                                    arrToPush = { ...arrToPush, [field["name"].split(".")[2]] : "" }
                                return (
                                    <Table.Cell>
                                        <Field name={`${field["name"].split(".")[0]}.${index}.${field["name"].split(".")[2]}`} isLabel={true} isTxn={true} placeholder={field["name"].split(".")[2]} component={FormikInputComponent}></Field>                          
                                    </Table.Cell>
                                )})}
                                    <Table.Cell>
                                        <Button onClick={() => remove(index)}> X </Button>
                                    </Table.Cell>
                            </Table.Row>
                            </>
                            ))}
                            <Table.Row>
                                {formUI["fieldArray"].map((field, index1) =>  (<Table.Cell></Table.Cell>))}
                                <Table.Cell>
                                    <Button type="button" float={'right'} onClick={() => push(arrToPush)}>Add a row</Button>
                                </Table.Cell>
                            </Table.Row>
                    </Table.Body>
                </Table>                                    
            </>
            )}
        </FieldArray>
        : null}
    </>
)


export const DynamicFormDisplayComponent = ({formUI, nestedFields, data}) => (
    // {console.log("DynamicFormDisplayComponent formUI", formUI)}
    <Grid columns={2} style={{paddingLeft: "25px"}}>
    {Array(formUI["meta"]["maxRows"]).fill(0).map((_, i) => (
        <Grid.Row widths={3}>
            {Object.keys(nestedFields[i+1]).map(k=>{
                let field = nestedFields[i+1][k]
                switch(field["component"]){
                case "input": 
                    return <Grid.Column> 
                        <div style={{color: "gray", fontSize: "0.9em"}}>{field["label"]}</div>
                        <div style={{paddingTop: "5px", fontSize: "1.1em"}}>{data[field["name"]] && data[field["name"]] != "" ? data[field["name"]] : "-"}</div>
                        </Grid.Column> 
                case "checkbox": 
                    return <Grid.Column> 
                        <div style={{color: "gray", fontSize: "0.9em"}}>{field["label"]}</div>
                        <div style={{paddingTop: "5px", fontSize: "1.1em"}}>Name</div>
                        </Grid.Column> 
        }
            })}
        </Grid.Row>
    ))}
    </Grid>
)