import { v4 } from "uuid";
import * as Yup from "yup";

export const role = () => ({
  id: v4(),
  roleName: "",
});

export const searchColumns = [
  {
    Header: "Name",
    accessor: "roleName",
  },
];

export const roleSchema = Yup.object({
  id: Yup.string().required("this is required field"),
  roleName: Yup.string().required("this is required field"),
});

export const permissionModel = (roleId) => ({
  moduleName: "",
  roleId: roleId,
  canCreate: 0,
  canUpdate: 0,
  canDelete: 0,
  canExport: 0,
  id: v4(),
});

export const permissionSchema = Yup.object({
  moduleName: Yup.string().required("required"),
  id: Yup.string().required("required"),
});

export const permissionOptionsArray = [
  "activity",
  "client",
  "process",
  "team",
  "api",
  "workroom",
  "scheduler",
  "users",
  "report",
  "tasks",
  "module",
];
