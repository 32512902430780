import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a teams schema
const teamSchema = new schema.Entity('teams',{}, { idAttribute: 'id' });
const teamListSchema = [teamSchema];

const defaultTeamParams = {
  teamFetched: false,
  searchFetched: false,
  isDelFetched: false,
  listTitle: 'Team List',
  createTitle: 'Create Team ',
  createSubmitButtonText: 'Save',
  editTitle: 'Edit Team',
  editSubmitButtonText: 'Update' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_TEAM') {
        const teamID = action.payload.id;
      return {...state, 
        [teamID]: action.payload
      }
    }
    if (action.type === 'FETCHED_TEAM') { 
        const normalizedTeam = normalize(action.payload, teamListSchema);
        return merge({},state,normalizedTeam.entities.teams)
    }
    if (action.type === 'UPDATED_USER_TEAM_PRIORITY') { 
      const normalizedTeam = normalize(action.payload, teamListSchema);
      return merge({},state,normalizedTeam.entities.teams)
    }       
    if (action.type === 'ADDED_TEAM_MEMBER') { 
      const teamId = action.payload.teamId;
      return {...state, 
        [teamId]: {...state[teamId],
          teamUsers : [...state[teamId].teamUsers, action.payload]
         } 
      }
    }
    if (action.type === 'REMOVED_TEAM_MEMBER') { 
      const teamId = action.payload.teamId;
      return {...state, 
        [teamId]: {...state[teamId],
          teamUsers : state[teamId].teamUsers.filter(user => user.id != action.payload.id)
         }
      }
    }
    if (action.type === 'ARCHIVED_TEAM') { 
      return {...state, 
        [action.payload.id]: { ...state[action.payload.id],
          status :0

        }
      }
    } if (action.type === 'RESTORED_TEAM') { 
      return {...state, 
        [action.payload.id]: { ...state[action.payload.id],
          status :1
        }
      }
    } 
    if (action.type === 'DELETED_TEAM') {
      if(action.payload.msg === "Success"){
        const teamID = action.payload.id;
        return {...state, 
          [teamID]: { ...state[teamID],
            status :0
          }
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) { 
  if (action.type === 'STORE_SEARCH_TEAM_RESULT') { 
    const normalizedTeam = normalize(action.payload, teamListSchema);
    return merge({},normalizedTeam.entities.teams)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_TEAM') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if (action.type === 'SAVED_TEAM') {
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        save: { 
          status : "success" ? 'success' : 'error',
          msg : action.txn
        }
      }
    }    
  }
  if(action.type === 'NOTIFICATION_DONE_TEAM'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultTeamParams, action) {
  if (action.type === 'FETCHED_TEAM') { 
      return {...state, 
        teamFetched: true,
        isFetching:false
      }
  }   if (action.type === 'SEARCH_TEAM') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_TEAM_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_TEAM') { 
    return {...state, 
      isDelFetched:true
    }
  }if(action.type === 'SET_TEAM_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}


function filterResults(state = {}, action) {
  if (action.type === 'FILTER_ITEMS') { 
      const col = action.payload.col;
      return {...state,
        [col]: action.payload
      }
  } else {
    return state
  }
}


const team = combineReducers({
    byId,
    notifications,
    searchResults,
    params,
    filterResults
});

export default team;

