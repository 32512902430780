import * as type from './types'


export function createActivity(activity) {
    // //console.log("From actions")
    return {
        type : type.CREATE_ACTIVITY,
        payload : activity,
        txn : 'initiated',
    };
}

export function savedActivity(activity) {
    return {
        type : type.SAVED_ACTIVITY,
        payload : activity,
        // txn : 'success',
        // diff : true
    };
}

export function editActivity(activity) {
    return {
        type : type.EDIT_ACTIVITY,
        payload : activity,
        txn : 'initiated',
    };
}

export function fetchActivity() {
    return {
        type : type.FETCH_ACTIVITY,
    };
}

export function fetchedActivity(activity) {
    return {
        type : type.FETCHED_ACTIVITY,
        payload : activity
    };
}

export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_ACTIVITY,
        payload: props
    };
}

export function fetchActivityByProcess(processId) {
    return {
        type : type.FETCH_ACTIVITY_BY_PROCESS,
        payload: processId,
        txn : 'initiated',
    };
}

export function fetchedActivityByProcess(activity) {
    return {
        type : type.FETCHED_ACTIVITY_BY_PROCESS,
        payload : activity
    };
}

export function selectActivity(activity_id) {
    return {
        type : type.SELECT_ACTIVITY,
        payload : activity_id
    };
}

export function updateActivityDefinition(activity) {
    console.log("From actions")
    return {
        type : type.UPDATE_ACTIVITY_DEFINITION,
        payload : activity,
        txn : 'initiated',
    };
}

export function updatedActivityDefinition(activity) {
    return {
        type : type.UPDATED_ACTIVITY_DEFINITION,
        payload : activity,
        // txn : 'success',
        // diff : true
    };
}

export function resetActivityDefinitionFlag() {
    console.log (' %%%% Resetting definition flag &&&&')
    return {
        type : type.RESET_ACTIVITY_DEFINITION_FLAG,
        // payload : activity,
        // txn : 'success',
        // diff : true
    };
}