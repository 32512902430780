import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProcess,
  deleteProcess,
  setNotifyDone,
  filterItems,
} from "../data/actions";
import * as select from "../data/selectors";
import { searchColumns } from "../data/model";
import { Container } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { updateHeaderTitle } from "../../app/data/actions";
import { TableList } from "../../../utilities/tableUtils";
import { Link } from "react-router-dom";

const ProcessList = (props) => {
  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  const dispatch = useDispatch();
  const process = useSelector((state) => select.getProcessArray(state));
  const processFetched = useSelector((state) =>
    select.getIsProcessFetched(state, props)
  );

  const ProcessActions = ({ object: process }) => {
    return (
      <>
        <Link
          to={{
            pathname: "/process/edit/" + process.id,
            processId: process.id,
            processName: process.process_name,
          }}
        >
          Edit
        </Link>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </>
    );
  };

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );

    if (!processFetched) {
      dispatch(fetchProcess());
    }
  }, [processFetched]);

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Process List" }));
  }, []);

  // Loading Logic if tasks not fetched
  return (
    <Container>
      {processFetched === false ? (
        <p>Loading ... </p>
      ) : (
        <div
          id="outerDiv"
          className="outerDivClass paddingRight20"
          style={{ width: widths + "px" }}
        >
          <div style={{ height: heights - 20 + "px", width: "100%" }}>
            <TableList
              columns={searchColumns}
              data={process}
              Actions={ProcessActions}
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default ProcessList;
