import React from "react";
import { Route } from "react-router";
import { Container } from "semantic-ui-react";
import ModuleForm from "./components/ModuleForm";
import ModuleList from "./components/ModuleList";
import ModuleFormEdit from "./components/ModuleFormEdit";
function Module() {
  return (
    <Container>
      <Route path="/module/create" component={ModuleForm}></Route>
      <Route path="/module/edit/:id" component={ModuleFormEdit}></Route>
      <Route path="/module/list" component={ModuleList}></Route>
    </Container>
  );
}

export default Module;
