import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { createActivity, editActivity, setNotifyDone } from '../data/actions';
import { cloneDeep } from 'lodash';
// import { activityOptions } from '../data/selectors';
import { activitySchema, newActivity } from '../data/model';
import { getActivityById, getActivityParams, getNotification, getStepsByProcess } from '../data/selectors'
import { stepsSchema, duplicateCheckStepsName } from '../../steps/data/model'
import { Container, Header, Button, Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent, FormikTextAreaComponent } from '../../../utilities/formUtils';
import ActivitySelect from '../../activities/components/activitySelect' 
import { heightSet } from '../../../utilities/heightForListing'
import userACL from '../../../store/access'  
import Notification from '../../../utilities/notificationUtils'
import v4 from 'uuid';
import moment  from 'moment'

const ActivityForm = (props) => {
    const activityId = props.match.params.id
    const processId = props.location.processId
    const [savedActivityId, setSavedActivityId] = useState(false)
    const [activity, setActivity] = useState()
    const [heights, setHeight] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    const saveActivity = (values, actions) => {
        console.log("activity values", values)
        // if (props.match.path === '/activity/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editActivity(values))
            // props.history.push({pathname: "/process/edit/" + processId, processId:processId}) 
            // setActivity (values)
            // setSavedActivityId(values.id)
            setSavedActivityId(true)
            setTimeout(function(){ 
                setSavedActivityId(false)  
            }, 2000);
        // }
        
        // actions.resetForm(values)
    }

    const activityFetched = useSelector(state => getActivityById(state, activityId, processId))
   /*  console.log("activity fetched", activityFetched)
    useEffect(() => {
        if (!activityFetched) {
            setActivity (newActivity())
        } else {
            
            setActivity(cloneDeep(activityFetched))
        }
    }, [activityFetched]) */

    return (
     
                // <Grid.Column className="paddingLeftRight70 paddingTopZero outerDivClassForForm" style={{height: (heights)+"px"}}>
                <>   
                <Formik id="activityForm" size="large" width={5}
                        enableReinitialize
                        initialValues={activityFetched}
                        // keepDirtyOnReinitialize
                        // validationSchema={activitySchema}
                        onSubmit={(values, {resetForm}) => saveActivity(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange, setFieldValue }) => (
                            <Form as={FormikForm} size="small" className="CustomeForm marginTopZero" width={5} onSubmit={handleSubmit}>
                                <Field required={true} name="activityName" label="Activity Name" component={FormikInputComponent} placeholder="Name"></Field>  
                                <Form.Group widths={2}>
                                    <ActivitySelect required={false} name="activityTemplate" label="Activity Template"/>
                                    <Field name="rank" required={false} component={FormikInputComponent} placeholder="Rank"></Field>
                                </Form.Group>
                                <Form.Group widths={2}> 
                                    <Field name="status" required={false} component={FormikInputComponent} placeholder="Status"></Field>  
                                    <Field name="role" required={false} component={FormikInputComponent} placeholder="Role"></Field>
                                </Form.Group>
                                    <Field name="guidelines" required={false} userProps="" component={FormikTextAreaComponent} placeholder="Guidelines" label='Guidelines'/>
                                <Button type="submit"  size="medium blue" className="CustomeBTN"> Submit </Button>
                                {/* {console.log(JSON.stringify(savedStepId)+"==============================================notification")
                                } */}
                                {savedActivityId ?
                                    // <Notification id={savedActivityId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    <Message positive>
                                    <Message.Header>Form submitted successfully</Message.Header>
                                  </Message>
                                    : 
                                null}
                            </Form>
                        )} 
                    />
                    </>
               
         
    )
}

export default ActivityForm;
