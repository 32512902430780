import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a jobss schema
const jobsSchema = new schema.Entity('jobs',{}, { idAttribute: 'id' });
const jobsListSchema = [jobsSchema];

const defaultJobsParams = {
  jobsFetched: false,
  createdJobsFetched: false,
  newJobCreated: null,
  jobDenied : [],
  searchFetched: false,
  isDelFetched: false,
  workroomJobsFetched: [],
  teamJobsFetched:[],
  createTitle: 'Create New Jobs',
  createSubmitButtonText: 'Create Jobs',
  editTitle: 'Edit Jobs',
  editSubmitButtonText: 'Edit Jobs' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_JOBS') {

      const jobsID = action.payload.id;
      return {...state, 
        [jobsID]: action.payload
      }
    }
    if (action.type === 'REMOVED_PRIORITY') { 
      console.log('REMOVED_PRIORITY', action.payload)
      return {...state, 
        [action.payload]: { ...state[action.payload],
          priority :null
        }
      }
    } 
    if (action.type === 'FETCHED_JOBS_BY_WORKROOM_ID') {
      const jobs = action.payload;  // array of jobs
      const normalizedJobs = normalize(action.payload, jobsListSchema);
        return merge({},state,normalizedJobs.entities.jobs)
  }
  if (action.type === 'FETCHED_JOBS_BY_JOBKEY') {
    // const jobs = action.payload.responseData;  // array of jobs
    const normalizedJobs = normalize(action.payload.responseData, jobsListSchema);
      return merge({},state,normalizedJobs.entities.jobs)
}
  if (action.type === 'FETCHED_JOBS_BY_TEAM_ID') {
    const jobs = action.payload;  // array of jobs
    const normalizedJobs = normalize(action.payload, jobsListSchema);
      return merge({},state,normalizedJobs.entities.jobs)
}    
    if (action.type === 'DELETED_JOBS') {

      const {id} = action.payload
      const { [id]: idValue, ...restOfState } = state;    
      return restOfState
     
    } else {
      return state
    }
  }


function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_JOBS_RESULT') { 
    const normalizedJobs = normalize(action.payload, jobsListSchema);
    return merge({},normalizedJobs.entities.jobss)
} else {
    return state
  }
}

    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_JOBS') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_JOBS'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultJobsParams, action) {
  if (action.type === 'FETCHED_QUEUE_JOBS') { 
      return {...state, 
        jobsFetched: true,
        isFetching:false
      }
  } 
  if (action.type === 'FETCHED_MY_CREATED_JOBS') { 
      return {...state, 
        createdJobsFetched: true,
        isCreatedJobsFetching:false
      }
  } 
  if (action.type === 'FETCH_MY_CREATED_JOBS') { 
      return {...state, 
        createdJobsFetched: false,
        isCreatedJobsFetching: true
      }
  } 
  if (action.type === 'FETCHED_JOBS_BY_JOBKEY') { 
    let data = action.payload.responseData
    if (data.length == 0 )
    {
      let requestedJobKey = action.payload.requestData.jobKey
      return {...state, 
        jobDenied: state.jobDenied.concat(requestedJobKey)
      }
      
    } else 
    return state

} 
  if (action.type === 'FETCHED_JOBS_BY_WORKROOM_ID') { 
    let data = action.payload
    if (data.length == 0 )
    {
      return state
      
    }
    
    let workroomid = data[0].workroomId
    // console.log (' Workroom id in reducer is ', workroomid)
    return {...state, 
      workroomJobsFetched: state.workroomJobsFetched.concat(workroomid)
    }
}
if (action.type === 'FETCHED_JOBS_BY_TEAM_ID') { 
  let data = action.payload
  if (data.length == 0 )
  {
    return state
    
  }
  
  let teamid = data[0].teamId
  // console.log (' TEAM id in reducer is ', teamid)
  return {...state, 
    teamJobsFetched: state.teamJobsFetched.concat(teamid)
  }
}
  if (action.type === 'SEARCH_JOBS') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_JOBS_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_JOBS') { 
    return {...state, 
      isDelFetched:true
    }
  }
  if (action.type === 'CREATED_JOBS') { 
    return {...state, 
      newJobCreated:action.payload.jobKey
    }
  }
  if (action.type === 'RESET_CREATED_JOB') { 
    return {...state, 
      newJobCreated:null
    }
  }
  if(action.type === 'SET_JOBS_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

const jobs = combineReducers({
    byId,
    notifications,
    searchResults,
    params
});

export default jobs;

