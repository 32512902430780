import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Comment, Label } from 'semantic-ui-react'
import axios from 'axios';
import apiContainer, { getHeaderJWT, URL } from '../../../store/path'
import { components } from 'react-select';
import { handleFileDownload } from './commentsUtils'
import moment from 'moment'
import system_icon from "../../../images/systems1.png";
import {parseSystemComment} from './commentsDisplayWorkroom'


//const axios = apiContainer.axios;

const getReadableDate = (longdate) => {
  let jdate = new Date(longdate / 1000000)
  let str = jdate.toLocaleString()

 /*  str = moment().calendar(jdate, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    // lastWeek: '[Last] dddd (DD/MM)',
    sameElse: 'DD/MM/YYYY'
}); */
  str = moment(jdate).fromNow() + " : " + moment(jdate).format('LT')

  return str
}

const getUserName = (userList, userid) => {
  if (userList == undefined || userList.length == 0) return null
  let userobj = userList.find((item) => item.value == userid)
  if (userobj == undefined) return null
  return userobj.text
}

const CommentsDisplay = ({ commentsRecd, userList }) => {

  if (!commentsRecd) return null
  let data = commentsRecd

  function renderUserComment(comment, i) {

    let attachIds = (comment.attachmentId && comment.attachmentId !='') ? comment.attachmentId.split('||') : undefined
    let attachNames = (comment.attachmentName && comment.attachmentName !='') ? comment.attachmentName.split('||') : undefined
    let commentString = (attachIds && attachIds.length > 1 ? 'Files Uploaded: ' : 'File Uploaded: ')
    const deletComments = (values)=>{
      // console.log("delete comments=",JSON.stringify(data))
    }
    return (
      <Comment key={comment.dtCreate} className='paddingBottom10'>
          <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
          <Comment.Content>
            <Comment.Author>{getUserName(userList, comment.userId)}
              <Comment.Metadata>
                {getReadableDate(comment.dtCreate)}
              </Comment.Metadata>
            </Comment.Author>
           {!attachIds ?
               <Comment.Text>
               <div dangerouslySetInnerHTML={{__html: comment.comment}} />
               </Comment.Text>
              :
              
              <Comment.Text>{comment.comment == '' ? commentString :  <div dangerouslySetInnerHTML={{__html: comment.comment}} /> }
              { attachIds.length > 1 ? <br /> : null}
              {attachIds.map(( attachmentid, i ) => 
             <>
              <Label as ='a' basic color='blue' className='borderNoneObj' id={comment.id} onClick= {()=>handleFileDownload(attachmentid)} >
                {attachNames[i]}
                
              </Label>
              <br />
              </>
              )}
              
              </Comment.Text>              
            }
            <Comment.Actions>
              <Comment.Action>Edit</Comment.Action>
              <Comment.Action onClick={deletComments}>Delete</Comment.Action>
            </Comment.Actions>
          </Comment.Content>
        </Comment>

    )
  }


  function renderSystemComment(comment, i) {

    return (

    <Comment key={comment.dtCreate} className='paddingBottom10'>
          <Comment.Avatar src={system_icon} />
          <Comment.Content>
            <Comment.Author className='fontColorDarkGrey'>{getUserName(userList, comment.userId)}
              <Comment.Metadata>
                {getReadableDate(comment.dtCreate)} : &nbsp; 
              </Comment.Metadata>
            </Comment.Author>
            <Comment.Text className='fontColorDarkGrey'>
               <div dangerouslySetInnerHTML={{__html: parseSystemComment(userList, comment.comment)}} />
               </Comment.Text>
          </Comment.Content>
        </Comment>
        
      )
  }

  return (
    <Comment.Group >
      {data.map((comment, i) => (
        comment.source =='system' ? 
        renderSystemComment (comment, i):
        renderUserComment (comment, i)
      ))
      }

    </Comment.Group>
  )
}



export default CommentsDisplay;
