import { v4 } from "uuid";
import * as Yup from "yup";

export const module = () => ({
  id: v4(),
  moduleName: "",
  workroomId: "",
  moduleUrl: "",
});

export const moduleSchema = Yup.object({
  id: Yup.string().required("required"),
  moduleName: Yup.string().required("required"),
  workroomId: Yup.string().required("required"),
  moduleUrl: Yup.string().required("required"),
});

export const processModel = (moduleId) => ({
  id: v4(),
  moduleId: moduleId,
  processName: "",
  processType: "",
});

export const processSchema = Yup.object({
  id: Yup.string().required("required"),
  processName: Yup.string().required("required"),
  processType: Yup.string().required("required"),
});
