import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as select from "../data/selectors";
import { Container, Icon } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { fetchJobsByWorkroomId } from "../data/actions";
import { JobTableList } from "../../../utilities/tableUtils";
import moment from "moment";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";

const JobsListOnHoldByWorkroom = (props) => {
  const workroomId = props.workroomId;
  const jobs = useSelector(state => select.getHoldJobsByWorkroomId(state, workroomId))
  const jobsFetched = useSelector((state) =>
    select.isJobsFetchedByWorkroomId(state, workroomId)
  );
  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!jobsFetched)
      dispatch(fetchJobsByWorkroomId({ workroomId: workroomId }));
  }, [workroomId]);

  // Loading Logic if tasks not fetched
  if (jobs && jobs.length == 0) {
    return <p> No records</p>;
  } else {
    return (
      <Container>
        <JobTableList columns={searchColumns} data={jobs} />
      </Container>
    );
  }
};
export const searchColumns = [
  {
    Header: "Sl No",
    accessor: "Sl No",
    className: "wkjh1 ellipsisField",
    headerClassName: "wkjh1 tableHeaderCell",
  },
  {
    Header: "Job Status",
    accessor: "jobStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "wkjh2 ellipsisField",
    headerClassName: "wkjh2 tableHeaderCell",
  },
  {
    Header: "Job",
    accessor: "job",
    className: "wkjh3 ellipsisField",
    headerClassName: "wkjh3 tableHeaderCell",
  },
  {
    Header: "Date Start",
    accessor: "dtStart",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "wkjh4",
    headerClassName: "wkjh4 tableHeaderCell",
  },
  {
    Header: "Date Update",
    accessor: "dtUpdate",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "wkjh5",
    headerClassName: "wkjh5 tableHeaderCell",
  },
  {
    Header: "Deadline",
    accessor: "deadline",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "wkjh6",
    headerClassName: "wkjh6 tableHeaderCell",
  },
  {
    Header: "Job Owner",
    accessor: "jobOwner",
    className: "wkjh7 ellipsisField",
    headerClassName: "wkjh7 tableHeaderCell",
  },
];

export default JobsListOnHoldByWorkroom;
