import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Label, TableHeader, TableBody, Header } from 'semantic-ui-react'
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
    
 

const AppPermissionDeniedPage = (props) => {
    const [heights, setHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
        }, false);
    }, []) 
    // //console.log("widths == "+widths)
    const dispatch = useDispatch()
 

    useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Permission Denied'}))
    }, [])

    // Loading Logic if tasks not fetched
    return (
        <Container>
           
                <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}>
                    <StickyTable className="tableLayOut padding55 borderNoneObj" stickyHeaderCount={1}>
                        <Row >
                            <Cell className="borderNoneObj">
                                <Label size='big' > You are not allowed to view this page. Please contact admin if desired</Label>
                            </Cell>
                        </Row>
                       
                    </StickyTable>
                </div>
            
        </Container>
    )
}

export default AppPermissionDeniedPage;
