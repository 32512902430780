import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const users = () => ({
    id: v4(),
    name : "",
    password : "",
    isClient : 0,
    clientId : "",
    mobile : "",
    email : "",
    group : "",
    resetPw : 0,
    segid: "",
    cid: "",
    status: 1,
    dtCreate: "",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})

export const searchColumns = [
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Mobile',
      accessor: 'mobile',
    },
  ]


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const usersSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    name: Yup.string()
                .required('This is a Required Field'),

    group: Yup.string().nullable(),
    mobile: Yup.string().nullable(),
    email: Yup.string().nullable(),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    userCreate: Yup.string().nullable(),
    userUpdate: Yup.string().nullable()

  });


export const duplicateCheckUsersCode = debounce(checkUsersCode, 500);
function checkUsersCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/users/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							//console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckUsersName = debounce(checkUsersName, 500);
function checkUsersName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/users/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            //console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  