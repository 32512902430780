import { call, takeEvery, all, put, select } from 'redux-saga/effects'
import { getIsTasksFetched } from './selectors'
import { savedTask, fetchedTasks, fetchedTasksByJob, updatedTaskComplete, assignedUserToTask, fetchedCurrTaskByTeam, updatedTaskStatus} from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL } from '../../../store/path'
import axios from 'axios';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//const axios = apiContainer.axios;

//#region Saga for Create Tasks 

function* createTask() {
    yield takeEvery('CREATE_TASK',saveTask)
  }

function* saveTask(action){
  const { response, error } = yield call(saveTaskAPI, action.payload)
  if (response) 
  try {
    yield put (savedTask(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveTaskAPI(data) {
    return axios.post(URL+'/task/insert', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Tasks 

function* editTask() {
  yield takeEvery('EDIT_TASK',saveEditedTask)
}

function* saveEditedTask(action){
  const { response, error } = yield call(editTaskAPI, action.payload)
  if (response) 
  try {
    yield put (savedTask(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editTaskAPI(data) {
  let task_id = data.id
  return axios.post(URL+'/task/update/'+ task_id, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Assign Tasks 

function* assignUserToTask() {
  yield takeEvery('ASSIGN_USER_TO_TASK',saveAssignUserToTask)
}

function* saveAssignUserToTask(action){
  console.log (' Assigning user to task with data ', action.payload)
  const { response, error } = yield call(saveAssignUserToTaskAPI, action.payload)
  console.log (' In assign user to task response is ', response)
  if (response) 
  try {
    yield put (assignedUserToTask(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveAssignUserToTaskAPI(data) {
  // let task_id = data.id
  return axios.post(URL+'/task/assign', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Complete Tasks 

function* updateTaskComplete() {
  yield takeEvery('UPDATE_TASK_COMPLETE',saveCompletedTask)
}

function* saveCompletedTask(action){
  const { response, error } = yield call(saveCompletedTaskAPI, action.payload)
  // console.log (' In completed task response is ', response)
  if (response) 
  try {
    yield put (updatedTaskComplete(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveCompletedTaskAPI(data) {
  // let task_id = data.id
  return axios.post(URL+'/task/complete', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Updating Task Status 

function* updateTaskStatus() {
  yield takeEvery('UPDATE_TASK_STATUS',saveTaskStatus)
}

function* saveTaskStatus(action){
  const { response, error } = yield call(saveTaskStatusAPI, action.payload)
  // console.log (' In completed task response is ', response)
  if (response) 
  try {
    yield put (updatedTaskStatus(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveTaskStatusAPI(data) {
  // let task_id = data.id
  return axios.post(URL+'/task/set-status', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



//#region Saga for List Tasks 

function* fetchTask() {

  yield takeEvery('FETCH_TASKS',requestTasks)

}
  
function* requestTasks(action){

  const isTasksFetched = yield select(getIsTasksFetched);
  if(!isTasksFetched){
    let { data } = yield call(requestTasksAPI)
   try {
    yield put (fetchedTasks(data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  }

}

function requestTasksAPI(data) {
  return axios.post(URL+'/task/list', data, getHeaderJWT())
}

//#endregion


//#region Saga for Fetching Task by Job 

function* fetchTasksByJob() {

  yield takeEvery('FETCH_TASKS_BY_JOB',requestTaskByJob)

}
  
function* requestTaskByJob(action){
  // const isFetched = yield select(getIsActivityFetchedByProcess);
  // if(!isFetched){

    let { response, error } = yield call(requestTaskByJobAPI, action.payload)
    
    if (response) 
    try {
      yield put (fetchedTasksByJob(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  // }

}

function requestTaskByJobAPI(data) {
  let jobId  = data.jobId
  let requrl = URL+'/task/list/' + jobId
  console.log (' %%%%%% Calling tasks for job : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetching Curr Task and Job by team 

function* fetchCurrTaskByTeam() {

  yield takeEvery('FETCH_CURRTASK_BY_TEAM',requestCurrTaskByTeam)

}
  
function* requestCurrTaskByTeam(action){
  // const isFetched = yield select(getIsActivityFetchedByProcess);
  // if(!isFetched){

    let teamId  = action.payload.teamId
    if (teamId != undefined && teamId !='') {
    let { response, error } = yield call(requestCurrTaskByTeamAPI, action.payload)
    
    if (response) 
    try {
      yield put (fetchedCurrTaskByTeam(response.data, action.payload.teamId))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  }

}

function requestCurrTaskByTeamAPI(data) {
  
  let teamId  = data.teamId
  // if (teamId == undefined || teamId=='') return
  let requrl = URL+'/reports/task/list/team/' + teamId
  console.log (' %%%%%% Calling fetch creetask for team: ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion




const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    //console.log(error)
    console.groupEnd()
}

export default function* taskSaga() {
    yield all([
        createTask(),
        fetchTask(),
        editTask(),
        fetchTasksByJob(),
        updateTaskComplete(),
        assignUserToTask(),
        fetchCurrTaskByTeam(),
        updateTaskStatus()
    ])
  }  