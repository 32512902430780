import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobs, deleteJobs, setNotifyDone } from "../data/actions";
import * as select from "../../jobs/data/selectors";
import { Formik, Field, Form as FormikForm } from "formik";
// import { FormikInputComponent, FormikTextAreaComponent } from '../../../utilities/formUtils';
import {
  Container,
  Table,
  TableHeader,
  TableBody,
  Button,
  TableRow,
  TableCell,
  Form,
  Icon,
  Popup,
} from "semantic-ui-react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { getWorkroomShortNameFromId } from "../../workRoom/data/selectors";
import { fetchCurrTaskByTeam } from "../../tasks/data/actions";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";
import { getJobsAndActiveTasksByTeamId } from "../data/selectors";
import UsersSelectSimple from "../../users/components/usersSelectSimple";
import { getIsTeamTasksFetched } from "../../tasks/data/selectors";
import { assignUserToTask } from "../../tasks/data/actions";
import { JobTableList } from "../../../utilities/tableUtils";
import moment from "moment";

const TeamAssignUser = (props) => {
  const teamId = props.teamId;
  const jobs = useSelector((state) =>
    getJobsAndActiveTasksByTeamId(state, teamId)
  );

  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!jobs) dispatch(fetchCurrTaskByTeam({ teamId: teamId }));
  }, [teamId]);

  // Loading Logic if tasks not fetched
  if (!jobs || jobs.length == 0) {
    return <p> No Records </p>;
  } else {
    return (
      <Container>
        <JobTableList
          columns={searchColumns({})}
          data={jobs}
          Actions={AssignActions}
          fn={{}}
        />
      </Container>
    );
  }
};

const AssignActions = ({ object: job, fn }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  function saveUserTask(userId) {
    console.log("value changed", userId);
    dispatch(assignUserToTask({ id: job.taskId, userId: userId }));
    setOpen(false);
  }

  return (
    <>
      {!open ? (
        <>
          <div>
            <span
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => setOpen(true)}
            >
              Assign
            </span>
          </div>
        </>
      ) : (
        <UsersSelectSimple
          initialValue={job.userId}
          getValue={saveUserTask}
          filterFromWorkroom={job.workroomId}
        />
      )}
    </>
  );
};

export const searchColumns = (fn) => [
  {
    Header: "Priority",
    accessor: "priority",
    className: "tau1 ellipsisField",
    headerClassName: "tau1 tableHeaderCell",
  },
  {
    Header: "Status",
    accessor: "taskStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "tau2 ellipsisField",
    headerClassName: "tau2 tableHeaderCell",
  },
  {
    Header: "Workroom",
    accessor: "workroomName",
    className: "tau3 ellipsisField",
    headerClassName: "tau3 tableHeaderCell",
  },
  {
    Header: "Job",
    accessor: "job",
    className: "tau4 ellipsisField",
    headerClassName: "tau4 tableHeaderCell",
  },
  {
    Header: "Task",
    accessor: "taskName",
    className: "tau5 ellipsisField",
    headerClassName: "tau5 tableHeaderCell",
  },
  {
    Header: "User",
    accessor: "currentUser",
    className: "tau6 ellipsisField",
    headerClassName: "tau6 tableHeaderCell",
  },
];

export default TeamAssignUser;
