import React from 'react';
import { Route, withRouter } from 'react-router';
import UsersList from "./components/usersList";
import UsersTeamPriority from './components/usersTeamPriority'
import UsersForm from "./components/usersForm";
import { Container } from 'semantic-ui-react'


const Users = () => {
    return (
        <Container>
            <Route path="/users/list" component={(UsersList)} />
            <Route path="/users/edit/:id" component={UsersForm} />
            <Route path="/users/create" component={UsersForm} />
            <Route path="/users/teampriority/:id" component={UsersTeamPriority} />
        </Container>
    )
};

export default Users;

