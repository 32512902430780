import React from 'react';
import { Route, withRouter } from 'react-router';
import { Container } from 'semantic-ui-react'
import TasksDispContainer from './components/tasksDispContainer'


const Tasks = () => {
    return (
        <Container>
            <Route path="/tasks/list" component={withRouter(TasksDispContainer)} />
        </Container>
    )
};

export default Tasks;

