import { call, takeEvery, all, put, select } from 'redux-saga/effects'
import { getIsActivityFetched } from './selectors'
import {savedActivity,fetchedActivity, updatedActivityDefinition, fetchedActivityByProcess} from './actions'
// import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import apiContainer, {getHeaderJWT, URL } from '../../../store/path'
import axios from 'axios';
import userACL from '../../../store/access' 
import { merge } from 'lodash' 

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Tasks 

function* create() {
    yield takeEvery('CREATE_ACTIVITY',save)
  }

function* save(action){
  // //console.log("Form saga")
  const { response, error } = yield call(saveAPI, action.payload)
  if (response) 
  try {
    yield put (savedActivity(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveAPI(data) {
    return axios.post(URL+'/activities/add', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Tasks 

function* edit() {
  yield takeEvery('EDIT_ACTIVITY',saveEdited)
}

function* saveEdited(action){
  const { response, error } = yield call(editAPI, action.payload)
  if (response) 
  try {
    yield put (savedActivity(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editAPI(data) {
  let _id = data.id
  return axios.post(URL+'/activity/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Tasks 

function* fetch() {

  yield takeEvery('FETCH_ACTIVITY',requestList)

}
  
function* requestList(action){
  const isFetched = yield select(getIsActivityFetched);
  if(!isFetched){
    try {
      let { response, error } = yield call(requestListAPI)
      if (response) {
        yield put (fetchedActivity(response.data))
      }
      else {
        // yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }}
     catch(error) {
      sagaErrorMessage(error, action)
    }
  }

}

function requestListAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.get(URL+'/activity/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Fetching Activity from process 

function* fetchActivityByProcess() {

  yield takeEvery('FETCH_ACTIVITY_BY_PROCESS',requestActivity)

}
  
function* requestActivity(action){
  // const isFetched = yield select(getIsActivityFetchedByProcess);
  // if(!isFetched){

    let { response, error } = yield call(requestActivityAPI, action.payload)
    
    if (response) 
    try {
      yield put (fetchedActivityByProcess(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  // }

}

function requestActivityAPI(data) {
  let processId  = data.processId
  let requrl = URL+'/activity/list/' + processId
  console.log (' %%%%%% Calling activities for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Update Activity Definition 

function* updateDefinition() {
  yield takeEvery('UPDATE_ACTIVITY_DEFINITION',createActivityDefinition)
}

function* createActivityDefinition(action){
console.log("#### In createActivityDefinition action is # ", action)
const { response, error } = yield call(updateDefinitionAPI, action.payload)
console.log (' response is ', response.data)
if (response) 
try {
  yield put (updatedActivityDefinition(response.data))
} catch(error) {
  sagaErrorMessage(error, action)
}
else {
  yield put (handlError(action, parseError(error)))
  sagaErrorMessage(error,action)  
}
}

function updateDefinitionAPI(data) {
  let keyname = data.keyname
  let processId = data.processId
  let requrl = URL+"/process/update/definition/"+keyname+"/"+processId
  console.log (' The Request url is ', requrl)
  return axios.get(URL+"/process/update/definition/"+keyname+"/"+processId, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



const sagaErrorMessage = (error, action) => {
    console.group("Activity Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* activitySaga() {
    yield all([
        create(),
        fetch(),
        edit(),
        updateDefinition(),
        // resetDefinitionFlag(),
        fetchActivityByProcess()
    ])
  }  