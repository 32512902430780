import { call, takeEvery, all, put, select } from 'redux-saga/effects'
import { normalize, schema } from 'normalizr';
import axios from 'axios';

import { getIsWorkflowFetched } from './selectors'
import { savedWorkflow,fetchedWorkflow } from './actions'
import { fetchedActivity } from '../../activities/data/actions'
import { handlError, parseError } from '../../app/serverError'
import { URL_TASKS as URL } from '../../../store/path'

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//#region Saga for Create Tasks 

function* create() {
    yield takeEvery('CREATE_WORKFLOW',save)
  }

function* save(action){
  const { response, error } = yield call(saveAPI, action.payload)
  if (response) yield put (savedWorkflow(response.data))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveAPI(data) {
    return axios.post(URL+'/workflow/add', data)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Tasks 

function* edit() {
  yield takeEvery('EDIT_WORKFLOW',saveEdited)
}

function* saveEdited(action){
  const { response, error } = yield call(editAPI, action.payload)
  if (response) yield put (savedWorkflow(action.payload))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editAPI(data) {
  let _id = data.id
  return axios.post(URL+'/workflow/update/'+ _id, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Tasks 
// Define a activities schema
const activitiesSchema = new schema.Entity('activities',{}, { idAttribute: '_id' });

// Define a workflow schema
const workflowSchema = new schema.Entity('workflow',{activities:[activitiesSchema]}, { idAttribute: '_id' });
const workflowListSchema = [workflowSchema];


function* fetch() {

  yield takeEvery('FETCH_WORKFLOW',requestList)

}
  
function* requestList(){

  const isFetched = yield select(getIsWorkflowFetched);
  if(!isFetched){
    let { data } = yield call(requestListAPI)
    const normalizedWorkflow = normalize(data, workflowListSchema);
    yield put (fetchedWorkflow(normalizedWorkflow.entities.workflow));
    yield put (fetchedActivity(normalizedWorkflow.entities.activities))
  }

}

function requestListAPI(data) {
  return axios.get(URL+'/workflow/list', data)
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Workflow Saga Error:"+action.type)
    //console.log(error)
    console.groupEnd()
}

export default function* workflowSaga() {
    yield all([
        create(),
        fetch(),
        edit()
    ])
  }  