import { chain, cloneDeep } from "lodash";
import { createSelector } from "reselect";
import { module } from "./model";

export const getModule = (state, props) => {
  if (props.match.path === "/module/create") {
    return module();
  }
  if (props.match.path === "/module/edit/:id") {
    let id = props.match.params.id;
    let obj = cloneDeep(state.module.byId[id]);
    return obj;
  }
};

export const searchColumns = [
  {
    Header: "ModuleName",
    accessor: "moduleName",
  },
];

export const getModuleList = (state) => state.module.byId;
export const getProcessList = (state) => state.module.processById;

export const getModuleArray = createSelector(getModuleList, (module) => {
  return chain(Object.values(module))
    .sortBy((item) => item.moduleName)
    .value();
});

export const getProcessInModule = (state, moduleId) => {
  const process = state.module.processById;
  const processList = Object.values(process);
  return processList.filter((p) => p.moduleId === moduleId);
};
