export const CREATE_USERS = 'CREATE_USERS'
export const SAVED_USERS = 'SAVED_USERS'
export const EDIT_USERS = 'EDIT_USERS'
export const EDITED_USERS = 'EDITED_USERS'
export const FETCH_USERS = 'FETCH_USERS'
export const FETCHED_USERS = 'FETCHED_USERS'
export const SEARCH_USERS = 'SEARCH_USERS'
export const STORE_SEARCH_USERS_RESULT = 'STORE_SEARCH_USERS_RESULT'
export const DELETE_USERS = 'DELETE_USERS'
export const DELETED_USERS = 'DELETED_USERS'
export const NOTIFICATION_DONE_USERS = 'NOTIFICATION_DONE_USERS'

export const FILTER_ITEMS = 'FILTER_ITEMS'