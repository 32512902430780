import React, { useEffect, useState } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import { Formik, Field, Form as FormikForm } from "formik";
import { v4 } from "uuid";
import {
  FormikInputComponent,
  FormikTextAreaComponent,
} from "../../../utilities/formUtils";
import { BasicTableList } from "../../../utilities/tableUtils";
import { processModel, processSchema } from "../data/model";
import { useDispatch, useSelector } from "react-redux";
import { createProcess, fetchProcess, removeProcess } from "../data/actions";
import { getProcessInModule } from "../data/selectors";
function ModuleProcess(props) {
  const dispatch = useDispatch();
  const { moduleId } = props;
  console.log(moduleId);

  const processList = useSelector((state) =>
    getProcessInModule(state, moduleId)
  );

  console.log(processList);

  const [process, setProcess] = useState({
    id: v4(),
    moduleId: moduleId,
    processName: "",
    processType: "",
  });

  useEffect(() => {
    // dispatch(fetchProcess());
    setProcess(processModel(moduleId));
  }, []);

  const saveProcess = (values, resetForm) => {
    console.log(values);
    values.id = v4();
    values.status = 1
    dispatch(createProcess(values));

    resetForm();
  };

  useEffect(()=>{
    dispatch(fetchProcess());
  },[])

  const removeProcessFromList = (processList) => {
    dispatch(removeProcess(processList));
  };
  return (
    <>
      <div>
        <br />
        <br />
        <Grid>
          <Grid.Column style={{ width: "80%" }}>
            <Formik
              initialValues={process}
              validationSchema={processSchema}
              onSubmit={(values, { resetForm }) =>
                saveProcess(values, resetForm)
              }
              render={({ handleSubmit }) => (
                <Form as={FormikForm} onSubmit={handleSubmit}>
                  <Form.Group widths={3}>
                    <Field
                      name="processName"
                      label="Process Name"
                      component={FormikInputComponent}
                    />
                    <Field
                      name="processType"
                      label="ProcessType"
                      component={FormikInputComponent}
                    />
                    <Button
                      type="submit"
                      floated="left"
                      color="blue"
                      size="small"
                    >
                      save
                    </Button>
                  </Form.Group>
                </Form>
              )}
            />
          </Grid.Column>
        </Grid>
        <br />
        <br />
      </div>
      <div style={{ height: "40vh", width: "95%", overflowY: "auto" }}>
        <BasicTableList
          columns={[{ Header: "ProcessName", accessor: "processName" }]}
          data={processList}
          Actions={removeProcessAction}
          fn={{ removeProcessFromList }}
        />
      </div>
    </>
  );
}

const removeProcessAction = ({ object: processList, fn }) => {
  console.log(processList);
  return (
    <>
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={() => fn.removeProcessFromList(processList)}
      >
        Remove
      </span>
      <span>&nbsp;&nbsp;</span>
    </>
  );
};

export default ModuleProcess;
