import React, { useEffect, useState } from "react";
import { Container, Icon } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import moment from "moment";
import { JobTableList } from "../../../utilities/tableUtils";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";

const MyTasksCreated = (props) => {
  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  const myqueue = props.myjobs;

  // Loading Logic if tasks not fetched
  if (myqueue && myqueue.length == 0) {
    return <p> No records</p>;
  } else {
    return (
      <Container>
        {!myqueue ? (
          <p> No records</p>
        ) : (
          <>
            <JobTableList columns={searchColumns} data={myqueue} />
          </>
        )}
      </Container>
    );
  }
};

export const searchColumns = [
  {
    Header: "Sl No",
    accessor: "Sl No",
    className: "owbm1 ellipsisField",
    headerClassName: "owbm1 tableHeaderCell",
  },
  {
    Header: "Status",
    accessor: "taskStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "owbm2 ellipsisField",
    headerClassName: "owbm2 tableHeaderCell",
  },
  {
    Header: "Ticket",
    accessor: "job",
    className: "owbm3 ellipsisField",
    headerClassName: "owbm3 tableHeaderCell",
  },
  {
    Header: "Workroom",
    accessor: "workroomname",
    className: "owbm4 ellipsisField",
    headerClassName: "owbm4 tableHeaderCell",
  },
  {
    Header: "Priority",
    accessor: "priority",
    className: "owbm5 ellipsisField textAlignRight",
    headerClassName: "owbm5 tableHeaderCell textAlignRight",
  },
  {
    Header: "Deadline",
    accessor: "deadline",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "owbm6",
    headerClassName: "owbm6 tableHeaderCell",
  },
  {
    Header: "Date Start",
    accessor: "dtStart",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "owbm7",
    headerClassName: "owbm7 tableHeaderCell",
  },
  {
    Header: "Assigned To",
    accessor: "assignedTo",
    className: "owbm8 ellipsisField",
    headerClassName: "owbm8 tableHeaderCell",
  },
];
export default MyTasksCreated;
