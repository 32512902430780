import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash';
import { heightSet } from '../../../utilities/heightForListing'
import { getTaskDetailsByJobActivityTask } from '../data/selectors'
import { editTaskdetails } from '../data/actions'
import { updateTaskComplete} from '../../tasks/data/actions'
import { getJobPathLink} from '../../../utilities/pathUtils'
import Iframe from 'react-iframe'
import axios from 'axios';

const TemplateIFrame = (props) => {
    const [heights, setHeight] = useState('')
    const [fetchUrl, setFetchUrl] = useState('')

    const dispatch = useDispatch()
    const editMode = props.editMode 
    const callBackParent = props.callBack // function

    const jobKey = props.job ? props.job.jobKey : "JOB"
    const jobId = props.job ? props.job.id : undefined
    const taskId = props.taskId
    const activityId = props.activityId
    const activity = props.activity

    const transport = axios.create({
        withCredentials: true
      })
      
    // const currTask = (props.currActiveTask.id == taskId ? true : false)

    const taskdetails = useSelector(state => getTaskDetailsByJobActivityTask (state, jobId, activityId, taskId))
    console.log ('In template #### taskdetails  is ', taskdetails)

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    useEffect(() => {
        if (editMode)
            setFetchUrl(activity["editUrl"])
        else
            setFetchUrl(activity["viewUrl"])
    }, [editMode])

    document.cookie = "jobData" +"=" + "jobId::" + jobId + "##jobKey::" + jobKey +'##taskId::'+ taskId + ";domain=.dev.localhost;path=/;samesite=strict;"


    if (fetchUrl == undefined && fetchUrl == '')
        return null
    else
        return (     
                <>  
                    <Iframe 
                            src={fetchUrl}
                            // srcdoc={content}
                            width="100%"
                            height="600px"
                            id="myId"
                            // className="myClassname"
                            display="initial"
                            position="relative"
                            style={{ "border-width": 0, "background-color": "#ffffff"}}
                    />
                </>
        )
}

export default TemplateIFrame;

