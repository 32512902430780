import React from 'react';
import { Route, withRouter } from 'react-router';
import TasksDispContainer from '../tasks/components/tasksDispContainer'
import { Container } from 'semantic-ui-react'


const Jobs = () => {
    return (
        <Container>
            <Route path="/jobs/view/:key" component={TasksDispContainer} />
            <Route path="/jobs/template/:id" component={TasksDispContainer} />
        </Container>
    )
};

export default Jobs;

