import * as type from './types'

export function insertMyCreated(tasks) {
    return {
        type : type.INSERT_MY_CREATED,
        payload : tasks
    };   
}

export function insertMyQueue(tasks) {
    return {
        type : type.INSERT_MY_QUEUE,
        payload : tasks
    };
    
}
