import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsTaskdetailsFetched } from './selectors'
import {savedTaskdetails,fetchedTaskdetailsByJob, storeSearchTaskdetailsResult, deletedTaskdetails } from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import apiContainer, {getHeaderJWT, URL} from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Taskdetails 

function* createTaskdetails() {
    yield takeEvery('CREATE_TASKDETAILS',saveTaskdetails)
  }

function* saveTaskdetails(action){
  const { response, error } = yield call(saveTaskdetailsAPI, action.payload)
  console.log("Saga",action.payload)
  if (response) 
  try { 
     yield put (savedTaskdetails(action.payload))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveTaskdetailsAPI(data) {
    return axios.post(URL+'/taskdetails/add', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Taskdetails 

function* editTaskdetails() {
  yield takeEvery('EDIT_TASKDETAILS',saveEditedTaskdetails)
}

function* saveEditedTaskdetails(action){
  const { response, error } = yield call(editTaskdetailsAPI, action.payload)  
  if (response) 
  try {
    yield put (savedTaskdetails(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editTaskdetailsAPI(data) {
  // let jobId = data.jobId
  console.log (' The data in saga edit details just before put is ', data)
  
  return axios.post(URL+'/task/details/update/', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Taskdetails 

function* deleteTaskdetails() {
  yield takeEvery('DELETE_TASKDETAILS',saveDeleteTaskdetails)
}

function* saveDeleteTaskdetails(action){
  const { response, error } = yield call(deleteTaskdetailsAPI, action.payload)
  if (response) 
  try {
    yield put (deletedTaskdetails({id: action.payload.id, msg : response.data}))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteTaskdetailsAPI(data) {
  let taskdetailsId = data.id
  return axios.post(URL+'/taskdetails/delete/'+ taskdetailsId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Taskdetails 

function* fetchTaskdetailsByJob() {
  yield takeEvery('FETCH_TASKDETAILS_BY_JOB',requestTaskdetailsByJob)
}
  

function* requestTaskdetailsByJob(action){

    let { response, error } = yield call(requestTaskdetailsAPI, action.payload)
    console.log (' #### Received task details data in saga : ', response)
    if (response) 
    try {
      yield put (fetchedTaskdetailsByJob(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
}}


function requestTaskdetailsAPI(data) {
  let jobId  = data.jobId
  let requrl = URL+'/task/details/' + jobId
  console.log (' %%%%%% Calling task details  for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Search Taskdetails 

function* searchTaskdetails() {

  yield takeLatest('SEARCH_TASKDETAILS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchTaskdetailsResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/taskdetails/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* taskdetailsSaga() {
    yield all([
        createTaskdetails(),
        fetchTaskdetailsByJob(),
        editTaskdetails(),
        deleteTaskdetails(),
        searchTaskdetails()
    ])
  }  