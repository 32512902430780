import React, {useState, useEffect} from 'react'
import { withRouter } from 'react-router';
import { Button, Header, Modal, Message, Label} from 'semantic-ui-react'
import { Container, Form, Grid } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikRichText } from '../../../utilities/formUtils';
import ProcessSelect from '../../process/components/processSelect' 
import UserSelect from '../../users/components/usersSelect' 
import WorkroomSelect from '../../workRoom/components/workroomSelect' 
import { getWorkroomList } from '../../workRoom/data/selectors'
import { jobs as createJob, jobsCreateSchema } from '../data/model'
import { createJobs, resetCreatedJob} from '../data/actions'
import { parseJwtHeader } from '../../../utilities/parseJWT'
import { getNewJobCreated } from '../data/selectors'
import { getJobPathLinkWithJobKey} from '../../../utilities/pathUtils'
import { getProcessList } from '../../process/data/selectors';
// import { DynamicFormComponent } from '../../../utilities/dynamicFormUtils';
// import { getFormFields, getValidationSchema } from '../../../utilities/yupUtils'

const JobsCreateModal = (props)=>{
  const [open, setOpen] = useState(false)
  const [workroomId, setWorkroomId] = useState()
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [formJob, setFormJob] = useState (createJob())

  const dispatch = useDispatch()
  const newJobCreated = useSelector(state=> getNewJobCreated(state, props))
  const workrooms = useSelector(state=> getWorkroomList(state, props))
  const processes = useSelector(state=> getProcessList(state, props))
  const token = localStorage.getItem('user')
  const isClientToken = token ? parseJwtHeader(token).isClient : undefined 
  const isClient = (isClientToken == 1)

  const saveJob = (values, resetForm) => {
    console.log(' Adding a NEW Job 11', values)
/* In the workroom and process Select we get the full Objects so we extract the relevant data from them*/
    const obj =
      {
        'id' : formJob.id,
        'workroomId': values.workroomId.id,
        'teamId' : values.workroomId.teamid,
        'userId': values.userId,
        'processId': values.processId.id,
        'processKey' : values.processId.processKey,
        'description': values.description,
        'jobName' :values.jobName,
        'priority': workrooms[values.workroomId.id] ? workrooms[values.workroomId.id].defaultPriority : null 
      }
      console.log(' Creating job start data is ', obj)
      dispatch(createJobs(obj))
      setSavedSuccess(true)   
      resetFormToAddMore()    
      resetForm()
  }

  const resetFormToAddMore = () => {
    dispatch(resetCreatedJob())
    setFormJob(createJob())
  }

  const redirectToNewJob =() =>{
    let newlink = getJobPathLinkWithJobKey(newJobCreated)
    props.history.push(newlink)
    setOpen (false)
  }

  useEffect(() => {
    dispatch(resetCreatedJob())    
  },[open]) 


  const getUsersList =(value)=> {
     if (value) {
       setWorkroomId (value.id)
     }
  }

  // const getDynamicFormUI = (processId) => {
  //   let process = processes[processId]
  //   return JSON.parse(process["startForm"])
  // }             
  // const getDynamicFormValidation = (processId) => {
  //   let process = processes[processId]
  //   let frm = JSON.parse(process["startForm"])
  //   return getValidationSchema(frm["fields"].concat(frm["fieldArray"]))
  // }             
  // const getDynamicFormFields = (processId) => {
  //   let process = processes[processId]
  //   let frm = JSON.parse(process["startForm"])
  //   console.clear()
  //   console.log(getFormFields(frm["fields"]));
  //   return getFormFields(frm["fields"])
  // }             


  return (
    <Modal
      closeIcon={{ size: 'large', name: 'remove', color: 'grey', style: {float: 'right' , marginTop: '20px', marginRight: '22px', cursor: 'pointer'}}}
      size='small'
      dimmer = 'inverted'
      open={open}
      trigger = {
        <Button color="green" size="large" className="FontHindBold"> New Request </Button>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header content='New Request' />
      <Modal.Content>
      <Container >
            <Grid  divided columns='equal'>
                <Grid.Column >
                    <Formik id="workroomForm" 
                        initialValues={formJob}
                        validationSchema={jobsCreateSchema}
                        onSubmit={(values, { resetForm }) => saveJob(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange, errors }) => (
                            <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                               <Form.Group widths={3}>
                                    <WorkroomSelect filterFromMyList = {isClient} required={true} name="workroomId" label="Workroom" returnFullObject = {true} getWorkroomValue = {getUsersList}/>
                                    <ProcessSelect returnFullObject = {true} required={true} name="processId" label="Process"/>
                                    <UserSelect name="userId" label="Assign to User" filterFromWorkroom= {workroomId}/>
                                    </Form.Group>
                                    <Field  name="jobName" label = "Job Name (Limit to 50 characters)" component={FormikInputComponent}></Field>
                                    {values.processId.id && processes[values.processId.id]["startForm"]
                                    // ? <DynamicFormComponent formUI={getDynamicFormUI(values.processId.id)} nestedFields={getDynamicFormFields(values.processId.id)} errors={errors} values={values}/>
                                    ? <h3 style = {{color: "#298feb"}}>This Process requires additional details, please go the Job Page after saving!</h3>
                                    : <Field  name="description" className= 'newTicket' label = "Description" component={FormikRichText}></Field> 
                                    }
                                <div style= {values.processId.id && processes[values.processId.id]["startForm"] ? {} :  {marginTop: 150}}>
                              { newJobCreated ?
                              <>
                                <Button type="button" floated="right" color="green" size="medium" className="CustomeBTN marginTopFifty" onClick={resetFormToAddMore}>Submit</Button> 
                               <Button floated="right" color="grey" size="medium" className="CustomeBTN marginTopFifty" onClick={() => setOpen(false)}> Cancel </Button> 
                              </> :
                              <>
                               <Button type="submit" floated="right" color="green" size="medium" className="CustomeBTN marginTopFifty"> Submit</Button> 
                               <Button floated="right" color="grey" size="medium" className="CustomeBTN marginTopFifty" onClick={() => setOpen(false)}> Cancel </Button> 
                              </>
                              }
                               </div>
                            </Form>
                        )}
                    />
                    
                </Grid.Column>
                      
            </Grid>
            {newJobCreated &&
                        <Message positive style = {{ zIndex: 100}}>
                        <Message.Header>
                          Ticket created successfully
                          <Label as ='a' basic size='large' className=' buttonAddTodos messageTransparent' onClick= {redirectToNewJob} >Link to Job: {newJobCreated}</Label>
                          {/* <Link as ='a' basic size='large' className=' buttonAddTodos messageTransparent' onClick= {redirectToNewJob} >Link to Job: {newJobCreated}</Label> */}
                          {/* <Link className=' buttonAddTodos messageTransparent' to={{ pathname: getJobPathLinkWithJobKey(newJobCreated)}}>Link to Job: {newJobCreated}</Link> */}
                        </Message.Header>
                      </Message>
                      
              }  
        </Container>
      </Modal.Content>
    </Modal>
  )
}

export default withRouter(JobsCreateModal)