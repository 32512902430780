import React, { useEffect, useState }from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getTeam, getNotification, getTeamParams } from '../data/selectors';
import { createTeam, setNotifyDone } from '../data/actions';
import { teamSchema } from '../data/model'
import { Container, Form, Button, Grid, } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import { updateHeaderTitle } from '../../app/data/actions'
import { newTeamUser } from '../data/model'
import { heightSet } from '../../../utilities/heightForListing'
import UsersSelect from '../../users/components/usersSelect' 
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'

const TeamForm = (props) => {
    const [heights, setHeight] = useState('')
    const [savedTeamId, setSavedTeamId] = useState(false)
    const [listOfUsersSelected, setListOfUsersSelected] = useState([])
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    
    const team = useSelector(state => getTeam(state, props))
    const params = useSelector(state => getTeamParams(state, props))
    const dispatch = useDispatch()
    
    useEffect(() => {
            dispatch(updateHeaderTitle({ 'title': 'Create Team'}))
    }, [])


    useEffect(() => {
            let vd = newTeamUser ()
            vd.teamId = team.id
            team.teamUsers.push (vd)
      })

    const saveTeam = (values, resetForm) => {
            userACL.atCreate(values)
            dispatch(createTeam(values))
            setSavedTeamId(values.id)
            // resetForm()
            props.history.push({pathname: "/team/edit/" + values.id})
    }
  
    return (
        <Container >
            <Grid  >
                <Grid.Column  className="paddingLeftRight70 paddingTopZero outerDivClassForForm" style={{height: "40vh", width : '95%', overflowY: "auto"}}>
                    <Formik id="teamForm" size="large" 
                        initialValues={team}
                        validationSchema={teamSchema}
                        onSubmit={(values, { resetForm }) => saveTeam(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                                 <Form.Group widths={3}>
                                    <Field name="teamName" label = 'Team Name' component={FormikInputComponent}></Field>
                                    <UsersSelect style={{width: "50%"}} required={true} name="teamLead" label="Select Team Lead"/>
                                    <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                        {savedTeamId ?
                                            <Notification id={savedTeamId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                            : 
                                        null}
                                </Form.Group>
                            </Form>
                        )}
                    />
                </Grid.Column>  
            </Grid>
        </Container>
    )
}

export default TeamForm;