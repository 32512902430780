import React from 'react';
import { Route, withRouter } from 'react-router';
import ClientList from "./components/clientList";
import ClientForm from "./components/clientForm";
import { Container } from 'semantic-ui-react'


const Client = () => {
    return (
        <Container>
            <Route path="/client/list" component={(ClientList)} />
            <Route path="/client/edit/:id" component={ClientForm} />
            <Route path="/client/create" component={ClientForm} />
        </Container>
    )
};

export default Client;

