import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { heightSet } from '../../../utilities/heightForListing'

import Iframe from 'react-iframe'
import axios from 'axios';

const WorkRoomModuleIFrame = (props) => {
    const [heights, setHeight] = useState('')
    const workroom = props.workroom

    const dispatch = useDispatch()
    let fetchUrl = workroom.moduleUrl
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    // data to be saved in guidelines field
    // baseUrl::localhost:3000/;reqPath::configuration;u::prasenjit@realbooks.in;p::Ams@1234



    if (fetchUrl == undefined && fetchUrl == '')
        return null
    else
        return (     
                <>  
                    <Iframe 
                            url={fetchUrl}
                            // srcdoc={content}
                            width="100%"
                            height="600px"
                            id="myId"
                            // className="myClassname"
                            display="initial"
                            position="relative"
                            style={{ "border-width": 0, "background-color": "#ffffff"}}
                    />
                </>
        )
}

export default WorkRoomModuleIFrame;

