import * as type from './types'

export function requestLogin(user) {
    return {
        type : type.LOGIN_REQUEST,
        payload : user,
        txn : 'initiated',
    };
}

export function successfulLogin(msg) {
    return {
        type : type.LOGIN_SUCCESS,
        payload : msg,
        txn : 'success',
        diff : true
    };
}


export function changePassword(pswd) {
    return {
        type : type.CHANGE_PASSWORD,
        payload : pswd,
        txn : 'initiated',
    };
}
export function changingPassword(status,changedPassword) {
    return {
        type : type.CHANGEING_PASSWORD,
        payload : status ? status : false,
        changedPassword:changedPassword?changedPassword:""
    };
}
export function changedPassword(msg) {
    return {
        type : type.CHANGED_PASSWORD,
        payload : msg,
        txn : 'success',
        diff : true
    };
}


export function failedLogin(msg) {
    return {
        type : type.LOGIN_FAILURE,
        payload : msg,
        txn : 'initiated',
    };
}

export function requestLogout() {
    return {
        type : type.LOGOUT_REQUEST,
    };
}


export function successfulLogout(msg) {
    return {
        type : type.LOGOUT_SUCCESS,
        payload : msg
    };
}


export function failedLogout(msg) {
    return {
        type : type.LOGOUT_FAILURE,
        payload : msg
    };
}
