import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Table, TableHeader, TableBody} from 'semantic-ui-react';

const StepsListForPanelList = ({stepsObj, pathToLink, processId, displayFields}) => {
    // console.log(('Item is in steps : ', stepsObj[item]))
    // console.log(('display field is  is in steps : ', displayFields))
    let str = ''

    displayFields.map ((item, index) => {
        if (index > 0 )
            str = str.concat (' - ')
        str = str.concat (stepsObj[item])
    })
    return (
        <Table.Row>
            <Table.Cell style={{width: "90%"}}>
                <Link to={{pathname: pathToLink+ stepsObj.id, processId:processId}}>{str}</Link>
                
            </Table.Cell>
        </Table.Row>
    )
}

const StepsListForProcessPanel = (props, {isEdit}) => {
    // let processName = props.processName === undefined ? "" : props.processName
    let processId = props.processId === undefined ? "" : props.processId
    let data = props.data === undefined ? [] : props.data
    let pathToLink = props.pathToLink=== undefined ? "" : props.pathToLink
    let displayFields = props.displayFields

    // console.log(' In STeps list props is ', props )
    if (props.data == undefined) return null
   
    return (
        <Container className="padding70">
            <Table selectable basic='very' style={{width: "100%"}}>
                <TableBody>
                    { Object.keys(data).map((key) => 
                    <StepsListForPanelList displayFields= {displayFields} pathToLink= {pathToLink} processId={processId} stepsObj={data[key]} /> )}
                </TableBody>
            </Table>
        </Container> 
    )
}

export default StepsListForProcessPanel;