export const CREATE_PROCESSFILE = 'CREATE_PROCESSFILE'
export const SAVED_PROCESSFILE = 'SAVED_PROCESSFILE'
export const EDIT_PROCESSFILE = 'EDIT_PROCESSFILE'
export const EDITED_PROCESSFILE = 'EDITED_PROCESSFILE'
export const FETCH_PROCESSFILE = 'FETCH_PROCESSFILE'
export const FETCHED_PROCESSFILE = 'FETCHED_PROCESSFILE'
export const SEARCH_PROCESSFILE = 'SEARCH_PROCESSFILE'
export const STORE_SEARCH_PROCESSFILE_RESULT = 'STORE_SEARCH_PROCESSFILE_RESULT'
export const DELETE_PROCESSFILE = 'DELETE_PROCESSFILE'
export const DELETED_PROCESSFILE = 'DELETED_PROCESSFILE'
export const NOTIFICATION_DONE_PROCESSFILE = 'NOTIFICATION_DONE_PROCESSFILE'

export const FETCH_PROCESSFILE_BY_PROCESSID = 'FETCH_PROCESSFILE_BY_PROCESSID'
export const FETCHED_PROCESSFILE_BY_PROCESSID = 'FETCHED_PROCESSFILE_BY_PROCESSID'

