import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedUsers } from '../data/actions'
import * as select from '../data/selectors'
import {  Dropdown } from 'semantic-ui-react';
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'
import { getListOfUsersFromWorkroom} from '../../workRoom/data/selectors'
import { fetchUsersInWorkroom } from '../../workRoom/data/actions'


const UsersSelect = ({ name, isTxn, required, isLabel, label, isSelection, excludeUsers, multiple, getValue : callback }, props) => {
    
    const usersFetched = useSelector(state => select.getIsUsersFetched(state, props))
    const options = useSelector(state => select.selectUsersExcluding(state, excludeUsers))

    console.log(options)
  
    const getValue = (values) => {
        console.log("Users Selected== ", values, name)
    }


    if (!usersFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Field name={name} 
                isLabel={isLabel}
                required = {required} 
                isTxn={isTxn} 
                label={label}
                multiple={multiple}
                component={FormikSelectComponent} 
                userProps={{ options, getValue }}> 
            </Field>
        )
            {/* <Dropdown 
            placeholder='User' 
            search selection options={options} 
            /> */}
        
    }

}


export default UsersSelect;
