import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const team = () => ({
    id: v4(),
    teamName: "",
    segid: "",
    cid: "",
    status: 1,
    dtCreate: "",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: "",
    teamLead : "",
    teamUsers: []
    /* [{ id: v4(),
    isActive: null,
    segid: null,
    status: null,
    teamId: "",
    userId: ""
    }] */
})

export const newTeamUser = () => ({
    id: v4(),
    teamId :"",
    userId:"-1",
    fromDt:"",
    toDt:"",
    priority: null,
    isActive:"",
    txnid: "",
    status: 1,
    segid: "",
    cid: "",
    dtCreate:"",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})

export const searchColumns = [
  {
    Header: 'Name',
    accessor: 'teamName', // accessor is the "key" in the data
  },
]


Yup.addMethod(Yup.array, "unique", function(message, path) {
    
    return this.test("unique", message, function(list) {
      const mapper = x => x.userId;
      const set = [...new Set(list.map(mapper))];
      const isUnique = list.length === set.length;
      console.log (' In unique isUnique is : ', isUnique)
      if (isUnique) {
        return true;
      }
  
      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    //   console.log (' In unique test: ', set, idx)
    //   return this.createError({ path: `[${idx}].${path}`, message });
      return this.createError({ path: `teamUsers[${idx}]`, message });
    });
  });

Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const teamSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    teamName: Yup.string()
                .required('This is a Required Field'),
    /* teamUsers: Yup.array()
                .unique("User already added"), */

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });


export const duplicateCheckTeamCode = debounce(checkTeamCode, 500);
function checkTeamCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/team/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							// //console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckTeamName = debounce(checkTeamName, 500);
function checkTeamName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/team/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            // //console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  