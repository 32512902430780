import { takeEvery, call, put, all } from "redux-saga/effects";
import {
  fetchedModule,
  removedProcess,
  savedModule,
  savedProcess,
  fetchedProcess
} from "./actions";
import axios from "axios";
import { getHeaderJWT, URL } from "../../../store/path";
import { handlError, parseError } from "../../app/serverError";
import { toast } from "react-toastify";

function* createModule() {
  yield takeEvery("CREATE_MODULE", saveModule);
}

function* saveModule(action) {
  const { response, error } = yield call(saveModuleAPI, action.payload);
  console.log(response);
  if (response) {
    try {
      yield put(savedModule(action.payload));
      toast.success("Module Created");
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Module Creation Failed");
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function saveModuleAPI(data) {
  return axios
    .post(URL + "/module/create", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* fetchModule() {
  yield takeEvery("FETCH_MODULE", requestModule);
}

function* requestModule(action) {
  const { response, error } = yield call(requestModuleAPI);
  console.log(response);
  if (response) {
    try {
      yield put(fetchedModule(response.data));
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function requestModuleAPI() {
  return axios
    .get(URL + "/module/list", getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* editModule() {
  yield takeEvery("EDIT_MODULE", saveEditModule);
}

function* saveEditModule(action) {
  const { response, error } = yield call(saveEditModuleAPI, action.payload);
  if (response) {
    try {
      yield put(savedModule(action.payload));
      toast.success("Module Updated Successfully")
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Module Update Failed")
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function saveEditModuleAPI(data) {
  return axios
    .post(URL + "/module/update", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* createProcess() {
  yield takeEvery("CREATE_PROCESS", saveProcess);
}

function* saveProcess(action) {
  const { response, error } = yield call(saveProcessAPI, action.payload);
  if (response) {
    try {
      yield put(savedProcess(action.payload));
      toast.success("Process Created");
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Process Creation Failed");
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function saveProcessAPI(data) {
  return axios
    .post(URL + "/module-process/create", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* fetchProcess() {
  yield takeEvery("FETCH_PROCESS", requestProcess);
}

function* requestProcess(action) {
  const { response, error } = yield call(requestProcessAPI);
  console.log(response);
  if (response) {
    try {
      yield put(fetchedProcess(response.data));
      
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function requestProcessAPI() {
  return axios
    .get(URL + "/module-process/list", getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

function* removeProcess() {
  yield takeEvery("REMOVE_PROCESS", deleteProcess);
}

function* deleteProcess(action) {
  const { response, error } = yield call(deleteProcessAPI, action.payload);
  if (response) {
    try {
      yield put(removedProcess(action.payload));
      toast.success("Process Deleted");
    } catch (error) {
      sagaErrorMessage(error, action);
    }
  } else {
    toast.error("Process Delete Failed");
    yield put(handlError(action, parseError(error)));
    sagaErrorMessage(error, action);
  }
}

function deleteProcessAPI(data) {
  return axios
    .post(URL + "/module-process/delete", data, getHeaderJWT())
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

const sagaErrorMessage = (error, action) => {
  console.group("Saga Error:" + action.type);
  console.log(error.response);
  console.groupEnd();
};

export default function* moduleSaga() {
  yield all([
    createModule(),
    fetchModule(),
    editModule(),
    createProcess(),
    fetchProcess(),
    removeProcess(),
  ]);
}
