import React, { useState } from 'react'
import { Button, Modal, Container, Icon, } from 'semantic-ui-react'
import { withRouter } from 'react-router';
import MyUploader from './dnd'

const AttachmentModal = (props) => {

  const { workroomId, jobId, processId,  userId } = props
  const [open, setOpen] = useState(false)

  return (
    <Modal
      closeIcon
      size='small'
      dimmer='inverted'
      open={open}
      trigger={
        <Button type="button" floated="right" color='green' style={{ height: "35px" }} icon size='medium'>
          <Icon name='paperclip' />
        </Button>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Modal.Content>
        <Container >
          <h2>File Uploader</h2>
          <MyUploader setOpen={setOpen} workroomId={workroomId} jobId={jobId} userId={userId} processId={processId}/>          
        </Container>
      </Modal.Content>
    </Modal>
  )
}
export default withRouter(AttachmentModal)