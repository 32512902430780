import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { taskdetails } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'
import { STARTFORM } from './templateMapping'

export const getIsFetchingTaskdetails = (state, props) => state.taskdetails.params.isFetching;
export const getIsTaskdetailsFetched = (state, props) => state.taskdetails.params.taskdetailsFetched;
export const getTaskdetailsList = (state, prop) => state.taskdetails.byId
export const getIsSearching = (state, props) => state.taskdetails.params.isSearching;
export const getIsSearchFetched = (state, props) => state.taskdetails.params.searchFetched;
export const getSearchResults = (state, prop) => state.taskdetails.searchResults
export const filters = (state, prop) =>  state.taskdetails.filterResults ;
export const getDeleteStatus = (state, prop) => state.taskdetails.deleteStatus
export const getDelFetched = (state, prop) => state.taskdetails.params.isDelFetched
export const getNotification = (state, id) => state.taskdetails.notifications[id]

export const getTaskdetails = (state, props) => {
    if(props.match.path==='/taskdetails/create'){
        return taskdetails()
    }
    if(props.match.path==='/taskdetails/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.taskdetails.byId[_id]);
        return obj        
    }
}


export const getTaskDetailsByJobActivityTask = (state, jobid, activityid, taskid) => {
   if (taskid  == undefined) return undefined
    const values = state.taskdetails.byId
   if (Object.keys(values).length ==0 ) return undefined

   const antid = activityid + "#" + taskid
   let taskdetails = values[jobid]
   if (!taskdetails) return undefined
//    console.log (' in selector taskdetails is ', taskdetails)
//    let obj = taskdetails.find (item => (item.jobId ==jobid && item.antId == antid))
   let obj = taskdetails[antid]
   return (obj ? obj.taskDetails : undefined)
}


export const getTaskDetailsByJobStartForm = (state, jobid) => {
     const values = state.taskdetails.byId
    if (Object.keys(values).length ==0 ) return undefined

    let activityId = STARTFORM
    let antid = STARTFORM + '#' + jobid
 
    let taskdetails = values[jobid]
    if (!taskdetails) return undefined
    // let obj = taskdetails.find (item => (item.jobId ==jobid && item.activityId == activityId))
    let obj = taskdetails[antid]
    return (obj ? obj.taskDetails : undefined)
 }

export const getTaskdetailsParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/taskdetails/create')){ 
        params.title = state.taskdetails.params.createTitle
        params.submitButtonText = state.taskdetails.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/taskdetails/edit/')){ 
        params.title = state.taskdetails.params.editTitle
        params.submitButtonText = state.taskdetails.params.editSubmitButtonText        
    };

    return params
}

export const selectTaskdetails = createSelector(
    getTaskdetailsList,
    taskdetails => { 
        const keys = Object.keys(taskdetails)
        const obj = keys.map( (key) => { return { key : key, value : key, text : taskdetails[key].name }})
        return obj
    })


export const selectTaskdetailsSearched = createSelector(
    getSearchResults,
    taskdetails => { 
        const keys = Object.keys(taskdetails)
        const obj = keys.map( (key) => { return { key : key, value : key, text : taskdetails[key].name }})
        return obj
    })

