import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getClient,
  getNotification,
  getClientParams,
  getIsClientFetched,
} from "../data/selectors";
import { createClient, editClient, setNotifyDone } from "../data/actions";
import { clientSchema, duplicateCheckClientName } from "../data/model";
import {
  Container,
  Header,
  Table,
  TableHeader,
  TableBody,
  Form,
  Button,
  Grid,
  Accordion,
} from "semantic-ui-react";
import { Formik, Field, Form as FormikForm, FieldArray } from "formik";
import { FormikInputComponent } from "../../../utilities/formUtils";
import userACL from "../../../store/access";
import StepsListForPanel from "../../app/components/stepsListForProcessPanel";
import { heightSet } from "../../../utilities/heightForListing";
import Notification from "../../../utilities/notificationUtils";
import { updateHeaderTitle } from "../../app/data/actions";

const ClientForm = (props) => {
  const [heights, setHeight] = useState("");
  const [savedClientId, setSavedClientId] = useState(false);

  useEffect(() => {
    heightSet(setHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
      },
      false
    );
  });

  useEffect(() => {
    if (props.match.path === "/client/edit/:id") {
      dispatch(updateHeaderTitle({ title: "Client: " + clientName }));
    } else dispatch(updateHeaderTitle({ title: "Create Client" }));
  }, [clientName]);

  const clientFetched = useSelector((state) =>
    getIsClientFetched(state, props)
  );

  const client = useSelector((state) => getClient(state, props));
  const params = useSelector((state) => getClientParams(state, props));
  const dispatch = useDispatch();
  let clientName = "";
  let clientId = "";

  if (props.match.path === "/client/edit/:id") {
    clientName =
      props.location.clientName === undefined ? "" : props.location.clientName;
    clientId =
      props.location.clientId === undefined ? "" : props.location.clientId;
  }

  const saveClient = (values, resetForm) => {
    if (props.match.path === "/client/create") {
      // userACL.atCreate(values)
      dispatch(createClient(values));
      setSavedClientId(values.id);
      // setTimeout(function(){
      //     props.history.replace({pathname: "/client/list", clientId:values.id, clientName: values.clientName})
      // }, 2000);
      resetForm();
    }
    if (props.match.path === "/client/edit/:id") {
      // userACL.atUpdate(values)
      dispatch(editClient(values));
      setSavedClientId(values.id);
      setTimeout(function () {
        props.history.replace({
          pathname: "/client/list",
          clientId: values.id,
          clientName: values.clientName,
        });
      }, 2000);
    }
  };

  return (
    <Container>
      <Grid columns={8} divided columns="equal">
        <Grid.Column
          width={13}
          style={{ height: heights + "px" }}
          className="paddingLeftRight70 paddingTopZero outerDivClassForForm"
        >
          <Formik
            id="clientForm"
            size="large"
            width={5}
            initialValues={client}
            validationSchema={clientSchema}
            onSubmit={(values, { resetForm }) => saveClient(values, resetForm)}
            render={({ handleSubmit, onChange, values, handleChange }) => (
              <Form
                as={FormikForm}
                size="large"
                className="CustomeForm"
                width={5}
                onSubmit={handleSubmit}
              >
                <Form.Group widths={2}>
                  <Field
                    name="clientName"
                    label="Client Name"
                    component={FormikInputComponent}
                  ></Field>
                  <Field
                    name="cidRb"
                    label="Cid Rb"
                    component={FormikInputComponent}
                  ></Field>
                </Form.Group>
                <Button
                  type="submit"
                  floated="left"
                  color="blue"
                  size="medium"
                  className="CustomeBTN"
                >
                  {" "}
                  {params.submitButtonText}{" "}
                </Button>
                {savedClientId ? (
                  <Notification
                    id={savedClientId}
                    notifySelector={getNotification}
                    notifyDoneAction={setNotifyDone}
                    type="save"
                  />
                ) : null}
              </Form>
            )}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default ClientForm;
