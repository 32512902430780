import { combineReducers } from 'redux';


const defaultLoginParams = {
  loginStage: '',
  currUser:'',
  successfulLogin: false,
  failedLogin: false,
  requestLogin: false,
  requestLogout: false,
  successfulLogout: false,
  failureMessage: '',
  changedPassword : false,
  changingPassword:false
}


function params(state = defaultLoginParams, action) {

  if (action.type === 'LOGIN_REQUEST') { 
      return {...state, 
        loginStage: 'pending',
        requestLogin: true,
        successfulLogout: false,
        failedLogin: false,
      }
  }   if (action.type === 'LOGIN_SUCCESS') { 
    return {...state, 
      loginStage: 'success',
      requestLogin: false,
      successfulLogin: true,
    }
  } 
  if (action.type === 'LOGIN_FAILURE') { 
    return {...state, 
      loginStage: 'error',
      failedLogin: true,
      requestLogin : false,
      failureMessage: action.payload,
    }
  } 
  if (action.type === 'LOGOUT_REQUEST') { 
    return {...state, 
      requestLogout:true
    }
  }if(action.type === 'CHANGED_PASSWORD'){
    return {...state, 
      changedPassword: action.payload
    }
  }if(action.type === 'CHANGEING_PASSWORD'){
    return {...state, 
      changingPassword: action.payload,
      changedPassword : !action.payload ? "" : action.changedPassword
    }
  }if (action.type === 'LOGIN_FAILURE') { 
    return {...state, 
      loginStage: 'error',
      failedLogin: true,
      requestLogin : false,
      failureMessage: action.payload,
    }
  }  else {
    return state
  }
}

const login = combineReducers({
    params
});

export default login;

