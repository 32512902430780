import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { scheduledprocess } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingScheduledprocess = (state, props) => state.scheduledprocess.params.isFetching;
export const getIsFetchingworkroom = (state, props) => state.workroom.params.isFetching;

export const getIsScheduledprocessFetched = (state, props) => state.scheduledprocess.params.scheduledprocessFetched;
export const getIsSearching = (state, props) => state.scheduledprocess.params.isSearching;
export const getIsSearchFetched = (state, props) => state.scheduledprocess.params.searchFetched;
export const getSearchResults = (state, prop) => state.scheduledprocess.searchResults
export const filters = (state, prop) =>  state.scheduledprocess.filterResults ;
export const getDeleteStatus = (state, prop) => state.scheduledprocess.deleteStatus
export const getDelFetched = (state, prop) => state.scheduledprocess.params.isDelFetched
export const getNotification = (state, id) => state.scheduledprocess.notifications[id]

export const getScheduledprocessList = (state, props) => state.scheduledprocess.byId
export const workroomList = (state, props) => state.workroom.byId
export const getScheduledprocess = (state, props) => {
    if(props.match.path==='/scheduledprocess/create'){
        return scheduledprocess()
    }
    if(props.match.path==='/scheduledprocess/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.scheduledprocess.byId[_id]);
        console.log('object', obj)
        return obj        
    }
}

export const getScheduledprocessParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/scheduledprocess/create')){ 
        params.title = state.scheduledprocess.params.createTitle
        params.submitButtonText = state.scheduledprocess.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/scheduledprocess/edit/')){ 
        params.title = state.scheduledprocess.params.editTitle
        params.submitButtonText = state.scheduledprocess.params.editSubmitButtonText        
    };

    return params
}

export const isDuplicateScheduledProcess = ( state, workroomid, processid ) => {
    // const list = state.scheduledprocess.byId
    const values = Object.values(state.scheduledprocess.byId)
    
    const obj = values.map( (scheduledprocess) => { 
        if (scheduledprocess.processId == processid && scheduledprocess.workroomId == workroomid)
        {
            return true
        }
    })
    return false

}

export const selectScheduledprocess = createSelector(
    getScheduledprocessList,
    scheduledprocess => { 
        const keys = Object.keys(scheduledprocess)
        const obj = keys.map( (key) => { return { key : key, value : key, text : scheduledprocess[key].name }})
        return obj
    })


export const selectScheduledprocessSearched = createSelector(
    getSearchResults,
    scheduledprocess => { 
        const keys = Object.keys(scheduledprocess)
        const obj = keys.map( (key) => { return { key : key, value : key, text : scheduledprocess[key].name }})
        return obj
    })


export const getScheduledprocessArray = createSelector(getScheduledprocessList, workroomList, (process, workroom) => {
    process = Object.values(process).filter(items => items.status == 1);
    let i = 0;
    return Object.values(process).map(p => {
        let newObj = cloneDeep(p)
        newObj.index = ++i;
        newObj.workroomName = workroom[p.workroomId] ? workroom[p.workroomId].name : null
        return newObj
    })    
})
