import React, { useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getProcess, getNotification, getProcessParams, getIsProcessFetched } from '../data/selectors';
import { createProcess, editProcess, setNotifyDone } from '../data/actions';
import { processSchema, duplicateCheckProcessName } from '../data/model'
import { Container, Header, Table, TableHeader, TableBody, Form, Button, Grid, Accordion } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'
import StepsListForPanel from '../../app/components/stepsListForProcessPanel'
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'

const steps = props => {
    const dispatch = useDispatch();
    const isNegative = props.steps.status === 1 ? false : true;
    
    
    return (
        <Table.Row>
            <Table.Cell>{props.index}</Table.Cell>
            <Table.Cell>{props.steps.process_name}</Table.Cell>
        </Table.Row>
)}






const StateForm = (props) => {
    const [heights, setHeight] = useState('')
    const [savedProcessId, setSavedProcessId] = useState(false)
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    // //console.log(savedProcessId, "==savedProcessId");
    
    const processFetched = useSelector(state=> getIsProcessFetched(state, props))
    // const processFetched = useSelector(state => select.getIsProcessFetched(state, props))

    const process = useSelector(state => getProcess(state, props))
    const params = useSelector(state => getProcessParams(state, props))
    const dispatch = useDispatch()
    let processName = "";
    let processId = "";
    
    if (props.match.path === '/process/edit/:id') {
        processName = props.location.processName === undefined ? "" : props.location.processName;
        processId = props.location.processId === undefined ? "" : props.location.processId;
    }

    const callNewStep = () => {
        props.history.push({pathname: "/steps/create", processId:processId, processName: processName})
    }
    
    const panels = [
        {
          key: 'Steps',
          title: { content:
            <span className="padding70">
                <span className="titleHead">
                    Steps
                </span>
                <Link className="newStep" to={{pathname: "/steps/create", processId:processId, processName: processName}}>
                  <Button className="buttonCssCstm" color='green'>
                    +
                  </Button>
                </Link>
                {/* <Button className="buttonCssCstm" onClick={callNewStep} color='green'>
                    Add
                </Button> */}
            </span>
             },
          content: {
              content: (
                <div >
                    <div className="maxMinHeight marginRight13">
                        <StepsListForPanel isEdit="show" processId={processId} processName={processName}/>
                    </div>
                    
                </div>
              ),
            },
        },
        {
          key: 'History',
          title: { content:
            <span className="titleHead">
              History
            </span> },
          content: {
            content: (
              <div className="padding40">
                  Hello
              </div>
            ),
          },
        },
      ]

    const saveProcess = (values, resetForm) => {
        if (props.match.path === '/process/create') {
            userACL.atCreate(values)
            dispatch(createProcess(values))
            setSavedProcessId(values.id)
            setTimeout(function(){ 
                props.history.push({pathname: "/steps/create", processId:values.id, processName: values.process_name})    
            }, 2000);            
            // resetForm()
        }
        if (props.match.path === '/process/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editProcess(values))
            setSavedProcessId(values.id)
            setTimeout(function(){ 
                props.history.push({pathname: "/steps/create", processId:values.id, processName: values.process_name})    
            }, 2000);
        }
    }

    return (
        <Container >
            <Header id="headDiv" className="padding55" as='h1'>Client Onbording</Header>
            <Grid columns={3} divided columns='equal'>
                <Grid.Column style={{height: (heights)+"px"}} className=" paddingTopZero outerDivClassForForm">
                    <Formik id="processForm" size="large" width={5}
                        // initialValues={process}
                        // validationSchema={processSchema}Start, Await Confirmation, Await Payment, Await  Data, Await Training, Await Client Confirmation
                        // onSubmit={(values, { resetForm }) => saveProcess(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="large" className="CustomeForm" width={5} onSubmit={handleSubmit}>
                                <Field name="state" component={FormikInputComponent} label="State"></Field>
                                <Form.Group widths={2}>
                                    <Field name="initialState1" component={FormikInputComponent} label="Initial State"></Field>
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name="task11" component={FormikInputComponent} label="task"></Field>
                                    <Field name="newState11" component={FormikInputComponent} label="New State"></Field>
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name="task12" component={FormikInputComponent} label="task"></Field>
                                    <Field name="newState12" component={FormikInputComponent} label="New State"></Field>
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name="initialState2" component={FormikInputComponent} label="Initial State"></Field>
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name="task21" component={FormikInputComponent} label="task"></Field>
                                    <Field name="newState21" component={FormikInputComponent} label="New State"></Field>
                                
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name="task22" component={FormikInputComponent} label="task"></Field>
                                    <Field name="newState22" component={FormikInputComponent} label="New State"></Field>
                                </Form.Group>
                                
                                <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                {/* {//console.log(JSON.stringify(savedProcessId)+"==============================================notification")} */}
                                {savedProcessId ?
                                    <Notification id={savedProcessId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default StateForm;