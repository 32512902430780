import React, { useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Table, Input } from "semantic-ui-react";
import { compileExpression } from "filtrex";
import Fuse from "fuse.js";

export const displayAmtInLakh = (val) => {
  const reg = /(\d)(?=(\d{2})+\d\.)/g;
  return parseFloat(val).toFixed(2).replace(reg, "$1,");
};

export const displayDate = (val) => {
  if (moment(val).isValid()) return moment(val).format("DD-MM-YYYY");
  return "";
};

// const numberFilterRegex = /^(=|>=|<=|!=|>|<)\s?(\d)+((\.)(\d)+)?\s?(\s{1}(and|or)\s{1}(=|>=|<=|!=|>|<)\s?([\d])+((\.)(\d)+)?)?$/
const numberFilterRegex =
  /^(=|>=|<=|!=|>|<|\d+)\s?(\d?)+((\.)(\d)+)?\s?(\s{1}(and|or)\s{1}(=|>=|<=|!=|>|<|\d+)\s?([\d?])+((\.)(\d)+)?)?$/;

export const FilterCell = ({ col, type, filterAction }) => {
  const dispatch = useDispatch();
  const [str, setStr] = useState();
  const [isError, setIsError] = useState();

  const handleChange = (e) => {
    let pass = true;
    if (type === "number" && e.target.value !== "")
      pass = numberFilterRegex.test(e.target.value);
    setStr(e.target.value);
    setIsError(!pass);
  };
  const handleBlur = () => {
    console.log("TextFilterCell", str);
    if (str) dispatch(filterAction({ col, str, type }));
    else dispatch(filterAction({ col, str: "", type }));
  };
  return (
    <Table.Cell>
      <Input
        fluid
        icon="search"
        iconPosition="left"
        name="str"
        value={str}
        onBlur={handleBlur}
        onChange={handleChange}
        error={isError}
      />
    </Table.Cell>
  );
};

export const numberFilter = (filteredItms, filters) => {
  const cols = Object.keys(filters);
  let filterExpression = "";
  let v = "";
  if (cols.length > 0) {
    cols.forEach((col) => {
      v = "";
      if (filters[col].str !== "" && filters[col].type === "number") {
        v = filters[col].str;
        //  if a single equals '=' is given => replace with '=='
        v = v.replace(/(?<!\!)[=]/g, "==");
        //  if only digits are given w/o any operators  => add '=='
        v = v.replace(/(?<!(=\s?|<\s?|>\s?|\d|\.))[\d.]+/g, "==$&");
        v = v.replace(/ and /g, ` and ${col} `);
        v = v.replace(/ or /g, ` or ${col} `);
        v =
          v.indexOf("(") === 0
            ? v.replace(" (", `(${col} `)
            : ` ${col} `.concat(v);
        console.log("v", v);
        if (filterExpression === "") filterExpression = filterExpression + v;
        else filterExpression = filterExpression + " and " + v;
        console.log("filterExpression", filterExpression);
        const numFilter = compileExpression(filterExpression);
        filteredItms = filteredItms.filter((itm) => {
          if (numFilter(itm) === 1) return itm;
        });
      }
    });
  }
  return filteredItms;
  // console.log('filterExpression', filterExpression)
};

export const textFilter = (filteredItms, filters) => {
  const cols = Object.keys(filters);
  let v = "";
  if (cols.length > 0) {
    cols.forEach((col) => {
      v = "";
      if (filters[col].str !== "" && filters[col].type === "text") {
        const options = {
          tokenize: true,
          threshold: 0,
          findAllMatches: true,
          minMatchCharLength: 1,
          keys: [col],
        };
        let fuse = new Fuse(filteredItms, options);
        console.log("options", fuse);
        filteredItms = fuse.search(filters[col].str);
      }
    });
  }
  console.log("Fuse: ", filteredItms);
  return filteredItms;
};

// Match for Number Filter
//^(=|>=|<=|!=|>|<)\s?(\d)+((\.)(\d)+)?\s?(\s{1}(and|or)\s{1}(=|>=|<=|!=|>|<)\s?([\d])+((\.)(\d)+)?)?+$

// function dateManipulation(e){
//     var j=$(e),f=j.val(),a=$("#vp_fromdt_hidden").val(),i=$("#vp_todt_hidden").val(),b=getCurrentDate("dd-MM-yyyy");
//     if($.trim(f)==""){
//         var g=getDateInYYYYMMDD(a,"-","-"),h=getDateInYYYYMMDD(i,"-","-"),d=getCurrentDate("yyyy-MM-dd"),c=checkVperiodDate(g,h,d);
//         if(j.hasClass("vprequired")){
//             if(c==true){
//                 if(j.hasClass("daterequired")){
//                     j.val(b)
//                     }
//                 }else{
//             jAlert("Please make sure the date falls within Financial Year selected or Change the Financial Year.", "Date Out Of Financial Year",function(){
//                 if(a!=undefined&&a!=""){
//                     j.val(a).select()
//                     }else{
//                     j.val("").focus()
//                     }
//                 })
//         }
//     }else{
//     if(j.hasClass("daterequired")){
//         j.val(b)
//         }
//     }
// }else{
//     f=f.replace(/[ ]/gi,"-").replace(/[*]/gi,"-").replace(/[.]/gi,"-").replace(/[+]/gi,"-").replace(/[/]/gi,"-"),checkValidDateFormats(f,j)
//     }
// }

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const copyToClipboardTable = (text) => {
  console.log(text);

  navigator.clipboard.writeText(text);
};

export const copyToClipboardCell = (text) => {
    console.log(text);
  
    navigator.clipboard.writeText(text);
  };
