import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {fetchSteps, deleteSteps, setNotifyDone } from '../data/actions'
import * as select from '../data/selectors'
import { Container, Table, TableHeader, TableBody, Header } from 'semantic-ui-react'
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'
    

const Steps = props => {
    const dispatch = useDispatch();
    const isNegative = props.steps.status === 1 ? false : true;
    
    const [deleteStepsId, setDeleteStepsId] = useState(false);
    
    const deleteStepsFromList = (values) => {
        const getValue = {id: values}
        userACL.atUpdate(getValue)
        dispatch(deleteSteps(getValue))
        setDeleteStepsId(values);
    }
    
    return (
        <Table.Row>
            <Table.Cell>{props.steps.key}</Table.Cell>
            <Table.Cell>{props.steps.name}</Table.Cell>
            <Table.Cell>{props.steps.short_name}</Table.Cell>
            <Table.Cell>
                    {isNegative === false ? 
                        <>
                            <Link to={"/steps/edit/"+props.steps.id}>Edit</Link><span>&nbsp;&nbsp;</span>
                            <span style={{cursor: 'pointer', color: 'red'}} onClick={() => deleteStepsFromList(props.area.id)}>Delete</span>
                        </>
                    :
                        <span>Deleted</span>
                    }
                    <>
                    {
                        deleteStepsId ? 
                        <Notification id={deleteStepsId} notifySelector={select.getNotification} notifyDoneAction={setNotifyDone} type='delete'/>
                        :
                        null
                    }
                    </>
                </Table.Cell>
        </Table.Row>
)}

const StepsList = (props) => {

    const steps = useSelector(state => state.steps.byId) 
    const stepsFetched = useSelector(state => select.getIsStepsFetched(state, props))
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchSteps());
    });        

    // Loading Logic if tasks not fetched
    if (!stepsFetched){
        return <p>Loading ... </p>
    }  else {
        return (
            <Container>
                <Table selectable basic='very'>
                    <TableHeader>
                        <Table.Row>
                            <Table.HeaderCell>Key</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Short Name</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </TableHeader>
                    <TableBody>
                        { Object.keys(steps).map((key) => 
                        <Steps steps={steps[key]} key={steps[key].id} /> )}
                    </TableBody>
                </Table>
            </Container>
        )
    }
}

export default StepsList;
