import React from 'react';
import {  Icon } from 'semantic-ui-react';
import * as Constants from '../modules/app/data/staticData'

export function getIconColour (status) {
    switch (status) {
        case Constants.STATUS_COMPLETED : 
        return 'green'

        case Constants.STATUS_WAITING :
        return 'yellow'

        case Constants.STATUS_WORKING :
        return 'red'

        case Constants.STATUS_CANCEL :
        return 'red'

        case Constants.STATUS_ACTIVE:
        return 'blue'

        case Constants.STATUS_HOLD:
        return 'orange'

        case Constants.STATUS_APPROVE:
        return 'purple'

        case Constants.STATUS_ALERT:
        return 'orange'

        case Constants.STATUS_UNRESOLVED:
        return 'yellow'

        case Constants.STATUS_PARTIAL_DONE:
        return 'green'

    }
    return null

}

export function getIconName (status) {
    switch (status) {
        case Constants.STATUS_COMPLETED : 
        return 'check circle'

        case Constants.STATUS_PARTIAL_DONE:
        return 'check'

        case Constants.STATUS_WAITING :
        return 'wait'

        case Constants.STATUS_WORKING :
        return 'sync'

        case Constants.STATUS_CANCEL :
        return 'cancel'

        case Constants.STATUS_ACTIVE:
        return ''

        case Constants.STATUS_HOLD:
        return 'pause'

        case Constants.STATUS_APPROVE:
        return 'bell'

        case Constants.STATUS_ALERT:
        return 'help circle'

        case Constants.STATUS_UNRESOLVED:
        return 'ban'
    }
    return ''

}
export function getTaskCompletedTick (status) {
    if (status == Constants.STATUS_COMPLETED) 
        return <Icon name='circle' color='green' size='small' />
    else 
        return null

}