export const setMenuHeight = (setHeight) => {
    let windowHeight = window.innerHeight
    let header1 = document.getElementById("header1") ? document.getElementById("header1").clientHeight : 0
    let setHeightDiv = parseFloat(windowHeight) - parseFloat(header1 - 13);
    setHeight(setHeightDiv)
}

export const setMainContainerHeight = (setHeight) => {
    let windowHeight = window.innerHeight
    setHeight(windowHeight + 10)
}


export const heightSet = (setHeight) => {
    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let getHeight = parseFloat(headDiv) + 100
    let windowHeight = window.innerHeight
    let setHeightDiv = parseFloat(windowHeight) - parseFloat(getHeight)
    // console.log(setHeightDiv, "==", windowHeight, "==", menuHeight, "==", getHeight);
    // console.log("TestTestTestTestTestTestTestTestTestTestTest");
    
    setHeight(setHeightDiv)
}

export const heightSetForTaskTab = (setHeight) => {

    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let pointing = 86
    let getHeight = parseFloat(headDiv) + parseFloat(pointing) + 10
    let windowHeight = window.innerHeight
    let setHeightDiv = parseFloat(windowHeight) - parseFloat(getHeight)
    // console.log(pointing, "==",setHeightDiv, "==", windowHeight, "==", menuHeight, "==", getHeight);
    setHeight(setHeightDiv)
}





export const heightSetFotTaskFormForNonMenu = (setHeightTaskForNonMenu) => {
    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let getHeight = parseFloat(headDiv) + 20
    let windowHeight = window.innerHeight
    let setHeightDiv = parseFloat(windowHeight) - parseFloat(getHeight)
    let secondaryMenu = document.getElementById("secondaryMenu") ? document.getElementById("secondaryMenu").clientHeight : 0
    // console.log(setHeightDiv, "==", secondaryMenu)
    let comentView = parseFloat(setHeightDiv) - parseFloat(secondaryMenu) - 15
    setHeightTaskForNonMenu(comentView)
}
export const heightSetFotTaskForm = (setHeightTask) => {

    let comments = document.getElementById("menuCell") ? document.getElementById("menuCell").clientHeight : 0
    let cmntSection = document.getElementById("cmntSection") ? document.getElementById("cmntSection").clientHeight : 0
    let comentView = parseFloat(comments) -  parseFloat(cmntSection) - 150;
    // console.log(comments, "==", cmntSection, "==", comentView);
    
    setHeightTask(comentView)

}

export const heightSetFotTaskFormActivityForm = (setHeightTask) => {

    let task = document.getElementById("task") ? document.getElementById("task").clientHeight : 0
    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let comentView = parseFloat(task) - parseFloat(headDiv) + 30;
    // console.log(task, "==", headDiv, "==",comentView);
    
    setHeightTask(comentView)

}

export const heightSetFotTaskFormSummary = (setHeightTask) => {

    let summary = document.getElementById("summary") ? document.getElementById("summary").clientHeight : 0
    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let comentView = parseFloat(summary) - parseFloat(headDiv);
    // console.log(summary, "==", headDiv, "==",comentView);
    
    setHeightTask(comentView)

}

export const heightSetFotTaskAttachment = (setHeightTask) => {

    let summary = document.getElementById("attachment") ? document.getElementById("attachment").clientHeight : 0
    let DocView = parseFloat(summary) - 20;
    
    setHeightTask(DocView)

}

export const widthSet = (setWidth) => {

    let windowWidth = window.innerWidth
    let menuContent = document.getElementById("menuCell") ? document.getElementById("menuCell").clientWidth : 0;
    let containerWidth = parseFloat(windowWidth) - parseFloat(menuContent) + 10;
    setWidth(containerWidth);
}

export const widthSetForComment = (setWidth) => {

    let menuContent = document.getElementById("mainDiv") ? document.getElementById("mainDiv").clientWidth : 0;
    setWidth(menuContent);
}

export const innerHeightSet = (setInnerHeight) => {

    // let menuHeight = document.getElementById("mainMenu") ? document.getElementById("mainMenu").clientHeight : 0
    let headDiv = document.getElementById("headDiv") ? document.getElementById("headDiv").clientHeight : 0
    let innerHeadDiv= document.getElementById("tblHead") ? document.getElementById("tblHead").clientHeight : 0
    let innerFootDiv= document.getElementById("tblFoot") ? document.getElementById("tblFoot").clientHeight : 0
    let getHeight = parseFloat(headDiv) + parseFloat(innerHeadDiv) + parseFloat(innerFootDiv)
    let windowHeight = window.innerHeight
    let setHeightDiv = parseFloat(windowHeight) - (parseFloat(getHeight))
    setInnerHeight(setHeightDiv)
}

export const menuAccordionHeight = (setInnerHeight, teamLed) => {

    let bottomOption = document.getElementById("bottomOption") ? document.getElementById("bottomOption").clientHeight : 0
    let homeIcon = document.getElementById("header1") ? document.getElementById("header1").clientHeight : 0
    let windowHeight = window.innerHeight
    
    let addHeight = 70
    if (teamLed && teamLed.length > 0)
        addHeight = 120
        

    let setHeightDiv = parseFloat(windowHeight) - (parseFloat(bottomOption) + parseFloat(homeIcon) + addHeight)
    // console.log (' windowHeight = ', windowHeight , ', homeIcon = ', homeIcon, ', setHeighTdiv = ', setHeightDiv)
    setInnerHeight(setHeightDiv)
}

export const lodingIconHeight = (setInnerHeight) => {
    let mainContainerHt  = document.getElementById("mainContainer") ? document.getElementById("mainContainer").clientHeight : 0
    // console.log(mainContainerHt,"==mainContainerHt");
    
    let loddingIconPosition = parseFloat(mainContainerHt) / 2
    setInnerHeight(loddingIconPosition)
}



