import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Grid } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { updateHeaderTitle } from "../../app/data/actions";
import {
  getMyCreatedTasks,
  getMyCurrAllTasks,
  getMyCurrPriorityTasks,
} from "../data/selectors";
import MyTasksCurrJobs from "./myTasksCurrJobs";
import MyTasksCreated from "./myTasksCreated";

const MyTasksDispContainer = (props) => {
  const myjobs = useSelector((state) => getMyCurrAllTasks(state));
  const myPriorityjobs = useSelector((state) => getMyCurrPriorityTasks(state));
  const myCreatedjobs = useSelector((state) => getMyCreatedTasks(state));

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  const [currActiveIndex, setCurrActiveIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    setCurrActiveIndex(0);
  }, [props.location.pathname, props.location.key]);

  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "My Dashboard" }));
    document.title = "Tasks: My Dashboard";
  }, []);

  const panes = () => {
    // console.log(' creating tabpanes')

    let tabpanes = [
      {
        menuItem: "My Prioritised Jobs",
        render: () => (
          <Tab.Pane className="borderNoneObj textAlignLeft">
            <MyTasksCurrJobs
              {...props}
              myjobs={myPriorityjobs}
              filteredByPrioritySet={true}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "All My Pending Jobs",
        render: () => (
          <Tab.Pane className="borderNoneObj textAlignLeft">
            <MyTasksCurrJobs {...props} myjobs={myjobs} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Owned By Me",
        render: () => (
          <Tab.Pane className="borderNoneObj textAlignLeft">
            <MyTasksCreated {...props} myjobs={myCreatedjobs} />
          </Tab.Pane>
        ),
      },
    ];

    return tabpanes;
  };

  return (
    <Grid divided columns="equal">
      <Grid.Column
        style={{ height: innerHeights - 120 + "px" }}
        className="borderNoneObj paddingTopZero"
      >
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            borderless: true,
            attached: true,
          }}
          // defaultActiveIndex={0}
          activeIndex={currActiveIndex}
          onTabChange={(event, data) => {
            setCurrActiveIndex(data.activeIndex);
          }}
          panes={panes()}
          className="borderNoneObj"
        />
      </Grid.Column>
    </Grid>
  );
};

export default MyTasksDispContainer;
