import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { Label, Container } from 'semantic-ui-react'
import { getAttachmentsByJobAndWorkroomId} from '../data/selectors'
import { widthSet, setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { handleFileDownload } from './commentsUtils'
import moment from 'moment'


const AttachmentsListDisplay = (props) => {

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [innerCommentsHeights, setInnerCommentHeight] = useState('')
  const [widths, setWidth] = useState('')

  const jobId = props.job ? props.job.id : undefined
  const workroomId = props.job ? props.job.workroomId : undefined
  const attachmentsRecd = useSelector(state => getAttachmentsByJobAndWorkroomId(state, workroomId, jobId)) 

  const getReadableDate = (longdate) => {
    let jdate = new Date(longdate / 1000000)
    let str = moment(jdate).format("DD-MM-YYYY")
    return str
  }

  useEffect(() => {
    setMenuHeight(setHeight)
    widthSet(setWidth)
    menuAccordionHeight(setInnerHeight)
    window.addEventListener('resize', function(){
        setMenuHeight(setHeight)
        widthSet(setWidth)
        menuAccordionHeight(setInnerHeight)
        // heightSetFotTaskForm(setInnerCommentHeight)
    }, false);
  }) 

function renderAttachment(comment, i) {
  let attachIds = (comment.attachmentId && comment.attachmentId !='') ? comment.attachmentId.split('||') : undefined
  let attachNames = (comment.attachmentName && comment.attachmentName !='') ? comment.attachmentName.split('||') : undefined

  return ( 
            <>
              {/* {attachIds.length > 1 ? <br /> : null} */}
              {attachIds.map(( attachmentid, i ) => 
              <>
              {}
              <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
              <Label as ='a' basic color='blue' className='borderNoneObj' id={comment.id} onClick= {()=>handleFileDownload(attachmentid)} >
                {attachNames[i]}     
              </Label>
              <span style={{fontSize: 11}}>{getReadableDate(comment.dtCreate)} </span>
              </div>
              </>
              )}
            </>        
      )
  }

  if (!attachmentsRecd) return null
  let data = attachmentsRecd
  return (
    <Container>
        <div  id = 'comments' className="outerDivClass hideScroll" >
        {data.map((comment) => (renderAttachment(comment)))}
    </div>
    </Container>
  )
}



export default AttachmentsListDisplay;
