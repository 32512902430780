import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsCommentsFetched } from './selectors'
import {savedComments,fetchedComments, uploadedFile, fetchedSignedUrl, storeSearchCommentsResult, deletedComments } from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import apiContainer,{getHeaderJWT, URL} from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Comments 

function* createComments() {
    yield takeEvery('CREATE_COMMENTS',saveComments)
  }

function* saveComments(action){
  const { response, error } = yield call(saveCommentsAPI, action.payload)
  console.log("In save comments the comment returned from saga is",response)
  if (response) 
  try {
    yield put (savedComments(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveCommentsAPI(data) {
    return axios.post(URL+'/comments/post', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Comments 

function* editComments() {
  yield takeEvery('EDIT_COMMENTS',saveEditedComments)
}

function* saveEditedComments(action){
  const { response, error } = yield call(editCommentsAPI, action.payload)  
  if (response) 
  try {
    yield put (savedComments(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editCommentsAPI(data) {
  let user_id = data._id
  return axios.post(URL+'/comments/update/'+ user_id, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Comments 

function* deleteComments() {
  yield takeEvery('DELETE_COMMENTS',saveDeleteComments)
}

function* saveDeleteComments(action){
  const { response, error } = yield call(deleteCommentsAPI, action.payload)
  if (response) 
  try {
    yield put (deletedComments({id: action.payload.id, msg : response.data}))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteCommentsAPI(data) {
  let commentsId = data.id
  return axios.post(URL+'/comments/delete/'+ commentsId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Comments 

function* fetchComments() {
  yield takeEvery('FETCH_COMMENTS',requestComments)
}
  

function* requestComments(action){
  // const isCommentsFetched = yield select(getIsCommentsFetched);
  // if(!isCommentsFetched){
    let { response, error } = yield call(requestCommentsAPI, action.payload)
    console.log(' Comments received for workroom : ', response.data)
    
    if (response) 
    try {
      yield put (fetchedComments({'responseData': response.data, 'requestData' : action.payload}))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}


function requestCommentsAPI(data) {

  let workroomId  = data.workroomId
  let dateCreated = data.dtCreated
  let requrl = URL+'/comments/list/' + workroomId + '/'+  dateCreated
  console.log (' %%%%%% Calling comments with URL for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for List Comments  by Job

function* fetchCommentsByJob() {
  yield takeEvery('FETCH_COMMENTS_BY_JOB',requestCommentsByJob)
}
  

function* requestCommentsByJob(action){
    let { response, error } = yield call(requestCommentsByJobAPI, action.payload)    
    if (response) 
    try {
      yield put (fetchedComments({'responseData': response.data, 'requestData' : action.payload}))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}


function requestCommentsByJobAPI(data) {

  console.log (' =========================================== ')
  let jobId  = data.jobId
  let dateCreated = data.dtCreated
  let requrl = URL+'/comments/list-by-job/' + jobId + '/'+  dateCreated
  console.log (' %%%%%% Calling comments with URL for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Signed URL

function* fetchSignedUrl() {
  yield takeEvery('FETCH_SIGNED_URL',requestSignedUrl)
}
  

function* requestSignedUrl(action){
    let { response, error } = yield call(requestSignedUrlAPI, action.payload)
    
    if (response) 
    try { 
      yield put (fetchedSignedUrl(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}


function requestSignedUrlAPI(data) {
  let workroomId = data.workroomId
  let jobId = data.jobId
  let fileId  = data.fileId
  let requrl = URL+'/comments/signed-url/upload/' + workroomId + '/' + jobId + '/' + fileId
  console.log (' %%%%%% Calling signed url URL for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Upload File

function* uploadFile() {
  yield takeEvery('UPLOAD_FILE',requestUploadFile)
}
  

function* requestUploadFile(action){
  // const isCommentsFetched = yield select(getIsCommentsFetched);
  // if(!isCommentsFetched){
    let { response, error } = yield call(requestUploadFileAPI, action.payload)
    
    if (response) yield put (uploadedFile(response.data))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}


function requestUploadFileAPI(data) {

  let fileId  = data.fileId
  let requrl = URL+'/comments/signed-url/upload/' +  fileId
  console.log (' %%%%%% Calling signed url URL for : ', requrl)

  return axios.get(requrl)
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



//#region Saga for Search Comments 

function* searchComments() {

  yield takeLatest('SEARCH_COMMENTS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchCommentsResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/comments/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* commentsSaga() {
    yield all([
        createComments(),
        fetchComments(),
        fetchCommentsByJob(),
        editComments(),
        deleteComments(),
        searchComments(),
        fetchSignedUrl()
        // uploadFile()
    ])
  }  