import * as type from './types'


export function createUsers(users) {
    return {
        type : type.CREATE_USERS,
        payload : users,
        txn : 'initiated',
    };
}


export function savedUsers(users) {
    return {
        type : type.SAVED_USERS,
        payload : users,
        txn : 'success',
        diff : true
    };
}


export function editUsers(users) {
    return {
        type : type.EDIT_USERS,
        payload : users,
        txn : 'initiated',
    };
}


export function fetchUsers() {
    return {
        type : type.FETCH_USERS,
    };
}


export function fetchedUsers(users) {
    return {
        type : type.FETCHED_USERS,
        payload : users
    };
}


export function searchUsers(users) {
    return {
        type : type.SEARCH_USERS,
        payload : users
    };
}


export function storeSearchUsersResult(users) {
    return {
        type : type.STORE_SEARCH_USERS_RESULT,
        payload : users
    };
}


export function deleteUsers(users) {
    return {
        type : type.DELETE_USERS,
        payload: users
    };
}


export function deletedUsers(msg) {
    return {
        type : type.DELETED_USERS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_USERS,
        payload: props
    };
}


export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}