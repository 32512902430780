export const currTeamId = {
    teamId: '359eab6c-6604-47d5-a858-3658e2bf85a3'
}

export const currUserId = {
    userId : 'c2400a4a-4d43-4461-8be1-13e1a4273826'
    // userId : '5048d00f-330a-4a91-a6e5-00b71085d7d1'
    // userId : '327e6339-cce5-4afc-8e9e-7f75f2e56ca4'
}

export const STATUS_CANCEL = -1
export const STATUS_COMPLETED = 0
export const STATUS_ACTIVE = 1
export const STATUS_HOLD = 2
export const STATUS_WORKING = 3
export const STATUS_WAITING = 4
export const STATUS_APPROVE = 5
export const STATUS_UNRESOLVED = 6
export const STATUS_ALERT = 7
export const STATUS_PARTIAL_DONE = 8
