import React from 'react'
import { Accordion, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import StepsListForPanel from '../../app/components/stepsListForProcessPanel';

const callNewStep = (props) =>{
  window.location({pathname: "/steps/create", processId:props.processId, processName: props.processName})
}

const panels = (props) => [
  {
    key: 'activities',
    title:{ content:
      <span className="padding70">
          <span className="titleHead">
          Activities
          </span>
            {props.isEdit === "show" ?
              <Link className="newStep" to={{pathname: "/steps/create", processId:props.processId, processName: props.processName}}>
                <Button className="buttonCssCstm" color='green'>
                  +
                </Button>
              </Link>
            : "" }
          </span> 
    },
    content: {
        content: (
          <div className="">
              <div className="maxMinHeight paddingRight45 marginRightNav15">
                  <StepsListForPanel isEdit="show" processId={props.processId} processName={props.processName}/>
              </div>
          </div>
        ),
      },
  },
  {
    key: 'action',
    title: { content:
      <span className="titleHead">
        Action
      </span> },
    content: { 
        content: (
          <div className="padding40">
              Hello
          </div>
        ),
    },
  },
  {
    key: 'activity',
    title: { content:
      <span className="titleHead">
        History
      </span> },
    content: { 
      content: (
        <div className="padding40">
            Hello
        </div>
      ),
    },
  },
]

const StepsAction = (props) => {
  return(
    <Accordion  className="boxNone" styled panels={panels({processName: props.processName, processId: props.processId, isEdit: props.isEdit})} />
  )
}

export default StepsAction