import React from 'react';
import { Route, withRouter } from 'react-router';
import WorkRoomList from "./components/workRoomList";
import WorkRoomForm from "./components/workRoomForm";
import { Container } from 'semantic-ui-react'
import WorkroomDispContainer from './components/workRoomDispContainer'

const WorkRoom = (props) => {

    let pathname = props.location.pathname
    return (
        <Container>
            
            <Route path="/workroom/list"    component={(WorkRoomList)} />
            <Route path="/workroom/edit/:id" component={WorkRoomForm} />
            <Route path="/workroom/view/:sname" component={WorkroomDispContainer} />
            <Route path="/workroom/create" component={WorkRoomForm} />
           {/*  {(pathname != '/workroom/list/all' && pathname != '/workroom/create' && !pathname.startsWith('/workroom/edit')) &&
            <Route path="/workroom/:sname" component={WorkroomDispContainer} />
            } */}
        </Container>
    )
};

export default WorkRoom;

