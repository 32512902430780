import { createSelector } from 'reselect'
import { startsWith, cloneDeep, at, concat, orderBy, chain, sortBy } from 'lodash';
import { client } from './model'

export const getIsFetchingClient = (state, props) => state.client.params.isFetching;
export const getIsClientFetched = (state, props) => state.client.params.clientFetched;
export const getClientList = (state, prop) => state.client.byId
export const getIsSearching = (state, props) => state.client.params.isSearching;
export const getIsSearchFetched = (state, props) => state.client.params.searchFetched;
export const getSearchResults = (state, prop) => state.client.searchResults
export const filters = (state, prop) =>  state.client.filterResults ;
export const getDeleteStatus = (state, prop) => state.client.deleteStatus
export const getDelFetched = (state, prop) => state.client.params.isDelFetched
export const getNotification = (state, id) => state.client.notifications[id]

export const getClient = (state, props) => {
    if(props.match.path==='/client/create'){
        return client()
    }
    if(props.match.path==='/client/edit/:id'){
        let id = props.match.params.id
        let obj = cloneDeep(state.client.byId[id]);
        return obj        
    }
}

export const getClientNameFromId = (state, clientid) => {
    if (clientid == undefined )
        return ""
    let obj = state.client.byId[clientid]
   if(obj)
        return obj.clientName
    else    
        return ""
}

export const getClientParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/client/create')){ 
        params.title = state.client.params.createTitle
        params.submitButtonText = state.client.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/client/edit/')){ 
        params.title = state.client.params.editTitle
        params.submitButtonText = state.client.params.editSubmitButtonText        
    };

    return params
}

export const selectClient = createSelector(
    getClientList,
    client => { 
        const keys = Object.keys(client)
        const obj = keys.map( (key) => { 
            if(client[key].status == 1){
                return { value : key, text : client[key].clientName, status : client[key].status, className: "selectLiCss" }
            }else{
                return false
            }
        })
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    })


export const selectClientSearched = createSelector(
    getSearchResults,
    client => { 
        const keys = Object.keys(client) 
        const obj = keys.map( (key) => { return { value : key, text : client[key].clientName, className: "selectLiCss" }})
        return obj
    })
    

export const getClientArray = createSelector(
    getClientList, client => {
        return chain(Object.values(client)).sortBy((item)=> item.clientName).value()
    })
    