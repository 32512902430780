import { combineReducers } from "redux";
import { merge, cloneDeep } from "lodash";
import { normalize, schema } from "normalizr";

// Define a processs schema
const processSchema = new schema.Entity("processs", {}, { idAttribute: "id" });
const processListSchema = [processSchema];

const defaultProcessParams = {
  processFetched: false,
  searchFetched: false,
  isDelFetched: false,
  listTitle: "Process List",
  createTitle: "Create Process ",
  createSubmitButtonText: "Save",
  editTitle: "Edit Process",
  editSubmitButtonText: "Update",
};

function byId(state = {}, action) {
  if (action.type === "SAVED_PROCESS") {
    const processID = action.payload.id;
    return { ...state, [processID]: action.payload };
  }
  if (action.type === "FETCHED_PROCESS") {
    const normalizedProcess = normalize(action.payload, processListSchema);
    return merge({}, state, normalizedProcess.entities.processs);
  }
  if (action.type === "UPDATE_ACTIVITY_DEFINITION") {
    const processKey = action.payload.keyname;
    const processId = action.payload.processId;
    let obj = cloneDeep(state[processId]);
    obj.processKey = processKey;
    return { ...state, [processId]: obj };
  }
  if (action.type === "DELETED_PROCESS") {
    if (action.payload.msg === "Success") {
      const processID = action.payload.id;
      return { ...state, [processID]: { ...state[processID], status: 0 } };
    } else {
      return state;
    }
  } else {
    return state;
  }
}

function searchResults(state = {}, action) {
  if (action.type === "STORE_SEARCH_PROCESS_RESULT") {
    const normalizedProcess = normalize(action.payload, processListSchema);
    return merge({}, normalizedProcess.entities.processs);
  } else {
    return state;
  }
}

function notifications(state = {}, action) {
  if (action.type === "DELETED_PROCESS") {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        delete: {
          status: action.payload.msg === "Success" ? "success" : "error",
          msg: action.payload.msg,
        },
      },
    };
  }
  if (action.type === "SAVED_PROCESS") {
    return {
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        save: {
          status: action.txn === "success" ? "success" : "error",
          msg: action.txn,
        },
      },
    };
  }
  if (action.type === "NOTIFICATION_DONE_PROCESS") {
    const { id, type } = action.payload;
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };
    return finalState;
  } else {
    return state;
  }
}

function params(state = defaultProcessParams, action) {
  if (action.type === "FETCHED_PROCESS") {
    return { ...state, processFetched: true, isFetching: false };
  }
  if (action.type === "SEARCH_PROCESS") {
    return { ...state, searchFetched: false, isSearching: true };
  }
  if (action.type === "STORE_SEARCH_PROCESS_RESULT") {
    return { ...state, searchFetched: true, isSearching: false };
  }
  if (action.type === "DELETED_PROCESS") {
    return { ...state, isDelFetched: true };
  }
  if (action.type === "UPDATE_LIST_TITLE") {
    return {
      ...state,
      listTitle: action.payload.title,
      editTitle: "Edit: " + action.payload.title,
    };
  }
  if (action.type === "SET_PROCESS_FETCH_OFF") {
    return { ...state, isDelFetched: false };
  } else {
    return state;
  }
}

function filterResults(state = {}, action) {
  if (action.type === "FILTER_ITEMS") {
    const col = action.payload.col;
    return { ...state, [col]: action.payload };
  } else {
    return state;
  }
}

const process = combineReducers({
  byId,
  notifications,
  searchResults,
  params,
  filterResults,
});

export default process;
