import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const comments = () => ({
    id: v4(),
    workroomId :"",
    jobId : "",
    taskId : "",
    userId : "",
    comment : "",
    attachmentId :"",
    attachmentName : "",
    source: "user",
    type: "",
    status : 1,
    dtCreate : 0,
    dtUpdate: "",
    segid: "",
    cid: "",
    
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const commentsSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),

  });


export const duplicateCheckCommentsCode = debounce(checkCommentsCode, 500);
function checkCommentsCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/comments/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckCommentsName = debounce(checkCommentsName, 500);
function checkCommentsName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/comments/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  