import React from 'react';
import { Route, withRouter } from 'react-router';
import { Container } from 'semantic-ui-react'
import AppLandingPage from './components/appLandingPage'
import AppPermissionDeniedPage from'./components/appPermissionDeniedPage'
import MyTasksDispContainer from '../mytasks/components/mytasksDispContainer'


const App = () => {
    return (
        <Container>
            <Route path="/app/landing" component={(MyTasksDispContainer)} />
            <Route path="/app/denied" component={(AppPermissionDeniedPage)} />
        </Container>
    )
};

export default App;

