import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { requestLogin } from '../data/actions';
import { loginSchema } from '../data/model'
import { Redirect } from "react-router-dom";
import { Grid, Form, Button, Image, Header, Message, Segment, Container } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikPasswordComponent,FormikFloatingLabelInputComponent } from '../../../utilities/formUtils';
import logo from "../../app/realbooks_front.png";
import { history } from '../../../configureStore'
import { parseJwt } from '../../../utilities/parseJWT'

const LoginForm = (props) => {

    // const login = useSelector(state => getLogin(state, props))
    const loginStage = useSelector(state => state.login.params.loginStage)
    const successfulLogout = useSelector(state => state.login.params.successfulLogout)
    const errorMessage = useSelector(state => state.login.params.failureMessage)
    const dateNow = new Date();
    const isAuthenticated = localStorage.getItem('user')
    const userid = isAuthenticated ? parseJwt(isAuthenticated).identity : null
    const isValid = localStorage.getItem('exp') > dateNow.getTime() / 1000 
    const initialLogin = {'user' : '', 'password' :''}
    const dispatch = useDispatch()
 
    const saveLogin = (values, resetForm) => {
        console.log(' Values are ', values)
            dispatch(requestLogin(values))
    }

    useEffect(() => {
        console.log('loginStage', loginStage);
        // console.log(props);
        if (loginStage === 'success' && !successfulLogout){
            console.log(' ---changing home ----')
            history.push('/app/landing')
        } 
      }, [loginStage]);

    

    //   console.log (' isAuthenticated is ', isAuthenticated, ' isValied is ', isValid)
    // //console.log('Login isValid', isValid, localStorage.getItem('exp'), dateNow.getTime() / 1000);
    if (isAuthenticated && isValid) {
        console.log ('----- Redirecting to home from here -----')
        return (
            <Redirect
                to={{
                    pathname: "/home",
                    // state: { from: props.location }
                }}
            />
        )
    } else {
        return (
            <Container style={{ height: '114vh' }}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
            <Grid centered middle aligned style={{ }}>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column width={6}>
                        <Segment padded='very'>
                        <Image src={logo} width="160" style={{ 'margin' : "0 auto" }} centered/>
                        <br/>
                            <Formik id="loginForm" size="large" width={5}
                                    initialValues = {initialLogin}
                                    validationSchema={loginSchema}
                                    onSubmit={(values, { resetForm }) => saveLogin(values, resetForm)}
                                    render={({ handleSubmit, onChange, values, handleChange }) => (
                                        <Form as={FormikForm} size="big" className="CustomeForm" width={5} onSubmit={handleSubmit}>
                                            <Field id="userName" name="user" component={FormikFloatingLabelInputComponent} placeholder="User email" className='loginField' inputType="text"></Field>
                                            <br/>
                                            <Field  id="password" name="password" component={FormikFloatingLabelInputComponent} placeholder="Password" className='loginField' inputType="password"></Field>
                                            <br/>
                                            <Button type="submit" positive fluid style={{"margin-top":"10px"}}>Login</Button>
                                            {(loginStage === 'error') ?  
                                            <div style={{color: 'red','font-size': '15px',position: 'relative',top: '10px',"text-align":"center"}}>{errorMessage.error_message}</div>
                                            : null}
                                            <br/><br/>
                                        </Form>
                                    )}
                                />
                            </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Container>
        )
    }
}

export default LoginForm;