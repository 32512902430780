import React, {useEffect, useState} from 'react';
import ActivityListForTaskPanel from './stepsListForTaskPanel'
import AttachmentsListDisplay from '../../comments/components/attachmentsList'
import JobDetailsForTaskRightPanel from './jobDetailsForTaskRightPanel'
import JobAuditForTaskRightPanel from './jobAuditForTaskRightPanel'
import { useSelector } from 'react-redux'
import { Grid, Accordion } from 'semantic-ui-react';
import { getActivityByProcessId } from '../../activities/data/selectors'
import { getProcessFromId } from '../../process/data/selectors'
import { getTasksByJobId, getActiveTask } from '../data/selectors'
import MyUploader from '../../comments/components/dnd'
import { parseJwt } from '../../../utilities/parseJWT'
import { widthSet, setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { selectUsers } from '../../users/data/selectors'

const TasksRightMenu = (props)=> {
  const job = props.job
  const jobId = job ? job.id : undefined
  const processId = (job) ? job.processId : undefined
  const workroomId = (job) ? job.workroomId : undefined
  const token = localStorage.getItem('user')
  const userId = token? parseJwt(token).identity : null

  const activitiesData = useSelector(state => getActivityByProcessId(state, processId))
  const taskList = useSelector(state=> getTasksByJobId(state, jobId)) 
  const currActiveTask = useSelector (state => getActiveTask (state, jobId))
  const process = useSelector(state => getProcessFromId(state, processId))
  const userList = useSelector(state => selectUsers(state, props))

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [innerCommentsHeights, setInnerCommentHeight] = useState('')
  const [widths, setWidth] = useState('')

  useEffect(() => {
    setMenuHeight(setHeight)
    widthSet(setWidth)
    menuAccordionHeight(setInnerHeight)
    window.addEventListener('resize', function(){
        setMenuHeight(setHeight)
        widthSet(setWidth)
        menuAccordionHeight(setInnerHeight)
        // heightSetFotTaskForm(setInnerCommentHeight)
    }, false);
  }) 

  const routeChange = () =>{ 
    console.log (' in route change')
    let path = '/process/processfile/create'
  }


  const panelsTasks = [
    {
      key: 'task',
      title: { content:
        <span className="padding70">
            <span className="titleHead">
                Tasks   
                   
            </span>
        </span>
         },
      content: {    
          content: (
            <div >
                <div className="paddingRight45 marginRightNav15">
                    <ActivityListForTaskPanel job={props.job} data = {activitiesData} taskList = {taskList} currActiveTask = {currActiveTask} process={process} userList={userList}/>
                </div>
                
            </div>
          ),
        },
    },
  {
    key: 'attachments',
    title: { content:
      <span className="padding70">
          <span className="titleHead">
              Attachments
          </span>
      </span>
       },
    content: {    
        content: (
          <div >
              <div className=" paddingRight45 marginRightNav15">
                 <AttachmentsListDisplay  job={props.job} />  
              </div>
              
          </div>
        ),
      },
  },
  {
    key: 'details',
    title: { content:
      <span className="padding70">
          <span className="titleHead">
              Details
          </span>
      </span>
       },
    content: {    
        content: (
          <div >
              <div className=" paddingRight45 marginRightNav15">
                 <JobDetailsForTaskRightPanel  job={props.job} />  
              </div>
              
          </div>
        ),
      },
  },
  {
    key: 'timesheet',
    title: { content:
      <span className="padding70">
          <span className="titleHead">
              Audit
          </span>
      </span>
       },
    content: {    
        content: (
          <div >
              <div className=" paddingRight45 marginRightNav15">
                 <JobAuditForTaskRightPanel  job={props.job} />  
              </div>
              
          </div>
        ),
      },
  },
]

return (
    <Grid.Column width={5} className="paddingZero paddingRight30 borderLeft">
        <div style={{height: (innerHeights - 100)+'px', overflowY: "auto"}} className="marginBottom15">
          <Accordion className="boxNone paddingRight15" styled panels={panelsTasks} exclusive = {false} defaultActiveIndex={[0,1,2]}/> 
        </div>
        <div className="paddingLeft15">
          <MyUploader  workroomId={workroomId} jobId={jobId} userId={userId} processId={processId}/>
          </div>
    </Grid.Column>
  )
}

 export default TasksRightMenu