import React from 'react';
import { Route, withRouter } from 'react-router';
import ProcessfileList from "./components/processfileList";
import ProcessfileDisplay from './components/processfileDisplay'
import ProcessfileForm from "./components/processfileForm";
import ProcessDispContainer from '../process/components/processDispContainer'
import { Container } from 'semantic-ui-react'


const Processfile = () => {
    return (
        <Container>
            <Route path="/processfile/list" component={(ProcessfileList)} />
            <Route path="/processfile/edit/:id" component={ProcessfileDisplay} />
            <Route path="/processfile/create" component={ProcessfileForm} />
            <Route path="/process/processfile/create" component={ProcessDispContainer} />
        </Container>
    )
};

export default Processfile;

