export const CREATE_ORGANISATION = 'CREATE_ORGANISATION'
export const SAVED_ORGANISATION = 'SAVED_ORGANISATION'
export const EDIT_ORGANISATION = 'EDIT_ORGANISATION'
export const EDITED_ORGANISATION = 'EDITED_ORGANISATION'
export const FETCH_ORGANISATION = 'FETCH_ORGANISATION'
export const FETCHED_ORGANISATION = 'FETCHED_ORGANISATION'
export const SEARCH_ORGANISATION = 'SEARCH_ORGANISATION'
export const STORE_SEARCH_ORGANISATION_RESULT = 'STORE_SEARCH_ORGANISATION_RESULT'
export const DELETE_ORGANISATION = 'DELETE_ORGANISATION'
export const DELETED_ORGANISATION = 'DELETED_ORGANISATION'
export const NOTIFICATION_DONE_ORGANISATION = 'NOTIFICATION_DONE_ORGANISATION'
