import React from "react";
import { Field } from "formik";
import { FormikSelectComponent } from "../../../utilities/formUtils";

function RoleSelect({
  name,
  isTxn,
  required,
  isLabel,
  label,
  options,
  multiple,
  getValue: callback,
}) {
  const getValue = (values) => {
    console.log(values);
  };

  return (
    <Field
      name={name}
      isLabel={isLabel}
      required={required}
      isTxn={isTxn}
      label={label}
      multiple={multiple}
      component={FormikSelectComponent}
      userProps={{ options, getValue }}
    ></Field>
  );
}

export default RoleSelect;
