import * as type from './types'


export function createJobs(jobs) {
    return {
        type : type.CREATE_JOBS,
        payload : jobs,
        txn : 'initiated',
    };
}


export function createdJobs(jobs) {
    return {
        type : type.CREATED_JOBS,
        payload : jobs,
        txn : 'success',
        diff : true
    };
}


export function resetCreatedJob() {
    return {
        type : type.RESET_CREATED_JOB,
    };
}



export function savedJobs(jobs) {
    return {
        type : type.SAVED_JOBS,
        payload : jobs,
        txn : 'success',
        diff : true
    };
}


export function editJobs(jobs) {
    return {
        type : type.EDIT_JOBS,
        payload : jobs,
        txn : 'initiated',
    };
}


export function updateJobPriority(jobs) {
    return {
        type : type.UPDATE_JOB_PRIORITY,
        payload : jobs,
        txn : 'success',
        diff : true
    };
}


export function updatedJobPriority(jobs) {
    return {
        type : type.UPDATED_JOB_PRIORITY,
        payload : jobs,
        txn : 'initiated',
    };
}

export function updateJobDeadline(job) {
    return {
        type : type.UPDATE_JOB_DEADLINE,
        payload : job,
        txn : 'success',
        diff : true
    };
}


export function updatedJobDeadline(jobs) {
    return {
        type : type.UPDATED_JOB_DEADLINE,
        payload : jobs,
        txn : 'initiated',
    };
}


export function updateJobName(job) {
    return {
        type : type.UPDATE_JOB_NAME,
        payload : job,
        txn : 'initiated',
        diff : true
    };
}


export function updatedJobName(jobs) {
    return {
        type : type.UPDATED_JOB_NAME,
        payload : jobs,
        txn : 'success',
    };
}


export function fetchQueueJobs(userid) {
    return {
        type : type.FETCH_QUEUE_JOBS,
        payload: userid
    };
}


export function fetchedQueueJobs(jobs) {
    return {
        type : type.FETCHED_QUEUE_JOBS,
        payload : jobs
    };
}

export function fetchMyCreatedJobs(userid) {
    return {
        type : type.FETCH_MY_CREATED_JOBS,
        payload: userid
    };
}


export function fetchedMyCreatedJobs(jobs) {
    return {
        type : type.FETCHED_MY_CREATED_JOBS,
        payload : jobs
    };
}


export function fetchJobsByWorkroomId(workroomId) {
    return {
        type : type.FETCH_JOBS_BY_WORKROOM_ID,
        payload : workroomId
    };
}


export function fetchedJobsByWorkroomId(jobs) {
    return {
        type : type.FETCHED_JOBS_BY_WORKROOM_ID,
        payload : jobs
    };
}


export function fetchJobsByTeamId(teamId) {
    return {
        type : type.FETCH_JOBS_BY_TEAM_ID,
        payload : teamId
    };
}


export function fetchedJobsByTeamId(jobs) {
    return {
        type : type.FETCHED_JOBS_BY_TEAM_ID,
        payload : jobs
    };
}




export function fetchJobsByJobKey(jobkey) {
    return {
        type : type.FETCH_JOBS_BY_JOBKEY,
        payload : jobkey
    };
}


export function fetchedJobsByJobKey(jobs) {
    return {
        type : type.FETCHED_JOBS_BY_JOBKEY,
        payload : jobs
    };
}

export function searchJobs(jobs) {
    return {
        type : type.SEARCH_JOBS,
        payload : jobs
    };
}


export function storeSearchJobsResult(jobs) {
    return {
        type : type.STORE_SEARCH_JOBS_RESULT,
        payload : jobs
    };
}


export function deleteJobs(jobs) {
    return {
        type : type.DELETE_JOBS,
        payload: jobs
    };
}


export function deletedJobs(msg) {
    return {
        type : type.DELETED_JOBS,
        payload : msg
    };
}


export function removePriority(jobs) {
    return {
        type : type.REMOVE_PRIORITY,
        payload: jobs
    };
}


export function removedPriority(msg) {
    return {
        type : type.REMOVED_PRIORITY,
        payload : msg
    };
}

export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_JOBS,
        payload: props
    };
}