import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getHeaderJWT, URL } from '../../../store/path'
import axios from 'axios';
import moment from 'moment'
import { createComments } from '../data/actions'
import { comments } from '../data/model'

const MyUploader = ({workroomId, jobId, processId, userId, setOpen}) => {

    const dispatch = useDispatch()
    const [files, addFile]  = useState([]);
    const [fileCount, setFileCount]  = useState(0);    
    const scrollRef = useRef(null);

    useEffect(() => {
      document.onpaste = function(event){
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        let index;
        for (index in items) {
          var item = items[index];
          if (item.kind === 'file') {
            // adds the file to your dropzone instance
            addFile(files => [ ...files, item.getAsFile()]);
          }
        }
      }
    }) 

    const getUploadParams = ({ meta, file }) => {

        let attachmentPath = jobId ? workroomId + "/" + jobId + "/" + file.name : workroomId + "/" + file.name
        let payload = {
            'workroomId' :  workroomId, 
            'jobId' : jobId ? jobId : "",
            'fileName' : file.name, 
            'fileType' : file.type, 
            'url': attachmentPath 
        } 

        let requrl = URL + '/comments/signed-url/upload'
        return axios.post(requrl, payload, getHeaderJWT())
                .then((res) => { 
                    return { 
                        url : res.data, 
                        type: file.type, 
                        body: file, 
                        method: "put", 
                        meta : {filePath: payload}}
                })
                .catch((error) => {return ""});
    }
    
    // called every time a file's `status` changes
    // const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
    const handleChangeStatus = ({ meta, file }, status) => {
      if (status == "done" && file)
        setFileCount(fileCount + 1)
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
      
    }
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
      allFiles.forEach(f => f.remove())

      let attachmentIds = files.map(f => f.meta.filePath.url)
      let attachmentNames = files.map(f => f.meta.filePath.fileName)
      let ids = attachmentIds.join('||')
      let names = attachmentNames.join('||')

      let obj = comments()
      obj.workroomId = workroomId
      obj.jobId = jobId
      obj.processId = processId ? processId : "" 
      obj.userId = userId
      obj.dtCreate = moment().valueOf() * 1000000
      obj.attachmentId = ids
      obj.attachmentName = names
  
      dispatch(createComments(obj))
      if(setOpen) 
        setOpen(false)
    }
  
    return (
      <>
      {fileCount > 0 ? 
      <div style={{ borderBottom: "2px dashed #d9d9d9", color: "red"}}><h3>File Uploaded : {fileCount} </h3></div>
      : null}
      <div id="uploader" style={{height: "280px", overflowY: "auto"}}>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        initialFiles={files}
        PreviewComponent={Preview}
        inputContent="Drag, Paste or Click to Upload "
        onSubmit={handleSubmit}
      />
      <div ref={scrollRef}></div>
      </div>
      </>
    )
  }


  const Preview = ({ meta }) => {
    const { name, percent, status } = meta
    return (
      <span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        {name}, {Math.round(percent)}%, {status}
      </span>
    )
  }
export default MyUploader
  
