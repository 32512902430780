import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Container, Grid } from "semantic-ui-react";
import { Formik, Field, Form as FormikForm } from "formik";
import { FormikInputComponent } from "../../../utilities/formUtils";
import { getModule } from "../data/selectors";
import { moduleSchema } from "../data/model";
import WorkroomSelect from "../../workRoom/components/workroomSelect";
import { editModule } from "../data/actions";
import ModuleProcess from "./ModuleProcess";
import { updateHeaderTitle } from "../../app/data/actions";

function ModuleFormEdit(props) {
  const dispatch = useDispatch();
  const module = useSelector((state) => getModule(state, props))

  
  useEffect(() => {
    dispatch(updateHeaderTitle({ title: "Module: " + module.moduleName }));
  }, [module.moduleName]);

  const saveModule = (values, resetForm) => {
    dispatch(editModule(values));
  };

  return (
    <Container>
      <Grid>
        <Grid.Column className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
          <Formik
            id="moduleForm"
            size="large"
            initialValues={module}
            validationSchema={moduleSchema}
            onSubmit={(values, { resetForm }) => saveModule(values, resetForm)}
            render={({ handleSubmit, onChange, values, handleChange }) => (
              <Form
                as={FormikForm}
                onSubmit={handleSubmit}
                className="CustomeForm"
              >
                <Form.Group widths={3}>
                  <Field
                    name="moduleName"
                    label="Module"
                    component={FormikInputComponent}
                  ></Field>
                  <Field
                    name="moduleUrl"
                    label="ModuleUrl"
                    component={FormikInputComponent}
                  ></Field>
                  <WorkroomSelect
                    name="workroomId"
                    label="Workroom"
                  ></WorkroomSelect>
                  <Button
                    type="submit"
                    floated="left"
                    color="blue"
                    size="medium"
                    className="CustomeBTN"
                  >
                    Update
                  </Button>
                </Form.Group>
              </Form>
            )}
          ></Formik>
          <ModuleProcess moduleId={module.id} />
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default ModuleFormEdit;
