import React,{useState} from 'react'
import { Header, Button, Modal, Grid, Message, Container, Form } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikTextAreaComponent } from '../../../utilities/formUtils';
import { jobNameCheckSchema } from '../data/model'
import { updateJobName } from '../data/actions'



const PopupJobEdit = ({job}) => {
  const [open, setOpen] = useState(false)
  const [workroomId, setWorkroomId] = useState()
  const [savedSuccess, setSavedSuccess] = useState(false)
  // const [listOfUsers, setListOfUsers] = useState ()


  // const job = createJob()
  const dispatch = useDispatch()
// const listOfUsers = (workroom)


  const saveJob = (values, resetForm) => {
/* In the workroom and process Select we get the full Objects so we extract the relevant data from them*/
    const obj =
      {
        'id' : values.id,
        'jobName' :values.jobName
      }
      console.log(' Editing job start data is ', obj)
       dispatch(updateJobName(obj))
       setSavedSuccess (true)
        setTimeout(function(){ 
          setOpen(false)
            setSavedSuccess (false)
        }, 1200);
     /*  setTimeout(function () {
        setOpen(false)
      }, 2000); */

  }

  return (
    <Modal
      closeIcon
      size='small'
      dimmer = 'inverted'
      open={open}
      trigger={<span className = 'buttonPopUp' style = {{color: 'blue', width : '60%', cursor:'pointer'}}>Edit</span>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      {/* <Header content='Create New Ticket' /> */}
      <Modal.Content>
      <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column >
                    <Formik id="jobForm" 
                        initialValues={job}
                        validationSchema={jobNameCheckSchema}
                        onSubmit={(values, { resetForm }) => saveJob(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                                    <Field  name="jobName" label = "Job Name (Limit to 50 characters)" component={FormikInputComponent}></Field>
                                    {/* <Field  name="description" label = "Description" component={FormikTextAreaComponent}></Field> */}
                                
                               <Button type="submit" floated="right" color="blue" size="medium" className="CustomeBTN"> Submit </Button> 
                               <Button floated="right" color="grey" size="medium" className="CustomeBTN" onClick={() => setOpen(false)}> Cancel </Button> 
                            </Form>
                        )}
                    />
                    
                </Grid.Column>
                      
            </Grid>
            {savedSuccess ?
                        // <Notification id={savedActivityId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                        <Message positive style = {{ zIndex: 100}}>
                        <Message.Header>Form submitted successfully</Message.Header>
                      </Message>
                        : 
                    null}  
        </Container>
      </Modal.Content>
    </Modal>
  )
}
export default PopupJobEdit