import React from 'react';
import {Route} from 'react-router';
import { Switch, Link } from "react-router-dom";
import { Provider } from 'react-redux';
import { ConnectedRouter} from 'connected-react-router'
import { history } from '../../configureStore'
import 'semantic-ui-less/semantic.less'
import 'react-confirm-alert/src/react-confirm-alert.css'
import TaskMenu from "./components/menu"
import LoginForm from '../login/components/loginForm'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = ({ store }) => (
    <Provider store = {store}>
      <ConnectedRouter history={history} >
      <Switch>
          <Route path="/login">
            <LoginForm />
          </Route>
          <Route path="/">
            <TaskMenu/>
          </Route>
        </Switch>
      </ConnectedRouter>
      <ToastContainer position="top-center" autoClose={7000} closeOnClick pauseOnHover hideProgressBar={false}/>
     </Provider>
  );

export default App;

