import React from 'react';
import { Route, withRouter } from 'react-router';
import TeamList from "./components/teamList";
import TeamForm from "./components/teamForm";
import TeamFormEdit from "./components/teamFormEdit";
import TeamDispContainer from './components/teamDispContainer'
import { Container } from 'semantic-ui-react'

const Team = () => {
    return (
        <Container>
            <Route path="/team/list" component={(TeamList)} />
            <Route path="/team/edit/:id" component={TeamFormEdit} />
            <Route path="/team/create" component={TeamForm} />
            <Route path="/team/view/:id" component={TeamDispContainer} />
        </Container>
    )
};

export default Team;

