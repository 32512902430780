export const CREATE_MODULE = "CREATE_MODULE";
export const SAVED_MODULE = "SAVED_MODULE";

export const FETCH_MODULE = "FETCH_MODULE";
export const FETCHED_MODULE = "FETCHED_MODULE";

export const EDIT_MODULE = "EDIT_MODULE";

export const CREATE_PROCESS = "CREATE_PROCESS";
export const SAVED_PROCESS = "SAVED_PROCESS";

export const FETCH_PROCESS = "FETCH_PROCESS";
export const FETCHED_PROCESS = "FETCHED_PROCESS";

export const REMOVE_PROCESS = "REMOVE_PROCESS";
export const REMOVED_PROCESS = "REMOVED_PROCESS";
