import * as type from './types'


export function createTaskdetails(taskdetails) {
    return {
        type : type.CREATE_TASKDETAILS,
        payload : taskdetails,
        txn : 'initiated',
    };
}


export function savedTaskdetails(taskdetails) {
    return {
        type : type.SAVED_TASKDETAILS,
        payload : taskdetails,
        txn : 'success',
        diff : true
    };
}


export function editTaskdetails(taskdetails) {
    return {
        type : type.EDIT_TASKDETAILS,
        payload : taskdetails,
        txn : 'initiated',
    };
}


export function fetchTaskdetailsByJob(jobid) {
    return {
        type : type.FETCH_TASKDETAILS_BY_JOB,
        payload: jobid
    };
}


export function fetchedTaskdetailsByJob(taskdetails) {
    return {
        type : type.FETCHED_TASKDETAILS_BY_JOB,
        payload : taskdetails
    };
}


export function searchTaskdetails(taskdetails) {
    return {
        type : type.SEARCH_TASKDETAILS,
        payload : taskdetails
    };
}


export function storeSearchTaskdetailsResult(taskdetails) {
    return {
        type : type.STORE_SEARCH_TASKDETAILS_RESULT,
        payload : taskdetails
    };
}


export function deleteTaskdetails(taskdetails) {
    return {
        type : type.DELETE_TASKDETAILS,
        payload: taskdetails
    };
}


export function deletedTaskdetails(msg) {
    return {
        type : type.DELETED_TASKDETAILS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_TASKDETAILS,
        payload: props
    };
}