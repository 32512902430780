export const CREATE_SCHEDULEDPROCESS = 'CREATE_SCHEDULEDPROCESS'
export const SAVED_SCHEDULEDPROCESS = 'SAVED_SCHEDULEDPROCESS'
export const EDIT_SCHEDULEDPROCESS = 'EDIT_SCHEDULEDPROCESS'
export const EDITED_SCHEDULEDPROCESS = 'EDITED_SCHEDULEDPROCESS'
export const FETCH_SCHEDULEDPROCESS = 'FETCH_SCHEDULEDPROCESS'
export const FETCHED_SCHEDULEDPROCESS = 'FETCHED_SCHEDULEDPROCESS'
export const SEARCH_SCHEDULEDPROCESS = 'SEARCH_SCHEDULEDPROCESS'
export const STORE_SEARCH_SCHEDULEDPROCESS_RESULT = 'STORE_SEARCH_SCHEDULEDPROCESS_RESULT'
export const DELETE_SCHEDULEDPROCESS = 'DELETE_SCHEDULEDPROCESS'
export const DELETED_SCHEDULEDPROCESS = 'DELETED_SCHEDULEDPROCESS'
export const NOTIFICATION_DONE_SCHEDULEDPROCESS = 'NOTIFICATION_DONE_SCHEDULEDPROCESS'

export const FILTER_ITEMS = 'FILTER_ITEMS'
