export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const SAVED_ACTIVITY = 'SAVED_ACTIVITY'
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY'
export const EDITED_ACTIVITY = 'EDITED_ACTIVITY'
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY'
export const FETCHED_ACTIVITY = 'FETCHED_ACTIVITY'
export const FETCH_ACTIVITY_BY_PROCESS = 'FETCH_ACTIVITY_BY_PROCESS'
export const FETCHED_ACTIVITY_BY_PROCESS = 'FETCHED_ACTIVITY_BY_PROCESS'
export const SELECT_ACTIVITY = 'SELECT_ACTIVITY'
export const UPDATE_ACTIVITY_DEFINITION = 'UPDATE_ACTIVITY_DEFINITION'
export const UPDATED_ACTIVITY_DEFINITION = 'UPDATED_ACTIVITY_DEFINITION'
export const RESET_ACTIVITY_DEFINITION_FLAG = 'RESET_ACTIVITY_DEFINITION_FLAG'

export const NOTIFICATION_DONE_ACTIVITY = 'NOTIFICATION_DONE_ACTIVITY'