import * as type from './types'


export function createProcess(process) {
    return {
        type : type.CREATE_PROCESS,
        payload : process,
        txn : 'initiated',
    };
}


export function savedProcess(process) {
    return {
        type : type.SAVED_PROCESS,
        payload : process,
        txn : 'success',
        diff : true
    };
}


export function editProcess(process) {
    return {
        type : type.EDIT_PROCESS,
        payload : process,
        txn : 'initiated',
    };
}


export function fetchProcess() {
    return {
        type : type.FETCH_PROCESS,
    };
}


export function fetchedProcess(process) {
    return {
        type : type.FETCHED_PROCESS,
        payload : process
    };
}


export function searchProcess(process) {
    return {
        type : type.SEARCH_PROCESS,
        payload : process
    };
}


export function storeSearchProcessResult(process) {
    return {
        type : type.STORE_SEARCH_PROCESS_RESULT,
        payload : process
    };
}


export function deleteProcess(process) {
    return {
        type : type.DELETE_PROCESS,
        payload: process
    };
}


export function deletedProcess(msg) {
    return {
        type : type.DELETED_PROCESS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_PROCESS,
        payload: props
    };
}

export function updateListTitle(title) {
    return {
        type : type.UPDATE_LIST_TITLE,
        payload: title
    };
}

export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}