export const CREATE_COMMENTS = 'CREATE_COMMENTS'
export const SAVED_COMMENTS = 'SAVED_COMMENTS'
export const EDIT_COMMENTS = 'EDIT_COMMENTS'
export const EDITED_COMMENTS = 'EDITED_COMMENTS'
export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const FETCH_COMMENTS_BY_JOB = 'FETCH_COMMENTS_BY_JOB'
export const FETCHED_COMMENTS = 'FETCHED_COMMENTS'
export const SEARCH_COMMENTS = 'SEARCH_COMMENTS'
export const STORE_SEARCH_COMMENTS_RESULT = 'STORE_SEARCH_COMMENTS_RESULT'
export const DELETE_COMMENTS = 'DELETE_COMMENTS'
export const DELETED_COMMENTS = 'DELETED_COMMENTS'
export const NOTIFICATION_DONE_COMMENTS = 'NOTIFICATION_DONE_COMMENTS'

export const FETCH_SIGNED_URL = 'FETCH_SIGNED_URL'
export const FETCHED_SIGNED_URL = 'FETCHED_SIGNED_URL'

export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOADED_FILE = 'UPLOADED_FILE'

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const DOWNLOADED_FILE = 'DOWNLOADED_FILE'

