export const CREATE_TASKDETAILS = 'CREATE_TASKDETAILS'
export const SAVED_TASKDETAILS = 'SAVED_TASKDETAILS'
export const EDIT_TASKDETAILS = 'EDIT_TASKDETAILS'
export const EDITED_TASKDETAILS = 'EDITED_TASKDETAILS'
export const FETCH_TASKDETAILS_BY_JOB = 'FETCH_TASKDETAILS_BY_JOB'
export const FETCHED_TASKDETAILS_BY_JOB = 'FETCHED_TASKDETAILS_BY_JOB'
export const SEARCH_TASKDETAILS = 'SEARCH_TASKDETAILS'
export const STORE_SEARCH_TASKDETAILS_RESULT = 'STORE_SEARCH_TASKDETAILS_RESULT'
export const DELETE_TASKDETAILS = 'DELETE_TASKDETAILS'
export const DELETED_TASKDETAILS = 'DELETED_TASKDETAILS'
export const NOTIFICATION_DONE_TASKDETAILS = 'NOTIFICATION_DONE_TASKDETAILS'
