import * as type from './types'


export function createTask(task) {
    return {
        type : type.CREATE_TASK,
        payload : task,
        txn : 'initiated',
    };
}

export function savedTask(task) {
    return {
        type : type.SAVED_TASK,
        payload : task,
        txn : 'success',
    };
}

export function editTask(task) {
    return {
        type : type.EDIT_TASK,
        payload : task,
        txn : 'initiated',
    };
}

export function fetchTasks() {
    return {
        type : type.FETCH_TASKS,
    };
}

export function fetchedTasks(tasks) {
    return {
        type : type.FETCHED_TASKS,
        payload : tasks
    };
    
}

export function fetchTasksByJob(jobid) {
    return {
        type : type.FETCH_TASKS_BY_JOB,
        payload: jobid
    };
}

export function fetchedTasksByJob(tasks) {
    return {
        type : type.FETCHED_TASKS_BY_JOB,
        payload : tasks
    };
    
}

export function fetchCurrTaskByTeam(teamid) {
    return {
        type : type.FETCH_CURRTASK_BY_TEAM,
        payload: teamid
    };
}

export function fetchedCurrTaskByTeam(tasks, teamId) {
    return {
        type : type.FETCHED_CURRTASK_BY_TEAM,
        payload : {'teamId' : teamId, 'tasks' : tasks }
    };
    
}

export function updatedTaskReport(tasks) {
    return {
        type : type.UPDATED_TASK_REPORT,
        payload : tasks
    };
    
}

export function insertTaskReport(tasks) {
    return {
        type : type.INSERT_TASK_REPORT,
        payload : tasks
    };    
}


export function assignUserToTask(userid) {
    return {
        type : type.ASSIGN_USER_TO_TASK,
        payload: userid
    };
}

export function assignedUserToTask(tasks) {
    return {
        type : type.ASSIGNED_USER_TO_TASK,
        payload : tasks
    };
    
}

export function updateTaskComplete(taskid) {
    return {
        type : type.UPDATE_TASK_COMPLETE,
        payload: taskid
    };
}

export function updatedTaskComplete(tasks) {
    return {
        type : type.UPDATED_TASK_COMPLETE,
        payload : tasks
    };
    
}


export function updateTaskStatus(task) {
    return {
        type : type.UPDATE_TASK_STATUS,
        payload: task
    };
}

export function updatedTaskStatus(tasks) {
    return {
        type : type.UPDATED_TASK_STATUS,
        payload : tasks
    };
}

export function setActiveTask(taskId) {
    return {
        type : type.SET_ACTIVE_TASK,
        payload : taskId
    };  
}