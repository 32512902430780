import teamSaga from './sagas';
import * as type from './types'


export function createTeam(team) {
    return {
        type : type.CREATE_TEAM,
        payload : team,
        txn : 'initiated',
    };
}


export function savedTeam(team) {
    return {
        type : type.SAVED_TEAM,
        payload : team,
        txn : 'success',
        diff : true
    };
}


export function editTeam(team) {
    return {
        type : type.EDIT_TEAM,
        payload : team,
        txn : 'initiated',
    };
}


export function updateUserTeamPriority(teamUsers) {
    return {
        type : type.UPDATE_USER_TEAM_PRIORITY,
        payload : teamUsers,
        txn : 'initiated',
    };
}


export function updatedUserTeamPriority(teams) {
    return {
        type : type.UPDATED_USER_TEAM_PRIORITY,
        payload : teams,
        txn : 'success',
        diff : true
    };
}


export function addTeamMember(member) {
    return {
        type : type.ADD_TEAM_MEMBER,
        payload : member,
        txn : 'initiated',
    };
}

export function addedTeamMember(member) {
    return {
        type : type.ADDED_TEAM_MEMBER,
        payload : member,
        txn : 'success',
    };
}

export function removeTeamMember(member) {
    return {
        type : type.REMOVE_TEAM_MEMBER,
        payload : member,
        txn : 'initiated',
    };
}

export function removedTeamMember(member) {
    return {
        type : type.REMOVED_TEAM_MEMBER,
        payload : member,
        txn : 'success',
    };
}


export function fetchTeam() {
    return {
        type : type.FETCH_TEAM,
    };
}


export function fetchedTeam(team) {
    return {
        type : type.FETCHED_TEAM,
        payload : team
    };
}


export function searchTeam(team) {
    return {
        type : type.SEARCH_TEAM,
        payload : team
    };
}


export function storeSearchTeamResult(team) {
    return {
        type : type.STORE_SEARCH_TEAM_RESULT,
        payload : team
    };
}


export function deleteTeam(team) {
    return {
        type : type.DELETE_TEAM,
        payload: team
    };
}


export function deletedTeam(msg) {
    return {
        type : type.DELETED_TEAM,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_TEAM,
        payload: props
    };
}


export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}

export function archiveTeam(team) {
    return {
        type : type.ARCHIVE_TEAM,
        payload: team
    };
}

export function archivedTeam(msg) {
    return {
        type : type.ARCHIVED_TEAM,
        payload : msg
    };
}

export function restoreTeam(team) {
    return {
        type : type.RESTORE_TEAM,
        payload: team
    };
}

export function restoredTeam(msg) {
    return {
        type : type.RESTORED_TEAM,
        payload : msg
    };
}