import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'

export const scheduledprocess = () => ({
    id: v4(),
    workroomId: "",
    processId: "demo123",
    processKey:"",
    userId:"",
    ownerId:"",
    jobName:"",
    copyFromJobKey:"",
    cronExpression:"0 0 9 */1 * 1",
    startDate:"",
    endDate:"",
    scheduledAt:"",
    isActive: 1,
    visible:1,
    status:1,
    segid: 1,
    cid: 1,
    dtCreate: "",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})

export const searchColumns = [
    {
      Header: 'Workroom',
      accessor: 'workroomName', // accessor is the "key" in the data
    },
    {
      Header: 'Process Key',
      accessor: 'processKey', // accessor is the "key" in the data
    },
    {
      Header: 'Job Name',
      accessor: 'jobName', // accessor is the "key" in the data
    },
    {
      Header: 'Cron Expression',
      accessor: 'cronExpression', // accessor is the "key" in the data
    },
    {
      Header: 'Scheduled At',
      accessor: 'scheduledAt', // accessor is the "key" in the data
    },
  ]

  function formatDate(date, orgDate) {
    console.log(date, orgDate)
    console.log("date", new Date(date).toLocaleDateString())
    return new Date(orgDate).toLocaleDateString()
  }
  
  
Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const scheduledprocessSchema = Yup.object().shape({
    id: Yup.string().required('This is a Required Field'),
    workroomId: Yup.string().required('This is a Required Field'),
    processId: Yup.string().required('This is a Required Field'),
    processKey: Yup.string().required('This is a Required Field'),
    userId: Yup.string().required('This is a Required Field'),
    ownerId: Yup.string().required('This is a Required Field'),
    jobName: Yup.string().required('This is a Required Field').max(50, 'Must be less than 50 characters'),
    deadline: Yup.number("It must be a number!").nullable(),
    copyFromJobKey: Yup.string().nullable(),
    cronExpression: Yup.string().nullable(),
    startDate: Yup.date().when('cronExpression',(exp)=>{
      if(exp)
        return Yup.date().required()
    }),
    endDate: Yup.date().when('startDate',(date)=>{
      if(date)
        return Yup.date().min(Yup.ref('startDate'), "Must be greater than Start Date").required()
    }),
    scheduledAt: Yup.date().nullable(),
    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });


export const duplicateCheckScheduledprocessCode = debounce(checkScheduledprocessCode, 500);
function checkScheduledprocessCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/scheduledprocess/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckScheduledprocessName = debounce(checkScheduledprocessName, 500);
function checkScheduledprocessName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/scheduledprocess/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  