import React from "react";
import { Route } from "react-router";
import { Container } from "semantic-ui-react";
import RolesForm from "./components/RolesForm";
import RolesFormEdit from "./components/RolesFormEdit";
import RoleList from "./components/RoleList";
function Roles() {
  return (
    <Container>
      <Route path="/roles/create" component={RolesForm}></Route>
      <Route path="/roles/edit/:id" component={RolesFormEdit}></Route>
      <Route path="/roles/list" component={RoleList}></Route>
    </Container>
  );
}

export default Roles;
