import { combineReducers } from "redux";
import { normalize, schema } from "normalizr";
import { merge } from "lodash";

const moduleSchema = new schema.Entity("module", {}, { idAttribute: "id" });
const moduleListSchema = [moduleSchema];

const processSchema = new schema.Entity("process", {}, { idAttribute: "id" });
const processListSchema = [processSchema];

function byId(state = {}, action) {
  if (action.type === "SAVED_MODULE") {
    const moduleId = action.payload.id;
    return {
      ...state,
      [moduleId]: action.payload,
    };
  }
  if (action.type === "FETCHED_MODULE") {
    const normalizedModule = normalize(action.payload, moduleListSchema);
    return merge({}, state, normalizedModule.entities.module);
  } else {
    return state;
  }
}

function processById(state = {}, action) {
  if (action.type === "SAVED_PROCESS") {
    const processId = action.payload.id;
    return {
      ...state,
      [processId]: action.payload,
    };
  }
  if (action.type === "FETCHED_PROCESS") {
    const normalizedProcess = normalize(action.payload, processListSchema);
    return merge({}, state, normalizedProcess.entities.process);
  }
  if (action.type === "REMOVED_PROCESS") {
    const { [action.payload.id]: del, ...rest } = state
    return rest
  } else {
    return state;
  }
}

const module = combineReducers({
  byId,
  processById,
});

export default module;
