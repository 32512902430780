import { parseJwt } from './parseJWT'

export function extractKeyFromJobKey (jobkey) {
    return (jobkey.split('-').pop())
   }


export function getJobPathLink (job) {
    if (!job) return '/home'
    // return "/jobs/" + extractKeyFromJobKey(job.jobKey)
    return "/jobs/view/" + (job.jobKey ? job.jobKey.toLowerCase(): undefined)

}

export function getJobPathLinkWithJobKey (jobkey) {
    if (!jobkey) return '/home'
    // return "/jobs/" + extractKeyFromJobKey(job.jobKey)
    return "/jobs/view/" + (jobkey.toLowerCase())

}

export function getJobPathLinkWithJobNum (jobnum) {
    if (!jobnum) return '/home'
    // return "/jobs/" + extractKeyFromJobKey(job.jobKey)
    return "/jobs/view/job-" + jobnum

}


export function getCurrUserId () {
    const token = localStorage.getItem('user')
    const userId = token ? parseJwt(token).identity : null

    return userId
}
