import React, { useState, useEffect } from 'react';
import { Field, useFormikContext, getIn } from 'formik';
import v4 from 'uuid';
import { Input, Table, Button, Form } from 'semantic-ui-react';
import { FormikTodosInputComponent, FormikDateComponent, FormikTodosDateComponent } from '../../../utilities/formUtils';
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import moment from 'moment'
import {newTodo} from '../data/model'



const ToDoAdd = ({
  name, move, swap, push, insert, onBlur, remove, pop, label,  // { name, value, onChange, onBlur }
  form: { initialValues, errors, values, setFieldValue, status, touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  disabledComp,
  // ...props
}, ...props) => {

  /*  const { values } = useFormikContext()
   const fieldValue = getIn(values, name)
 console.log(' fieldvalue is ', fieldValue) */

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [widths, setWidth] = useState('')
  useEffect(() => {
    heightSet(setHeight)
    widthSet(setWidth)
    innerHeightSet(setInnerHeight)
    window.addEventListener('resize', function () {
      heightSet(setHeight)
      widthSet(setWidth)
      innerHeightSet(setInnerHeight)
    }, false);
  }, [])

  useEffect(() => {
    if (values[fieldName].length == 0 ) {
      let tuser = newTodo()
      push(tuser)
    
}}, [values[fieldName]])

  let arrKeys = [];
  // const fieldName = (name == 'todos#true'
  const fieldName = name
  if (values[fieldName] && values[fieldName].length) {
    arrKeys = Object.keys(values[fieldName]);
  }

  const updateTodoChange = (e, arrIndex)=>{
    // console.log(' changing update date ')
    setFieldValue(`${fieldName}[${arrIndex}].updateDate`, moment().format('YYYY-MM-DD HH:mm:ss'))
  }

  const getNewLineItem = (index) => {
    insert((index + 1), newTodo())
  }

  const removeItem = (index) => {
    remove(index)
    if (index == 0) {
      let tuser = newTodo()
      push(tuser)
    }
  }


  return (
    <>
      {/* <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}> */}
      {/* <div style={{height: (heights-20)+'px', width: "100%"}}> */}
      <Table className="borderNoneObj">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="messageTransparent paddingBottom0">To Do</Table.HeaderCell>
            <Table.HeaderCell className="messageTransparent paddingLeft18 paddingBottom0" width="3">Target Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {values && values[fieldName] && values[fieldName].map((val, index) => (
            <Table.Row key={`${fieldName}[${index}]`} className="borderNoneObj paddingZeroRow">

              <Table.Cell className="borderNoneObj paddingZeroRow">
                <Field className="borderNoneObj"
                  name={`${fieldName}[${index}].text`}
                  disabled={disabledComp}
                  label=" "
                  arrIndex = {index}
                  myChangeFunction = {updateTodoChange}
                  component={FormikTodosInputComponent}
                  placeholder="To Do"
                />
               
              </Table.Cell>
              <Table.Cell width="3" className="borderNoneObj paddingLeft18 paddingZeroRow">
                <Field className="borderNoneObj paddingLeft18" 
                      name={`${fieldName}[${index}].targetDate`} 
                      disabled={disabledComp} 
                      label=" " 
                      component={FormikDateComponent} 
                      placeholder="Date"
                />
              </Table.Cell>
              <input style={{ display: "none !important", height: 10 }} type='hidden' name={`${fieldName}[${index}].updateDate`} id="updateDateValue" value={values[fieldName][index]['updateDate']} />
              <Table.Cell width="1" className="borderNoneObj">
                <Button type="button" disabled={disabledComp} icon='add' onClick={(e) => getNewLineItem(index)} className="buttonAddTodos"></Button>
              </Table.Cell>
              { index > 0 &&
                <Table.Cell width="1" className="borderNoneObj">
                  <Button type="button" disabled={disabledComp} icon='cancel' onClick={(e) => removeItem(index)} className="buttonAddTodos" ></Button>
                </Table.Cell>
              }
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}

export default ToDoAdd;