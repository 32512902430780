import React from 'react';
import { Route, withRouter } from 'react-router';
import StepsList from "./components/stepsList";
import StepsForm from "./components/stepsForm";
import { Container } from 'semantic-ui-react'


const Steps = () => {
    return (
        <Container>
            <Route path="/steps/list" component={withRouter(StepsList)} />
            <Route path="/steps/edit/:id" component={StepsForm} />
            <Route path="/steps/create" component={StepsForm} />
        </Container>
    )
};

export default Steps;

