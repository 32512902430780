import { createSelector } from 'reselect'
import { startsWith, orderBy, groupBy, cloneDeep, chain } from 'lodash';
import { getListOfTeamUserObjsForUser } from '../../team/data/selectors'
import { getUsersList } from '../../users/data/selectors'
import { getWorkroomList } from '../../workRoom/data/selectors'
import { STATUS_CANCEL, STATUS_COMPLETED, STATUS_HOLD, STATUS_PARTIAL_DONE } from '../../app/data/staticData'


export const getIsFetchingJobs = (state, props) => state.jobs.params.isFetching;
export const getIsJobsFetched = (state, props) => state.jobs.params.jobsFetched;
export const getJobsList = (state, prop) => state.mytasks.byId
export const getQueueJobsList = (state, prop) => state.mytasks.byQueue
export const getMyCreatedJobsList = (state, prop) => state.mytasks.byMyCreated


export const getMyCurrTasks = createSelector(getQueueJobsList, getJobsList, getListOfTeamUserObjsForUser, (queueList, mainList, teamPref) => {

   const values = Object.values(queueList)
   if (!teamPref) return undefined
   if (!values) return undefined

   let arrayOfJobs = values.map((obj) => mainList[obj])
   if (arrayOfJobs.length == 0) return undefined

   let sortedArray = []
   if (teamPref.length == 1) 
       sortedArray = orderBy(arrayOfJobs, [(item)=> item.teamId, (item)=>item.priority], ['asc', 'asc']);
   else {
       teamPref.map ((item) => {
           let teamjobs = arrayOfJobs.filter ((obj) => obj.teamId == item.teamId)
           let sortedteamjobs = orderBy(teamjobs, [(item)=> item.priority, (item)=> item.jobKey], ['asc', 'desc']);
           if (sortedteamjobs.length > 0)
               sortedArray = [...sortedArray, ...sortedteamjobs]
       })
   }
   return sortedArray
})


export const getMyCurrPriorityTasks = createSelector(getMyCurrTasks, getWorkroomList, (myjobs, workrooms) => {
    if (!myjobs) return undefined
    let jobs = myjobs.filter(function (obj) {
        if (obj.priority != null && obj.taskStatus != STATUS_COMPLETED && obj.taskStatus != STATUS_CANCEL && obj.taskStatus != STATUS_HOLD && obj.taskStatus != STATUS_PARTIAL_DONE)
            return obj
    });
    const finalListOfJobs =  jobs.map((obj, index)=>{
        const newObj = cloneDeep(obj)
        newObj["Sl No"] = index + 1
        newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
        newObj["workroomname"] = workrooms[newObj.workroomId].name
        return newObj
    })
    return finalListOfJobs    
})

export const getMyCurrAllTasks = createSelector(getMyCurrTasks, getWorkroomList, (myjobs, workrooms) => {
    if (!myjobs) return undefined
    let jobs = myjobs.filter(function (obj) {
        if (obj.taskStatus != STATUS_COMPLETED && obj.taskStatus != STATUS_CANCEL && obj.taskStatus != STATUS_HOLD && obj.taskStatus != STATUS_PARTIAL_DONE)
            return obj
    });
    const finalListOfJobs =  jobs.map((obj, index)=>{
        const newObj = cloneDeep(obj)
        newObj["Sl No"] = index + 1
        newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
        newObj["workroomname"] = workrooms[newObj.workroomId].name
        return newObj
    })
    return finalListOfJobs    
})


export const getMyCreatedTasks = createSelector(getMyCreatedJobsList, getJobsList, getWorkroomList, getUsersList, (jobsList, mainList, workrooms, users) => {
    if (!jobsList || !workrooms || !mainList || !users) return undefined

    const values = Object.values(jobsList)

   if (!values) return undefined
   let arrayOfJobs = values.map((obj) => mainList[obj])
   if (arrayOfJobs.length == 0) return undefined

   let sortedArray = orderBy(arrayOfJobs, [(item)=> parseInt(item.jobKey.substring(4))], ['asc']);   
   const finalListOfJobs =  sortedArray.map((obj, index)=>{
        const newObj = cloneDeep(obj)
        newObj["Sl No"] = index + 1
        newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
        newObj["assignedTo"] = newObj.userId ? users[newObj.userId] ? users[newObj.userId].name : null : null
        newObj["workroomname"] = workrooms[newObj.workroomId] ? workrooms[newObj.workroomId].name : undefined
        return newObj
    })
    console.log(finalListOfJobs)
    return finalListOfJobs
})



export const getJobsParams = (state, props) => {
    const params =  {}
    if(startsWith(state.router.location.pathname,'/jobs/create')){ 
        params.title = state.jobs.params.createTitle
        params.submitButtonText = state.jobs.params.createSubmitButtonText        
    };
    if(startsWith(state.router.location.pathname,'/jobs/edit/')){ 
        params.title = state.jobs.params.editTitle
        params.submitButtonText = state.jobs.params.editSubmitButtonText        
    };
    return params
}

