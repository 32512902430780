import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {fetchTeam, deleteTeam, setNotifyDone } from '../data/actions'
import * as select from '../data/selectors'
import { Container, Table, TableHeader, TableBody, Header } from 'semantic-ui-react'
import userACL from '../../../store/access'
import Notification from '../../../utilities/notificationUtils'
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
    
 
const TeamUser = props => {
    const dispatch = useDispatch();
    // const isNegative = props.team.status === 1 ? false : true;
    
    
    // const userIdObj = props.userId.split(",");
    return (
        <Row>
            <Cell style={{width: '10%'}} className='borderNoneObj padding15 borderBottomObj'>
                {props.index}
            </Cell>
            <Cell style={{width: '35%'}} className='borderNoneObj padding15 borderBottomObj'>
                {props.user.name}
            </Cell>
           {/*  <Cell style={{width: '35%'}} className='borderNoneObj borderBottomObj'>
                {props.team.short_name}
            </Cell> */}
           
        </Row>
)}

const TeamUsersList = (props) => {
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 
    // //console.log("widths == "+widths)
    const dispatch = useDispatch();
    
    const teamId = props.teamId
    const team = useSelector(state => select.getListOfUsersFromTeam(state, teamId))
    // console.log (' Team fetched is : ', team)
    // const teamFetched = useSelector(state => select.getListOfUsersFromTeam(state, teamId))
    /* useEffect(() => {
        if(!teamFetched){
            dispatch(fetchTeam());
        }
    }); */

    /* useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Team List'}))
    }, []) */

    // Loading Logic if tasks not fetched
    return (
        <Container>
            {/* {teamFetched === false ? 
                <p>Loading ... </p> 
            :  */}
                {/* <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}> */}
                <>
                    <div style={{height: (heights-20)+'px'}}>
                
                    <StickyTable className="tableLayOut " stickyHeaderCount={1}>
                        <Row style={{widths: widths+"px"}}>
                            <Cell style={{width: '10%'}} className='borderNoneObj head borderBottomObj'>
                                Key
                            </Cell>
                            <Cell style={{width: '35%'}} className='borderNoneObj head borderBottomObj'>
                                User Name
                            </Cell>
                            {/* <Cell style={{width: '35%'}} className='borderNoneObj head borderBottomObj'>
                                Short Name
                            </Cell> */}
                            
                        </Row>
                        { Object.keys(team).map((key, i) => 
                            <TeamUser user={team[key]} key={team[key].id} index={parseInt((i + 1), 10)}/> )}
                    </StickyTable>
                    </div>
                {/* </div> */}
                </>
            
        </Container>
    )
}

export default TeamUsersList;
