import React, { useState, useEffect, useRef } from 'react';
import { ErrorMessage } from 'formik'

import ReactQuill from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.snow.css';

import moment from 'moment'
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';
import { startCase, toLower, get, mapValues, has, isObject } from "lodash";
import { Form, Radio, Checkbox, Input, Label, Dropdown, TextArea, Button } from 'semantic-ui-react';
import "react-datepicker/dist/react-datepicker.css";
import { checkValidDateFormats, getDateInYYYYMMDD } from './dateUtils'
import FloatingLabelInput from 'react-floating-label-input';

// import MockDate from 'mockdate'

export const FormikInputComponent = ({
  validateInput, placeholder, width, isLabel, label, isTxn, focus, disabled, required, style,
  compute,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) =>  (
    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field required={required ? required : false} width={width ? width : null}  error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        <Input name={field.name}
          type="text"
          onChange={handleChange}
          value={field.value}
          disabled = {disabled}
          style = {style}
          // transparent = {disabled}
          placeholder={placeholder}
          autoFocus={focus ? focus : false}
          onBlur={(e) =>{
            if (compute) compute(values, setFieldValue)
          }}
        />
        {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
);


export const FormikTodosInputComponent = ({
  validateInput, placeholder, width, isLabel, label, isTxn, focus, disabled, required, style, myChangeFunction,arrIndex,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, handleBlur, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) =>  {

  const handleCustomChangeFunction = (e) =>{
    handleChange(e)
    // handleBlur(e)
    myChangeFunction(e, arrIndex)
  }

  return(
    <Form.Field required={required ? required : false} width={width ? width : null}  error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        <Input name={field.name}
          type="text"
          onChange={handleCustomChangeFunction}
          // onBlur={handleCustomChangeFunction}
          value={field.value}
          disabled = {disabled}
          style = {style}
          // transparent = {disabled}
          placeholder={placeholder}
          autoFocus={focus ? focus : false}
        />
        {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
)
        };


    
          /**
           * 
           * myChangeFunction and arrIndex are used when you have a field array and you need to do some changes to an index with your own function
           */
  export const FormikDateComponent = ({
    isLabel, label, placeholder, width, focus, disabled, myChangeFunction,arrIndex,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values, handleChange, setFieldValue, dirty }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    

    const [dt, setDt] = useState(field.value ? moment(field.value, 'YYYY-MM-DD').toDate(): null)
    // const [dt, setDt] = useState(field.value ? moment(field.value).toDate() : null)
    let rawEvent = ''
    const handleDateChange = (e) => {
      // MockDate.set('2019-10-10');
      // MockDate.reset();
      console.log(Date.now());
      console.log("rawEvent: ", rawEvent.type)
      if (rawEvent.type === 'change'){
          let str = rawEvent.target.value
          const dt1 = checkValidDateFormats(str)
          handleChange(dt1)
          console.log('dt1', dt1)
          setFieldValue(field.name, getDateInYYYYMMDD(dt1))
          setDt(moment(getDateInYYYYMMDD(dt1)).toDate())  
      } else {
          setDt(e)
          const dt = moment(e)
          handleChange(dt.format("DD-MM-YYYY"))
          setFieldValue(field.name, dt.format("YYYY-MM-DD"))
          if (myChangeFunction)
            myChangeFunction (e, arrIndex) 
      }
    }

    return (
      // <Form.Field error={errors[field.name] && touched[field.name]}>
        <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
          {errors[field.name] && touched[field.name] ?
            <label>{errors[field.name]}</label> :
            <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
          <DatePicker
            name={field.name}
            placeholderText={placeholder}
            disabled={disabled}
            dateFormat="dd-MM-yyyy"
            scrollableYearDropdown
            selected={field.value ? moment(field.value, 'YYYY-MM-DD').toDate() : null}
            onChange={handleDateChange}
            onChangeRaw={e=> rawEvent = e}
            autoComplete='off'
            autoFocus={focus ? focus : false}
          />
        </Form.Field>
    )};

    
export const FormikAmountComponent = ({
  isLabel, label, saveOnBlur, decimal, compute, isTxn, path, placeholder, focus, width,
  field, // { name, value, onChange, onBlur }
  index,
  form: { touched, errors, values, handleSubmit, handleBlur, setFieldValue, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label>{errors[field.name]}</label> :
        <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
      <NumberFormat 
        thousandSeparator={true} 
        thousandsGroupStyle="lakh"
        decimalScale = {(decimal) ? decimal : 2 }
        placeholder={placeholder}
        name={field.name}
        value={field.value}
        onValueChange={(val) => setFieldValue(field.name, val.floatValue)}
        onBlur={(e) =>{
          // e.persist()
          // handleBlur(e)
          if (compute) compute(values, index, setFieldValue)
        }}
        autoFocus={focus ? focus : false}
      />
      {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
    </Form.Field>
  );
  export const FormikFloatingLabelInputComponent = ({  
    validateInput, placeholder, width, isLabel, label, isTxn, focus, disabled, required, style,id,inputType,
    compute,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values, handleChange, submitCount, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) =>  (
    // https://www.npmjs.com/package/react-floating-label-input
<Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
    <br/>
    <FloatingLabelInput
              id= {id}
              label= {placeholder}
              onBlur={(e) =>{
                if (compute) compute(values, setFieldValue)
              }}
              onChange={handleChange}
              onFocus={focus ? focus : false}
              value={field.value}
              name={field.name}
              type={inputType}
            />
            {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
            </Form.Field>
  );

  export const FormikRadioButtonComponent = ({
    userProps, focus, width, label, isTxn, isLabel, toggle, onChangeFunc,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values,  handleChange, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {

   
  
    return(
      // <Form.Field error={errors[field.name] && touched[field.name]}>
      <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        <Radio
        toggle = {toggle ? toggle : null}
        name={field.name ? field.name : null}
        // id={userProps.id}
        value={userProps ? userProps.id : null}
        // checked={userProps.id === field.value}
        onChange={onChangeFunc ? (e, field) => onChangeFunc(e, field) : handleChange}
        autoFocus={focus ? focus : false}
      />
        <label>{userProps ? userProps.id : null}</label>
      </Form.Field>
    )};  
  
  
  export const FormikCheckBoxComponent = ({
    userProps, focus, width, label, isTxn, labelOnTop= false, isLabel, className,toggle, onChangeFunc, defaultChecked, disabled, checked, checkedFunc, 
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values,  handleChange, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {


    
  
    return(
      // <Form.Field error={errors[field.name] && touched[field.name]}>
      <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{labelOnTop ? label : null}</label>
        }
        <Checkbox
        id = {field.name ? field.name : null}
        className = {className}
        // defaultChecked = {defaultChecked ? defaultChecked : null}
        toggle = {toggle ? toggle : null}
        name={field.name ? field.name : null}
        label = {labelOnTop ? null: label}
        disabled = {disabled}
        value={userProps ? userProps.value : null}
        checked= {userProps? userProps.value == 1 : field.value}
        // onChange={onChangeFunc ? (e, {checked}) => onChangeFunc(e, {checked}) : handleChange}
        onChange={onChangeFunc ? (e, {checked}) => onChangeFunc(e, checked) : handleChange}
        autoFocus={focus ? focus : false}
      />
        {/* <label>{userProps ? userProps.id : null}</label> */}
      </Form.Field>
    )};  
  
  export const FormikSelectComponent = ({
    userProps, isLabel, label, isTxn, isSelection, focus, required, multiple,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values, handleChange, setFieldValue, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
  
  const onChange = (value) => {
    setFieldValue(field.name, value)
    if (userProps.getValue)
      userProps.getValue(value, setFieldValue, values);
  }
  
  return (
    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field required={required ? required : false} error={get(errors,field.name) && get(touched,field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label>{errors[field.name]}</label> :
        <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        
      <Dropdown
        search
        selectOnBlur = {false}
        selection={isSelection ? isSelection : true}
        id={field.id}
        multiple = {multiple ? multiple : false}
        name={field.name}
        className= {userProps.className}
        placeholder={userProps.placeholder}
        options={userProps.options}
        value={field.value}
        onChange={(e, { value }) => onChange(value)}
        autoFocus={focus ? focus : false}
        // clearable
        disabled={userProps ? userProps.isDisable ? userProps.isDisable : null : null}
        />     
      {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
  )};  
  
  
export const FormikAsyncSelectComponent = ({
  userProps, isLabel, label, isTxn, isSelection, focus, width,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, setFieldValue, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const onChange = (value) => {
    console.log('onChange', value);
    setFieldValue(field.name, value)
    if (userProps.getValue)
      userProps.getValue(value);
  }


  const loadOptions = (searchQuery) => {
    console.log(searchQuery)
    userProps.getInputValue(searchQuery, values.id);
  }

  return (

    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label>{errors[field.name]}</label> :
        <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        {/* {console.log(field.value, userProps.options)} */}
        {console.log(userProps.searchOptions)}
      <Dropdown
        search
        selection={isSelection ? true : false}
        // selectOnNavigation={true}
        id={field.id}
        name={field.name}
        placeholder={userProps.placeHolder}
        options={userProps.searchOptions && userProps.searchOptions.length>0 ? userProps.searchOptions : userProps.options}
        value={field.value}
        onSearchChange={(e, { searchQuery }) => loadOptions(searchQuery)}
        onChange={(e, { value }) => onChange(value)}
        autoFocus={focus ? focus : false}
        />
      {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
  )
};  



export const FormikTextAreaComponent = ({
  userProps, isTxn, isLabel, label, placeholder, disabled,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, handleBlur, setFieldValue, HeaderClick }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const [ count, setCount ] = useState(0)

  const onChange = (syntheticEvent, value) => {
    setFieldValue(field.name, value.value)
    setCount(value.value.length)
    if(value.value !== ""){
      if (userProps && userProps.getValue)
        userProps.getValue(value.value);
    }
    /* else{
      if (userProps.getValue)
        userProps.getValue('');
    } */
  }
  
  useEffect(() => {
    if(userProps && userProps.displayCount !== undefined && userProps.displayCount === true && field.value.length === 0)
      setCount(0)
  }, [field.value])

  const height = userProps ? (userProps.height === undefined ? 3 : (userProps.height === '' ? 3 : userProps.height === 0 ? 3 : userProps.height)): 3;
  const isFloatLeft = userProps && userProps.displayCount !== undefined && userProps.displayCount === true ? 'left' : null
  return (
    <Form.Field error={get(errors,field.name) && get(touched,field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label style={{float: isFloatLeft}}>{errors[field.name]}</label> :
        <label style={{float: isFloatLeft}}>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
      {userProps ? userProps.displayCount !== undefined && userProps.displayCount === true ?
          <label style={{float: 'right'}}>{count} / {userProps.maxLength}</label>
          : null: null}
        <TextArea
          name={field.name}
          style={{ 
              resize: 'none', 
              width: '100%'
          }}
          value={field.value}
          onChange={onChange}
          disabled = {disabled}
          rows={height}
          maxLength={userProps ? userProps.maxLength : null}
          placeholder={placeholder}>
        </TextArea>
        {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
  )
};



export const FormikPasswordComponent = ({
  validateInput, placeholder, width, isLabel, label, isTxn, focus,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) =>  (
    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
        <Input name={field.name}
          type="password"
          onChange={handleChange}
          value={field.value}
          placeholder={placeholder}
          autoFocus={focus ? focus : false}
        />
        {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
);

export const FormikFileUploadComponent = ({
  validateInput, placeholder, width, isLabel, label, isTxn, focus,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, handleUpload, submitCount }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) =>  (
    // <Form.Field error={errors[field.name] && touched[field.name]}>
    <Form.Field width={width ? width : null} error={get(errors,field.name) && get(touched,field.name)}>
        {errors[field.name] && touched[field.name] && !isTxn ?
          <label>{errors[field.name]}</label> :
          <label>{isLabel ? null : label ? label: startCase(toLower(field.name))}</label>}
         <Form.Group widths={1}>
        <Input name={field.name}
          type="file"
          style={{width: "85.5%"}}
          onChange={handleChange}
          value={field.value}
          placeholder={placeholder}
          autoFocus={focus ? focus : false}
        />
         <Button type="button" onClick ={handleUpload} floated="right" color="grey" size="small" className="CustomeBTN"> Upload File </Button>
         </Form.Group>
        {isTxn ? <ErrorMessage name={field.name} render= { msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label> }/>: null }
      </Form.Field>
);



export const FormikRichText = ({
  userProps, isLabel, label, name, buttonObj, isTxn, isSelection, focus, required, className, id, placeHolder, disabled,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, handleBlur, setFieldValue, HeaderClick }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const [count, setCount] = useState(0)
  const richRef = useRef(null)
  const [value, setValue] = useState('');

  useEffect(() => {
    if (values[field.name] == '') {
      let qeditor = richRef.current.getEditor()
      qeditor.setContents('')
      if (focus)
        qeditor.focus()
      qeditor.clipboard.addMatcher('IMG', (node, delta) => {
        return new Delta().insert('')
      })
    }
  }, [values]);


  const onChange = (value) => {        
    setValue(value);
    setFieldValue(field.name, value)
  }

  const modules = {
    toolbar: [
      [{ 'header': 1 }, { 'header': 2 }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', { 'align': [] }],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
  }

  // const height = userProps ? (userProps.height === undefined ? 3 : (userProps.height === '' ? 3 : userProps.height === 0 ? 3 : userProps.height)): 3;
  const isFloatLeft = userProps && userProps.displayCount !== undefined && userProps.displayCount === true ? 'left' : null
  return (
    <Form.Field error={get(errors, field.name) && get(touched, field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label style={{ float: isFloatLeft }}>{errors[field.name]}</label> :
        <label style={{ float: isFloatLeft }}>{isLabel ? null : label ? label : startCase(toLower(field.name))}</label>}
      {userProps ? userProps.displayCount !== undefined && userProps.displayCount === true ?
        <label style={{ float: 'right' }}>{count} / {userProps.maxLength}</label>
        : null : null}

      <ReactQuill
        ref={richRef}
        theme='snow'
        style={{ height: 80, 'overflowY': 'scroll !important' }}
        modules={modules}
        readOnly= {disabled}
        className = {className}
        // value={values[field.name] == "" ? '': value} 
        placeholder='Type your text here'
        defaultValue={values[field.name]}
        onChange={onChange}
      />

      <input style={{ display: "none !important", height: 10 }} type='hidden' name={field.name} id="commentValue" value={value} />
      {isTxn ? <ErrorMessage name={field.name} render={msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label>} /> : null}
    </Form.Field>
  )
};

export const FormikRichTextDescription = ({
  userProps, isLabel, label, name, buttonObj, isTxn, isSelection, focus, required, className, id, placeHolder, disabled,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, handleChange, handleBlur, setFieldValue, HeaderClick }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [count, setCount] = useState(0)
  const richRef = useRef(null)

  const [value, setValue] = useState('');


  useEffect(() => {

    if (values[field.name] == '') {
      let qeditor = richRef.current.getEditor()
      qeditor.setContents('')
      qeditor.focus()
      qeditor.clipboard.addMatcher('IMG', (node, delta) => {
        return new Delta().insert('')
      })
    }

  }, [values]);

  const onChange = (value) => {
    setValue(value);
    setFieldValue(field.name, value)
  }

  const modules = {
    toolbar: [
      [{ 'header': 1 }, { 'header': 2 }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link', { 'align': [] }],
      ['clean']
    ]
  }

  // const height = userProps ? (userProps.height === undefined ? 3 : (userProps.height === '' ? 3 : userProps.height === 0 ? 3 : userProps.height)): 3;
  const isFloatLeft = userProps && userProps.displayCount !== undefined && userProps.displayCount === true ? 'left' : null
  return (
    <Form.Field error={get(errors, field.name) && get(touched, field.name)}>
      {errors[field.name] && touched[field.name] && !isTxn ?
        <label style={{ float: isFloatLeft }}>{errors[field.name]}</label> :
        <label style={{ float: isFloatLeft }}>{isLabel ? null : label ? label : startCase(toLower(field.name))}</label>}
      {userProps ? userProps.displayCount !== undefined && userProps.displayCount === true ?
        <label style={{ float: 'right' }}>{count} / {userProps.maxLength}</label>
        : null : null}

      <ReactQuill
        ref={richRef}
        theme='snow'
        style={{ 'min-height': '350px', 'overflow-y': 'scroll !important;' }}
        modules={modules}
        readOnly= {disabled}
        className = {className}
        // value={values[field.name] == "" ? '': value} 
        placeholder='Type your text here'
        defaultValue={values[field.name]}
        onChange={onChange}
      />

      <input style={{ display: "none !important", height: 10 }} type='hidden' name={field.name} id="commentValue" value={value} />
      {isTxn ? <ErrorMessage name={field.name} render={msg => <Label size="mini" color='red' basic pointing prompt>{msg}</Label>} /> : null}
    </Form.Field>
  )
};