import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import axios from 'axios';
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const organisation = () => ({
    id: v4(),
    name: "",
    short_name : '',
    location : '',
    mobile : '',
    email : '',   
    segid: "",
    cid: "",
    status: 1,
    dt_create: "",
    dt_update: "",
    uid_create: "",
    uid_update: ""
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const organisationSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    short_name: Yup.string()
                .required('This is a Required Field'),

    name: Yup.string()
                .required('This is a Required Field'),
    location: Yup.string()
                .required('This is a Required Field'),
    mobile: Yup.string()
                .required('This is a Required Field'),
    email: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dt_create: Yup.string().nullable(),
    dt_update: Yup.string().nullable(),
    uid_create: Yup.string().nullable(),
    uid_update: Yup.string().nullable()

  });


export const duplicateCheckOrganisationCode = debounce(checkOrganisationCode, 500);
function checkOrganisationCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/organisation/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							// //console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckOrganisationName = debounce(checkOrganisationName, 500);
function checkOrganisationName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/organisation/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            // //console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  