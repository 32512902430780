import { combineReducers } from 'redux';
import { merge, cloneDeep } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a users schema
const taskSchema = new schema.Entity('tasks',{}, { idAttribute: 'id' });
const taskListSchema = [taskSchema];

const reportTaskSchema = new schema.Entity('reporttasks',{}, { idAttribute: 'taskId' });
const reportTaskListSchema = [reportTaskSchema];

const defaultTasksParams = {
  tasksFetched: false,
  tasksFetchedOfTeam: false,
  createTitle: 'Create New Task',
  createSubmitButtonText: 'Create Task',
  editTitle: 'Edit Task',
  editSubmitButtonText: 'Edit Task',
  activeTask: 'Edit Task' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_TASK') {
        const taskID = action.payload.id;
        const jobId = action.payload.jobId
      return {...state, 
        [jobId]: {...state[jobId], [taskID] : action.payload}
      }
    }
    if (action.type === 'FETCHED_TASKS_BY_JOB') {
      let data = action.payload
      if (data.length > 0) {
          const jobId = data[0].jobId
          const normalizedTasks = normalize(action.payload, taskListSchema);
          let newobj = merge({},state[jobId],normalizedTasks.entities.tasks)
        return {...state, 
          [jobId]: newobj
        }
      } else return state
  }
  if (action.type === 'DELETED_JOBS') {

    const {id} = action.payload
    const { [id]: idValue, ...restOfState } = state;    
    return restOfState
   
  }
    if (action.type === 'FETCHED_TASKS') { 
          const normalizedTask = normalize(action.payload, taskListSchema);
        return merge({},state,normalizedTask.entities.tasks)
    } else {
      return state
    }
  }

  function byTeamJob(state = {}, action) {

  if (action.type === 'FETCHED_CURRTASK_BY_TEAM') {
    let data = action.payload
    let teamId = action.payload.teamId
    let tasks = action.payload.tasks

    console.log()

    const normalizedTask = normalize(action.payload.tasks, reportTaskListSchema);
    console.log(' normalizedTask is ', normalizedTask)
    let newobj = merge({},state[teamId],normalizedTask.entities.reporttasks)
    console.log(' Newobj is ', newobj)
   
    return {...state, 
      [teamId]: newobj
    }
  }
  if (action.type === 'DELETED_JOBS') {

    const {id, teamId} = action.payload
    let existingArray = state[teamId]
    if (!existingArray) return state

    // find the task report object key which needs to be deleted
    let taskobj = Object.values(existingArray).find((item) => item.jobId == id)
    const parentKey = teamId;
    const childKey = taskobj ? taskobj.taskId : undefined;
    // Remove the 'c' element from original
    const { [parentKey]: parentValue, ...noChild } = state;
    // Remove the 'y' from the 'c' element
    const { [childKey]: removedValue, ...childWithout } = parentValue;
    // Merge back together
    return { ...noChild, [parentKey]: childWithout };
    // console.log(withoutThird); // Will be { "foo": "bar", "c": { "x": 1 } }
   
  }

  if (action.type === 'UPDATED_TASK_REPORT') {
    let taskId = action.payload.taskId
    let teamId = action.payload.teamId
   
    return {...state, 
      [teamId]: {...state[teamId], [taskId]: action.payload}
    }
  }

  /* if (action.type === 'ASSIGNED_USER_TO_TASK') {
    let taskId = action.payload.id
    let teamId = action.payload.teamId
    let userId = action.payload.userId

  
    let currObject = state[teamId]
    currObject = currObject[taskId]
    // console.log (' currObj in  in reducer is ', currObject)
    let newObject = cloneDeep(currObject)
    newObject.userId = userId
    
    return {...state, 
      [teamId]: {...state[teamId], [taskId]: newObject}
    } 
  }*/

    else
    return state
}
  

function tasksParams(state = defaultTasksParams, action) {
  if (action.type === 'SAVED_TASK') {
    if (action.payload.id == state.activeTask.id){
    return {...state, 
      activeTask: action.payload,
    }    
  }}
  if (action.type === 'SET_ACTIVE_TASK') {     
    return {...state, 
      activeTask: action.payload,
    }    
  }
  if (action.type === 'FETCHED_CURRTASK_BY_TEAM') { 
    return {...state, 
      tasksFetchedOfTeam: true,
      isFetching:false
    }
  }
  if (action.type === 'FETCHED_TASKS') { 
      return {...state, 
        tasksFetched: true,
        isFetching:false
      }
  } else {
    return state
  }
}

const tasks = combineReducers({
    byId,
    tasksParams,
    byTeamJob
});

export default tasks;

