import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsTeamFetched } from './selectors'
import * as actionsTypes from './types'
import { savedTeam, fetchedTeam, updatedUserTeamPriority, addedTeamMember, removedTeamMember,archivedTeam, restoredTeam } from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL } from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import axios from 'axios'
import { toast } from 'react-toastify'

//#region Saga for Create Team 
function* createTeam() {
    yield takeEvery('CREATE_TEAM',saveTeam)
  }

function* saveTeam(action){
  const { response, error } = yield call(saveTeamAPI, action.payload)
  // //console.log("Saga",action.payload)
  if (response) 
  try{
    yield put (savedTeam(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveTeamAPI(data) {
    return axios.post(URL+'/team/create', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Team 

function* editTeam() {
  yield takeEvery('EDIT_TEAM',saveEditedTeam)
}

function* saveEditedTeam(action){
  const { response, error } = yield call(editTeamAPI, action.payload)  
  if (response) 
  try{
    yield put (savedTeam(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editTeamAPI(data) {
  return axios.post(URL+'/team/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Updating User Team Priority 

function* updateUserTeamPriority() {
  yield takeEvery('UPDATE_USER_TEAM_PRIORITY',saveUserTeamPriority)
}

function* saveUserTeamPriority(action){
  const { response, error } = yield call(saveUserTeamPriorityAPI, action.payload) 
  if (response) 
  try {
    yield put (updatedUserTeamPriority(response.data))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    // yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveUserTeamPriorityAPI(data) {
  // let user_id = data.id
  return axios.post(URL+'/team/users/set-priority', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Team 

function* fetchTeam() {
  yield takeEvery('FETCH_TEAM',requestTeam)
}
  

function* requestTeam(action){
  const isTeamFetched = yield select(getIsTeamFetched);
  if(!isTeamFetched){
  try {
      let { response, error } = yield call(requestTeamAPI)
      if (response) {
        yield put (fetchedTeam(response.data))
      } else {
        yield put (handlError(action, parseError(error)))
        sagaErrorMessage(error,action)  
      }
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  
  }
}


function requestTeamAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.get(URL+'/team/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion

//#region Saga for Create Team 
//const axios = apiContainer.axios;

function* addTeamMember() {
  yield takeEvery(actionsTypes.ADD_TEAM_MEMBER,saveTeamMember)
}

function* saveTeamMember(action){
  try{
      const { response, error } = yield call(saveTeamMemberAPI, action.payload)
      if (response) {
          console.log(response)
          yield put (addedTeamMember(action.payload))
      }
      if (error) {
        sagaErrorMessage(error, action)
      }

    } catch(error) {
      sagaErrorMessage(error, action)
    }
}

function saveTeamMemberAPI(data) {
  return axios.post(URL+'/team/members/add', data, getHeaderJWT())
    .then(response => ({response}))
    .catch(error => ({error}))
}

//#endregion

//#region Saga for Remove Team Member
//const axios = apiContainer.axios;

function* removeTeamMember() {
  yield takeEvery(actionsTypes.REMOVE_TEAM_MEMBER, deleteTeamMember)
}

function* deleteTeamMember(action){
  try{ 
    const { response, error } = yield call(deleteTeamMemberAPI, action.payload)
    if (response) yield put (removedTeamMember(action.payload))
    if(error) sagaErrorMessage(error, action)
  }
  catch {
      // yield put (handlError(action, parseError(error)))
      sagaErrorMessage("error",action)  
  }
}

function deleteTeamMemberAPI(data) {
  return axios.post(URL+'/team/members/remove', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for Archive Team 

function* archiveTeam() {
  yield takeEvery('ARCHIVE_TEAM',saveArchiveTeam)
}

function* saveArchiveTeam(action){
  const { response, error } = yield call(archiveTeamAPI, action.payload)
  if (response) {
    yield put (archivedTeam({id: action.payload.id, msg : response.data}))
    toast.success("Archived Successfully")
  }
  else {
    let err = parseError(error)
    yield put (handlError(action, err))
    sagaErrorMessage(error,action)  
    toast.error(err.data.message)
  }
}

function archiveTeamAPI(data) {
  return axios.post(URL+'/team/archive', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion

//#region Saga for Archive Team 

function* restoreTeam() {
  yield takeEvery('RESTORE_TEAM',saveRestoreTeam)
}

function* saveRestoreTeam(action){
  const { response, error } = yield call(restoreTeamAPI, action.payload)
  if (response) {
    yield put (restoredTeam({id: action.payload.id, msg : response.data}))
    toast.success("Restored successfully.")
  }
  else {
    let err = parseError(error)
    yield put (handlError(action, err))
    sagaErrorMessage(error,action)  
    toast.error(err.data.message)
  }
}

function restoreTeamAPI(data) {
  return axios.post(URL+'/team/restore', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error.response)
    console.groupEnd()
}

export default function* teamSaga() {
    yield all([
        createTeam(),
        fetchTeam(),
        editTeam(),
        addTeamMember(),
        removeTeamMember(),
        updateUserTeamPriority(),
        archiveTeam(),
        restoreTeam()
    ])
  }  