import { createSelector } from 'reselect'
import * as Yup from 'yup';
import moment from 'moment'
import { parseDateFromFormats } from '../../../utilities/parseDate';
import { endsWith, at, concat, orderBy } from 'lodash';
import {activitytemplates}  from '../../taskdetails/data/templateMapping'
import { isDate } from 'util';

export const getIsFetchingActivity = (state, props) => state.activity.params.isFetching;
export const getIsActivityFetched = (state, props) => state.activity.params.activityFetched;
export const getIsActivityDefinitionUpdated = (state, props) => state.activity.params.activityDefinitionUpdated;
export const getActivityList = (state, prop) => state.activity.byId
export const getNotification = (state, id) => state.activity.notifications[id]

export const activityOptions = [
    { key : 'steps', value : 'steps', text : 'To Do' },
    { key : 'followup', value : 'followup', text : 'Follow Up' },
    { key : 'checklist', value : 'checklist', text : 'Checklist' },
    { key : 'notifyEmail', value : 'notifyEmail', text : 'Email Notification' },
    { key : 'notifySMS', value : 'notifySMS', text : 'SMS Notification' },
    { key : 'notifyTelegram', value : 'notifyTelegram', text : 'Telegram Notification' },
    { key : 'input', value : 'input', text : 'Input Screen' },
    { key : 'postRealBooks', value : 'postRealBooks', text : 'Post to RealBooks' },
    { key : 'fetchRealBooks', value : 'fetchRealBooks', text : 'Data from RealBooks' },
]

export const getActivityById=(state, id, processId) =>{ 
    let process_activities = state.activity.byId
    let activities = process_activities[processId]

    const obj = activities.find((item) => item.id == id)

    return (obj)

}

export const getActivityByActivityId=(state, activityid, processId) =>{ 
    let process_activities = state.activity.byId
    let activities = process_activities[processId]
    // console.log (' Activities in selector for activityid=', activityid, "=process id =", processId)
    // console.log (' Activities in selector=', activities)

    if (!activities || activities.length == 0) return undefined

    const obj = activities.find((item) => item.activityId == activityid)

    return (obj)

}

export const getActivityTemplateByActivityId=(state, activityid, processId) =>{ 
    if (!activityid) return undefined

    let obj = getActivityByActivityId (state, activityid, processId)
    return (obj ? obj.activityTemplate : undefined)

}

export const getActivities = (state, _id) => {
    let newActivity = {
        activityID : "",
        name : "",
        process : _id,
        role : _id,
        guidelines : '',
        activityType : ""
    };    

    // let activityIds = state.workflow.byId[_id].activities
    // let activities = at(state.activity.byId,activityIds)
    return concat([],newActivity)
    // return newActivity
}

export const getActivityParams = (state, props) => {
    const params =  {}
    if(endsWith(state.router.match.path,'/activity/create')){ 
        params.title = state.activity.params.createTitle
        params.submitButtonText = state.activity.params.createSubmitButtonText        
    };

    if(endsWith(state.router.match.path,'/activity/:id')){ 
        params.title = state.activity.params.editTitle
        params.submitButtonText = state.activity.params.editSubmitButtonText        
    };

    return params
}

export const getActivityByProcessId=(state, processId) =>{
    if (processId == undefined ) return undefined
        const activities = getActivityList(state)

        let obj = activities[processId]
        let sortedobj = orderBy(obj, [(item)=>item.rank], ['asc']);

        return (sortedobj)

    }


    export const getActivityListByJobId=(state, jobId) =>{ 
        const job = state.jobs.byId[jobId]
        // const processId = job.
        const activities = getActivityList(state)

        // console.log (' Activities are ', activities[processId])
        return (activities[0])
        // const keys = Object.keys(activities)
        // const obj = keys.map( (key) => { return { value : key, text : activity[key].name, className: "selectLiCss" }})
        // return obj
    }


    export const getIsActivityFetchedByProcess=(state, processId) =>{ 
        const activities = getActivityByProcessId(state, processId)

        // console.log (' Activities are ', activities[processId])
        return (activities[processId] == undefined ? false: true)
        // const keys = Object.keys(activities)
        // const obj = keys.map( (key) => { return { value : key, text : activity[key].name, className: "selectLiCss" }})
        // return obj
    }

export const selectActivity = createSelector(
    getActivityList,
    activity => { 
        const keys = Object.keys(activity)
        const obj = keys.map( (key) => { return { value : key, text : activity[key].name, className: "selectLiCss" }})
        return obj
    })

    export const selectActivityFromJsonObject = () => {
            // const keys = template
            const obj = activitytemplates.map( (template) => { return { value : template.component, text : template.text, className: "selectLiCss" }})
            return obj
    }