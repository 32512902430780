export const CREATE_ROLE = "CREATE_ROLE";
export const SAVED_ROLE = "SAVED_ROLE";
export const FETCH_ROLE = "FETCH_ROLE";
export const FETCHED_ROLE = "FETCHED_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const CREATE_PERMISSION = "CREATE_PERMISSION";
export const SAVED_PERMISSION = "SAVED_PERMISSION";
export const FETCH_PERMISSION = "FETCH_PERMISSION"
export const FETCHED_PERMISSION = "FETCHED_PERMISSION" 
export const REMOVE_PERMISSION = "REMOVE_PERMISSION"
export const REMOVED_PERMISSION = "REMOVED_PERMISSION" 


