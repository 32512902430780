import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Comment, Label, Container, Icon, Divider } from 'semantic-ui-react'
import {fetchComments} from '../../comments/data/actions'
import { getCommentsByWorkroomId} from '../data/selectors'
import {replace} from 'lodash'
import { heightSet, widthSet, innerHeightSet, setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { components } from 'react-select';
import moment from 'moment'
import system_icon from "../../../images/systems1.png";
import { selectUsers } from '../../users/data/selectors'
import { handleFileDownload } from './commentsUtils'
import { getUnreadCountForWorkroom } from '../../app/data/selectors'


const getReadableDate = (longdate) => {
  let jdate = new Date(longdate / 1000000)
  let str = jdate.toLocaleString()
  // str = moment(jdate).fromNow() + " : " + moment(jdate).format('LT')

  return str
}

const getUserName = (userList, userid) => {
  if (userList == undefined || userList.length == 0) return null
  let userobj = userList.find((item) => item.value == userid)
  if (userobj == undefined) return null
  return userobj.text
}

const getJobKeyName = (jobsList, jobid) => {
  if (jobsList == undefined || jobsList.length == 0) return null
  let jobobj = jobsList.find((item) => item.value == jobid)
  if (jobobj == undefined) return null
  return jobobj.text
}



export function parseSystemComment (userList, text) {

  let str = text
  let RED_COLOUR_TAG = '<span style="color: rgb(230, 0, 0);">'
  let GREEN_COLOUR_TAG = '<span style="color: rgb(0, 138, 0);">'
  let ORANGE_COLOUR_TAG = '<span style="color: rgb(255, 153, 0);">'
  // let GREY_COLOUR_TAG = '<span style="color: rgb(102, 102, 102);">'
  let GREY_COLOUR_TAG = '<span style="color: rgb(51, 51, 51);">'
  let CLOSING_TAG = '</span>'
  
    if (str.indexOf ('@') != -1) 
    {
      let arrofwords = text.split(' ')
      arrofwords = arrofwords.map((word, index) => {
        if (word.startsWith('@')) { // this is to convert userid to username
          let name = word.substring(1)
          name = getUserName (userList, name)
          if (arrofwords [index-1] == 'from')
            return GREY_COLOUR_TAG + name + CLOSING_TAG
          else  
            return RED_COLOUR_TAG + name + CLOSING_TAG
        }
        return word
    })
      str = arrofwords.join(' ')
    }

  // the following changes the elements between {{ xxxx }} to a different colour
    str = '<p>' + str + '</p>'
    str = replace(str, new RegExp('from {{','g'), 'from ' + GREY_COLOUR_TAG)
    str = replace(str, new RegExp('to {{','g'), 'to ' + RED_COLOUR_TAG)
    str = replace(str, new RegExp('}}', 'g'), CLOSING_TAG)

    return str
}

const CommentsDisplayWorkroom = (props) => {

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [innerCommentsHeights, setInnerCommentHeight] = useState('')
  const [widths, setWidth] = useState('')
  const [dataToDisplay, setDataToDisplay] = useState()
  const [zoomedInMode, setZoomedInMode] = useState(false)

  const workroomId = props.workroomId ? props.workroomId : undefined
  const unreadMessagesCount = useSelector(state => getUnreadCountForWorkroom(state, workroomId)) 
  // const userList = useSelector(state => selectUsers(state, props))
  const commentsRecd = props.commentsRecd 
  const userList = props.userList
  const jobsList = props.jobsList

  const dispatch = useDispatch()


  const zoomOutToWorkroom = () => {
    setDataToDisplay (props.commentsRecd)
    setZoomedInMode (false)
  }


  const zoomInToJob = (jobid) => {
    const obj = commentsRecd.filter( (value) => { 
      if (value.jobId == jobid )
      {
          return value
      }
  })
    setDataToDisplay (obj)
    setZoomedInMode (true)

  }

  const icon = () => {
    return (
    <>
      <div style={{  float: "left" }}>
        <Icon name="bookmark outline" color="grey" size="large" style={{  paddingLeft: "10px" ,marginRight: "24px", marginTop: "8px" }}/>
      </div> 
    </>
    )}
  

  function renderSystemComment(comment, i) {

    // console.log(' unreadMessagesCount is ', unreadMessagesCount)
    let jobkey = getJobKeyName (jobsList, comment.jobId)
    return (

    <Comment key={comment.dtCreate} className='paddingBottom10'>
          <Comment.Avatar as={icon}/>
          <Comment.Content>
            <Comment.Author className='fontColorDarkGrey'>{getUserName(userList, comment.userId)}
              <Comment.Metadata>
                {getReadableDate(comment.dtCreate)} : &nbsp; 
                {jobkey && 
                  <Link to={{ pathname: '/jobs/view/' + jobkey.toLowerCase() , hash: '#comments'}}>
                  ( {jobkey} )
                  </Link>
                }
              </Comment.Metadata>
            </Comment.Author>
            <Comment.Text className='fontColorDarkGrey'>
               <div dangerouslySetInnerHTML={{__html: parseSystemComment(userList, comment.comment)}} />
               {/* <div dangerouslySetInnerHTML={{__html: comment.comment}} /> */}
               </Comment.Text>
              <Comment.Actions className = 'marginTopTen' >
                  { !zoomedInMode ?
                  <Icon name = 'zoom-in' size='large' color="grey" onClick={() => zoomInToJob(comment.jobId)}/>
                  :
                  <Icon name = 'zoom-out' size='large'  color="grey" onClick={() => zoomOutToWorkroom()}/>
                  }
              </Comment.Actions>
          </Comment.Content>
        </Comment>
        
      )
  }
  

  function renderUserComment(comment, i) {

    // console.log(' unreadMessagesCount is ', unreadMessagesCount)
    let jobkey = getJobKeyName (jobsList, comment.jobId)
    let attachIds = (comment.attachmentId && comment.attachmentId !='') ? comment.attachmentId.split('||') : undefined
    let attachNames = (comment.attachmentName && comment.attachmentName !='') ? comment.attachmentName.split('||') : undefined
    let commentString = (attachIds && attachIds.length > 1 ? 'Files Uploaded: ' : 'File Uploaded: ')

    return (
      
      /* {(i == (dataToDisplay.length - unreadMessagesCount )) && 
      setTimeout(function(){ 
        <Divider horizontal>Unread Comments</Divider>
      }, (unreadMessagesCount * 1000))
      } */

    <Comment key={comment.dtCreate} className='paddingBottom10'>
          <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
          <Comment.Content>
            <Comment.Author>{getUserName(userList, comment.userId)}
              <Comment.Metadata>
                {getReadableDate(comment.dtCreate)} : &nbsp; 
                {jobkey && 
                  <Link to={{ pathname: '/jobs/view/' + jobkey.toLowerCase() , hash: '#comments'}}>
                  ( {jobkey} )
                  </Link>
                }
              </Comment.Metadata>
            </Comment.Author>
            {!attachIds ?
               <Comment.Text>
               <div dangerouslySetInnerHTML={{__html: comment.comment}} />
               </Comment.Text>
              :
              
              <Comment.Text>{comment.comment == '' ? commentString :  <div dangerouslySetInnerHTML={{__html: comment.comment}} /> }
              { attachIds.length > 1 ? <br /> : null}
              {attachIds.map(( attachmentid, i ) => 
             <>
              <Label as ='a' basic color='blue' className='borderNoneObj' id={comment.id} onClick= {()=>handleFileDownload(attachmentid)} >
                {attachNames[i]}
                
              </Label>
              <br />
              </>
              )}
              
              </Comment.Text>
              
            }
              <Comment.Actions className = 'marginTopTen' >
                  { !zoomedInMode ?
                  <Icon name = 'zoom-in' size='large' color="grey" onClick={() => zoomInToJob(comment.jobId)}/>
                  :
                  <Icon name = 'zoom-out' size='large'  color="grey" onClick={() => zoomOutToWorkroom()}/>
                  }
              </Comment.Actions>
          </Comment.Content>
        </Comment>
        
      )
  }

  useEffect(() => {
    setMenuHeight(setHeight)
    widthSet(setWidth)
    menuAccordionHeight(setInnerHeight)
    let objDiv = document.getElementById("comments");
    objDiv.scrollTop = objDiv.scrollHeight;


    // setMainContainerHeight(setMainComtainerHeight)
    window.addEventListener('resize', function(){
        setMenuHeight(setHeight)
        widthSet(setWidth)
        menuAccordionHeight(setInnerHeight)
        // heightSetFotTaskForm(setInnerCommentHeight)
    }, false);
}) 

  useEffect(() => {
       
    if(!commentsRecd){
        console.log ( ' comments not recd is ', commentsRecd)
        dispatch(fetchComments({'workroomId': workroomId, 'dtCreated': 0}));
    } else
    setDataToDisplay (commentsRecd)
},[commentsRecd]);

  if (!dataToDisplay) return null
  // let data = commentsRecd
  
  return (
    // <Container>
    // <div  id = 'comments' className="outerDivClass hideScroll paddingLeft20" style={{height: (innerHeights)+'px', widths: widths+"px"}}>
    <Comment.Group minimal>
      {dataToDisplay.map((comment, i) => (
          comment.source =='system' ? 
          renderSystemComment (comment, i):
          renderUserComment (comment, i)
        
      ))
      }
         </Comment.Group>
        // </div>
        // </Container>
      

   
  )
}



export default CommentsDisplayWorkroom;
