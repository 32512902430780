import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getProcessfile, getProcessfileParams } from '../data/selectors';
import { Container, Header, Form, Button, Input, Grid} from 'semantic-ui-react';
import { heightSet } from '../../../utilities/heightForListing'


const ProcessfileDisplay = (props) => {


    const processName = props.location.processName
    const processId =  props.location.processId
    const [heights, setHeight] = useState('')
    const dispatch = useDispatch()
     
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    
    return (
  
            <>
            <Header as='h1'>Process Template Display</Header>
            </>
            
    )
}

export default ProcessfileDisplay;