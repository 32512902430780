import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedUsers } from '../data/actions'
import * as select from '../data/selectors'
import {  Dropdown } from 'semantic-ui-react';
import { getListOfUsersFromWorkroom } from '../../workRoom/data/selectors'
import { fetchUsersInWorkroom } from '../../workRoom/data/actions'

const filterFromWorkroomCalled = []

const UsersSelectSimple = ({ initialValue, filterFromWorkroom, getValue : callback }, props) => {

    const userList = useSelector(state => getListOfUsersFromWorkroom(state, filterFromWorkroom))

    let users =  useSelector(state => state.users.byId)
    const [val, setVal] = useState()
    
    const usersFetched = useSelector(state => select.getIsUsersFetched(state, props))
    const options = filterFromWorkroom ? useSelector(state => select.selectUsersFromList( userList)) : useSelector(state => select.selectUsers(state, props))
    const dispatch = useDispatch()
    // dispatch(fetchUsersInWorkroom({ 'workroomId': filterFromWorkroom}))
    useLayoutEffect(() => {
        if (filterFromWorkroom && (userList == undefined || userList.length ==0) && filterFromWorkroomCalled.indexOf(filterFromWorkroom) == -1) {
            console.log("filterFromWorkroom")
            dispatch(fetchUsersInWorkroom({ 'workroomId': filterFromWorkroom}))
            filterFromWorkroomCalled.push(filterFromWorkroom)
        }
    }, [])
  

    const handleChange = (e, {value}) => {
        console.log(value)
        callback(value)
    }


    if (!usersFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Dropdown
                search
                selection
                onChange={handleChange}
                options={options}
                defaultValue={initialValue}
                placeholder='Select User'
                value={val}
            />
      )   
    }
}


export default UsersSelectSimple;
