import { takeEvery, all, put } from 'redux-saga/effects'
import { insertMyCreated, insertMyQueue } from './actions'
import { parseJwt } from '../../../utilities/parseJWT'


function* insertTaskMyCreated() {
    yield takeEvery('INSERT_TASK_REPORT',saveTaskMyCreated)
  }

function* saveTaskMyCreated(action){
    const token = localStorage.getItem('user')
    const userId = token? parseJwt(token).identity : null
    if (userId == action.payload.ownerId) 
      yield put (insertMyCreated(action.payload))      
}

//#endregion


export default function* myTasksSaga() {
    yield all([
      insertTaskMyCreated()
    ])
  }  