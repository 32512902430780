export const CREATE_CLIENT = 'CREATE_CLIENT'
export const SAVED_CLIENT = 'SAVED_CLIENT'
export const EDIT_CLIENT = 'EDIT_CLIENT'
export const EDITED_CLIENT = 'EDITED_CLIENT'
export const FETCH_CLIENT = 'FETCH_CLIENT'
export const FETCHED_CLIENT = 'FETCHED_CLIENT'
export const SEARCH_CLIENT = 'SEARCH_CLIENT'
export const STORE_SEARCH_CLIENT_RESULT = 'STORE_SEARCH_CLIENT_RESULT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETED_CLIENT = 'DELETED_CLIENT'
export const NOTIFICATION_DONE_CLIENT = 'NOTIFICATION_DONE_CLIENT'
export const FILTER_ITEMS = 'FILTER_ITEMS'
export const ARCHIVE_CLIENT = 'ARCHIVE_CLIENT'
export const ARCHIVED_CLIENT = 'ARCHIVED_CLIENT'
export const RESTORE_CLIENT = 'RESTORE_CLIENT'
export const RESTORED_CLIENT = 'RESTORED_CLIENT'
