import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';


export const tasks = () => ({
    id: v4(),
    workroomId: "",
    teamId: "",
    processId: "",
    activityId :"",
    jobId: "",
    userId: "",
    taskName: "",
    taskStatus: 0,
    secondary: null,
    status: 1,
    dtCreate: "",
    dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const taskSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),
    taskName: Yup.string().max(10)
                .required('This is a Required Field'),
    userId: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    dtCreate: Yup.string().nullable(),
    dtUpdate: Yup.string().nullable(),
    userCreate: Yup.string().nullable(),
    userUpdate: Yup.string().nullable()

  });


