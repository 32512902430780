import * as type from './types'


export function createClient(client) {
    return {
        type : type.CREATE_CLIENT,
        payload : client,
        txn : 'initiated',
    };
}


export function savedClient(client) {
    return {
        type : type.SAVED_CLIENT,
        payload : client,
        txn : 'success',
        diff : true
    };
}


export function editClient(client) {
    return {
        type : type.EDIT_CLIENT,
        payload : client,
        txn : 'initiated',
    };
}


export function fetchClient() {
    return {
        type : type.FETCH_CLIENT,
    };
}


export function fetchedClient(client) {
    return {
        type : type.FETCHED_CLIENT,
        payload : client
    };
}


export function searchClient(client) {
    return {
        type : type.SEARCH_CLIENT,
        payload : client
    };
}


export function storeSearchClientResult(client) {
    return {
        type : type.STORE_SEARCH_CLIENT_RESULT,
        payload : client
    };
}


export function deleteClient(client) {
    return {
        type : type.DELETE_CLIENT,
        payload: client
    };
}


export function deletedClient(msg) {
    return {
        type : type.DELETED_CLIENT,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_CLIENT,
        payload: props
    };
}


export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}
export function archiveClient(CLIENT) {
    return {
        type : type.ARCHIVE_CLIENT,
        payload: CLIENT
    };
}

export function archivedClient(msg) {
    return {
        type : type.ARCHIVED_CLIENT,
        payload : msg
    };
}

export function restoreClient(CLIENT) {
    return {
        type : type.RESTORE_CLIENT,
        payload: CLIENT
    };
}

export function restoredClient(msg) {
    return {
        type : type.RESTORED_CLIENT,
        payload : msg
    };
}
