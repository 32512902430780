import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash';
import { Container, Header, Button, Form, Icon } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import {  FormikDateComponent, FormikInputComponent } from '../../../utilities/formUtils';
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'
import moment  from 'moment'
import {getTaskDetailsByJobActivityTask, getNotification} from '../data/selectors'
import {approveTaskDetails} from '../../taskdetails/data/model'
import {editTaskdetails, setNotifyDone} from '../data/actions'
import { updateTaskComplete} from '../../tasks/data/actions'
import {getJobPathLink} from '../../../utilities/pathUtils'

const TemplateApproveDev = (props) => {
    const [heights, setHeight] = useState('')
    const [taskApprove, setTaskApprove] = useState()
    const [savedTaskDetails, setSavedTaskDetails] = useState(false)
    // const [editMode, setEditMode] = useState(props.editMode)

    const dispatch = useDispatch()
    const editMode = props.editMode 
    const callBackParent = props.callBack // function

    const jobId = props.job ? props.job.id : undefined
    const taskId = props.taskId
    const activityId = props.activityId
    const currTask = (props.currActiveTask.id == taskId ? true : false)

    // const taskList = props.taskList

    // console.log ('In template #### disabled is ', editMode)
    // console.log ('In template #### TaskId is ', taskId)
    // console.log ('In template #### Active task is  is ', activityId)
    

    const taskdetails = useSelector(state => getTaskDetailsByJobActivityTask (state, jobId, activityId, taskId))
    console.log ('In template #### taskdetails  is ', taskdetails)

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })


    useEffect(() => {
        if (!taskdetails) {
            let task1 = approveTaskDetails()
            setTaskApprove (task1)
        } else
            setTaskApprove(cloneDeep(taskdetails))
        
    }, [taskdetails])

    const completeTask = () => {
        let obj = {
            'id' : taskId,
            'variables' : []
        }
        console.log(' Complete  form task: ', obj)
        dispatch (updateTaskComplete(obj))
        setTimeout(function(){ 
            props.history.push({pathname: getJobPathLink (props.job) })    
        }, 1000); 
    }

    const saveActivity = (values, actions) => {
        let obj = {
            jobId : jobId,
            activityId : activityId,
            antId :activityId + '#' + taskId,
            taskDetails : cloneDeep (values)
        }
        console.log(' Start form todos: ', obj)
        dispatch (editTaskdetails(obj))
       /*  setTimeout(function(){ 
            props.history.replace({pathname: "/tasks/edit/" + jobId })    
        }, 1000); */   
        callBackParent ()
    }

    
    return (
     
                <>   

                <Formik id="activityForm" size="large" width={5}
                        enableReinitialize
                        initialValues={taskApprove}
                        // validationSchema={activitySchema}
                        onSubmit={(values, {resetForm}) => saveActivity(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange, setFieldValue }) => (
                            <Form as={FormikForm} size="large" className="CustomeForm borderNoneObj  marginTopTen" width={5} onSubmit={handleSubmit}>
                                 <Form.Group widths={2}>
                                    <Field name="priority" label="Priority" disabled = {!editMode} component={FormikInputComponent} ></Field>  
                                    <Field name="customername" label="Customer Name"  disabled = {!editMode}  component={FormikInputComponent} ></Field> 
                                </Form.Group>   
                               
                                <Form.Group widths={2}>
                                    <Field name="deadline" label="Deadline"   disabled = {!editMode}  component={FormikDateComponent} ></Field>  
                                    <Field name="chargeable" label="Chargeable"   disabled = {!editMode}  component={FormikInputComponent} ></Field> 
                                </Form.Group>   
                                { editMode &&
                                //  <Form.Group widths={2}>
                                    <Button type="submit"  size="medium blue" className="CustomeBTN "> Save </Button>
                                    
                                    // </Form.Group>
                                }
                                {
                                    !editMode && taskId && currTask &&
                                    <Button type="button" floated='right' size="medium" color= "green" className="CustomeBTN " onClick = {completeTask}> Mark as complete </Button>
                                }
                                {/* {console.log(JSON.stringify(savedStepId)+"==============================================notification")
                                } */}
                                {savedTaskDetails ?
                                    <Notification id={savedTaskDetails} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )} 
                    />
                    </>
               
         
    )
}

export default TemplateApproveDev;
