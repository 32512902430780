import { createSelector } from 'reselect'
import { startsWith, cloneDeep, at, concat, chain, sortBy } from 'lodash';
import { process } from './model'


export const getIsFetchingProcess = (state, props) => state.process.params.isFetching;
export const getIsProcessFetched = (state, props) => state.process.params.processFetched;
export const getProcessList = (state, prop) => state.process.byId
export const getIsSearching = (state, props) => state.process.params.isSearching;
export const getIsSearchFetched = (state, props) => state.process.params.searchFetched;
export const getSearchResults = (state, prop) => state.process.searchResults
export const filters = (state, prop) =>  state.process.filterResults ;
export const getDeleteStatus = (state, prop) => state.process.deleteStatus
export const getDelFetched = (state, prop) => state.process.params.isDelFetched
export const getNotification = (state, id) => state.process.notifications[id]

export const getProcess = (state, props) => {
    if(props.match.path==='/process/create'){
        return process()
    }
    else{
        let id = props.match.params.id
        let obj = cloneDeep(state.process.byId[id]);
        return obj        
    }
}

export const getProcessParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/process/create')){ 
        params.title = state.process.params.createTitle
        params.submitButtonText = state.process.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/process/edit/')){ 
        params.title = state.process.params.editTitle
        params.submitButtonText = state.process.params.editSubmitButtonText        
    };

    return params
}


export const getProcessNameFromId = (state, processid) => {
    if (processid == undefined )
        return ""
    let obj = state.process.byId[processid]
   if(obj)
        return obj.processName
    else    
        return ""
}

export const getProcessFromId = (state, processid) => {
    if (processid == undefined )
        return undefined
    return (state.process.byId[processid])
   
}

export const selectProcess = createSelector(
    getProcessList,
    process => { 
        const keys = Object.keys(process)
        const obj = keys.map( (key) => { return { value : key, text : process[key].processName, className: "selectLiCss", key: key }})
        return obj
    })

    export const selectProcessObject = createSelector(
        getProcessList,
        process => { 
            const keys = Object.keys(process)
            const obj = keys.map( (key) => { return { value : process[key], text : process[key].processName, className: "selectLiCss", key: key }})
            return obj
        })

    export const selectProcessKey = createSelector(
        getProcessList,
        process => { 
            const keys = Object.keys(process)
            const obj = keys.map( (key) => { return { value : process[key].processKey, text : process[key].processName, className: "selectLiCss", key: key }})
            return obj
        })


export const selectProcessSearched = createSelector(
    getSearchResults,
    process => { 
        const keys = Object.keys(process) 
        const obj = keys.map( (key) => { return { value : key, text : process[key].processName, className: "selectLiCss", key: key }})
        return obj
    })


export const getProcessByDecision = (state, decision_id) => {
    let newProcess = {
        event : "",
        objective : "",
        motivation : "",
        identity : "",
        id : "",
        headProcess:'127da9e6-8f66-4162-83c5-85b017594aab',
        decision : decision_id,
        // cid : orgSelected(state),
        // project : projectSelected(state)
    };    

    let processIds = state.decision.byId[decision_id].processes

    let processes = at(state.process.byId,processIds)
    return concat(processes,newProcess)
}


export const getProcessArray = createSelector(
    getProcessList, process => {
        return chain(Object.values(process)).sortBy(item => item.processName).value()
    })
        