import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';
import {getCurrUserId} from '../../../utilities/pathUtils'

// Define a apps schema
const lastSeenSchema = new schema.Entity('lastSeen');
const lastSeenListSchema = [lastSeenSchema];

const defaultAppParams = {
  appFetched: false,
  lastSeenDataFetched: false,
  unreadCountFetched: false,
  isDelFetched: false,
  headerTitle :{title:''},
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_APP') {
        const appID = action.payload._id;
      return {...state, 
        [appID]: action.payload
      }
    }
       
     else {
      return state
    }
  }


  function byLastSeenData(state = {}, action) {
    if (action.type === 'FETCHED_LAST_SEEN') {
      const normalizedApp = normalize(action.payload, lastSeenListSchema);
      // console.log (' normalizedAppare: ', normalizedApp)
      if (normalizedApp.entities.lastSeen)
      return merge({},state,normalizedApp.entities.lastSeen.undefined) 
    else return state    
    }
    if (action.type === 'UPDATED_USER_ONLINE') {
      let workroomId = action.payload.workroomId
      return {...state, 
        [workroomId]: action.payload.dtTime
      }
    }
    if (action.type === 'EDITED_LAST_SEEN') {
      let userId = getCurrUserId()
      let workroomId = action.payload.workroomId
      return {...state, 
        [workroomId]: action.payload.dtTime
      }
      return state
      
    }
    else {
      return state
    }
  }

  function byUnreadCountData(state = {}, action) {
    if (action.type === 'FETCHED_UNREAD_COUNT') {
      // console.log (' action.payload in fetched unread: ', action.payload)
      const normalizedApp = normalize(action.payload, lastSeenListSchema);
      if (normalizedApp.entities.lastSeen)
        return merge({},state,normalizedApp.entities.lastSeen.undefined) 
      else return state    
    }
    if (action.type === 'UPDATED_USER_ONLINE') {
      console.log (' The data received from UPDATED_USER_ONLINE saga is: ', action.payload)
      let userId = getCurrUserId()
      let workroomId = action.payload.workroomId
      return {...state, 
        [workroomId]: 0
      }
    }
    if (action.type === 'EDITED_UNREAD_COUNT') {
      let workroomId = action.payload.workroomId
      return {...state, 
        [workroomId]: action.payload.count
      }
    }
    else {
      return state
    }
  }


function params(state = defaultAppParams, action) {
  if (action.type === 'SET_CURR_USER') { 
    return {...state, 
      currUser: action.payload,
    }
  }
  if (action.type === 'FETCHED_LAST_SEEN') { 
    return {...state, 
      lastSeenDataFetched: true,
      
    }
  }
  if (action.type === 'FETCHED_UNREAD_COUNT') { 
    return {...state, 
      unreadCountFetched: true,
      
    }
  }
    if (action.type === 'UPDATE_HEADER_TITLE') { 
      return {...state, 
        headerTitle: action.payload,
        
      }
  }else {
    return state
  }
}

const app = combineReducers({
    byId,
    byLastSeenData,
    byUnreadCountData,
    params
});

export default app;