import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const newTodo = () => ({
    id: v4(),
    text: "",
    value: "",
    createDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    updateDate: '',
    targetDate: '',
    finishDate: '',
  })

export const taskdetails = () => ({
    id: v4(),
    jobId: "",
    antId: "",
    activityId: "",
    taskDetails: null,
})

export const todoTaskDetails =() =>{
     let todo1 = newTodo()
     let newtaskdetail = {
         id: v4(),
         description :'',
         todos : [],
         lastSyncDate :'',
         maxUpdateDateForTodos :''
     }
    return newtaskdetail
}

export const approveTaskDetails=() => ({
        id: v4(),
        priority : '',
        customername : '',
        deadline :'',
        chargeable :''
    
})

Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const taskdetailsSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

  });

  