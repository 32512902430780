import React, { useState,useEffect, useRef} from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import { Input, Label, Icon, Button} from 'semantic-ui-react';
import {getJobPathLinkWithJobNum} from '../../../utilities/pathUtils'
import { getJobByJobKeyNumber} from '../data/selectors'
import { fetchTaskdetailsByJob} from '../../taskdetails/data/actions'



const CopyTaskStepsFromJobTextBox = ({callbackfn}) => {
    const [jobNumber, setJobNumber ] = useState()
    const [jobIdToCopyFrom, setJobIdToCopyFrom] = useState ()
    const [jobNumInTextBox, setJobNumInTextBox ] = useState()
    
    const dispatch = useDispatch()
    const refCopyFromTaskTextbox = useRef(null)

    const job = useSelector ( state => getJobByJobKeyNumber ( state, jobNumber))

    useEffect(() => {
        if (job) {
            setJobIdToCopyFrom (job.id)
        } 
    }, [job])

    useEffect(() => {
        console.log(' jobIdToCopyFrom is ', jobIdToCopyFrom)
        if (jobIdToCopyFrom) {
            dispatch (fetchTaskdetailsByJob ({'jobId': jobIdToCopyFrom}))
            callbackfn (jobIdToCopyFrom)
            
        } 
    }, [jobIdToCopyFrom])


    const keyPressed = (event)=> {
        /* if (event.key === "Enter") {
            let jobkey = event.target.value
            console.log(' the task number to copy from is :', jobkey)
            setJobNumber('job-' + jobkey)
        } */
        console.log('setting job umber :', jobNumInTextBox)
        setJobNumber ('job-' + jobNumInTextBox)
      }
        
        return (
<>
            <Button  size="medium" color="lightgrey" className="CustomeBTN marginTopTen " onClick={keyPressed}>
            Copy from Job
        </Button>
            <Input
               /*  action={{
                color: 'lightgrey',
                // icon: 'copy',
                'pointer-events' : 'none',
                content: 'Copy from task',
                }} */
                type="number"  
                size='small'
                className = 'marginTopTen jobNav'
                // actionPosition='left'
                placeholder='Enter Job No..'
                onChange ={(e) => setJobNumInTextBox(e.target.value)}
                // onKeyPress={keyPressed}
            />
            
       </>    
        )
    

}


export default withRouter(CopyTaskStepsFromJobTextBox);
