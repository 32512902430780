import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchWorkroom, deleteWorkroom, setNotifyDone } from "../data/actions";
import * as select from "../data/selectors";
import { Container, Tab, Grid, TableBody, Header } from "semantic-ui-react";
import userACL from "../../../store/access";
import Notification from "../../../utilities/notificationUtils";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { updateHeaderTitle } from "../../app/data/actions";
import { getClientNameFromId } from "../../client/data/selectors";
import { getTeamNameFromId } from "../../team/data/selectors";
// import JobsPriorityListByTeam from '../../jobs/components/jobsListByTeam'
import JobsPriorityByTeam from "../../jobs/components/jobsPriorityByTeam";
import TeamUsersList from "./teamUsersList";
import TeamRightMenu from "./teamRightMenu";
import TeamWorkroomsList from "./teamWorkroomsList";
import TeamAssignUser from "./teamAssignUser";
import TeamAssignDeadline from "./teamAssignDeadline";
import TeamJobsListForEdit from "./teamJobsListForEdit";

const TeamDispContainer = (props) => {
  const teamId = props.match.params.id;

  const teamName = useSelector((state) => getTeamNameFromId(state, teamId));

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");
  const [currActiveIndex, setCurrActiveIndex] = useState(0);

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  });

  useEffect(() => {
    let path = props.match.path;
    let hash = props.location.hash;
    //  console.log (' In workroomDispContainer props is ', path)
    if (path == "/team/edit/:id") {
      setCurrActiveIndex(0);
    }
    if (path == "/team/jobs/edit/:id") {
      setCurrActiveIndex(1);
    }
    if (path == "/workroom/view/:sname" && hash == "#comments") {
      // console.log (' the workroom id is ', workroomId)
      setCurrActiveIndex(2);
    }
  }, [props.location.pathname, props.location.hash]);

  const panes = [
    {
      menuItem: "Update Priority",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <JobsPriorityByTeam teamId={teamId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assign User",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <TeamAssignUser teamId={teamId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Set Deadline",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <TeamAssignDeadline teamId={teamId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Edit Ticket",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <TeamJobsListForEdit teamId={teamId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Team",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <TeamUsersList teamId={teamId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Workrooms",
      render: () => (
        <Tab.Pane className="borderNoneObj">
          <TeamWorkroomsList teamId={teamId} />
        </Tab.Pane>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (teamName) {
      dispatch(updateHeaderTitle({ title: teamName }));
      document.title = "Team: " + teamName;
    }
  }, [teamName]);

  // Loading Logic if tasks not fetched
  return (
    <Container>
      <Grid columns={8} divided columns="equal">
        <Grid.Column
          style={{ height: innerHeights - 120 + "px" }}
          className="borderNoneObj paddingTopZero"
        >
          <Tab
            menu={{
              secondary: true,
              pointing: true,
              borderless: true,
              attached: true,
            }}
            panes={panes}
            className="borderNoneObj"
          />
        </Grid.Column>

        {/* <TeamRightMenu {...props}/> */}
      </Grid>
    </Container>
  );
};

export default TeamDispContainer;
