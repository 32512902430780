import React from 'react';
import { Route, withRouter } from 'react-router';
import ScheduledProcessList from "./components/scheduledprocessList";
import ScheduledProcessForm from "./components/scheduledprocessForm";
import { Container } from 'semantic-ui-react'


const ScheduledProcess = () => {
    return (
        <Container>
            <Route path="/scheduledprocess/list" component={(ScheduledProcessList)} />
            <Route path="/scheduledprocess/edit/:id" component={ScheduledProcessForm} />
            <Route path="/scheduledprocess/create" component={ScheduledProcessForm} />
        </Container>
    )
};

export default ScheduledProcess;

