import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsAppFetched, getLastSeenDataFetched } from './selectors'
import {savedApp,fetchedApp, storeSearchAppResult, deletedApp, fetchedLastSeen, fetchedUnreadCount} from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {getHeaderJWT, URL, URL_NOTIFICATION }  from '../../../store/path'
import { getStompClient } from '../../../store/configureWebSocket'

// const delay = (ms) => new Promise(res => setTimeout(res, ms))


//#region Saga for Fetch last Seen 

function* fetchLastSeen() {
  yield takeEvery('FETCH_LAST_SEEN',requestLastSeen)
}
  

function* requestLastSeen(action){
  const isLastSeenFetched = yield select(getLastSeenDataFetched);
  console.log (' getlastseen data is ', isLastSeenFetched)
  if(!isLastSeenFetched){
    try {
        let { response, error } = yield call(requestLastSeenAPI, action.payload)
        if ( response ) {
          yield put (fetchedLastSeen(response.data))
        } 
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
  }
}


function requestLastSeenAPI(data) {
  let userId = data.userId
  let queryURL = URL_NOTIFICATION+'/get-last-seen/' + userId
  console.log (' Last seen usrl is :', queryURL)
  return axios.get(queryURL, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion



//#region Saga for Fetch Unread Count 

function* fetchUnreadCount() {
  yield takeEvery('FETCH_UNREAD_COUNT',requestUnreadCount)
}
  

function* requestUnreadCount(action){
  const isLastSeenFetched = yield select(getLastSeenDataFetched);
  console.log (' requestUnreadCount data is ', isLastSeenFetched)
  if(!isLastSeenFetched){
    try {
        let { response, error } = yield call(requestUnreadCountAPI, action.payload)
        if ( response ) {
          yield put (fetchedUnreadCount(response.data))
        } 
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
  }
}


function requestUnreadCountAPI(data) {
  let userId = data.userId
  let queryURL = URL_NOTIFICATION+'/get-unread-count/' + userId
  console.log (' Get unread Count is :', queryURL)
  return axios.get(queryURL, getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion




//#region Saga for Edit last Seen 

function* editLastSeen() {
  yield takeEvery('EDIT_LAST_SEEN',requestEditLastSeen)
}
  

function* requestEditLastSeen(action){
    try {
        /* let { response, error } = yield call(requestEditLastSeenAPI, action.payload)
        if ( response ) {
          yield put (fetchedLastSeen(response.data))
        } 
        else {
          yield put (handlError(action, parseError(error)))
          sagaErrorMessage(error,action)  
        } */
        let data = action.payload
        const stompClient = getStompClient() 
        console.log (' %%%%% Sending last seen message to web socket %%%%%%%', data)
       /*  stompClient.send("/update-last-seen", {}, JSON.stringify({
          'userId' : data.userId,
          'workroomId' : data.workroomId
        }));  */
        stompClient.send("/app/update-last-seen", {}, JSON.stringify(data)); 
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
}


function requestEditLastSeenAPI(data) {
  const stompClient = getStompClient() 
  console.log (' %%%%% Sending last seen message to web socket %%%%%%%')
  stompClient.send("/update-last-seen", {}, JSON.stringify({
    'userId' : data.userId,
    'workroomId' : data.workroomId
  })); 
}

//#endregion


//#region Saga for Update User Online 

function* updateUserOnline() {
  yield takeEvery('UPDATE_USER_ONLINE',requestUpdateUserOnline)
}
  

function* requestUpdateUserOnline(action){
    try {
        let data = action.payload
        let stompClient = getStompClient()
        if (stompClient == null ) {
          yield delay(2000)
          stompClient = getStompClient()
        
        }
        console.log (' %%%%% Stomclient %%%%%%%', stompClient) 
        console.log (' %%%%% Sending requestUpdateUserOnline to web socket %%%%%%%', data)
        stompClient.send("/app/update-user-online", {}, JSON.stringify(data)); 
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
}


function requestUpdateUserOnlineAPI(data) {
  const stompClient = getStompClient() 
  console.log (' %%%%% Sending last seen message to web socket %%%%%%%')
  stompClient.send("/update-last-seen", {}, JSON.stringify({
    'userId' : data.userId,
    'workroomId' : data.workroomId
  })); 
}

//#endregion



const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* appSaga() {
    yield all([
        fetchLastSeen(),
        fetchUnreadCount(),
        updateUserOnline(),
        editLastSeen()
    ])
  }  