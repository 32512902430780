/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import { useDispatch } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell,
  TableRow,
  TableCell,
  Input,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getJobPathLink } from "./pathUtils";
import { getIconColour, getIconName } from "./iconUtils";
import { copyToClipboardTable, copyToClipboardCell } from "./listUtils";
import PopUp from "./popUp";

export const TableList = ({ columns, data, Actions, fn }) => {
  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, defaultColumn }, useFilters);

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <>
        <Input
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          placeholder={`Search ${count} records...`}
        />
      </>
    );
  }
  return (
    <Table className="padding55" basic="very" selectable>
      {headerGroups.map((headerGroup) => (
        <TableHeader {...headerGroup.getHeaderGroupProps()}>
          <Table.Row style={{ verticalAlign: "top" }}>
            <TableHeaderCell>Sl No</TableHeaderCell>
            {headerGroup.headers.map((column) => (
              <TableHeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </TableHeaderCell>
            ))}
            {Actions && <TableHeaderCell>Action</TableHeaderCell>}
          </Table.Row>
        </TableHeader>
      ))}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              <TableCell>{parseInt(row.index) + 1}</TableCell>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              <TableCell>
                {Actions ? (
                  <Actions object={row.cells[0].row.original} fn={fn} />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const BasicTableList = ({ columns, data, Actions, fn }) => {
  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, defaultColumn }, useFilters);
  const dispatch = useDispatch();

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <Input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  return (
    <Table basic="very" selectable collapsing>
      {headerGroups.map((headerGroup) => (
        <TableHeader {...headerGroup.getHeaderGroupProps()}>
          <Table.Row style={{ verticalAlign: "top" }}>
            <TableHeaderCell>Sl No</TableHeaderCell>
            {headerGroup.headers.map((column) => (
              <TableHeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </TableHeaderCell>
            ))}
            <TableHeaderCell>Action</TableHeaderCell>
          </Table.Row>
        </TableHeader>
      ))}
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              <TableCell>{parseInt(row.index) + 1}</TableCell>
              {row.cells.map((cell, index) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {" "}
                    {cell.render("Cell")}
                  </TableCell>
                );
              })}
              <TableCell>
                {Actions ? (
                  <Actions object={row.cells[0].row.original} fn={fn} />
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const JobTableList = ({ columns, data, Actions, fn, HeaderLinks }) => {
  // const defaultColumn = React.useMemo(() => ({Filter: DefaultColumnFilter,}),[])
  const {
    getTableProps,
    getTableBodyProps,
    state,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const [tableBodyHeight, setTableBodyHeight] = useState("");
  const listRef = useRef();

  useLayoutEffect(() => {
    const headerHeight = document.getElementById("headerTitle")?.clientHeight;
    const tableHeader = document.getElementById("tableHeader")?.clientHeight;
    const windowHeight = window.innerHeight;
    const height = windowHeight - (headerHeight + tableHeader) - 80;
    setTableBodyHeight(height);
  }, []);

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      if (!row) {
        return null;
      }
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tableBodyRow"
        >
          {row.cells.map((cell) => {
            let str = `${cell.row.original.jobKey}:${cell.row.original.jobName}`;
            if (cell.column.Header === "Ticket" || cell.column.Header === "Job")
              return (
                <>
                  <div
                    {...cell.getCellProps()}
                    style={{ padding: "0.5em" }}
                    className={cell.column.className}
                  >
                    <PopUp
                      msg={str}
                      basic
                      size={"small"}
                      onClick={copyToClipboardCell}
                      style={{ fontSize: "12px" }}
                    />
                    <Link
                      to={{
                        pathname: getJobPathLink(cell.row.original),
                      }}
                      title={cell?.value || ""}
                    >
                      {cell.render("Cell")}
                    </Link>
                  </div>
                </>
              );
            else
              return (
                <div
                  {...cell.getCellProps()}
                  style={{ padding: "0.5em" }}
                  className={cell.column.className}
                  title={cell?.value || ""}
                >
                  {cell.render("Cell")}
                </div>
              );
          })}
          {Actions && (
            <div className="flex1">
              <Actions object={row.cells[0].row.original} fn={fn} />
            </div>
          )}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <div {...getTableProps()}>
      <Table basic="very" id="tableHeader" style={{ marginBottom: "0px" }}>
        {headerGroups.map((headerGroup, index) => (
          <TableHeader {...headerGroup.getHeaderGroupProps()}>
            <div style={{ display: "flex", padding: "10px 5px" }}>
              <div
                // colSpan={visibleColumns.length - 1}
                style={{ textAlign: "left", flex: 1 }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  rows={rows}
                />
              </div>

              {HeaderLinks ? (
                <div colSpan={1} style={{ textAlign: "left" }}>
                  <HeaderLinks />
                </div>
              ) : null}
              {Actions ? <div></div> : <></>}
            </div>
            <div
              key={index}
              className="tableBodyHeader"
              style={{
                width: "99.1%",
              }}
            >
              {headerGroup.headers.map((column, i) => (
                <div
                  key={i}
                  {...column.getHeaderProps()}
                  className={column.headerClassName}
                  style={{ padding: "0.5em" }}
                >
                  {column.render("Header")}
                </div>
              ))}
              {Actions && <div>Action</div>}
            </div>
          </TableHeader>
        ))}
      </Table>
      {/* <div style={{ height: `${tableBodyHeight}px`, overflow: "scroll" }}> */}
      {/* {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let str = `${cell.row.original.jobKey}:${cell.row.original.jobName}`;
                    if (
                      cell.column.Header === "Ticket" ||
                      cell.column.Header === "Job"
                    )
                      return (
                        <>
                          <TableCell
                            {...cell.getCellProps()}
                            style={{ padding: "0.5em" }}
                          >
                            <PopUp
                              msg={str}
                              basic
                              size={"small"}
                              onClick={copyToClipboardCell}
                              style={{ fontSize: "12px" }}
                            />
                            <Link
                              to={{
                                pathname: getJobPathLink(cell.row.original),
                              }}
                            >
                              {cell.render("Cell")}
                            </Link>
                          </TableCell>
                        </>
                      );
                    else if (cell.column.Header === "Status")
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          style={{ padding: "0.5em" }}
                        >
                          <Icon
                            name={getIconName(cell.row.original.taskStatus)}
                            color={getIconColour(cell.row.original.taskStatus)}
                            size="large"
                          />
                        </TableCell>
                      );
                    else if (cell.column.Header === "Job Status")
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          style={{ padding: "0.5em" }}
                        >
                          <Icon
                            name={getIconName(cell.row.original.jobStatus)}
                            color={getIconColour(cell.row.original.jobStatus)}
                            size="large"
                          />
                        </TableCell>
                      );
                    else
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          style={{ padding: "0.5em" }}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                  })}
                  <TableCell>
                    {Actions ? (
                      <Actions object={row.cells[0].row.original} fn={fn} />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              );
            })} */}
      {rows.length ? (
        <Table basic="very" style={{ marginTop: "0px" }}>
          <TableBody {...getTableBodyProps()}>
            <VariableSizeList
              height={parseFloat(tableBodyHeight)}
              itemCount={data.length}
              itemSize={() => 35}
              width={"100%"}
              ref={listRef}
              className="rowGroupContentChild"
              style={{ overflow: "hidden scroll" }}
            >
              {RenderRow}
            </VariableSizeList>
          </TableBody>
        </Table>
      ) : (
        ""
      )}
      {/* </div> */}
    </div>
  );
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  rows,
}) {
  const count = preGlobalFilteredRows.length;

  const jobKeys = rows.reduce((accum, row) => {
    let str = ` ${row.original.jobKey}: ${row.original.jobName}\n`;
    return (accum += str);
  }, "");

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="fontBold">
      {/* <Icon
        // style={{ position: "absolute", right: "3%", top: "1.5%" }}
        name="copy outline"
        basic
        size="large"
        // color="green"
        onClick={() => {
          // copyToClipboardTable(jobKeys);
        }}
      /> */}
      <PopUp
        msg={` List of ${count} Job Keys`}
        msgStr={jobKeys}
        basic
        size={"small"}
        onClick={copyToClipboardTable}
        style={{ fontSize: "15px" }}
      />{" "}
      Search:{" "}
      <Input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        transparent
        style={{ fontSize: "1.2rem", width: "90%" }}
      />
    </span>
  );
}
