

export const CREATE_WORKROOM = 'CREATE_WORKROOM'
export const SAVED_WORKROOM = 'SAVED_WORKROOM'
export const EDIT_WORKROOM = 'EDIT_WORKROOM'
export const EDITED_WORKROOM = 'EDITED_WORKROOM'
export const FETCH_WORKROOM = 'FETCH_WORKROOM'
export const FETCHED_WORKROOM = 'FETCHED_WORKROOM'

export const FETCH_MY_LIST_WORKROOM = 'FETCH_MY_LIST_WORKROOM'
export const FETCHED_MY_LIST_WORKROOM = 'FETCHED_MY_LIST_WORKROOM'

export const FETCH_USERS_IN_WORKROOM = 'FETCH_USERS_IN_WORKROOM'
export const FETCHED_USERS_IN_WORKROOM = 'FETCHED_USERS_IN_WORKROOM'

export const SEARCH_WORKROOM = 'SEARCH_WORKROOM'
export const STORE_SEARCH_WORKROOM_RESULT = 'STORE_SEARCH_WORKROOM_RESULT'
export const DELETE_WORKROOM = 'DELETE_WORKROOM'
export const DELETED_WORKROOM = 'DELETED_WORKROOM'

export const ARCHIVE_WORKROOM = 'ARCHIVE_WORKROOM'
export const ARCHIVED_WORKROOM = 'ARCHIVED_WORKROOM'
export const RESTORE_WORKROOM = 'RESTORE_WORKROOM'
export const RESTORED_WORKROOM = 'RESTORED_WORKROOM'
export const NOTIFICATION_DONE_WORKROOM = 'NOTIFICATION_DONE_WORKROOM'

export const FILTER_ITEMS = 'FILTER_ITEMS'
