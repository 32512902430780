import React, { useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTaskDetailsByJobActivityTask, getTaskDetailsByJobStartForm } from '../data/selectors'
import { Container, Icon, Button, Form, Checkbox, Message, Label } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikCheckBoxComponent } from '../../../utilities/formUtils';
import { heightSet } from '../../../utilities/heightForListing'
import { todoTaskDetails, newTodo } from '../../taskdetails/data/model'
import { editTaskdetails } from '../data/actions'
import { cloneDeep, isEqual } from 'lodash';
import { updateTaskComplete, updateTaskStatus } from '../../tasks/data/actions'
import { parseJwt } from '../../../utilities/parseJWT'
import { getActiveTask, getTasksByJobId } from '../../tasks/data/selectors'
import { getJobPathLink } from '../../../utilities/pathUtils'
import { getStateMachine, getActions , enumStates } from '../data/state';
import v4 from 'uuid';
import moment from 'moment'

const TemplateTaskToDo = (props) => {
    const [heights, setHeight] = useState('')
    const [savedStepId, setSavedstepId] = useState(false)
    const [todoTasks, setTodoTasks] = useState(todoTaskDetails())
    const [clickDone, setClickDone] = useState()
    const [checkAllText, setCheckAllText] = useState('Check All')
    const [currTaskStatus, setCurrTaskStatus] = useState()

    const dispatch = useDispatch()

    const token = localStorage.getItem('user')
    const userId = token? parseJwt(token).identity : null

    const job = props.job ? props.job : undefined
    const jobId = props.job ? props.job.id : undefined
    const activityId = props.activityId
    const editMode = props.editMode
    const callBackParent = props.callBack // function
    const currActiveTask = useSelector (state => getActiveTask (state, jobId))
    const taskId = currActiveTask ? currActiveTask.id : undefined
    const currTaskUserId = currActiveTask.userId
    // const currTask = (props.currActiveTask.id == taskId ? true : false)
    const isPrimary = (currActiveTask["secondary"] == null ? true : false)
    const setShowSyncButton = props.setShowSyncButton
    const isReadOnly = props.isReadOnly
    const syncMode = props.syncMode
    const refAllCheck = useRef(null)
    const [stateMachine, setStateMachine] = useState(null)
    const [actions, setActions] = useState(null)

    const taskdetails = useSelector(state => getTaskDetailsByJobActivityTask(state, jobId, activityId, taskId))
    const startFormTaskDetails = useSelector(state => getTaskDetailsByJobStartForm(state, jobId))

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function () {
            heightSet(setHeight)
        }, false);
    })


    useEffect(() => {
        setCurrTaskStatus(currActiveTask.taskStatus)
        setActions(getActions(currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
    }, [currActiveTask])
    
    useEffect(() => {
        setActions(getActions(currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
        if(job && currActiveTask)
            if (job.ownerId == userId || currTaskUserId == userId)
                setStateMachine(getStateMachine(job.jobKey, currActiveTask.taskStatus, isPrimary, job.ownerId == userId, currTaskUserId == userId))
    }, [job, currActiveTask])


    function getParsedTaskDetailsObject(tobj) {
        if (!tobj || !tobj.todos) return undefined
        let obj = cloneDeep(tobj)

        obj.todos = JSON.parse(tobj.todos)
        let allchecked = true
        let tobjclicksobj = Object.assign({}, ...obj.todos.map(item => {
            return { [item.id]: item.value }
        }))
        setClickDone(tobjclicksobj)
        // setCheckAllText (allchecked ? 'Uncheck All' : 'Check All')

        // console.log (' Clickdone arr is ', tobjclicksobj)
        // obj.id = v4()
        return (obj)
    }


    function isOutOfSyncWithStartTemplate(startObj, taskObj) {
        // console.log('---- Startobj is ', startObj)
        // console.log('---- Taskobj is ', taskObj)
        if (startObj && taskObj) {
            let startTodos = startObj.todos
            let taskTodos = taskObj.todos

            if (startTodos.length != taskTodos.length)
                return false
            // this bit checks the last synced date with the max update date for start form and
            // if last sync date is less , then it decides it is out of sync. Technically we could have just 
            // done with checking if the text is equal (the condition below), but this was put in later
            if (taskTodos.lastSyncDate && startTodos.maxUpdateDateForTodos) {
                let lastsyncdate = moment(taskTodos.lastSyncDate, 'YYYY-MM-DD HH:mm:ss')
                let lastupdatedate = moment(taskTodos.maxUpdateDateForTodos, 'YYYY-MM-DD HH:mm:ss')
                if (lastsyncdate < lastupdatedate)
                    return false
            }
            let startTodosText = startTodos.map(item => item.text)
            let taskTodosText = taskTodos.map(item => item.text)
            return (isEqual(startTodosText, taskTodosText))

        }
        return true
    }

    useEffect(() => {
        let obj, startObj, taskObj
        if (taskdetails == undefined) {
            if (startFormTaskDetails == undefined)
                obj = (todoTaskDetails())
            else {
                obj = getParsedTaskDetailsObject(startFormTaskDetails)
                startObj = obj
            }
        } else {
            obj = getParsedTaskDetailsObject(taskdetails)
            startObj = getParsedTaskDetailsObject(startFormTaskDetails)
            taskObj = obj
        }
        if (!isReadOnly) {
            let sync = isOutOfSyncWithStartTemplate(startObj, taskObj)
            console.log(' Sync is ', sync)
            if (sync)
                setShowSyncButton(false)
            else
                setShowSyncButton(true)
        }
        obj.id = v4()
        setTodoTasks(obj)

    }, [taskdetails, startFormTaskDetails])


    useEffect(() => {
        if (syncMode) {
            // do syncing up 
            console.log(' syncing up ')
            let taskObj = getParsedTaskDetailsObject(taskdetails)
            //    console.log (' syncing up taskobj currently is ', taskObj)
            let startObj = getParsedTaskDetailsObject(startFormTaskDetails)
            let startTodos = startObj.todos
            let taskTodos = taskObj.todos
            let nowdate = moment().format('YYYY-MM-DD HH:mm:ss')

            let newTaskTodos = startTodos.map((item, i) => {
                let taskText = taskTodos[i] ? taskTodos[i].text : ""
                let startText = item.text
                if (startText == taskText) {
                    return taskTodos[i]
                } else {
                    let newtodo = newTodo()
                    newtodo.text = startText
                    newtodo.value = 0
                    newtodo.targetDate = item.targetDate
                    newtodo.createDate = item.createDate
                    newtodo.updateDate = nowdate
                    newtodo.finishDate = ''
                    return newtodo
                }
            })
            taskObj.lastSyncDate = nowdate
            taskObj.todos = newTaskTodos
            saveTaskDetail(taskObj)
            callBackParent()
        }
    }, [syncMode])

    // helper function for the checkbox clicks
    const changeClickValues = (obj) => {
        let id = obj.id
        let newobj = { ...clickDone, [id]: obj.value }
        return newobj
    }

    const saveTaskDetail = (newTodos) => {
        let newarray = newTodos.todos

        let str = JSON.stringify(newarray)
        newTodos.todos = str
        let obj = {
            jobId: jobId,
            activityId: activityId,
            antId: activityId + '#' + taskId,
            taskDetails: newTodos
            // taskDetails : {'description': newTodos.description, 'todos' : newTodos.todonewTodos{'description': newTodos.description, 'todos' : newTodos.todos}
        }
        console.log(' Start form todos: ', obj)
        dispatch(editTaskdetails(obj))
    }

    const saveActivity = (values, actions) => {
        saveTaskDetail(values)
        callBackParent()
    }

    const completeTask = () => {
        let obj = {
            'id': taskId,
            'processId': props.job.processId,
            'variables': []
        }
        dispatch(updateTaskComplete(obj))
        setTimeout(function () {
            props.history.push({ pathname: getJobPathLink(props.job) })
        }, 2000);
    }

    const triggerAction = (action) => {
        let nextState = stateMachine.send(action.action)
        console.log("nextState", nextState.context.stateId)
        if (action.targetState == 0) 
            completeTask()
        else {
            let obj = {
                'id': taskId,
                'targetStateId': action.id
            }
            dispatch(updateTaskStatus(obj))
            setCurrTaskStatus(action.targetState)
        }
    }

    const clickAllTasks =(e, data) =>{
        if (!editMode) return
        let finishdate = moment().format('YYYY-MM-DD HH:mm:ss')
        todoTasks.todos.map((obj) => {
        if (data.checked) {
            obj.value = "1"
            obj.finishDate = finishdate
        } else {
            obj.value = "0"
            obj.finishDate = ''
        }
        })
        let tobjclicksobj = Object.assign({}, ...todoTasks.todos.map(item => {
            return { [item.id]: item.value }
        }))
        setCheckAllText (data.checked ? 'Uncheck All' : 'Check All')
        setClickDone(tobjclicksobj)
    }

    // const activity = useSelector(state => getActivityById(state, activityId, processId))
    const summary = {}
    const tasksDefined = Array.isArray(todoTasks.todos) && (todoTasks.todos[0] && todoTasks.todos[0].text != "")
    return (
        <>
        {
             tasksDefined &&
             <Checkbox label={checkAllText}
              disabled={!editMode} 
              ref = {refAllCheck}
              onClick = {(e, data) => clickAllTasks(e, data)}
             />

        }
            <Formik id="activityForm" size="large" width={6}
                enableReinitialize
                initialValues={todoTasks}
                // validationSchema={activitySchema}
                onSubmit={(values, { resetForm }) => saveActivity(values, resetForm)}
                render={({ handleSubmit, onChange, values, handleChange, setFieldValue }) => (
                    <Form as={FormikForm} size="medium" className="CustomeForm borderNoneObj" width={6} onSubmit={handleSubmit}>
                        {
                            tasksDefined &&
                            todoTasks.todos.map((obj, index) => (
                                <Container key={index}>
                                    <Field name={obj.id} id={obj.id} label={obj.text} inline
                                        className='fontColorBlack'
                                        component={FormikCheckBoxComponent}
                                        disabled={!editMode}
                                        // checked = {(obj.value == "1")? true: false}
                                        userProps={{ 'value': obj.value }}

                                        onChangeFunc={(e) => {
                                            if (obj.value == "0" || obj.value == "") {
                                                obj.value = "1"
                                                obj.finishDate = moment().format('YYYY-MM-DD HH:mm:ss')
                                            } else {
                                                obj.value = "0"
                                                obj.finishDate = ""
                                            }

                                            setClickDone(changeClickValues(obj))
                                        }}
                                    />
                                    { obj.finishDate &&
                                        <Label basic color='grey' className=' borderNoneObj paddingZero labelNormal newStep' size='small'>Completed: {moment(obj.finishDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}</Label>
                                    }
                                </Container>
                            ))
                        }

                        {/* <Field  name="description" disabled = {!editMode} className="marginTopTen" label = "Description/ Notes" userProps={{'height' : 7}}  component={FormikTextAreaComponent}></Field> */}
                        <Message className=" minHeight40 messageTransparent paddingLeft0">
                            {/* {  todoTasks.description && */}
                            <div className=' marginTop5 marginBottom15' >
                                {/* <Message.Header >Description : </Message.Header> */}
                                {/*  <div className=' borderNoneObj borderBottomObj' >
                                        <Label basic className=' paddingLeft5 borderNoneObj labelNormal' size='huge'>Description</Label>
                                        </div> */}
                                <div className=' borderNoneObj borderBottomObj' >
                                    <Label basic className=' marginTop5  borderNoneObj paddingZero labelNormal' size='huge'>
                                        <Icon name='sticky note' color='grey' size='small' className='borderNoneObj' />
                                            Description
                                         </Label>
                                </div>
                                <Message.Content className=' marginTop5 '>
                                    <div dangerouslySetInnerHTML={{ __html: todoTasks.description }} />
                                </Message.Content>
                            </div>
                            {/* } */}
                        </Message>
                        {console.log("taskId", taskId)}
                        {!isReadOnly && editMode &&
                            <Button type="submit" size="medium grey" color="blue" className="CustomeBTN"> Save </Button>
                        }
                        {   !isReadOnly && !editMode && actions ?
                            actions.map(action=>{
                                if (action.action)
                                    return <Button type="button" floated={action.align} size="medium" color={action.color} className="CustomeBTN " onClick={()=>triggerAction(action)}> {action.action} </Button>
                                return null
                            })
                            : null
                        }
                    </Form>
                )}
            />
        </>
    )
}

export default TemplateTaskToDo;
