import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { processfile } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingProcessfile = (state, props) => state.processfile.params.isFetching;
export const getIsProcessfileFetched = (state, props) => state.processfile.params.processfileFetched;
export const getProcessfileList = (state, prop) => state.processfile.byId
export const getIsSearching = (state, props) => state.processfile.params.isSearching;
export const getIsSearchFetched = (state, props) => state.processfile.params.searchFetched;
export const getSearchResults = (state, prop) => state.processfile.searchResults
export const filters = (state, prop) =>  state.processfile.filterResults ;
export const getDeleteStatus = (state, prop) => state.processfile.deleteStatus
export const getDelFetched = (state, prop) => state.processfile.params.isDelFetched
export const getNotification = (state, id) => state.processfile.notifications[id]

export const getProcessfile = (state, props) => {
    if(props.match.path==='/processfile/create'){
        return processfile()
    }
    if(props.match.path==='/processfile/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.processfile.byId[_id]);
        return obj        
    }
}

export const getProcessfileParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/processfile/create')){ 
        params.title = state.processfile.params.createTitle
        params.submitButtonText = state.processfile.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/processfile/edit/')){ 
        params.title = state.processfile.params.editTitle
        params.submitButtonText = state.processfile.params.editSubmitButtonText        
    };

    return params
}

export const getProcessFileByProcessKey=(state, processKey) =>{ 
    const processfiles = getProcessfileList(state)

    return (processfiles[processKey])
}

export const selectProcessfile = createSelector(
    getProcessfileList,
    processfile => { 
        const keys = Object.keys(processfile)
        const obj = keys.map( (key) => { return { key : key, value : key, text : processfile[key].name }})
        return obj
    })


export const selectProcessfileSearched = createSelector(
    getSearchResults,
    processfile => { 
        const keys = Object.keys(processfile)
        const obj = keys.map( (key) => { return { key : key, value : key, text : processfile[key].name }})
        return obj
    })


/* export const getFilteredProcessfile = createSelector(
    processfileSelector, filters,
    (processfile, filters) => {
        let filteredProcessfile =  Object.keys(processfile).map(function(key) {
            return processfile[key];
            })     
        filteredProcessfile = textFilter(filteredProcessfile, filters)
        filteredProcessfile = numberFilter(filteredProcessfile, filters)
        return filteredProcessfile
    }) */
    