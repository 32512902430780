import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Table,
  TableHeader,
  TableBody,
  Icon,
  Label,
  Input,
  Button,
} from "semantic-ui-react";
import { getUnreadCountForWorkroom } from "../../app/data/selectors";

const WorkroomObjForPanelList = ({
  workroomObj,
  pathToLink,
  processId,
  displayFields,
}) => {
  if (workroomObj === undefined) return undefined;
  const unreadcount = useSelector((state) =>
    getUnreadCountForWorkroom(state, workroomObj.id)
  );

  return (
    <Table.Row>
      <Table.Cell style={{ width: "90%" }}>
        <div>
          <Link
            to={{
              pathname: "/workroom/view/" + workroomObj.sname,
              workroomId: workroomObj.id,
            }}
          >
            {workroomObj.name}{" "}
          </Link>
          {unreadcount && unreadcount != 0 ? (
            <Label circular color="red" size="tiny">
              {unreadcount}
            </Label>
          ) : null}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const StepsListForWorkroomPanel = (props) => {
  let data = props.data === undefined ? [] : props.data;
  const [showInput, setShowInput] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");

  const filteredArray = props.data.filter((workroom) => {
    return workroom.name.toLowerCase().includes(searchedValue.toLowerCase());
  });
  // console.log(filteredArray);

  const arrayFiltered = !searchedValue ? props.data : filteredArray;

  return (
    <Container className="padding70">
      <Table selectable basic="very" style={{ width: "100%" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left">
              <div>
                <>
                  <Input
                    placeholder="Search..."
                    style={{ fontSize: "0.9rem", marginRight: "15px" }}
                    value={searchedValue}
                    onChange={(e) => setSearchedValue(e.target.value)}
                  />
                </>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <TableBody>
          {Object.values(arrayFiltered).map((value) => (
            <WorkroomObjForPanelList key={value.id} workroomObj={value} />
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default StepsListForWorkroomPanel;
