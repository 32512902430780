import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import { URL }from '../../../store/path'
import { debounce } from 'lodash'


export const steps = () => ({
    id: v4(),
    step_id: "",
    step_name: "",
    process_id: "",
    activity_id: "",
    rank: "",
    role: "",
    guidelines: "",
    on_create_status: "",
    on_start_status: "",
    on_wait_status: "",
    on_completed_status: "",
    on_closed_status: "",
    on_delete_status: "",
    txnid: "",
    segid: "",
    cid: "",
    status: 1,
    dt_create: "",
    dt_update: "",
    uid_create: "",
    uid_update: ""
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const stepsSchema = Yup.object().shape({

    id: Yup.string()
        .required('This is a Required Field'),

    step_name: Yup.string()
        .required('This is a Required Field'),

    // status: Yup.string()
    //     .required('This is a Required Field'),

    // role: Yup.string()
    //     .required('This is a Required Field'),

    rank: Yup.string()
        .required('This is a Required Field'),
    
    // guidelines: Yup.string()
    //     .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dt_create: Yup.string().nullable(),
    dt_update: Yup.string().nullable(),
    user_create: Yup.string().nullable(),
    user_update: Yup.string().nullable()

  });


export const duplicateCheckStepsCode = debounce(checkStepsCode, 500);
function checkStepsCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/steps/exists/'+ value, {type: 'checkCode'})
			.then(response => {
                if (response.data==='duplicate') {
                    //console.log("response",response.data)
                    return "Duplicate"
                }
            })
			.catch(error => "")
  }


export const duplicateCheckStepsName = debounce(checkStepsName, 500);
function checkStepsName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/steps/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            //console.log("response",response.data)
                            return "Duplicate"
                        } 
                    })   
            .catch(error => "") 
}
  