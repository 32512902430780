import * as type from './types'


export function createApp(app) {
    return {
        type : type.CREATE_APP,
        payload : app,
        txn : 'initiated',
    };
}


export function savedApp(app) {
    return {
        type : type.SAVED_APP,
        payload : app,
        txn : 'success',
        diff : true
    };
}


export function editApp(app) {
    return {
        type : type.EDIT_APP,
        payload : app,
        txn : 'initiated',
    };
}


export function fetchLastSeen(userId) {
    return {
        type : type.FETCH_LAST_SEEN,
        payload : userId,
    };
}


export function fetchedLastSeen(data) {
    return {
        type : type.FETCHED_LAST_SEEN,
        payload : data,
    };
}


export function fetchUnreadCount(userId) {
    return {
        type : type.FETCH_UNREAD_COUNT,
        payload : userId,
    };
}


export function fetchedUnreadCount(data) {
    return {
        type : type.FETCHED_UNREAD_COUNT,
        payload : data,
    };
}


export function editedUnreadCount(data) {
    return {
        type : type.EDITED_UNREAD_COUNT,
        payload : data,
    };
}


export function editLastSeen(data) {
    return {
        type : type.EDIT_LAST_SEEN,
        payload : data,
    };
}


export function editedLastSeen(data) {
    return {
        type : type.EDITED_LAST_SEEN,
        payload : data,
    };
}



export function updateUserOnline(data) {
    return {
        type : type.UPDATE_USER_ONLINE,
        payload : data,
    };
}


export function updatedUserOnline(data) {
    return {
        type : type.UPDATED_USER_ONLINE,
        payload : data,
    };
}



export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_APP,
        payload: props
    };
}

export function updateHeaderTitle(title) {
    return {
        type : type.UPDATE_HEADER_TITLE,
        payload: title
    };
}


export function setCurrentUser(userid) {
    return {
        type : type.SET_CURR_USER,
        payload : userid
    };
}
