import { createSelector } from 'reselect'
import { startsWith, cloneDeep, orderBy, chain, sortBy } from 'lodash';
import { team } from './model'
import { STATUS_CANCEL, STATUS_COMPLETED, STATUS_HOLD, STATUS_PARTIAL_DONE } from '../../app/data/staticData'
import { extractKeyFromJobKey } from '../../../utilities/pathUtils'
import { getCurrentUserId } from '../../app/data/selectors'

export const getIsFetchingTeam = (state, props) => state.team.params.isFetching;
export const getIsTeamFetched = (state, props) => state.team.params.teamFetched;
export const getTeamList = (state, prop) => state.team.byId
export const getIsSearching = (state, props) => state.team.params.isSearching;
export const getIsSearchFetched = (state, props) => state.team.params.searchFetched;
export const getSearchResults = (state, prop) => state.team.searchResults
export const filters = (state, prop) =>  state.team.filterResults ;
export const getDeleteStatus = (state, prop) => state.team.deleteStatus
export const getDelFetched = (state, prop) => state.team.params.isDelFetched
export const getNotification = (state, id) => state.team.notifications[id]

export const getTeam = (state, props) => {
    if(props.match.path==='/team/create'){
        return team()
    }
    if(props.match.path==='/team/edit/:id'){
        let id = props.match.params.id
        let obj = cloneDeep(state.team.byId[id]);
        return obj        
    }
}

export const getTeamNameFromId = (state, teamid) => {
    if (teamid == undefined )
        return ""
    let obj = state.team.byId[teamid]
   if(obj)
        return obj.teamName
    else    
        return ""
}

export const getListOfUsersFromTeam = (state, teamid) => {
    if (teamid == undefined )
        return []
    let obj = state.team.byId[teamid]
    let userData = state.users.byId
    let teamusers = obj.teamUsers
    const objUsers = teamusers.map( (key) => { 
        return userData[key.userId]

    })
    return objUsers
}

export const getJobsAndActiveTasksByTeamId = (state, tid) => {
    const allJobs = state.tasks.byTeamJob
    const workrooms = state.workroom.byId
    const users = state.users.byId
    const jobs = allJobs[tid]    
    if (!jobs) return undefined

    return chain(Object.values(jobs))
                        .filter(obj => obj.taskStatus!= 0)
                        .map((j, index) => {
                            let newObj = cloneDeep(j)
                            newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                            newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                            newObj["workroomName"] = workrooms ?  workrooms[j.workroomId]['name'] : null
                            return newObj
                        })                               
                        .orderBy([(item)=>(item.priority? parseInt(item.priority): null),(item)=> parseInt(extractKeyFromJobKey(item.jobKey))], ['asc','desc'])
                        .value();
}


export const getJobsAndActiveTasksByTeamIdAndWorkroomId = (state, tid, workroomid) => {
    const obj = state.tasks.byTeamJob
    const users = state.users.byId
    const arr = obj[tid]

    if (!arr) return undefined
    return chain(Object.values(arr))
            .filter(value =>{
                if (value.workroomId == workroomid && value.taskStatus != STATUS_COMPLETED && value.taskStatus != STATUS_CANCEL && value.taskStatus != STATUS_HOLD && value.taskStatus != STATUS_PARTIAL_DONE)
                return value    
            })
            .map((j, index) => {
                    let newObj = cloneDeep(j)
                    newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                    newObj.jobOwner = j.ownerId ? users[j.ownerId] ? users[j.ownerId].name : null : null
                    newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                    return newObj
                })
            .orderBy([(item)=>(item.priority? parseInt(item.priority): null),(item)=> parseInt(extractKeyFromJobKey(item.jobKey)), (item)=>item.dtCreate], ['asc','desc', 'asc'])
            .map((j, index) => {
                 j["Sl No"] = index + 1
                 return j
                })
            .value()
}

export const getTeamUserObjForUser = ( state, obj, userid ) => {
    let teamusers = obj.teamUsers
    let userobj = teamusers.find ((tuser) => tuser.userId == userid)
    return userobj
}


export const getListOfTeamUserObjsForUser = createSelector( getTeamList, getCurrentUserId, ( obj, userid) => {   
 
    const teamarr = Object.values(obj)
    if (teamarr.length == 0) return undefined

    let finalArr = []
    teamarr.map((obj) =>{
        let teamusers = obj.teamUsers
        let tuser = teamusers.find ((tuser) => tuser.userId == userid)
       if (tuser) {
            finalArr.push (tuser)
       }
    })
    finalArr = orderBy(finalArr, [(item)=>item.priority], ['asc']);
    return finalArr
})


export const getTeamParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/team/create')){ 
        params.title = state.team.params.createTitle
        params.submitButtonText = state.team.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/team/edit/')){ 
        params.title = state.team.params.editTitle
        params.submitButtonText = state.team.params.editSubmitButtonText        
    };

    return params
}

export const selectTeam = createSelector(
    getTeamList,
    team => { 
        const keys = Object.keys(team)
        const obj = keys.map( (key) => {
            if(team[key].status == 1){
            return { value : key, text : team[key].teamName, status : team[key].status, className: "selectLiCss" }
            }else{
                return false
            }
        })
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    })


export const selectTeamSearched = createSelector(
    getSearchResults,
    team => { 
        const keys = Object.keys(team) 
        const obj = keys.map( (key) => { return { value : key, text : team[key].teamName, className: "selectLiCss" }})
        return obj
    })


export const getTeamArray = createSelector(
    getTeamList, team => {
        return chain(Object.values(team)).sortBy((item)=> item.teamName).value()
    })
        