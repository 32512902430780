import React, { useEffect, useState }from 'react';
import { Link, Redirect} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getProcess, getNotification, getProcessParams, getIsProcessFetched } from '../data/selectors';
import {getActivityByProcessId} from '../../activities/data/selectors'
import Notification from '../../../utilities/notificationUtils'
import ProcessRightMenu from './processRightMenu'
import { Message } from 'semantic-ui-react'

const ProcessSummary = (props) => {

    const dispatch = useDispatch()
    let processName = "";
    let processId = "";

    processName = props.location.processName === undefined ? "" : props.location.processName;
    processId = props.location.processId === undefined ? "" : props.location.processId;


    return (
            <>

<Message>
    <Message.Header>Process Created</Message.Header>
    <Message.List>
      <Message.Item>Number of activities : 0</Message.Item>
      <Message.Item>Add the process file to create activities</Message.Item>
    </Message.List>
  </Message>     
            </>
    )
}

export default ProcessSummary;