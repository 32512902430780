import React, { useState,} from 'react';
import { withRouter } from 'react-router';
import { Input} from 'semantic-ui-react';
import {getJobPathLinkWithJobNum} from '../../../utilities/pathUtils'


const JumpToJobTextBox = (props) => {
    const [jobNumber, setJobNumber ] = useState()


    const keyPressed = (event)=> {
        if (event.key === "Enter") {
            let newlink = getJobPathLinkWithJobNum(jobNumber)
            props.history.push(newlink)
            setJobNumber('')
        }
      }
        
        return (
            <Input 
            fluid
             type="number"  
             placeholder='Go to job..'
             onKeyPress={keyPressed}
             value={jobNumber}
             size='large'
             className ='jobNav' // have created a small css change to disable up and down arrows in input text since type is number
             onChange ={(e) => setJobNumber(e.target.value)}

            />

        )
    

}


export default withRouter(JumpToJobTextBox);
