import React, { useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getWorkroom, getNotification, getWorkroomParams, getIsWorkroomFetched } from '../data/selectors';
import { createWorkroom, editWorkroom, setNotifyDone } from '../data/actions';
import { workroomSchema, duplicateCheckWorkroomName } from '../data/model'
import { Container, Header, Table, TableHeader, TableBody, Form, Button, Grid, Accordion } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import ProcessSelect from '../../process/components/processSelect' 
import TeamSelect from '../../team/components/teamSelect' 
import ClientSelect from '../../client/components/clientSelect' 

import userACL from '../../../store/access'
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'
import { updateHeaderTitle } from '../../app/data/actions'

const WorkroomForm = (props) => {
    const [heights, setHeight] = useState('')
    const [savedWorkroomId, setSavedWorkroomId] = useState(false)
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })
    // //console.log(savedWorkroomId, "==savedWorkroomId");
    
    const workroomFetched = useSelector(state=> getIsWorkroomFetched(state, props))
    // const workroomFetched = useSelector(state => select.getIsWorkroomFetched(state, props))

    const workroom = useSelector(state => getWorkroom(state, props))
    const params = useSelector(state => getWorkroomParams(state, props))
    const dispatch = useDispatch()
    let workroomName = "";
    let workroomId = "";
    
    if (props.match.path === '/workroom/edit/:id') {
        workroomName = workroom === undefined ? "" : workroom.name;
        workroomId = workroom === undefined ? "" : workroom.id;
    }
    

    useEffect(() => {
        if (props.match.path === '/workroom/edit/:id') {
            dispatch(updateHeaderTitle({ 'title': 'Workroom: ' + workroomName }))
        } else
            dispatch(updateHeaderTitle({ 'title': 'Create Workroom'}))
    }, [workroomName])

    const saveWorkroom = (values, resetForm) => {
        if (props.match.path === '/workroom/create') {
            console.log (' Adding a NEW WORKROOM ', values)
            // userACL.atCreate(values)
            dispatch(createWorkroom(values))
            setSavedWorkroomId(values.id)
            resetForm()
        }
        if (props.match.path === '/workroom/edit/:id') {
            // userACL.atUpdate(values)
            dispatch(editWorkroom(values))
            setSavedWorkroomId(values.id)
            setTimeout(function(){ 
                props.history.push({pathname: "/workroom/list", workroomId:values.id, workroomName: values.name})    
            }, 2000);
        }
    }

    return (
        <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column width={13} style={{height: (heights)+"px"}} className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                    <Formik id="workroomForm" size="large" width={5}
                        initialValues={workroom}
                        validationSchema={workroomSchema}
                        onSubmit={(values, { resetForm }) => saveWorkroom(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="large" className ="CustomeForm" width={5} onSubmit={handleSubmit}>
                                <Field style={{width: "65.5%"}} name="name" label= 'Name' required={true} component={FormikInputComponent}></Field>
                                <Field style={{width: "65.5%"}} name="sname" label= 'Short Name (less than 10 chars)' required={true} component={FormikInputComponent}></Field>
                                <Field style={{width: "65.5%"}} name="defaultPriority" label= 'Default Priority for New Jobs (Between 90-100)' component={FormikInputComponent}></Field>
                                <Form.Group widths={2}>
                                    <ClientSelect required={true} name= "clientid" required={true} label="Client"/>
                                    <TeamSelect required={true} name="teamid" required={true} label="Team"/>
                                </Form.Group>
                                
                                <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                {savedWorkroomId ? 
                                    <Notification id={savedWorkroomId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default WorkroomForm;