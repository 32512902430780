import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Notification = ({ id, notifySelector, type, notifyDoneAction }, props) => {
    const notifyObj = useSelector(state => notifySelector(state, id))    
    const dispatch = useDispatch()
    useEffect(()=>{
        if(id !== 0 && notifyObj){
            const options = {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true
            }
            const getNotObj = type === "save" ? notifyObj.save : type === "edit" ? notifyObj.edit : type === "delete" ? notifyObj.delete : {}
            if(getNotObj){
                toast[getNotObj.status]( getNotObj.msg, options );
                dispatch(notifyDoneAction({id, type}))
            }
        }
    })

    if (notifyObj){
    return (
        <ToastContainer />
    )} else return null
}


export const SimpleNotification = ({message}) => {
    const notify = () => toast.success( message, {
        position: "top-center",
        // z-index : 300,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    return (
      <div>
        {notify}
      </div>
    );
}

export default Notification;