import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FormikDateComponent,
  FormikTextAreaComponent,
} from "../../../utilities/formUtils";
import {
  Container,
  Table,
  TableHeader,
  TableBody,
  Button,
  TableRow,
  TableCell,
  Form,
  Icon,
  Input,
} from "semantic-ui-react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import {
  fetchJobsByTeamId,
  editJobs,
  updateJobDeadline,
} from "../../jobs/data/actions";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";
import { cloneDeep } from "lodash";
import { getInCompleteJobsByTeamIdforList } from "../../jobs/data/selectors";
import userACL from "../../../store/access";
import moment from "moment";
import { getWorkroomShortNameFromId } from "../../workRoom/data/selectors";
// import PopupJobActivities from'./popUpJobActivities'
import {
  getActivityByProcessId,
  getActivityTemplateByActivityId,
} from "../../activities/data/selectors";
import { JobTableList } from "../../../utilities/tableUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  checkValidDateFormats,
  getDateInYYYYMMDD,
} from "../../../utilities/dateUtils";

// const JobsTeam = ({ job, index, widths }) => {
//     const dispatch = useDispatch();
//     // const activitiesData = useSelector(state => getActivityByProcessId(state, job.processId))
//     const workroomname = useSelector(state => getWorkroomShortNameFromId(state,job.workroomId))

//     const saveDeadline = (values, resetForm) => {
//         let newJob = cloneDeep(values)
//         if (values.deadline)
//             newJob.deadline = values.deadline + 'T00:00:00'
//         dispatch(updateJobDeadline(newJob))
//         let btnI = document.getElementById('btn' + values.id)

//         btnI.style.backgroundColor = "green"
//         setTimeout(function () {
//             btnI.style.backgroundColor = "lightgrey"
//         }, 2000);

//     }

//     let jobDeadline = {'id' : job.id, 'deadline' : job.deadline}

//     return (

//         <TableRow className='borderBottomObj '>
//             <TableCell style={{ width: "5%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 {job.priority}
//             </TableCell>
//             <TableCell style={{ width: "5%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 <Icon name={getIconName(job.jobStatus)} color={getIconColour(job.jobStatus)} size='large' />
//             </TableCell>
//             <TableCell style={{ width: "10%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 {/* <PopupJobActivities msg = {`${job.jobKey} - ${job.jobName}`} activities = {activitiesData}/> */}
//                 {workroomname}
//             </TableCell>
//             <TableCell style={{ width: "10%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 {/* <PopupJobActivities msg = {`${job.jobKey} - ${job.jobName}`} activities = {activitiesData}/> */}
//                 {job.jobKey}
//             </TableCell>
//             <TableCell style={{ width: "30%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 {`${job.jobName}`}
//             </TableCell>
//             <TableCell style={{ width: "10%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 {job.dtCreate ? moment(job.dtCreate).format('DD-MM-YYYY') : null}
//             </TableCell>
//             <TableCell style={{ width: "25%" }} className='borderBottomObj paddingTopZero paddingBottomZero'>
//                 <Formik id="userSelectForm"
//                     initialValues={jobDeadline}
//                     // validationSchema={workroomSchema}
//                     onSubmit={(values, { resetForm }) => saveDeadline(values, resetForm)}
//                     render={({ handleSubmit, onChange, values, handleChange }) => (
//                         <Form as={FormikForm} onSubmit={handleSubmit} className='marginBottom5 paddingTopZero paddingBottomZero'>
//                             <Form.Group className='marginBottom5'>
//                                 {/* <Field name="initialDeadline"  label=" " placeholder='Initial Deadline' component={FormikDateComponent} ></Field> */}
//                                 <Field name="deadline"  label=" " placeholder='Enter Deadline' component={FormikDateComponent} ></Field>
//                                 <Button icon id={`btn${job.id}`} type="submit" color="lightgrey" size="small" className="buttonCstmWithIcon paddingBottomZero marginLeft40 paddingTopZero">
//                                     <Icon id={`icon${job.id}`} name='exchange' />

//                                 </Button>
//                                {/*  <Button icon id={`deletebtn${job.id}`} color="lightgrey" size="small" className="buttonCstmWithIcon paddingBottomZero paddingTopZero">
//                                     <Icon id={`deleteicon${job.id}`} name='delete' />

//                                 </Button> */}
//                             </Form.Group>
//                         </Form>
//                     )}
//                 />
//             </TableCell>

//         </TableRow>
//     )
// }

const TeamAssignDeadline = (props) => {
  const teamId = props.teamId;
  const jobs = useSelector((state) =>
    getInCompleteJobsByTeamIdforList(state, teamId)
  );

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  // Loading Logic if tasks not fetched
  if (!jobs || jobs.length == 0) {
    return <p> No Records </p>;
  } else {
    return (
      <Container>
        <JobTableList columns={searchColumns({})} data={jobs} />
      </Container>
    );
  }
};

const Actions = ({ object: job, fn }) => {
  const [dt, setDt] = useState(
    job.deadline ? moment(job.deadline, "YYYY-MM-DD").toDate() : null
  );
  const dispatch = useDispatch();

  const saveDeadline = () => {
    console.log("dt", moment(dt).format("YYYY-MM-DDT00:00:00"));
    let newJob = {
      id: job.id,
      deadline: moment(dt).format("YYYY-MM-DDT00:00:00"),
    };
    dispatch(updateJobDeadline(newJob));
  };

  let rawEvent = "";

  const handleDateChange = (e) => {
    if (rawEvent.type === "change") {
      let str = rawEvent.target.value;
      const dt1 = checkValidDateFormats(str);
      console.log("dt1", dt1);
      setDt(moment(getDateInYYYYMMDD(dt1)).toDate());
    } else {
      setDt(e);
      const dt = moment(e);
    }
  };
  return (
    <div className="teamDeadlineDatePickerContainer">
      <DatePicker
        name="dt"
        dateFormat="dd-MM-yyyy"
        scrollableYearDropdown
        selected={dt}
        onChange={handleDateChange}
        popperPlacement="bottom-end"
        onChangeRaw={(e) => (rawEvent = e)}
        autoComplete="off"
      />
      <Button onClick={saveDeadline} size="small">
        Save
      </Button>
    </div>
  );
};

export const searchColumns = (fn) => [
  {
    Header: "Priority",
    accessor: "priority",
    className: "tsd1 ellipsisField",
    headerClassName: "tsd1 tableHeaderCell",
  },
  {
    Header: "Job Status",
    accessor: "jobStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "tsd2 ellipsisField",
    headerClassName: "tsd2 tableHeaderCell",
  },
  {
    Header: "Workroom",
    accessor: "workroomName",
    className: "tsd3 ellipsisField",
    headerClassName: "tsd3 tableHeaderCell",
  },
  {
    Header: "Job",
    accessor: "job",
    className: "tsd4 ellipsisField",
    headerClassName: "tsd4 tableHeaderCell",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    className: "tsd5 ellipsisField",
    headerClassName: "tsd5 tableHeaderCell",
  },
  {
    Header: "Date Created",
    accessor: "dtCreate",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return (
        <div style={{ textAlign: "center" }}>
          <span className="ellipsisField">{custom_date}</span>
        </div>
      );
    },
    className: "tsd6",
    headerClassName: "tsd6 tableHeaderCell",
  },
  {
    Header: "Action",
    Cell: (props) => {
      return <Actions object={props.row.original} fn={fn} />;
    },
    className: "tsd7 ellipsisField",
    headerClassName: "tsd7 tableHeaderCell",
  },
];

export default TeamAssignDeadline;
