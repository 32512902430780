import { all } from "redux-saga/effects";
import taskSaga from "../modules/tasks/data/sagas";
import myTasksSaga from "../modules/mytasks/data/sagas";
import taskdetailsSaga from "../modules/taskdetails/data/sagas";
import usersSaga from "../modules/users/data/sagas";
import loginSaga from "../modules/login/data/sagas";
import processSaga from "../modules/process/data/sagas";
// import projectSaga from '../modules/project/data/sagas'
import processfileSaga from "../modules/processfile/data/sagas";

import activitySaga from "../modules/activities/data/sagas";
import organisationSaga from "../modules/organisation/data/sagas";
import workflowSaga from "../modules/workflow/data/sagas";
import stepsSaga from "../modules/steps/data/sagas";
import workroomSaga from "../modules/workRoom/data/sagas";
import clientSaga from "../modules/client/data/sagas";
import appSaga from "../modules/app/data/sagas";
import teamSaga from "../modules/team/data/sagas";
import jobsSaga from "../modules/jobs/data/sagas";
import commentsSaga from "../modules/comments/data/sagas";
import scheduledprocessSaga from "../modules/scheduledprocess/data/sagas";
import roleSaga from "../modules/rolesAndPermission/data/sagas";
import moduleSaga from "../modules/module/data/sagas";

export default function* rootSaga() {
  yield all([
    appSaga(),
    taskSaga(),
    myTasksSaga(),
    organisationSaga(),
    usersSaga(),
    loginSaga(),
    processSaga(),
    activitySaga(),
    processfileSaga(),
    jobsSaga(),
    scheduledprocessSaga(),
    stepsSaga(),
    workroomSaga(),
    clientSaga(),
    teamSaga(),
    taskdetailsSaga(),
    commentsSaga(),
    roleSaga(),
    moduleSaga(),
  ]);
}
