import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Container, Table, TableBody, Icon } from 'semantic-ui-react';
import { setActiveTask } from '../data/actions'
import { getIconColour, getIconName } from '../../../utilities/iconUtils'
import { getJobPathLink} from '../../../utilities/pathUtils'


const ActivityListForTaskPanel = (props) => {
    
    let data = props.data === undefined ? [] : props.data
    let process = props.process === undefined ? null : props.process
    let job = props.job
    let currActiveTask = props.currActiveTask
    let currActiveActivity = currActiveTask ? currActiveTask.activityId : null
    let taskList = props.taskList
    let userList = props.userList

    return (
        <>
        {taskList != undefined && process != undefined && data != undefined
        ? process.processType == "Regular" 
            ? displayRegularTasks(taskList, userList, job, currActiveTask)
            : displayProcessTasks(data, currActiveActivity, taskList, job, currActiveTask)
        : null
        }
        </>
    )
}


const  displayRegularTasks = (taskList, userList, job, currActiveTask) => {
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0)
    const onClick = (e, index) => {
        dispatch(setActiveTask(taskList[index]))
        setCurrentIndex(index)
    }
    const getUserName = (userid) => {
        if (userList == undefined || userList.length == 0) return null
        let userobj = userList.find((item) => item.value == userid)
        if (userobj == undefined) return null
        return userobj.text
      }
    return (
        <Container className="padding70">
                <Table selectable basic='very' style={{width: "100%"}}>
                    <TableBody>
                    { taskList.map((value, index) => (
                         <Table.Row key = {index} style = {value.id == currActiveTask.id ? {'backgroundColor':'#f6f7f8'} : {'backgroundColor':'#ffffff'}}>
                         <Table.Cell style={{width: "90%"}}>
                            <Icon name ={value.taskStatus == 1 ? "tasks" : getIconName(value.taskStatus)}  color ={getIconColour(value.taskStatus)} size='large' className='marginRight13'/>
                            {value.secondary == null 
                            ? <Icon name="bullseye" corner="top right" color ={getIconColour(value.taskStatus)} size='large' className='marginRight13' />                    
                            : null}
                            <Link to={{pathname: getJobPathLink (job), job : job, activityId: value.activityId, regularTask : value, processType: "Regular"}} className='marginRight13' onClick={((e)=>onClick(e, index))}>{value.taskName} : {getUserName(value.userId)}</Link>                             
                        </Table.Cell>
                        </Table.Row>
                    ))
                    }
                </TableBody>
            </Table>
        </Container>     
    )
}



    
const displayProcessTasks = (data, currActiveActivity, taskList, job, currActiveTask) =>{ 

    const checkTaskStatusOfActivity = (taskList, activityId) =>{
        if (!taskList) return undefined
        let obj = taskList.find( (task) => (task.activityId == activityId))
        return (obj ? obj.taskStatus :undefined)
    } 

    return (
        <Container className="padding70">
                <Table selectable basic='very' style={{width: "100%"}}>
                    <TableBody>
                    { Object.values(data).map((value) => (
                         <Table.Row key = {value.activityId} style = {value.activityId == currActiveActivity ? {'backgroundColor':'#f6f7f8'} : {'backgroundColor':'#ffffff'}}>
                         <Table.Cell style={{width: "90%"}}>
                            <Icon name = {checkTaskStatusOfActivity(taskList, value.activityId)===1 ? "tasks" : getIconName(checkTaskStatusOfActivity(taskList, value.activityId))}  color = {getIconColour(checkTaskStatusOfActivity(taskList, value.activityId))} size='large' className='marginRight13'/>
                             <Link to={{pathname: getJobPathLink (job), job : job, activityId: value.activityId, processType: "Process"}}>{value.activityName}</Link>
                         </Table.Cell>
                        </Table.Row>
                    ))
                    }
                </TableBody>
            </Table>
        </Container>     
    )
}



export default ActivityListForTaskPanel;