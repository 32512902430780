import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getProcessfile, getProcessfileParams } from '../data/selectors';
import { getIsActivityDefinitionUpdated } from '../../activities/data/selectors'
import {updateActivityDefinition, resetActivityDefinitionFlag} from '../../activities/data/actions';
import { createProcessfile, editProcessfile } from '../data/actions';
import { processfileSchema} from '../data/model'
import  {getHeaderJWT, URL } from '../../../store/path'
import { Container, Header, Form, Button, Input, Grid } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'  
import axios from 'axios';


// import UserDropdown from '../../users/components/userDropdown'


const ProcessfileForm = (props) => {
    const [fileName, setFileName] = useState ()
    const [heights, setHeight] = useState('')

    const processfile = useSelector(state => getProcessfile(state, props))
    const activityDefinitionUpdated = useSelector ( state=> getIsActivityDefinitionUpdated(state, props))
    const processName = props.location.processName
    const processId =  props.location.processId
    const dispatch = useDispatch()

    const setFile = ({ target: { files } }) =>{
        setFileName( files[0] )
    }


    useEffect(()=>{
        if (activityDefinitionUpdated) {
            props.history.replace({pathname: "/process/activity/list", processId: processId, processName: processName}) 
            dispatch (resetActivityDefinitionFlag())
        }
        
    }, [activityDefinitionUpdated]);   



    const updateDefinition = (data) => {
        let deployedDefinitions = data.deployedProcessDefinitions
        let val = Object.values(deployedDefinitions)
        let keyname = val[0].key
        dispatch ( updateActivityDefinition ({'keyname' : keyname, 'processId': processId}))
    }



    const uploadFile = (values) =>{
        console.log( fileName )
         console.log(' Deployment name is ', values.deployment_name)
        let data = new FormData();
        data.append( 'file', fileName )
        data.append( 'deployment-name', values.deployment_name )

        
        // axios.post("https://www.mocky.io/v2/5cc8019d300000980a055e76", data, options).then(res => { 
            axios.post(URL + "/rest/engine/default/deployment/create", data, getHeaderJWT())
            .then((res) => { 
            console.log("--------Received data -----------")    
             console.log(res.data)
             updateDefinition (res.data)
            
        }, (error) => {
            console.log("--------Received data ERROR-----------")    
            console.log(error);
          });
        
      }



    const saveProcessfile = (values, resetForm) => {
        console.log ('!!! In process file !!!, values is :', values)
        if (props.match.path.endsWith('/processfile/create')){
            // userACL.atCreate(values)
            // dispatch(createProcessfile(values))
            uploadFile (values)
            // resetForm()
        }
        if (props.match.path === '/processfile/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editProcessfile(values))
            props.history.push(`/processfile/list`)
        }
    }

    return (

            <>
              <Formik id="processfileForm" size="large" width={8}
                 initialValues={{'processName' : {processName}, 'deployment_name': ''}}
                // validationSchema={processfileSchema}
                onSubmit={(values, {resetForm}) => saveProcessfile(values, resetForm)}
                render={({ handleSubmit, onChange, values, handleChange }) => (
                    <Form as={FormikForm} className="CustomeForm" width={8} onSubmit={handleSubmit}>
                            <Field name="deployment_name" component={FormikInputComponent} placeholder="Deployment Name"></Field>
                        <Form.Group >

                        <Input type="file" className="form-control profile-pic-uploader" onChange={setFile} />
                        </Form.Group>
                        <Button type="submit" size="medium blue" className="CustomeBTN"> Submit </Button>
                        <Button type="cancel" size="medium red" className="CustomeBTN" onClick={()=> props.history.goBack()}> Cancel </Button>
                    </Form>
                )}
            /> 
            </>
    )
}

export default ProcessfileForm;