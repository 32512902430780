import React from 'react';
import { useSelector } from 'react-redux'
import { Container, Table, TableBody, } from 'semantic-ui-react';
import moment from 'moment'
import { getActiveTask } from '../data/selectors'
import { selectUsers } from '../../users/data/selectors'


const getUserName = (userList, userid) => {
    if (userList == undefined || userList.length == 0) return null
    let userobj = userList.find((item) => item.value == userid)
    if (userobj == undefined) return null
    return userobj.text
  }


const JobAuditForTaskRightPanel = (props) => {
    let job = props.job
    const userList = useSelector(state => selectUsers(state, props))
    const currActiveTask = useSelector(state => getActiveTask(state, job))


   if (!job) return null
    return (
        <Container>
            <Table selectable basic='very'>
                <TableBody>
                        <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Task Created By:&nbsp;
                            {currActiveTask.uidCreate? getUserName(userList, currActiveTask.uidCreate ): "-"}
                         </Table.Cell>
                        </Table.Row>
                        <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Task Updated By:&nbsp;
                            <span className="FontHindBold">
                            {currActiveTask.uidUpdate? getUserName(userList, currActiveTask.uidUpdate ): "-"}
                            </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Task Created On :&nbsp;
                            {currActiveTask && currActiveTask.dtUpdate ? moment(currActiveTask.dtCreate).format('DD-MMM-YY, HH:mm') : "-"}
                         </Table.Cell>
                        </Table.Row>
                        <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Task Updated On:&nbsp;
                            {currActiveTask && currActiveTask.dtUpdate ? moment(currActiveTask.dtUpdate).format('DD-MMM-YY, HH:mm') : "-"}
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                                Job Created By:&nbsp;
                            <span className="FontHindBold">
                             {job.uidCreate ? getUserName(userList, job.uidCreate ): "-"}
                             </span>
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Job Updated By:&nbsp;
                            {job.uidUpdate? getUserName(userList, job.uidUpdate ): "-"}
                         </Table.Cell>
                        </Table.Row>
                        <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Job Created On:&nbsp;
                             {job.dtCreate ? moment(job.dtCreate).format('DD-MMM-YY, HH:mm') : "-"}
                         </Table.Cell>
                         </Table.Row>
                         <Table.Row  >
                         <Table.Cell style={{width: "90%"}}>
                            Job Updated On:&nbsp;
                            {job.dtUpdate ? moment(job.dtUpdate).format('DD-MMM-YY, HH:mm') : "-"}
                         </Table.Cell>
                        </Table.Row>
                </TableBody>
            </Table>
        </Container>  
    )
}


export default JobAuditForTaskRightPanel;