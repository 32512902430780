import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsProcessfileFetched } from './selectors'
import {savedProcessfile,fetchedProcessfile, storeSearchProcessfileResult, deletedProcessfile, fetchedProcessfileByProcessId } from './actions'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import apiContainer, {getHeaderJWT, URL} from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Processfile 

function* createProcessfile() {
    yield takeEvery('CREATE_PROCESSFILE',saveProcessfile)
  }

function* saveProcessfile(action){
  const { response, error } = yield call(saveProcessfileAPI, action.payload)
  console.log("Saga",action.payload)
  if (response) 
  try {
    yield put (savedProcessfile(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveProcessfileAPI(data) {
    return axios.post(URL+'/processfile/add', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Processfile 

function* editProcessfile() {
  yield takeEvery('EDIT_PROCESSFILE',saveEditedProcessfile)
}

function* saveEditedProcessfile(action){
  const { response, error } = yield call(editProcessfileAPI, action.payload)  
  if (response) 
  try {
    yield put (savedProcessfile(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editProcessfileAPI(data) {
  let user_id = data._id
  return axios.post(URL+'/processfile/update/'+ user_id, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Processfile 

function* deleteProcessfile() {
  yield takeEvery('DELETE_PROCESSFILE',saveDeleteProcessfile)
}

function* saveDeleteProcessfile(action){
  const { response, error } = yield call(deleteProcessfileAPI, action.payload)
  if (response) 
  try {
    yield put (deletedProcessfile({id: action.payload.id, msg : response.data}))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteProcessfileAPI(data) {
  let processfileId = data.id
  return axios.post(URL+'/processfile/delete/'+ processfileId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Processfile 

function* fetchProcessfile() {
  yield takeEvery('FETCH_PROCESSFILE',requestProcessfile)
}
  

function* requestProcessfile(action){
  const isProcessfileFetched = yield select(getIsProcessfileFetched);
  if(!isProcessfileFetched){
    let { data } = yield call(requestProcessfileAPI)
    try {
      yield put (fetchedProcessfile(data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
  }
}


function requestProcessfileAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.post(URL+'/processfile/', data, getHeaderJWT())
}

//#endregion


//#region Saga for Fetching Processfile by process 

function* fetchProcessfileByProcess() {

  yield takeEvery('FETCH_PROCESSFILE_BY_PROCESSID',requestProcessfilebyProcess)

}
  
function* requestProcessfilebyProcess(action){
    let { response, error } = yield call(requestProcessfilebyProcessAPI, action.payload)
    
    if (response) 
    try {
      yield put (fetchedProcessfileByProcessId(response.data))
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    else {
      yield put (handlError(action, parseError(error)))
      sagaErrorMessage(error,action)  
    }
  // }

}

function requestProcessfilebyProcessAPI(data) {
  let processKey  = data.processKey
  //  processKey = 'RealBooks-Enhancement'
  let requrl = URL+'/rest/engine/default/process-definition?keyLike='+ processKey+ '&sortBy=version&sortOrder=asc'
  console.log (' %%%%%% Calling processfile for : ', requrl)

  return axios.get(requrl, getHeaderJWT())
  .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion



//#region Saga for Search Processfile 

function* searchProcessfile() {

  yield takeLatest('SEARCH_PROCESSFILE',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchProcessfileResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/processfile/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    console.log(error)
    console.groupEnd()
}

export default function* processfileSaga() {
    yield all([
        createProcessfile(),
        fetchProcessfile(),
        editProcessfile(),
        deleteProcessfile(),
        searchProcessfile(),
        fetchProcessfileByProcess()
    ])
  }  