import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {fetchActivity} from '../data/actions'
import * as select from '../data/selectors'
import { heightSet } from '../../../utilities/heightForListing'
import StepsListForPanel from '../../app/components/stepsListForProcessPanel'
import { Container, Table, TableBody, Header, Grid, Accordion } from 'semantic-ui-react'
    

const Activity = props => (
    <Table.Row>
        <Table.Cell>{props.activity.activityId}</Table.Cell>
        <Table.Cell>{props.activity.activityName}</Table.Cell>
        <Table.Cell>
            <Link to={"/activity/edit/"+props.activity.id}>Edit</Link>
        </Table.Cell>
    </Table.Row>
)

const ActivityList = (props) => {
    const processId = props.location.processId
    const processName = props.location.processName

    // const activity = useSelector(state => state.activity.byId) 
    const activity = useSelector(state => select.getActivityByProcessId(state, processId))
    const activityFetched = useSelector(state => select.getIsActivityFetched(state, props))
    const dispatch = useDispatch();

    /* useEffect(()=>{
        dispatch(fetchActivity());
    },[processId]);    */     

    const panels = [
        {
          key: 'activities',
          title: { content:
            <span className="padding70">
                <span className="titleHead">
                    Activities
                </span>
                
                {/* <Button className="buttonCssCstm" onClick={callNewStep} color='green'>
                    Add
                </Button> */}
            </span>
             },
          content: {
              content: (
                <div >
                    <div className="maxMinHeight paddingRight45 marginRightNav15">
                        <StepsListForPanel isEdit="show" processId={processId} processName={processName}/>
                    </div>
                    
                </div>
              ),
            },
        },
        {
          key: 'History',
          title: { content:
            <span className="titleHead">
              History
            </span> },
          content: {
            content: (
              <div className="padding40">
                  Hello
              </div>
            ),
          },
        },
      ]

    // Loading Logic if tasks not fetched
    if (!activity){
        return <p>Loading ... </p>
    }  else {
        return (
            <Container>
                <Grid columns={5} divided >
                <Grid.Column className="paddingLeftRight70 paddingTopZero "></Grid.Column>
                {/* <Header as='h1' className="padding55" align='Left'>Activity List</Header> */}
                <Table >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ActivityID</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <TableBody>
                        { Object.keys(activity).map((key) => 
                        <Activity activity={activity[key]} key={activity[key].id} /> )}
                    </TableBody>
                </Table>
                {/* <Grid.Column width={5} className="paddingZero paddingRight30">
                   <Accordion className="boxNone" styled panels={panels} /> 
                </Grid.Column> */}
                </Grid>
            </Container>
        )
    }
}

export default ActivityList;
