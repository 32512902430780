import React,{useState} from 'react'
import { Button, Modal, Grid, Message, Container, Form, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import { tasks, taskSchema } from '../data/model'
import { createTask } from '../data/actions'
import UserSelect from '../../users/components/usersSelect' 
import v4 from 'uuid';

const PopupTaskCreate = ({job}) => {
  const [open, setOpen] = useState(false)
  const [savedSuccess, setSavedSuccess] = useState(false)

  const task = tasks()
  const dispatch = useDispatch()

  const saveJob = (values, resetForm) => {
    const taskToSave = {
      "id": v4(),
      "workroomId": job["workroomId"],
      "teamId": job["teamId"],
      "jobId": job["id"],
      "processId": job["processId"],
      "activityId": "Activity_1py78760",
      "taskName": values["taskName"],
      "taskStatus": 1,
      "userId": values["userId"],
      "secondary": 1,
      "status": 1,
    }

    dispatch(createTask(taskToSave))
    setSavedSuccess (true)
    setTimeout(function(){ 
      setOpen(false)
        setSavedSuccess (false)
    }, 1200);
  }

  return (
    <Modal
      closeIcon
      size='small'
      dimmer = 'inverted'
      open={open}
      trigger={<Button basic color="grey" size="medium" className="CustomeBTN marginTopTen " >Add New Task</Button>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      {/* <Header content='Create New Ticket' /> */}
      <Modal.Content>
      <Container >
          <Grid columns={8} divided columns='equal'>
            <Grid.Column >
              <Formik id="taskForm" 
                initialValues={task}
                validationSchema={taskSchema}
                onSubmit={(values, { resetForm }) => saveJob(values, resetForm)}
                render={({ handleSubmit, onChange, values, handleChange }) => (
                  <Form as={FormikForm} className ="CustomeForm" onSubmit={handleSubmit}>
                          <Field  name="taskName" label = "Task Name (Limit to 10 characters)" component={FormikInputComponent}></Field>
                          {/* <Field  name="description" label = "Description" component={FormikTextAreaComponent}></Field> */}
                          <UserSelect name="userId" label="Assign to User" filterFromWorkroom= {job.workroomId}/>
                      <Button type="submit" floated="right" color="blue" size="medium" className="CustomeBTN"> Submit </Button> 
                      <Button floated="right" color="grey" size="medium" className="CustomeBTN" onClick={() => setOpen(false)}> Cancel </Button> 
                  </Form>
                )}
              />
            </Grid.Column>                      
          </Grid>
          {savedSuccess 
          ?
              <Message positive style = {{ zIndex: 100}}>
                <Message.Header>Form submitted successfully</Message.Header>
              </Message>
          : 
          null}  
        </Container>
      </Modal.Content>
    </Modal>
  )
}
export default PopupTaskCreate