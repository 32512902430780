import axios from 'axios';
import { getHeaderJWT, URL } from '../../../store/path'

export const handleFileDownload = (attachmentid) => {
    console.log('Attachment id and id :', attachmentid)
    if (!attachmentid) return
  
    let attachId = URL + '/comments/signed-url/download/' + attachmentid
    axios.get(attachId, getHeaderJWT())
      .then((resp) => {
        console.log('Data after downloading signed URL file is :', resp)
        window.open(resp.data)
      }, (error) => {
        console.log("--------Received data Download Signed url ERROR-----------")
        console.log(error);
      });
  
  }