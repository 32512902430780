import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const client = () => ({
    id: v4(),
    clientName: "",
    cidRb: "",
    segid: "",
    cid: "",
    status: 1,
    // dtCreate: "",
    // dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})


export const searchColumns = [
    {
      Header: 'Name',
      accessor: 'clientName', // accessor is the "key" in the data
    },
  ]


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const clientSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    clientName: Yup.string()
                .required('This is a Required Field'),

    cidRb: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    // dtCreate: Yup.string().nullable(),
    // dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });


export const duplicateCheckClientCode = debounce(checkClientCode, 500);
function checkClientCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/client/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							// //console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckClientName = debounce(checkClientName, 500);
function checkClientName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/client/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            // //console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  