import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, getUsersParams } from '../data/selectors';
import { createUsers, editUsers } from '../data/actions';
import { usersSchema, duplicateCheckUsersName } from '../data/model'
import { Container, Header, Form, Button, Grid, Checkbox } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikCheckBoxComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'  
import { heightSet } from '../../../utilities/heightForListing'
import ClientSelect from '../../client/components/clientSelect' 
import { updateHeaderTitle } from '../../app/data/actions'

 
// import UserDropdown from '../../users/components/userDropdown'


const UsersForm = (props) => {
    const [heights, setHeight] = useState('')
    const [showClient, setShowClient] = useState(false)
    const [resetPassword, setResetPassword] = useState(false)

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    const users = useSelector(state => getUsers(state, props))
    const params = useSelector(state => getUsersParams(state, props))
    const dispatch = useDispatch()
    let userName = "";
    let userId = "";
    
    if (props.match.path === '/users/edit/:id') {
        userName = ( users === undefined ? "" : users.name )
        // clientId = props.location.clientId === undefined ? "" : props.location.clientId;
    }

    useEffect(() => {
        setShowClient(users && users.isClient == 1 ? true : false);
        if (props.match.path === '/users/edit/:id') {
            dispatch(updateHeaderTitle({ 'title': 'User: ' + userName }))
        } else
            dispatch(updateHeaderTitle({ 'title': 'Create User'}))
    }, [userName])
    const saveUsers = (values, resetForm) => {
        if (values.isClient !=1)
            values.clientId = null
        if(resetPassword)
             values.password = "Ams@1234";
        if (props.match.path === '/users/create') {
            // userACL.atCreate(values)
            dispatch(createUsers(values))
            resetForm()
        }
        if (props.match.path === '/users/edit/:id') {
            // userACL.atUpdate(values)
            dispatch(editUsers(values))
            props.history.push(`/users/list`)
        }
    }

    return (
        <Container >
            <Grid.Column style={{height: (heights)+"px"}} className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                <Formik id="usersForm" size="large" width={5}
                    initialValues={users}
                    validationSchema={usersSchema}
                    onSubmit={(values, {resetForm}) => saveUsers(values, resetForm)}
                    render={({ handleSubmit, onChange, values, handleChange }) => (
                        <React.Fragment>
                        <Form as={FormikForm} size="small" className="CustomeForm" width={5} onSubmit={handleSubmit}>
                            <Form.Group widths={2}>
                                <Field name="name" component={FormikInputComponent}></Field>
                                <Field name="group" component={FormikInputComponent}></Field>
                            </Form.Group> 
                            { (props.match.path == ('/users/create')) && 
                            <Form.Group widths={2}> 
                                <Field name="password" component={FormikInputComponent} ></Field>
                            </Form.Group>
                            }
                            <Form.Group widths={2}> 
                                <Field name="mobile" component={FormikInputComponent}></Field>
                                <Field name="email" component={FormikInputComponent}></Field>
                            </Form.Group>

                            <Form.Group widths={2} className='paddingTop10'>
                                <Field name="resetPw" id = 'resetPw' label="Reset Password" width={showClient ? 10 : 8} 
                                    onChangeFunc = {(e, data)=> {
                                        (values.resetPw == "0" || values.resetPw == null) ? values.resetPw = 1 : values.resetPw = 0 
                                        setResetPassword (data)
                                      }}
                                    userProps = {{'value' : values.resetPw}}
                                    toggle={true}  
                                    labelOnTop
                                    defaultChecked = {values.resetPw == 1 ? true: false}
                                    component={FormikCheckBoxComponent}/>
                                <Field name="isClient" id = 'isClient' label="Is Client User" 
                                    width={2} 
                                    onChangeFunc = {(e, data)=> {
                                        (values.isClient == "0" || values.isClient == null) ? values.isClient = 1 : values.isClient = 0 
                                        setShowClient (data)
                                      }}
                                      userProps = {{'value' : values.isClient}}
                                    toggle={true}  
                                    labelOnTop
                                    defaultChecked = {values.isClient == 1 ? true: false}
                                    component={FormikCheckBoxComponent}/>
                                    <br/>
                                    {(showClient) &&
                                        <ClientSelect required={false} name= "clientId" label="Client" customwidth={4}/>
                                    }
                                </Form.Group>

                            <Button type="submit" size="medium blue" className="CustomeBTN"> {params.submitButtonText} </Button>
                        </Form>
                        </React.Fragment>
                    )}
                />
            </Grid.Column>
        </Container>
    )
}

export default UsersForm;