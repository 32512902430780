import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { organisation } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingOrganisation = (state, props) => state.organisation.params.isFetching;
export const getIsOrganisationFetched = (state, props) => state.organisation.params.organisationFetched;
export const getOrganisationList = (state, prop) => state.organisation.byId
export const getIsSearching = (state, props) => state.organisation.params.isSearching;
export const getIsSearchFetched = (state, props) => state.organisation.params.searchFetched;
export const getSearchResults = (state, prop) => state.organisation.searchResults
export const filters = (state, prop) =>  state.organisation.filterResults ;
export const getDeleteStatus = (state, prop) => state.organisation.deleteStatus
export const getDelFetched = (state, prop) => state.organisation.params.isDelFetched
export const getNotification = (state, id) => state.organisation.notifications[id]

export const getOrganisation = (state, props) => {
    if(props.match.path==='/organisation/create'){
        return organisation()
    }
    if(props.match.path==='/organisation/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.organisation.byId[_id]);
        return obj        
    }
}

export const getOrganisationParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/organisation/create')){ 
        params.title = state.organisation.params.createTitle
        params.submitButtonText = state.organisation.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/organisation/edit/')){ 
        params.title = state.organisation.params.editTitle
        params.submitButtonText = state.organisation.params.editSubmitButtonText        
    };

    return params
}

export const selectOrganisation = createSelector(
    getOrganisationList,
    organisation => { 
        const keys = Object.keys(organisation)
        const obj = keys.map( (key) => { return { key : key, value : key, text : organisation[key].name }})
        return obj
    })


export const selectOrganisationSearched = createSelector(
    getSearchResults,
    organisation => { 
        const keys = Object.keys(organisation)
        const obj = keys.map( (key) => { return { key : key, value : key, text : organisation[key].name }})
        return obj
    })


// export const getFilteredOrganisation = createSelector(
//     organisationSelector, filters,
//     (organisation, filters) => {
//         let filteredOrganisation =  Object.keys(organisation).map(function(key) {
//             return organisation[key];
//             })     
//         filteredOrganisation = textFilter(filteredOrganisation, filters)
//         filteredOrganisation = numberFilter(filteredOrganisation, filters)
//         return filteredOrganisation
//     })
    