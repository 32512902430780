export const CREATE_STEPS = 'CREATE_STEPS'
export const SAVED_STEPS = 'SAVED_STEPS'
export const EDIT_STEPS = 'EDIT_STEPS'
export const EDITED_STEPS = 'EDITED_STEPS'
export const FETCH_STEPS = 'FETCH_STEPS'
export const FETCHED_STEPS = 'FETCHED_STEPS'
export const SEARCH_STEPS = 'SEARCH_STEPS'
export const STORE_SEARCH_STEPS_RESULT = 'STORE_SEARCH_STEPS_RESULT'
export const DELETE_STEPS = 'DELETE_STEPS'
export const DELETED_STEPS = 'DELETED_STEPS'
export const NOTIFICATION_DONE_STEPS = 'NOTIFICATION_DONE_STEPS'
