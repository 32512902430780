import React from "react";
import { Popup, Icon } from "semantic-ui-react";

function PopUp({ msg, style, onClick, size, msgStr }) {
  return (
    <Popup
      content={"Copied:" + msg}
      style={{ color: "green" }}
      on={["click"]}
      trigger={
        <Icon
          style={style}
          name="copy outline"
          basic
          size={size}
          color="gray"
          onClick={() => {
            if (msgStr) {
              onClick(msgStr);
            } else {
              onClick(msg);
            }
          }}
        />
      }
    />
  );
}

export default PopUp;
