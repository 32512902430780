import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { steps } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingSteps = (state, props) => state.steps.params.isFetching;
export const getIsStepsFetched = (state, props) => state.steps.params.stepsFetched;
export const getStepsList = (state, prop) => state.steps.byId
export const getIsSearching = (state, props) => state.steps.params.isSearching;
export const getIsSearchFetched = (state, props) => state.steps.params.searchFetched;
export const getSearchResults = (state, prop) => state.steps.searchResults
export const filters = (state, prop) =>  state.steps.filterResults ;
export const getDeleteStatus = (state, prop) => state.steps.deleteStatus
export const getDelFetched = (state, prop) => state.steps.params.isDelFetched
export const getNotification = (state, id) => state.steps.notifications[id]

export const getSteps = (state, props) => {
    if(props.match.path==='/steps/create'){
        return steps()
    }
    if(props.match.path==='/steps/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.steps.byId[_id]);   
        return obj        
    }
}

export const getStepsParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/steps/create')){ 
        params.title = state.steps.params.createTitle
        params.submitButtonText = state.steps.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/steps/edit/')){ 
        params.title = state.steps.params.editTitle
        params.submitButtonText = state.steps.params.editSubmitButtonText        
    };

    return params
}

export const selectSteps = createSelector(
    getStepsList,
    steps => { 
        const keys = Object.keys(steps)
        const obj = keys.map( (key) => { return { key : key, value : key, text : steps[key].name }})
        return obj
    })


export const selectStepsSearched = createSelector(
    getSearchResults,
    steps => { 
        const keys = Object.keys(steps)
        const obj = keys.map( (key) => { return { key : key, value : key, text : steps[key].name }})
        return obj
    })


export const getFilteredSteps = createSelector(
     filters,
    (steps, filters) => {
        let filteredSteps =  Object.keys(steps).map(function(key) {
            return steps[key];
            })     
        filteredSteps = textFilter(filteredSteps, filters)
        filteredSteps = numberFilter(filteredSteps, filters)
        return filteredSteps
    })

export const getStepsByProcess = (state, processId) => {
    //console.log(processId, "=====processId");
    
    const steps = getStepsList(state);
    const keys = Object.keys(steps)
    const obj = keys.map( (key) => { 
            return steps[key] 
    }).filter((obj)=> obj.process_id === processId)
    return obj
}

// export const getStepsByProcess = createSelector(
//     getStepsList,
//     (steps, processId) => {
//         const keys = Object.keys(steps);
//         //console.log(keys+"--keys")
        
//         const filteredSteps = keys.map((key) => {
//             return  steps[key] 
//         }).filter((obj) => obj.process_id === "e7f90d3c-f4c8-421b-bb96-6e15572cfb4f")
//         //console.log("filteredSteps=="+JSON.stringify(filteredSteps));
        
//         return filteredSteps
// })
    