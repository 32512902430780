import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import {fetchComments, createComments, fetchCommentsByJob} from '../data/actions'
import { selectUsers } from '../../users/data/selectors'
import { selectJobs } from '../../jobs/data/selectors'
 import moment from 'moment'
import { comments } from '../data/model'
import * as select from '../data/selectors'
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikRichText, FormikTextAreaComponent } from '../../../utilities/formUtils'
import logo from "../../../images/testImg.jpg";
import { heightSet, widthSet, innerHeightSet, heightSetFotTaskForm, setMenuHeight, menuAccordionHeight } from '../../../utilities/heightForListing'
import { Container, Input,Text ,Table, Form, Modal, Image,Button, Icon, TextArea, Label} from 'semantic-ui-react'
import { findLastIndex } from 'lodash';
import CommentsDisplay from './commentsDisplay'
import CommentsDisplayWorkroom from './commentsDisplayWorkroom'
import axios from 'axios';
import { parseJwt } from '../../../utilities/parseJWT'
import apiContainer, { URL }  from '../../../store/path'
import v4 from 'uuid';
import { currUserId } from '../../app/data/staticData'
import AttachmentModal from './attachmentModal'
    

 
const CommentsList = (props) => {
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [innerCommentsHeights, setInnerCommentHeight] = useState('')
    const [widths, setWidth] = useState('')
    const [fileSelected, setFileSelected] = useState()
    const [uploadText, setUploadText] = useState ()
    const [attachmentDetails, setAttachmentDetails] = useState (null)
    const [newComment, setNewComment] = useState (comments())
    const [disableAttachButton, setDisableAttachButton] = useState(false)
    const [fileUploadDone, setFileUploadDone] = useState(false)

    // const richTextInput = useRef(null);
    const adjustHeight = () =>{
      setMenuHeight(setHeight)
      widthSet(setWidth)
      menuAccordionHeight(setInnerHeight)
      heightSetFotTaskForm(setInnerCommentHeight)
    }


    useEffect(() => {
        adjustHeight()
        let objDiv = document.getElementById("comments");
        objDiv.scrollTop = objDiv.scrollHeight;
        window.addEventListener('resize', adjustHeight, false);
        return () =>{
          window.removeEventListener('resize', adjustHeight)
        }
    }) 

    // moving to another location resets the comment editor
    useEffect(() => {
      resetAll()
    }, [props.location.pathname])

    const dispatch = useDispatch()

    const isWorkroomList = props.isWorkroomList
    const jobId = props.job ? props.job.id : undefined
    const processId = props.job ? props.job.processId : undefined
    const token = localStorage.getItem('user')
    const userId = token? parseJwt(token).identity : null
    // const userId = currUserId.userId
    const workroomId = isWorkroomList ? props.workroomId: ( props.job ? props.job.workroomId : undefined)
    const commentsRecd = isWorkroomList ? useSelector(state => select.getCommentsByWorkroomId(state, workroomId)) : useSelector(state => select.getCommentsByJobAndWorkroomId(state, workroomId, jobId)) 
    // const signedUrl = useSelector(state => select.getSignedUrl(state)) 
    const userList = useSelector(state => selectUsers(state, props))
    const jobsList = useSelector(state => selectJobs(state, props))
    //const axios = apiContainer.axios;
    
    
    // useEffect(() => {
       
    //     if(!commentsRecd){
    //         dispatch(fetchComments({'workroomId': workroomId, 'dtCreated': 0}));
    //     }
    // },[commentsRecd]);


    useEffect(() => { 
        if(!isWorkroomList && jobId){
            dispatch(fetchCommentsByJob({'workroomId': workroomId, 'jobId': jobId, 'dtCreated': 0}));
        }
    },[jobId]);


    const resetAll = () => {
      setNewComment (comments())
      setUploadText (null)

    }

    const saveComment = (values) => {
      let comment = values.comment
      if (comment.startsWith ('<p><br></p>')) {
        comment = comment.split('<p><br></p>').pop()
      }
      console.log (' Comment after splitting is ', comment)
      if (comment == '' && attachmentDetails== null) return
      let obj = values
      obj.comment = comment
      obj.processId = processId
      obj.workroomId = workroomId
      obj.jobId = jobId
      obj.userId = userId
      obj.dtCreate = moment().valueOf() * 1000000

       dispatch (createComments(obj)) 
       resetAll()
  }

  let styleclass = isWorkroomList ? "cstmCssComment" : "cstmCssComment width47"
    // Loading Logic if tasks not fetched
    return (
        <Container>
                <div id="mainDiv" className='borderNoneObj'  style={{height: (innerHeights- 40)+'px', widths: widths+"px"}}>
                    <div  id = 'comments' className="outerDivClass hideScroll paddingLeft20" style={{height: (innerHeights- 50)+'px', widths: widths+"px"}}>
                        { isWorkroomList ?
                          <CommentsDisplayWorkroom commentsRecd = {commentsRecd} 
                          userList = {userList}
                          jobsList = {jobsList}
                          height ={innerCommentsHeights}
                          />
                        : 
                          <CommentsDisplay commentsRecd = {commentsRecd} 
                            userList = {userList}
                            height ={innerCommentsHeights}
                        />
                        }
                    </div>
                    {/* <div className=""> */}
                      <div id="commentAdd" className= "outerDivClass hideScroll paddingLeft20 paddingRight27" >
                        <Formik id="processForm" className="" size="large" width={8}
                            enableReinitialize
                            initialValues={newComment}
                            onSubmit={(values, { resetForm }) => saveComment(values, resetForm)}
                            render={({ handleSubmit, onChange, values, handleChange,  setSubmitting }) => (
                            <Form as={FormikForm} size="large" id="cmntSection" className= {styleclass}  onSubmit={(e) => { e.preventDefault(); handleSubmit(e)}}>
                          
                              <Field name="comment"  userProps= {{'height' :5}} placeHolder="Please type here..." isLabel={true} focus={true} label=""  component={FormikRichText} ></Field>
                              <div style = {{position: 'absolute', top: '8px', right : '5px', zIndex: 5}} >
                              <Button type="submit" floated="right"  style={{height:"35px"}} color="blue" size="medium" > Post </Button>
                              <Button type="reset"  floated="right" style={{height:"35px"}} color="lightgrey" size="small" onClick={resetAll}> Cancel </Button>
                              <AttachmentModal 
                                  jobId ={jobId}
                                  workroomId = {workroomId}
                                  userId = {userId}
                                  processId = {processId}
                                />
                              </div>
                            </Form>
                             
                          )}
                        />
                        <Label basic className = 'borderNoneObj paddingRight15' style= {{float: 'right', zIndex: 10, position:'fixed', right : '5px',bottom : '5px'}}>{uploadText} </Label>
                      </div>
                    {/* </div> */}
                </div>
        </Container>
    )
}

export default CommentsList;
