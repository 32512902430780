// const xstate = require('xstate') ;
import { createMachine, interpret, assign } from 'xstate';
import { filter, chain, cloneDeep, flattenDeep, union } from 'lodash';

export const enumStates = {
    "-1": {id: -1, name: "cancelled", color: "red"}, 
    "0": {id: 0, name: "completed", color: "green"}, 
    "1": {id: 1, name: "active", color: "blue"}, 
    "2": {id: 2, name: "hold", color: "orange"},
    "3": {id: 3, name: "working", color: "red"},
    "4": {id: 4, name: "waiting", color: "red"},
    "5": {id: 5, name: "approve", color: "purple"},
    "6": {id: 6, name: "unresolved", color: "yellow"},
    "7": {id: 7, name: "alert", color: "red"},
    "8": {id: 8, name: "partially_done", color: "green"}
  }

const taskStatesDB = [
  {id: 1, currentState : "1, 6, 7,",	isPrimary : true, isOwner:	true, isAssignee:	null  , action: "hold", targetState: "2", color: "orange", align: "left"},
  {id: 2, currentState : "1, 6, 7,",	isPrimary : true, isOwner:	true, isAssignee:	null	, action: "cancel", targetState: "-1", color: "grey", align: "left"},
  {id: 3, currentState : "1, 6, 7,",	isPrimary : true, isOwner:	true, isAssignee:	null	, action: "done", targetState: "0", color: "green", align: "left"},
  {id: 4, currentState : "1, 6,",	isPrimary : true, isOwner:	null, isAssignee:	true	, action: "check in", targetState: "3", color: "red", align: "right"},
  {id: 5, currentState : "1, 6,",	isPrimary : true, isOwner:	false, isAssignee:	true	, action: "notify as done", targetState: "5", color: "purple", align: "right"},
  {id: 6, currentState : "-1, 2,",	isPrimary : true, isOwner:	true, isAssignee:	null	, action: "activate", targetState: "1", color: "blue", align: "right"},
  {id: 7, currentState : "5,",	isPrimary : true, isOwner:	true, isAssignee:	null	, action: "done", targetState: "0", color: "green", align: "right"},
  {id: 8, currentState : "5,",	isPrimary : true, isOwner:	true, isAssignee:	null	, action: "unresolved", targetState: "6", color: "yellow", align: "right"},
  {id: 9, currentState : "1, 6,",	isPrimary : false, isOwner:	null, isAssignee:	true	, action: "check in", targetState: "3", color: "red", align: "right"},
  {id: 10, currentState : "1, 6,",	isPrimary : false, isOwner:	null, isAssignee:	true	, action: "done", targetState: "8", color: "green", align: "right"},
  {id: 11, currentState : "1, 6,",	isPrimary : false, isOwner:	false, isAssignee:	true	, action: "need help", targetState: "7", color: "orange", align: "right"},
  {id: 12, currentState : "8,",	isPrimary : false, isOwner:	null, isAssignee:	true	, action: "reopen", targetState: "1", color: "blue", align: "right"},
  {id: 13, currentState : "0,",	isPrimary : true, isOwner:	true, isAssignee:	null	, isFinal : true },
  {id: 14, currentState : "3,",	isPrimary : true, isOwner:	null, isAssignee:	true	, action: "check out", targetState: "1", color: "red", align: "right"},
  {id: 15, currentState : "5,",	isPrimary : true, isOwner:	false, isAssignee:	true	, isFinal : true },
  {id: 16, currentState : "3,",	isPrimary : false, isOwner:	null, isAssignee:	true	, action: "check out", targetState: "1", color: "blue", align: "right"},
  {id: 17, currentState : "7,",	isPrimary : false, isOwner:	null, isAssignee:	true	, action: "clarified", targetState: "1", color: "blue", align: "right"},
  {id: 18, currentState : "1, 6,",	isPrimary : true, isOwner:	false, isAssignee:	true	, action: "need help", targetState: "7", color: "orange", align: "right"},
  {id: 19, currentState : "7,",	isPrimary : true, isOwner:	null, isAssignee:	true	, action: "clarified", targetState: "1", color: "blue", align: "right"},
]

let taskStatesDBExpanded=[]
taskStatesDB.map(state=>{
    let stateArray = state.currentState.split(",")
    stateArray.map(st => {
      if (st != ''){
        let newState = cloneDeep(state)
        newState.currentState = st.trim() 
        taskStatesDBExpanded.push(newState) 
      }
      return st
    })
})

const primaryOwner = filter(taskStatesDBExpanded,(taskState)=> taskState.isPrimary && taskState.isOwner);
const primaryAssignee = filter(taskStatesDBExpanded,(taskState)=> taskState.isPrimary && taskState.isAssignee);
const secondaryOwner = filter(taskStatesDBExpanded,(taskState)=> !taskState.isPrimary && taskState.isOwner);
const secondaryAssignee = filter(taskStatesDBExpanded,(taskState)=> !taskState.isPrimary && taskState.isAssignee);

const primaryOwnerAssignee = chain([primaryOwner, primaryAssignee]).flattenDeep().union().filter((taskState)=> (taskState.isOwner ||  taskState.isOwner == null) && (taskState.isAssignee ||  taskState.isAssignee == null)).value();
const secondaryOwnerAssignee = chain([secondaryOwner, secondaryAssignee]).flattenDeep().union().filter((taskState)=> (taskState.isOwner ||  taskState.isOwner == null) && (taskState.isAssignee ||  taskState.isAssignee == null)).value();

const getStatesDb = (isPrimary, isOwner, isAssignee) => {

  if (isPrimary && isOwner && !isAssignee) return primaryOwner;
  if (isPrimary && !isOwner && isAssignee) return primaryAssignee;
  if (isPrimary && isOwner && isAssignee) return primaryOwnerAssignee;
  if (!isPrimary && isOwner && !isAssignee) return secondaryOwner;
  if (!isPrimary && !isOwner && isAssignee) return secondaryAssignee;
  if (!isPrimary && isOwner && isAssignee) return secondaryOwnerAssignee;
  return null

}

const getState = (isPrimary, isOwner, isAssignee) =>{

  let stateDB = getStatesDb(isPrimary, isOwner, isAssignee)
  let statesFinal = {}

  Object.values(enumStates).map(enumStateObj=>{
    stateDB.map(state=>{
      if (state.currentState == enumStateObj.id){
        if(statesFinal[enumStateObj.name])
          statesFinal = { ...statesFinal,
            [enumStateObj.name]: { on: { 
              ...statesFinal[enumStateObj.name]["on"],
              [state.action]: {target: enumStates[state.targetState].name, actions: [assign({stateId: (context, event) => context.stateId = state.id})]}
            }},
          }
        else 
          if (state.isFinal)
            statesFinal = { ...statesFinal,
              [enumStateObj.name]: { type : 'final', entry: [assign({stateId: (context, event) => context.stateId = state.id})]},
            }
          else
            statesFinal = { ...statesFinal,
              [enumStateObj.name]: { on: { 
                [state.action]: {target: enumStates[state.targetState].name, actions: [assign({stateId: (context, event) => context.stateId = state.id})]}
              }},
            }        
        }
      }
    )

    return enumStateObj
  })
  return statesFinal;
}


export const getStateMachine = (jobNo, initialState, isPrimary, isOwner, isAssignee) => {

    const state = getState(isPrimary, isOwner, isAssignee)

    const taskMachine = createMachine({
        id: jobNo,
        context: { stateId: 0 },
        initial: enumStates[initialState].name,
        states: state
      }
    );
    
    try {
      // Machine instance with internal state
      const taskService = interpret(taskMachine)
        .onTransition((state) => console.log(state.value))
        .start();
      
      console.log("taskMachineMachine", taskMachine.initialState.value);
      return taskService;
    } 
    catch {
      console.log("Error in taskService")
      return undefined
    }
}


export const getActions = (initialState, isPrimary, isOwner, isAssignee) => {

    let stateDB = getStatesDb(isPrimary, isOwner, isAssignee)

    if (stateDB == null) return undefined
    let filteredStates = stateDB.filter(state => state.currentState == initialState)  
    console.log(filteredStates)
    return filteredStates 

}