import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchProcess } from '../data/actions'
import * as select from '../data/selectors'
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'

/*  returnFullObject is a flag. If set to true the value returned fro the select is the entire Process Object. If it is false,
the value returned is the Process Id which is selected */
const ProcessSelect = ({name, isTxn, isLabel, label, required, returnFullObject=false, callback }, props) => {
    
    const process = useSelector(state => state.process.byId)
    const processFetched = useSelector(state => select.getIsProcessFetched(state, props))

    const options = returnFullObject ? useSelector(state => select.selectProcessObject(state, props)) : useSelector(state => select.selectProcess(state, props))

    const dispatch = useDispatch()
    
    useEffect(()=>{
        if (!processFetched)
            dispatch(fetchProcess());
    },[processFetched]);  


    const getValue = (value, setFieldValue, values) => {
        
        if(callback)
            callback(value, setFieldValue, values)
    }

    

    if (!processFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Field name={name} 
                isLabel={isLabel}
                required = {required} 
                isTxn={isTxn} 
                label={label}
                component={FormikSelectComponent} 
                userProps={{ options, getValue }}> 
            </Field>
        )
    }

}


export default ProcessSelect;
