import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsProcessFetched } from './selectors'
import {savedProcess,fetchedProcess, storeSearchProcessResult, deletedProcess } from './actions'
import { handlError, parseError } from '../../app/serverError'
import apiContainer, {getHeaderJWT, URL } from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import fetch from 'cross-fetch';
import axios from 'axios'

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
//const axios = apiContainer.axios;

//#region Saga for Create Process 

function* createProcess() {
    yield takeEvery('CREATE_PROCESS',saveProcess)
  }

function* saveProcess(action){
  const { response, error } = yield call(saveProcessAPI, action.payload)
  // //console.log("Saga",action.payload)
  if (response) 
  try {
    yield put (savedProcess(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveProcessAPI(data) {
  console.log('data is ', data)
    return axios.post(URL+'/process/create', data,  getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Process 

function* editProcess() {
  yield takeEvery('EDIT_PROCESS',saveEditedProcess)
}

function* saveEditedProcess(action){
  const { response, error } = yield call(editProcessAPI, action.payload)  
  if (response) 
  try{
    yield put (savedProcess(action.payload))
  } catch(error) {
    sagaErrorMessage(error, action)
  }
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editProcessAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/process/update', data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Process 

function* deleteProcess() {
  yield takeEvery('DELETE_PROCESS',saveDeleteProcess)
}

function* saveDeleteProcess(action){
  const { response, error } = yield call(deleteProcessAPI, action.payload)
  if (response) yield put (deletedProcess({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteProcessAPI(data) {
  let processId = data.id
  return axios.post(URL+'/process/delete/'+ processId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Process 

function* fetchProcess() {
  yield takeEvery('FETCH_PROCESS',requestProcess)
}
  

function* requestProcess(action){
  const isProcessFetched = yield select(getIsProcessFetched);
  if(!isProcessFetched){
    try {
        const { response, error} = yield call(requestProcessAPI)
        if (response) 
          yield put (fetchedProcess(response.data))
        else {
          yield put (handlError("FETCH_PROCESS", parseError(error)))
          sagaErrorMessage(error,action)  
        }
    } catch(error) {
      sagaErrorMessage(error, action)
    }
    
  }
}


function requestProcessAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.get(URL+'/process/list', getHeaderJWT())
  .then(response => ({ response }))
  .catch(error => ({ error }))
}

//#endregion


//#region Saga for Search Process 

function* searchProcess() {

  yield takeLatest('SEARCH_PROCESS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchProcessResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/process/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* processSaga() {
    yield all([
        createProcess(),
        fetchProcess(),
        editProcess(),
        deleteProcess(),
        searchProcess()
    ])
  }  