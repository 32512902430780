import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


  export const process = () => ({
    id: v4(),
    processName: "",
    processType: "",
    processCode: "",
    processObjective: "",
    processNotes: "",
    processKey:"",
    visible: 1,
    // dtFrom: "",
    // dtTo: "",
    segid: "",
    cid: "",
    status: 1,
    // dtCreate: "",
    // dtUpdate: "",
    uidCreate: "",
    uidUpdate: ""
})  

export const searchColumns = [
  {
    Header: 'Process Name',
    accessor: 'processName',
  },
  {
    Header: 'Process Key',
    accessor: 'processKey', // accessor is the "key" in the data
  },
  {
    Header: 'Process Type',
    accessor: 'processType',
  },
]

  Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const processSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    processName: Yup.string()
                .required('This is a Required Field'),

   /*  processCode: Yup.string()
                .required('This is a Required Field'), */

    processType: Yup.string().nullable(),
    processObjective: Yup.string().nullable(),
    processNotes: Yup.string().nullable(),
    visible: Yup.string().nullable(),
    // dtFrom: Yup.string().nullable(),
    // dtTo: Yup.string().nullable(),


    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    // dtCreate: Yup.string().nullable(),
    // dtUpdate: Yup.string().nullable(),
    uidCreate: Yup.string().nullable(),
    uidUpdate: Yup.string().nullable()

  });  

  /* Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const processSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    process_name: Yup.string()
                .required('This is a Required Field'),

    process_id: Yup.string()
                .required('This is a Required Field'),

    process_type: Yup.string().nullable(),
    process_objective: Yup.string().nullable(),
    process_notes: Yup.string().nullable(),
    visible: Yup.string().nullable(),
    // dtFrom: Yup.string().nullable(),
    // dtTo: Yup.string().nullable(),


    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    // dtCreate: Yup.string().nullable(),
    // dtUpdate: Yup.string().nullable(),
    uid_create: Yup.string().nullable(),
    uid_update: Yup.string().nullable()

  });  */


export const duplicateCheckProcessCode = debounce(checkProcessCode, 500);
function checkProcessCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/process/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							// //console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckProcessName = debounce(checkProcessName, 500);
function checkProcessName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/process/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            // //console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  