import * as type from './types'


export function createProcessfile(processfile) {
    return {
        type : type.CREATE_PROCESSFILE,
        payload : processfile,
        txn : 'initiated',
    };
}


export function savedProcessfile(processfile) {
    return {
        type : type.SAVED_PROCESSFILE,
        payload : processfile,
        txn : 'success',
        diff : true
    };
}


export function editProcessfile(processfile) {
    return {
        type : type.EDIT_PROCESSFILE,
        payload : processfile,
        txn : 'initiated',
    };
}


export function fetchProcessfile() {
    return {
        type : type.FETCH_PROCESSFILE,
    };
}


export function fetchedProcessfile(processfile) {
    return {
        type : type.FETCHED_PROCESSFILE,
        payload : processfile
    };
}


export function fetchProcessfileByProcessKey(processKey) {
    return {
        type : type.FETCH_PROCESSFILE_BY_PROCESSID,
        payload: processKey,
        txn : 'initiated',
    };
}


export function fetchedProcessfileByProcessId(processfile) {
    return {
        type : type.FETCHED_PROCESSFILE_BY_PROCESSID,
        payload : processfile
    };
}


export function searchProcessfile(processfile) {
    return {
        type : type.SEARCH_PROCESSFILE,
        payload : processfile
    };
}


export function storeSearchProcessfileResult(processfile) {
    return {
        type : type.STORE_SEARCH_PROCESSFILE_RESULT,
        payload : processfile
    };
}


export function deleteProcessfile(processfile) {
    return {
        type : type.DELETE_PROCESSFILE,
        payload: processfile
    };
}


export function deletedProcessfile(msg) {
    return {
        type : type.DELETED_PROCESSFILE,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_PROCESSFILE,
        payload: props
    };
}