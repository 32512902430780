import * as type from "./types";

export function createRole(role) {
  return {
    type: type.CREATE_ROLE,
    payload: role,
    txn: "initiated",
  };
}

export function savedRole(role) {
  return {
    type: type.SAVED_ROLE,
    payload: role,
    txn: "success",
    diff: true,
  };
}


export function editRole(role) {
  return {
    type: type.EDIT_ROLE,
    payload: role,
    txn: "initiated",
  };
}


export function fetchRole() {
  return {
    type: type.FETCH_ROLE,
  };
}


export function fetchedRole(role) {
  return {
    type: type.FETCHED_ROLE,
    payload: role,
  };
}


export function createPermission(permission) {
  return {
    type: type.CREATE_PERMISSION,
    payload: permission,
    txn: "initiated",
  };
}


export function savedPermission(permission) {
  return {
    type: type.SAVED_PERMISSION,
    payload: permission,
    txn: "success",
    diff: true,
  };
}


export function fetchPermission() {
  return {
    type: type.FETCH_PERMISSION,
  };
}


export function fetchedPermission(permission) {
  return {
    type: type.FETCHED_PERMISSION,
    payload: permission,
  };
}


export const removePermission = (permission) => {
  return {
    type: type.REMOVE_PERMISSION,
    payload: permission,
    txn: "initiated",
  };
};


export const removedPermission = (permission) => {
  return {
    type: type.REMOVED_PERMISSION,
    payload: permission,
    txn: "success",
  };
};


