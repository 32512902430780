import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux'
import { getActivityByActivityId } from '../../activities/data/selectors'
import { getTasksByJobId, getActiveTask} from '../data/selectors'
import { Container, Label, Button, Grid, Icon } from 'semantic-ui-react';
import { heightSet } from '../../../utilities/heightForListing'
import { getIconColour, getIconName } from '../../../utilities/iconUtils'
import { componentTemplates,syncButtonForTemplates } from '../../taskdetails/data/templateMapping'
import { STATUS_COMPLETED} from '../../app/data/staticData'
import { getProcessFromId } from '../../process/data/selectors'

const TaskTemplate = (props) => {
    
    const [heights, setHeight] = useState('')
    const [componentToShow, setComponentToShow] = useState (null)
    const [editMode, setEditMode] = useState(false)
    const [showEditButton, setShowEditButton] = useState(true)
    const [syncMode, setSyncMode] = useState(false)
    const [showSyncButton, setShowSyncButton] = useState(true)
    const [savedSuccess, setSavedSuccess] = useState(false)

    const jobId = props.job ? props.job.id : undefined
    const taskList = useSelector(state=> getTasksByJobId(state, jobId))
    const currActiveTask = useSelector (state => getActiveTask (state, jobId))
    const activityId = props.location.activityId ? props.location.activityId:  (currActiveTask ? currActiveTask.activityId : undefined)
    const processId = props.job ? props.job.processId : undefined
    const activity = useSelector(state => getActivityByActivityId(state, activityId, processId))
    const process = useSelector(state => getProcessFromId(state, processId))
    const processType = process ? process.processType : undefined
    const isReadOnly = props.job ? (props.job.jobStatus == STATUS_COMPLETED) : false

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })


    const handleSyncConfirm = () =>{
        setSyncMode (true)
    }


    const callbackAfterChildSave = (isSuccess = true) => {
        setEditMode (false)
        setSyncMode (false)
        if (isSuccess) {
            setSavedSuccess (true)
            setTimeout(function(){ 
                setSavedSuccess (false)
            }, 2000);
        }
    }


    useEffect(() => {
        if (activity != undefined )
         {
             let activityTemplate = activity.activityTemplate
             if(syncButtonForTemplates.indexOf(activityTemplate) != -1)
                    setShowSyncButton (true)
            else    
                    setShowSyncButton (false)
              setComponentToShow(GetComponent (activityTemplate))
         }
    }, [activity, editMode, syncMode])


    const getTaskIdForComponent = () =>{
        if (!processType) return undefined
        if (processType == "Regular"){
            return (currActiveTask ? currActiveTask.id :undefined)   
        }

        if (processType == "Process"){
            let obj = undefined
            obj = taskList.find(item => item.activityId === activityId);
            let tid = obj ? obj.id : undefined
            if (tid == undefined){
                setShowEditButton (false)
                setShowSyncButton(false)
                setEditMode (false)
            }
            else 
                isReadOnly ? setShowEditButton (false) : setShowEditButton(true)
            return obj ? obj.id : undefined
        }
    }


    const checkTaskStatusOfActivity = () =>{
        if (!taskList) return undefined
        if (!processType) return undefined

        if (processType == "Process"){
            let obj = taskList.find( (task) => (task.activityId == activityId))
            return (obj ? obj.taskStatus :undefined)    
        }

        if (processType == "Regular"){
            return (currActiveTask ? currActiveTask.taskStatus :undefined)    
        }
    }


    function GetComponent(activityTemplate) {
        if (!activityTemplate) 
            activityTemplate  = 'TemplateTasksToDo' // default if no template is defined
        const TemplateComponent = componentTemplates[activityTemplate];
        return <TemplateComponent 
            {...props} 
            editMode = {editMode} 
            syncMode = { syncMode }
            activityId = {activityId} 
            taskId = {getTaskIdForComponent()} 
            callBack = {callbackAfterChildSave}
            currActiveTask = {currActiveTask}
            setShowSyncButton = {setShowSyncButton}
            isReadOnly = {isReadOnly} 
            activity = {activity}
        />;
    }
    
    return (
        <Container>
            <Grid columns={1}>
                <Grid.Column className="borderNoneObj paddingLeftRight40 paddingTop11 ">
                    <Grid.Row className=' marginBottom15 marginTopTen borderNoneObj borderBottomObj'>
                        <Label basic className=' marginTop5  borderNoneObj paddingZero labelNormal' size='huge'>
                            <Icon name={checkTaskStatusOfActivity()===1 ? "tasks" : getIconName(checkTaskStatusOfActivity())} color={getIconColour(checkTaskStatusOfActivity())} size='large' className='borderNoneObj marginRight13 ' />
                            {activity && currActiveTask.taskName}
                        </Label>
                        <div style = {{ float: 'right'}}>
                        {!isReadOnly && showSyncButton &&
                            <Button icon size='small' className="buttonCstmWithIcon "
                                onClick={handleSyncConfirm}
                            >
                                <Icon name='sync' />
                            </Button>
                        }
                        {!isReadOnly && showEditButton &&
                            <Button icon floated='right' size='small' className="buttonCstmWithIcon "
                                onClick={() => {
                                    setEditMode(!editMode)
                                }}
                            >
                                <Icon name='edit' />
                            </Button>
                        }
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        {componentToShow}
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </Container>   
    )
}

export default TaskTemplate;
