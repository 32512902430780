import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as select from "../data/selectors";
import { Container, Icon } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { getTeamIdFromWorkroom } from "../../workRoom/data/selectors";
import { getJobsAndActiveTasksByTeamIdAndWorkroomId } from "../../team/data/selectors";
import { getActiveJobsByWorkroomId } from "../data/selectors";
import { fetchJobsByWorkroomId } from "../data/actions";
import { fetchCurrTaskByTeam } from "../../tasks/data/actions";
import moment from "moment";
import { JobTableList } from "../../../utilities/tableUtils";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";

const JobsListByWorkroom = (props) => {
  const workroomId = props.workroomId;
  const jobsFetched = useSelector((state) =>
    select.isJobsFetchedByWorkroomId(state, workroomId)
  );

  const teamId = useSelector((state) =>
    getTeamIdFromWorkroom(state, workroomId)
  );
  const jobsReport = useSelector((state) =>
    getActiveJobsByWorkroomId(state, workroomId)
  );
  // const jobsReport = useSelector(state => getJobsAndActiveTasksByTeamIdAndWorkroomId(state, teamId, workroomId))

  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");
  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!jobsFetched)
      dispatch(fetchJobsByWorkroomId({ workroomId: workroomId }));
    dispatch(fetchCurrTaskByTeam({ teamId: teamId }));
  }, [workroomId]);

  // useEffect(() => {
  //     if (!jobsReport)
  //         dispatch(fetchCurrTaskByTeam({ 'teamId': teamId }));
  // }, [teamId]);

  // Loading Logic if tasks not fetched
  if (jobsReport && jobsReport.length == 0) {
    return <p> No records</p>;
  } else {
    return (
      <Container>
        {!jobsReport ? (
          <p> No records</p>
        ) : (
          <>
            <JobTableList columns={searchColumns} data={jobsReport} />
          </>
        )}
      </Container>
    );
  }
};

export const searchColumns = [
  {
    Header: "Sl No",
    accessor: "Sl No",
    className: "wkcj1 ellipsisField",
    headerClassName: "wkcj1 tableHeaderCell",
  },
  {
    Header: "Status",
    accessor: "jobStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "wkcj2 ellipsisField",
    headerClassName: "wkcj2 tableHeaderCell",
  },
  {
    Header: "Job",
    accessor: "job",
    className: "wkcj3 ellipsisField",
    headerClassName: "wkcj3 tableHeaderCell",
  },
  {
    Header: "Task Name",
    accessor: "taskName",
    className: "wkcj4 ellipsisField",
    headerClassName: "wkcj4 tableHeaderCell",
  },
  {
    Header: "Current User",
    accessor: "currentUser",
    className: "wkcj5 ellipsisField",
    headerClassName: "wkcj5 tableHeaderCell",
  },
  {
    Header: "Priority",
    accessor: "priority",
    className: "wkcj6 ellipsisField textAlignRight",
    headerClassName: "wkcj6 tableHeaderCell textAlignRight",
  },
  {
    Header: "Deadline",
    accessor: "deadline",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "wkcj7",
    headerClassName: "wkcj7 tableHeaderCell",
  },
  {
    Header: "Date Start",
    accessor: "dtStart",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "wkcj8",
    headerClassName: "wkcj8 tableHeaderCell",
  },
  {
    Header: "Job Owner",
    accessor: "jobOwner",
    className: "wkcj9 ellipsisField",
    headerClassName: "wkcj9 tableHeaderCell",
  },
];

export default JobsListByWorkroom;
