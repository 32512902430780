import { createSelector } from 'reselect'
import { startsWith, cloneDeep } from 'lodash';
import { app } from './model'


export const getIsFetchingApp = (state, props) => state.app.params.isFetching;
export const getIsAppFetched = (state, props) => state.app.params.appFetched;
export const getAppList = (state, prop) => state.app.byId
export const getIsSearching = (state, props) => state.app.params.isSearching;
export const getLastSeenDataFetched = (state, props) => state.app.params.lastSeenDataFetched;
export const getIsSearchFetched = (state, props) => state.app.params.searchFetched;
export const getSearchResults = (state, prop) => state.app.searchResults
export const filters = (state, prop) =>  state.app.filterResults ;
export const getDeleteStatus = (state, prop) => state.app.deleteStatus
export const getDelFetched = (state, prop) => state.app.params.isDelFetched
export const getNotification = (state, id) => state.app.notifications[id]
export const getCurrentUserId = (state) => state.app.params.currUser


export const getApp = (state, props) => {
    if(props.match.path==='/app/create'){
        return app()
    }
    if(props.match.path==='/app/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.app.byId[_id]);
        return obj        
    }
}

export const getMenuTitle = (state, props) => {
    return (state.app.params.headerTitle);
}


export const getUnreadCountForWorkroom = (state, workroomId) => {
    if (workroomId == undefined) return 0

    let unreadcount = state.app.byUnreadCountData[workroomId]
    return (unreadcount ? parseInt(unreadcount) : 0)
}


export const getTotalUnreadMessages = (state, wlist) => {
    if (wlist == undefined) return 0
    let total = 0
    let wcount = 0
    wlist.map((item) => {
        wcount = getUnreadCountForWorkroom (state, item.id)
        // wcount != undefined ? (total += wcount) : (total+=0)
        total = total + wcount
    })
    return total
}


export const getWorkroomWhereStatusIsOnline = (state) =>{
    let obj = state.app.byLastSeenData
    if (!obj) return undefined
    let objKeys = Object.keys (obj)

    const wroomObjs = objKeys.filter( (wid) => { 
        if (obj[wid] == 'online')
        {
            return wid
        }
    })
    return wroomObjs[0]
    // let onlinearray = Object.values(obj)

}

export const getAppParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/app/create')){ 
        params.title = state.app.params.createTitle
        params.submitButtonText = state.app.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/app/edit/')){ 
        params.title = state.app.params.editTitle
        params.submitButtonText = state.app.params.editSubmitButtonText        
    };

    return params
}


    