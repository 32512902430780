import React, { useEffect, useState }from 'react';
import { Link, Redirect} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { getProcess, getNotification, getProcessParams, getIsProcessFetched } from '../data/selectors';
import { createProcess, editProcess, setNotifyDone } from '../data/actions';
import { processSchema, duplicateCheckProcessName } from '../data/model'
import { Container, Input, Header, Table, TableHeader, TableBody, Form, Button, Grid, Accordion } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent, FormikFileUploadComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'
import { updateHeaderTitle } from '../../app/data/actions'

const steps = props => {
    const dispatch = useDispatch();
    const isNegative = props.steps.status === 1 ? false : true;
    
    
    return (
        <Table.Row>
            <Table.Cell>{props.index}</Table.Cell>
            <Table.Cell>{props.steps.process_name}</Table.Cell>
        </Table.Row>
)}






const ProcessFormCreate = (props) => {
    const [heights, setHeight] = useState('')
    const [savedProcessId, setSavedProcessId] = useState(false)
    
    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    useEffect(() => {
        console.log ('$$$$ Came to form create useefect ', props.match.path)
        dispatch (updateHeaderTitle ({'title':'Create Process'}))
        
    }, [])
    // //console.log(savedProcessId, "==savedProcessId");
    
    const processFetched = useSelector(state=> getIsProcessFetched(state, props))
    // const processFetched = useSelector(state => select.getIsProcessFetched(state, props))

    const process = useSelector(state => getProcess(state, props))
    const params = useSelector(state => getProcessParams(state, props))
    const dispatch = useDispatch()
    let processName = "";
    let processId = "";
    

    const saveProcess = (values, resetForm) => {
        if (props.match.path === '/process/create') {
            userACL.atCreate(values)
            dispatch(createProcess(values))
            setSavedProcessId(values.id)   
             setTimeout(function(){ 
                props.history.replace({pathname: "/process/summary", processId:values.id, processName: values.processName})    
            }, 2000);  
            }  
        
    }
 
    
    return (
        <Container >
            <Grid columns={8} divided columns='equal'>
                <Grid.Column style={{height: (heights)+"px"}} className="paddingLeftRight70 paddingTopZero outerDivClassForForm">
                    <Formik id="processForm" size="large" width={5}
                        initialValues={process}
                        validationSchema={processSchema}
                        onSubmit={(values, { resetForm }) => saveProcess(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="large" className =  "CustomeForm paddingRight272" width={5} onSubmit={handleSubmit}>
                                <Form.Group widths={2}>
                                    <Field name="processKey" label= "Process Key" component={FormikInputComponent}></Field>
                                    <Field name="processType" label= "Process Type" component={FormikInputComponent}></Field>
                                </Form.Group>
                                <Field style={{width: "65.5%"}} name="processName"  label= "Process Name"  component={FormikInputComponent}></Field>
                                <Field name="processObjective"  label= "Process Objective"  component={FormikInputComponent}></Field>
                                <Field name="processNotes"  label= "Process Notes"  component={FormikInputComponent}></Field>

                                <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                {savedProcessId ?
                                    <Notification id={savedProcessId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default ProcessFormCreate;