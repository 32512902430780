import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a userss schema
const usersSchema = new schema.Entity('userss',{}, { idAttribute: 'id' });
const usersListSchema = [usersSchema];

const defaultUsersParams = {
  usersFetched: false,
  searchFetched: false,
  isDelFetched: false,
  listTitle: 'Users List',
  createTitle: 'Create New Users',
  createSubmitButtonText: 'Create Users',
  editTitle: 'Edit Users',
  editSubmitButtonText: 'Edit Users' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_USERS') {
        const usersID = action.payload.id;
      return {...state, 
        [usersID]: action.payload
      }
    }
    if (action.type === 'FETCHED_USERS') { 
        const normalizedUsers = normalize(action.payload, usersListSchema);
        return merge({},state,normalizedUsers.entities.userss)
    }     
    if (action.type === 'DELETED_USERS') {
      if(action.payload.msg === "Success"){
        const usersID = action.payload.id;
        return {...state, 
          [usersID]: { ...state[usersID],
            status :0
          }
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_USERS_RESULT') { 
    const normalizedUsers = normalize(action.payload, usersListSchema);
    return merge({},normalizedUsers.entities.userss)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_USERS') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_USERS'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultUsersParams, action) {
  if (action.type === 'FETCHED_USERS') { 
      return {...state, 
        usersFetched: true,
        isFetching:false
      }
  }   if (action.type === 'SEARCH_USERS') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_USERS_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_USERS') { 
    return {...state, 
      isDelFetched:true
    }
  }if(action.type === 'SET_USERS_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

function filterResults(state = {}, action) {
  if (action.type === 'FILTER_ITEMS') { 
      const col = action.payload.col;
      return {...state,
        [col]: action.payload
      }
  } else {
    return state
  }
}

const users = combineReducers({
    byId,
    notifications,
    searchResults,
    params,
    filterResults
});

export default users;

