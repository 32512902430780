import { createSelector } from 'reselect'
import { startsWith, cloneDeep, at, concat, orderBy, chain } from 'lodash';
import { workroom } from './model'
import { getClientNameFromId } from '../../client/data/selectors'

export const getIsFetchingWorkroom = (state, props) => state.workroom.params.isFetching;
export const getIsWorkroomFetched = (state, props) => state.workroom.params.workroomFetched;
export const getWorkroomList = (state, prop) => state.workroom.byId
export const getIsSearching = (state, props) => state.workroom.params.isSearching;
export const getIsSearchFetched = (state, props) => state.workroom.params.searchFetched;
export const getSearchResults = (state, prop) => state.workroom.searchResults
export const filters = (state, prop) =>  state.workroom.filterResults ;
export const getDeleteStatus = (state, prop) => state.workroom.deleteStatus
export const getDelFetched = (state, prop) => state.workroom.params.isDelFetched
export const getNotification = (state, id) => state.workroom.notifications[id]

const getClientList = (state) => state.client.byId
const getTeamList = (state) => state.team.byId

export const getWorkroom = (state, props) => {
    if(props.match.path==='/workroom/create'){
        return workroom()
    }
    if(props.match.path==='/workroom/edit/:id'){
        let id = props.match.params.id
        let obj = cloneDeep(state.workroom.byId[id]);
        return obj        
    }
}

export const getMyListWorkroom = (state) => {
    const values = Object.values(state.workroom.byMyList)
   const mainList = state.workroom.byId

   if (!values) return undefined

   let arrayOfWR = values.map((obj) => {
       return mainList[obj]
   })
   
   const sortedOptions = orderBy(arrayOfWR, [(item)=> item.name], ['asc']);
   return sortedOptions

}


export const getWorkroomFromShortName = (state, sname) => {
    if (sname == undefined )
        return undefined
    const values = Object.values(state.workroom.byId)
        
        const obj = values.filter( (value) => { 
            if (value.sname.toLowerCase() == sname.toLowerCase() )
            {
                return value
            }
        })
        return obj[0]
}

export const getWorkroomNameFromId = (state, workroomid) => {
    if (workroomid == undefined )
        return ""
    let obj = state.workroom.byId[workroomid]
   if(obj)
        return obj.name
    else    
        return ""
}




export const getWorkroomShortNameFromId = (state, workroomid) => {
    if (workroomid == undefined )
        return ""
    let obj = state.workroom.byId[workroomid]
   if(obj)
        return obj.sname
    else    
        return ""
}

export const getClientNameFromWorkroom = (state, workroomid) => {
    if (workroomid == undefined )
        return ""
    let obj = state.workroom.byId[workroomid]
    let clientid = obj.clientid
    let clientname = getClientNameFromId ( state, clientid)
    return clientname
   
}

export const getTeamIdFromWorkroom = (state, workroomid) => {
    // console.log (' ### Workroom id is ', workroomid)
    if (workroomid == undefined )
        return ""
    let obj = state.workroom.byId[workroomid]
    let teamid = obj.teamid
    // console.log (' ### Workroom team id is ', teamid)
   
    return teamid
   
}


export const getWorkroomsFromTeamid = (state, teamid) => {
    console.log(' Team id in getworkrooms is ', teamid)
    if (teamid == undefined )
        return []
        const values = Object.values(state.workroom.byId)
        
        const obj = values.filter( (value) => { 
            if (value.teamid == teamid )
            {
                return value
            }
        })
        console.log(' Objects is is ', obj)
        return obj
   
}


export const getListOfUsersFromWorkroom = (state, workroomid) => {
    if (workroomid == undefined )
        return []
    let obj = state.workroom.byUsersInWorkroom[workroomid]
    if (obj == undefined) return undefined
    let users = state.users.byId
    let userArray = obj.map((userid) => {return users[userid]})
   return userArray
    // let userid, username

}


export const getWorkroomParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/workroom/create')){ 
        params.title = state.workroom.params.createTitle
        params.submitButtonText = state.workroom.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/workroom/edit/')){ 
        params.title = state.workroom.params.editTitle
        params.submitButtonText = state.workroom.params.editSubmitButtonText        
    };

    return params
}

export const selectWorkroom = createSelector(
    getWorkroomList,
    workroom => { 
        const keys = Object.keys(workroom)
        const obj = keys.map( (key) => { return { value : key, text : workroom[key].name, className: "selectLiCss", key: key }})
        const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
        return sortedOptions
    })

    export const selectWorkroomFromList = (wList) => {
        // console.log(' userLIst is', userList)
      
            const keys = Object.keys(wList)
            const obj = keys.map( (key) => { return { value : wList[key].id, text : wList[key].name, className: "selectLiCss", key: key }})
            const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
            return sortedOptions
        }
    
    export const selectWorkroomObjFromList = (wList) => {
        // console.log(' userLIst is', userList)
        
            const keys = Object.keys(wList)
            const obj = keys.map( (key) => { return { value : wList[key], text : wList[key].name, className: "selectLiCss", key: key }})
            const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
            return sortedOptions
        }

    export const selectWorkroomObject = createSelector(
        getWorkroomList,
        workroom => { 
            const keys = Object.keys(workroom)
            const obj = keys.map( (key) => { return { value : workroom[key], text : workroom[key].name, className: "selectLiCss", key: key }})
            const sortedOptions = orderBy(obj, [(item)=> item.text], ['asc']);
            return sortedOptions
        })


export const selectWorkroomSearched = createSelector(
    getSearchResults,
    workroom => { 
        const keys = Object.keys(workroom) 
        const obj = keys.map( (key) => { return { value : key, text : workroom[key].workroom_name, className: "selectLiCss", key: key }})
        return obj
    })


export const getWorkroomByDecision = (state, decision_id) => {
    let newWorkroom = {
        event : "",
        objective : "",
        motivation : "",
        identity : "",
        id : "",
        headWorkroom:'127da9e6-8f66-4162-83c5-85b017594aab',
        decision : decision_id,
        // cid : orgSelected(state),
        // project : projectSelected(state)
    };    

    let workroomIds = state.decision.byId[decision_id].workroomes

    let workroomes = at(state.workroom.byId,workroomIds)
    return concat(workroomes,newWorkroom)
}


export const getWorkroomArray = createSelector(
    getWorkroomList, getClientList, getTeamList,  (workroom, client, team) => {
        return chain(Object.values(workroom)).map(w => {
            let newObj = cloneDeep(w)
            newObj.clientName = client[w.clientid] ? client[w.clientid].clientName : null
            newObj.teamName = team[w.teamid] ? team[w.teamid].teamName : null
            return newObj
        }).sortBy((item)=> item.name).value()
    })
        