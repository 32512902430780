export const CREATE_PROCESS = 'CREATE_PROCESS'
export const SAVED_PROCESS = 'SAVED_PROCESS'
export const EDIT_PROCESS = 'EDIT_PROCESS'
export const EDITED_PROCESS = 'EDITED_PROCESS'
export const FETCH_PROCESS = 'FETCH_PROCESS'
export const FETCHED_PROCESS = 'FETCHED_PROCESS'
export const SEARCH_PROCESS = 'SEARCH_PROCESS'
export const STORE_SEARCH_PROCESS_RESULT = 'STORE_SEARCH_PROCESS_RESULT'
export const DELETE_PROCESS = 'DELETE_PROCESS'
export const DELETED_PROCESS = 'DELETED_PROCESS'
export const NOTIFICATION_DONE_PROCESS = 'NOTIFICATION_DONE_PROCESS'

export const UPDATE_LIST_TITLE = 'UPDATE_LIST_TITLE'
export const FILTER_ITEMS = 'FILTER_ITEMS'