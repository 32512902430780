export const CREATE_TASK = 'CREATE_TASK'
export const SAVED_TASK = 'SAVED_TASK'
export const EDIT_TASK = 'EDIT_TASK'
export const EDITED_TASK = 'EDITED_TASK'
export const FETCH_TASKS = 'FETCH_TASKS'
export const FETCHED_TASKS = 'FETCHED_TASKS'

export const FETCH_CURRTASK_BY_TEAM = 'FETCH_CURRTASK_BY_TEAM'
export const FETCHED_CURRTASK_BY_TEAM = 'FETCHED_CURRTASK_BY_TEAM'

export const UPDATED_TASK_REPORT = 'UPDATED_TASK_REPORT'
export const INSERT_TASK_REPORT = 'INSERT_TASK_REPORT'

export const ASSIGN_USER_TO_TASK = 'ASSIGN_USER_TO_TASK'
export const ASSIGNED_USER_TO_TASK = 'ASSIGNED_USER_TO_TASK'

export const FETCH_TASKS_BY_JOB = 'FETCH_TASKS_BY_JOB'
export const FETCHED_TASKS_BY_JOB = 'FETCHED_TASKS_BY_JOB'

export const UPDATE_TASK_COMPLETE = 'UPDATE_TASK_COMPLETE'
export const UPDATED_TASK_COMPLETE = 'UPDATED_TASK_COMPLETE'

export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const UPDATED_TASK_STATUS = 'UPDATED_TASK_STATUS'

export const SET_ACTIVE_TASK = 'SET_ACTIVE_TASK'
