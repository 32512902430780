import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { getSteps, getStepsParams, getNotification, getStepsByProcess } from '../data/selectors';
import { createSteps, editSteps, setNotifyDone } from '../data/actions';
import { stepsSchema, duplicateCheckStepsName } from '../data/model'
import { Container, Table, TableHeader, TableBody, Header, Form, Accordion, Radio, Button, Grid } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm } from 'formik';
import { FormikInputComponent, FormikTextAreaComponent } from '../../../utilities/formUtils';
import StepsAction from '../components/stepsPanel' 
import ActivitySelect from '../../activities/components/activitySelect' 
import userACL from '../../../store/access'  
import Notification from '../../../utilities/notificationUtils'
import { heightSet } from '../../../utilities/heightForListing'
import moment  from 'moment'
import v4 from 'uuid';
 
// import UserDropdown from '../../users/components/userDropdown'





const StepsForm = (props) => {
    const [heights, setHeight] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    })

    const steps = useSelector(state => getSteps(state, props))
    const params = useSelector(state => getStepsParams(state, props))
    const dispatch = useDispatch()
    const [savedStepId, setSavedstepId] = useState(false)
    let  processId = "";
    let processName = "";
    let isEdit = "";
    
    if (props.match.path === '/steps/create') {
        isEdit = "hide"
        processId = props.location.processId;
        processName = props.location.processName;
    }else if (props.match.path === '/steps/edit/:id') {
        isEdit = "show"
        processName = props.location.processName;
        processId = props.location.processId;
    }
    const saveSteps = (values, resetForm) => {
        if (props.match.path === '/steps/create') { 
            values.process_id = processId

            userACL.atCreate(values)
            dispatch(createSteps(values))
            setSavedstepId(values.id)
            resetForm()
        }
        if (props.match.path === '/steps/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editSteps(values))
            setSavedstepId(values.id)
            resetForm()
            // props.history.push({pathname: "/process/edit/"+processId, processId:processId, processName: processName})
        }
    } 

    // const callNewStep = (props) =>{
    //     history.push({pathname: "/steps/create", processId:processId, processName: processName})
    // }

    return (
        <Container className="padding_20">
            <Grid id="headDiv" className="padding55">
                <Grid.Row className="paddingBottomZero padding6_0_8_0">
                    <Grid.Column>
                        <Header as='h5' align='left' className="color818284 SmallHeadingAlign">Process Name :</Header>
                    </Grid.Column>
                </Grid.Row> 
            </Grid>
            <br/>
            <Grid columns={3} style={{height: (heights)+"px"}} divided columns='equal'>
                <Grid.Column className="paddingLeftRight70 paddingTopZero outerDivClassForForm" style={{height: (heights)+"px"}}>
                    <Formik id="stepsForm" size="large" width={5}
                        enableReinitialize
                        initialValues={steps}
                        validationSchema={stepsSchema}
                        onSubmit={(values, {resetForm}) => saveSteps(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange, setFieldValue }) => (
                            <Form as={FormikForm} size="small" className="CustomeForm marginTopZero" width={5} onSubmit={handleSubmit}>
                                <Field required={true} name="step_name" label="Activity Name" component={FormikInputComponent} placeholder="Name"></Field>  
                                <Form.Group widths={2}>
                                    <ActivitySelect required={true} name="activity_id" label="Activity Template"/>
                                    <Field name="rank" required={true} component={FormikInputComponent} placeholder="Rank"></Field>
                                </Form.Group>
                                <Form.Group widths={2}> 
                                    <Field name="status" required={true} component={FormikInputComponent} placeholder="Status"></Field>  
                                    <Field name="role" required={false} component={FormikInputComponent} placeholder="Role"></Field>
                                </Form.Group>
                                    <Field name="guidelines" required={false} userProps="" component={FormikTextAreaComponent} placeholder="Guidelines" label='Guidelines'/>
                                <Button type="submit"  size="medium blue" className="CustomeBTN"> {params.submitButtonText} </Button>
                                {/* {console.log(JSON.stringify(savedStepId)+"==============================================notification")
                                } */}
                                {savedStepId ?
                                    <Notification id={savedStepId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )} 
                    />
                </Grid.Column>
                <Grid.Column width={5} className="paddingZero paddingRight30">
                    <StepsAction  isEdit={isEdit} processId={processId} processName={processName}/>
                </Grid.Column> 
            </Grid>
        </Container> 
    )
}

export default StepsForm;