import moment  from 'moment'
import v4 from 'uuid';

const _data = {
    domain : '',
    cid : '-1',
    segid : '-1',
    uid : '1',
};

function init(values) {
    _data.domain = values.domain
    _data.cid = values.cid
    _data.segid = values.segid
    _data.uid = values.uid
    Object.freeze(_data);
}

function create(values) {
    values.cid = _data.cid
    values.segid = _data.segid
    values.txnid = v4();
    values.status = 1;
    // values.dtCreate = moment().format('YYYY-MM-DDTHH:mm:ss')
    // values.dtUpdate =  moment().format('YYYY-MM-DDTHH:mm:ss')
    values.uidCreate = _data.uid
    values.uidUpdate = _data.uid
}

function update(values) {
    values.cid = _data.cid
    values.segid = _data.segid
    values.txnid = v4();
    // values.dtUpdate =  moment().format('YYYY-MM-DDTHH:mm:ss')
    values.uidUpdate = _data.uid
}

const userACL = {
  init: values => init(values),
  add: item => _data.push(item),
  atFetch: () => _data,
  atCreate: values => create(values),
  atUpdate: values => update(values),
}

Object.freeze(userACL);
export default userACL;