import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';


export const newActivity = () => ({
    activityId : '',
    activityName : "",
    activityTemplate : "",
    processId : '',
    role : '',
    guidelines : '',
    activityStatus : ''
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const activitySchema = Yup.object().shape({
    id: Yup.string()
        .min(4)
        .max(15, 'Too Long!')
        .required('Required'),
    // description: Yup.string()
    //     .min(2)
    //     .max(50)
    //     .required()
    // path: Yup.string()
    //     .max(50),
    // steps: Yup.array().of(Yup.object().shape({
    //     rank: Yup.number(),
    //     action : Yup.string()
    //         .max(12, 'Too Long!'),
    //     notes : Yup.string()
    //   }))
  });