import React, { useEffect, useState } from "react";
import moment from "moment";
import { Container, Icon } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { JobTableList } from "../../../utilities/tableUtils";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";

const MyTasksCurrJobs = (props) => {
  const myjobs = props.myjobs ? props.myjobs : undefined;
  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  const [filter, setFilter] = useState("");
  const applyFilter = (status) => {
    setFilter(status);
  };

  // Loading Logic if tasks not fetched
  if (myjobs && myjobs.length == 0) {
    return <p> No records</p>;
  } else {
    return (
      <Container>
        {!myjobs ? (
          <p> No records</p>
        ) : (
          <>
            <JobTableList columns={searchColumns} data={myjobs} />
          </>
        )}
      </Container>
    );
  }
};

export const searchColumns = [
  {
    Header: "Sl No",
    accessor: "Sl No",
    className: "prjb1 ellipsisField",
    headerClassName: "prjb1 tableHeaderCell",
  },
  {
    Header: "Status",
    accessor: "taskStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "prjb2 ellipsisField",
    headerClassName: "prjb2 tableHeaderCell",
  },
  {
    Header: "Ticket",
    accessor: "job",
    className: "prjb3 ellipsisField",
    headerClassName: "prjb3 tableHeaderCell",
  },
  {
    Header: "Workroom",
    accessor: "workroomname",
    className: "prjb4 ellipsisField",
    headerClassName: "prjb4 tableHeaderCell",
  },
  {
    Header: "Priority",
    accessor: "priority",
    className: "prjb5 ellipsisField textAlignRight",
    headerClassName: "prjb5 tableHeaderCell textAlignRight",
  },
  {
    Header: "Deadline",
    accessor: "deadline",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "prjb6",
    headerClassName: "prjb6 tableHeaderCell",
  },
  {
    Header: "Date Start",
    accessor: "dtStart",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "prjb7",
    headerClassName: "prjb7 tableHeaderCell",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    className: "prjb8 ellipsisField",
    headerClassName: "prjb8 tableHeaderCell",
  },
];

export default MyTasksCurrJobs;
