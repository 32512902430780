import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchClient,archiveClient, restoreClient } from '../data/actions'
import * as select from '../data/selectors'
import { searchColumns } from '../data/model'
import { Container, Icon } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet, lodingIconHeight } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
import { TableList } from '../../../utilities/tableUtils'
import { Link } from 'react-router-dom';

const ClientList = (props) => {
    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')
    const [lodingIconPosition, setLodingIconPosition] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        lodingIconHeight(setLodingIconPosition)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
            lodingIconHeight(setLodingIconPosition)
        }, false);
    }, []) 

    
    // const client = useSelector(state => state.client.byId) 
    const client = useSelector(state => select.getClientArray(state))
    const clientFetched = useSelector(state => select.getIsClientFetched(state, props))
    const archiveClientFromList = (values) => {
        const obj = {id: values}
        dispatch(archiveClient(obj))
    }
    const restoreClientFromList = (values) => {
        const obj = {id: values}
        dispatch(restoreClient(obj))
    }
    const ClientActions = ({object: client}) => {
        const Clientstatus = client.status === 1 ? true : false;
        return (
            <>
            {Clientstatus ? 
                <>
                 <Link to={"/client/edit/"+client.id}>Edit</Link><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span style={{cursor: 'pointer', color: 'red'}} onClick={() => archiveClientFromList(client.id)}>Archive</span><span>&nbsp;&nbsp;</span>
                </>
                :<>
                <Link to={"/client/edit/"+client.id}>Edit</Link><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span style={{cursor: 'pointer', color: 'green'}} onClick={() => restoreClientFromList(client.id)}>Restore</span><span>&nbsp;&nbsp;</span>
                </>
                }    
            </> 
        )
    } 

    useEffect(() => {
        if(!clientFetched){
            dispatch(fetchClient());
        }
    });

    
    useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Client List'}))
    }, [])

    // Loading Logic if tasks not fetched
    return (
        <Container>
            {clientFetched === false ? 
                <p><center><Icon size="big" style={{top:lodingIconPosition}} loading name='spinner' /></center></p>  
            : 
                <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}>
                    <div style={{height: (heights-20)+'px', width: "100%"}}>
                        <TableList columns={searchColumns} data={client} Actions={ClientActions} />
                    </div>
                </div>
            }
        </Container>
    )
}

export default ClientList;
