import { Subject } from 'rxjs';

const subject = new Subject();
// let state = initialState;
const viewStore = {
  init: () => subject.next(state),
  subscribe: setState => subject.subscribe(setState),
  toggleView: message => subject.next(processMessage(message)),
  getState: () => state,
  initialState
};

let initialState = {
  component: 'list',
  newViewCount: 0,
};


let state = initialState

const processMessage = (message) => {
  let msg = ''
  if (state.component == "list") msg = "dnd"
  if (state.component == "dnd") msg = "list"

  state = {
    component: msg,
    newViewCount: state.newViewCount + 1
  };
  return state
}

export default viewStore;