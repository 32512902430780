import { createSelector } from 'reselect'
import { startsWith, orderBy, groupBy, cloneDeep, chain } from 'lodash';
import { STATUS_CANCEL, STATUS_COMPLETED, STATUS_HOLD } from '../../app/data/staticData'
import { extractKeyFromJobKey } from '../../../utilities/pathUtils'

export const getIsFetchingJobs = (state, props) => state.jobs.params.isFetching;
export const getIsJobsFetched = (state, props) => state.jobs.params.jobsFetched;
export const getNewJobCreated = (state, props) => state.jobs.params.newJobCreated;
export const getQueueJobsList = (state, prop) => state.jobs.byQueue
export const getJobsList = (state, prop) => state.jobs.byId
export const getMyCreatedJobsList = (state, prop) => state.jobs.byMyCreated
export const getIsSearching = (state, props) => state.jobs.params.isSearching;
export const getIsSearchFetched = (state, props) => state.jobs.params.searchFetched;
export const getSearchResults = (state, prop) => state.jobs.searchResults
export const filters = (state, prop) =>  state.jobs.filterResults ;
export const getDeleteStatus = (state, prop) => state.jobs.deleteStatus
export const getDelFetched = (state, prop) => state.jobs.params.isDelFetched
export const getNotification = (state, id) => state.jobs.notifications[id]


export const getJobById = (state, jobid) => {
    if (jobid == undefined )
        return undefined
    return (state.jobs.byId[jobid])   
}


export const getJobDisplayNameWithWorkroomById = (state, jobid) => {
    if (jobid == undefined )
        return undefined
    let job = getJobById(state, jobid)
    if (job == undefined) return undefined
    let workroom = state.workroom.byId[job.workroomId]
    if (workroom == undefined) return undefined
    return (workroom.sname + ' - ' + job.jobKey + ' - ' + job.jobName)
}

export const getCompletedJobsByWorkroomId = (state, wid ) => {
    const users = state.users.byId
    return chain(Object.values(state.jobs.byId))   
                        .filter( value => { 
                            if (value.workroomId == wid && (value.jobStatus == STATUS_COMPLETED || value.jobStatus == STATUS_CANCEL))
                                return value
                        })
                        .map(j => {
                            let newObj = cloneDeep(j)
                            newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                            newObj.jobOwner = j.ownerId ? users[j.ownerId] ? users[j.ownerId].name : null : null
                            newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                            return newObj
                        })
                        .orderBy([(item)=> item.dtFinish ? item.dtFinish : item.dtUpdate, (item)=> parseInt(extractKeyFromJobKey(item.jobKey))], ['desc','desc'])
                        .map((j, index) => {
                            j["Sl No"] = index + 1
                            return j
                           })
                        .value();
}


export const getActiveJobsByWorkroomId = (state, wid ) => {
    const users = state.users.byId
    return chain(Object.values(state.jobs.byId))   
                        .filter( value => { 
                            if (value.workroomId == wid && value.jobStatus != STATUS_COMPLETED && value.jobStatus != STATUS_CANCEL && value.jobStatus != STATUS_HOLD)
                            return value
                        })
                        .map(j => {
                            let newObj = cloneDeep(j)
                            newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                            newObj.jobOwner = j.ownerId ? users[j.ownerId] ? users[j.ownerId].name : null : null
                            newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                            return newObj
                        })
                        .orderBy([(item)=> item.dtFinish ? item.dtFinish : item.dtUpdate, (item)=> parseInt(extractKeyFromJobKey(item.jobKey))], ['desc','desc'])
                        .map((j, index) => {
                            j["Sl No"] = index + 1
                            return j
                           })
                        .value();
}


export const getHoldJobsByWorkroomId = (state, wid ) => {
    const users = state.users.byId
    return chain(Object.values(state.jobs.byId))   
                        .filter( value => { 
                            if (value.workroomId == wid && (value.jobStatus == STATUS_HOLD))
                                return value
                        })
                        .map(j => {
                            let newObj = cloneDeep(j)
                            newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                            newObj.jobOwner = j.ownerId ? users[j.ownerId] ? users[j.ownerId].name : null : null
                            newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                            return newObj
                        })
                        .orderBy([(item)=> item.dtFinish ? item.dtFinish : item.dtUpdate, (item)=> parseInt(extractKeyFromJobKey(item.jobKey))], ['desc','desc'])
                        .map((j, index) => {
                            j["Sl No"] = index + 1
                            return j
                           })
                        .value();    
}


export const getJobByJobKeyNumber = (state, keynum) => {
    const values = Object.values(state.jobs.byId)
    if (keynum == undefined) return undefined
    const obj = values.filter( (value) => {
        if ((value.jobKey.toLowerCase()) == keynum )
            return value
    })
    return obj[0] // you should get a unique job 
}


export const isJobsFetchedByWorkroomId = (state, wid) => {
    const fetchedW = state.jobs.params.workroomJobsFetched
    if (fetchedW.includes(wid))
        return true
    return false
}


export const getJobsByTeamId = (state, tid) => {
    const values = Object.values(state.jobs.byId)    
    const obj = values.filter( (value) => { 
        if (value.teamId == tid )
            return value
    })
     //sort array in asc order of date
    let sorteddata = orderBy(obj, [(item)=>(item.priority? parseInt(item.priority): null),(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc']);
    return sorteddata
}

export const getCompleteJobsByTeamId = (state, tid) => {
    const values = Object.values(state.jobs.byId)
    const obj = values.filter( (value) => { 
        if (value.teamId == tid && value.jobStatus == STATUS_COMPLETED)
            return value
    })
     //sort array in asc order of date
     let sorteddata = orderBy(obj, [(item)=>(item.priority? parseInt(item.priority): null),(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc']);
    return sorteddata
}


export const isJobDenied = (state, jobkey) => {
    const jobsDenied = state.jobs.params.jobDenied
    if (jobsDenied.includes(jobkey))
        return true
    return false
}


export const getInCompleteJobsByTeamId = (state, tid) => {
    return chain(Object.values(state.jobs.byId))   
                .filter( (value) => value.teamId == tid && value.priority != null && value.jobStatus != STATUS_COMPLETED 
                                                                                  && value.jobStatus != STATUS_CANCEL 
                                                                                  && value.jobStatus != STATUS_HOLD)                                                                                  
                .orderBy([(item)=>item.priority,(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc'])
                .value();
}



export const getInCompleteJobsByTeamIdforList = (state, tid) => {
    const workrooms = state.workroom.byId
    const users = state.users.byId

    return chain(Object.values(state.jobs.byId))
            .filter((value) => { 
                if (value.teamId == tid && value.jobStatus != STATUS_COMPLETED 
                                        && value.jobStatus != STATUS_CANCEL 
                                        && value.jobStatus != STATUS_HOLD)
                    return value
            })
            .map(j => {
                let newObj = cloneDeep(j)
                newObj["workroomName"] = workrooms ?  workrooms[j.workroomId]['name'] : null
                newObj["job"] = newObj["jobKey"] + " - " + newObj["jobName"] 
                newObj.jobOwner = j.ownerId ? users[j.ownerId] ? users[j.ownerId].name : null : null
                newObj.currentUser = users[j.userId] ? users[j.userId].name : null
                newObj.createdBy = users[j.uidCreate] ? users[j.uidCreate].name : null
    return newObj
            })
            .orderBy([(item)=>item.priority,(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc'])
            .value()
}


export const isJobsFetchedByTeamId = (state, tid) => {
    const fetchedT = state.jobs.params.teamJobsFetched
    if (fetchedT.includes(tid))
        return true
    return false
}


export const getJobsParams = (state, props) => {
    const params =  {}
    if(startsWith(state.router.location.pathname,'/jobs/create')){ 
        params.title = state.jobs.params.createTitle
        params.submitButtonText = state.jobs.params.createSubmitButtonText        
    };
    if(startsWith(state.router.location.pathname,'/jobs/edit/')){ 
        params.title = state.jobs.params.editTitle
        params.submitButtonText = state.jobs.params.editSubmitButtonText        
    };
    return params
}


export const selectJobs = createSelector( getJobsList, jobs => { 
    const keys = Object.keys(jobs)
    const obj = keys.map( (key) => { return { key : key, value : key, text : jobs[key].jobKey }})
    return obj
})


export const selectJobsSearched = createSelector( getSearchResults, jobs => { 
    const keys = Object.keys(jobs)
    const obj = keys.map( (key) => { return { key : key, value : key, text : jobs[key].name }})
    return obj
})


// export const getInCompleteJobsByTeamIdWithPriority = (state, tid) => {
//     let workrooms = state.workroom.byId
//     return chain(Object.values(state.jobs.byId))
//             .filter((value) => { 
//                 if (value.teamId == tid && value.priority != null   && value.jobStatus != STATUS_COMPLETED 
//                                                                     && value.jobStatus != STATUS_CANCEL 
//                                                                     && value.jobStatus != STATUS_HOLD)
//                     return value
//             })
//             .map(j => {
//                 let newObj = cloneDeep(j)
//                 newObj["workroomName"] = workrooms ?  workrooms[j.workroomId]['name'] : null
//                 return newObj
//             })
//             .orderBy([(item)=>item.priority,(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc'])
//             .value()
// }

// export const getInCompleteJobsByTeamIdWithoutPriority = (state, tid) => {
//     let workrooms = state.workroom.byId
//     return chain(Object.values(state.jobs.byId))
//             .filter((value) => { 
//                 if (value.teamId == tid && value.priority == null && value.jobStatus != STATUS_COMPLETED 
//                                                                   && value.jobStatus != STATUS_CANCEL 
//                                                                   && value.jobStatus != STATUS_HOLD)
//                     return value
//             })
//             .map(j => {
//                 let newObj = cloneDeep(j)
//                 newObj["workroomName"] = workrooms ?  workrooms[j.workroomId]['name'] : null
//                 return newObj
//             })
//             .orderBy([(item)=>item.priority,(item)=>item.deadline, (item)=>item.dtCreate], ['asc','asc', 'asc'])
//             .value()
// }