import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const processfile = () => ({
    id: v4(),
    key: "",
    category: "",
    description:"",
    name:"",
    version:0,
    resource:"",
    deploymentId :"",
    diagram:"",
    suspended:"",
    tenantId:"",
    versionTag:"",
   /*  segid: "",
    cid: "",
    status: 1,
    dt_create: "",
    dt_update: "",
    user_create: "",
    user_update: "" */
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const processfileSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    code: Yup.string()
                .required('This is a Required Field'),

    name: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dt_create: Yup.string().nullable(),
    dt_update: Yup.string().nullable(),
    user_create: Yup.string().nullable(),
    user_update: Yup.string().nullable()

  });


export const duplicateCheckProcessfileCode = debounce(checkProcessfileCode, 500);
function checkProcessfileCode (value)  {
	if (value.length > 0)
		return axios.post(URL+'/processfile/exists/'+ value, {type: 'checkCode'})
			.then(response => {
						if (response.data==='duplicate') {
							console.log("response",response.data)
							return "Duplicate"
						}
					}) 
			.catch(error => "")
  }


export const duplicateCheckProcessfileName = debounce(checkProcessfileName, 500);
function checkProcessfileName (value)  {
    if (value.length > 2)
        return axios.post(URL+'/processfile/exists/'+ value, {type: 'checkName'})
            .then(response => {
                        if (response.data==='duplicate') {
                            console.log("response",response.data)
                            return "Duplicate"
                        }
                    }) 
            .catch(error => "")
}
  