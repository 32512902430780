import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import { getModuleArray, searchColumns } from "../data/selectors";
import { useSelector, useDispatch } from "react-redux";
import { TableList } from "../../../utilities/tableUtils";
import { fetchModule } from "../data/actions";
import { Link } from "react-router-dom";
import { updateHeaderTitle } from '../../app/data/actions'
function ModuleList() {
  const dispatch = useDispatch();
  const moduleList = useSelector((state) => getModuleArray(state));
 

  useEffect(()=>{dispatch (updateHeaderTitle ({'title':'Module List'}))},[])



  const ModuleActions = ({ object: moduleList }) => {
    return (
      <>
        <Link to={"/module/edit/" + moduleList.id}>Edit</Link>
      </>
    );
  };

  return (
    <Container>
      <TableList
        columns={searchColumns}
        data={moduleList}
        Actions={ModuleActions}
      ></TableList>
    </Container>
  );
}

export default ModuleList;
