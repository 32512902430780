export const CREATE_JOBS = 'CREATE_JOBS'
export const CREATED_JOBS = 'CREATED_JOBS'
export const RESET_CREATED_JOB = 'RESET_CREATED_JOB'

export const SAVED_JOBS = 'SAVED_JOBS'
export const EDIT_JOBS = 'EDIT_JOBS'
export const EDITED_JOBS = 'EDITED_JOBS'

export const UPDATE_JOB_PRIORITY = 'UPDATE_JOB_PRIORITY'
export const UPDATED_JOB_PRIORITY = 'UPDATED_JOB_PRIORITY'
export const UPDATE_JOB_DEADLINE = 'UPDATE_JOB_DEADLINE'
export const UPDATED_JOB_DEADLINE = 'UPDATED_JOB_DEADLINE'
export const UPDATE_JOB_NAME = 'UPDATE_JOB_NAME'
export const UPDATED_JOB_NAME = 'UPDATED_JOB_NAME'

export const SEARCH_JOBS = 'SEARCH_JOBS'
export const STORE_SEARCH_JOBS_RESULT = 'STORE_SEARCH_JOBS_RESULT'
export const DELETE_JOBS = 'DELETE_JOBS'
export const DELETED_JOBS = 'DELETED_JOBS'
export const NOTIFICATION_DONE_JOBS = 'NOTIFICATION_DONE_JOBS'

export const FETCH_QUEUE_JOBS = 'FETCH_QUEUE_JOBS'
export const FETCHED_QUEUE_JOBS = 'FETCHED_QUEUE_JOBS'
export const FETCH_MY_CREATED_JOBS = 'FETCH_MY_CREATED_JOBS'
export const FETCHED_MY_CREATED_JOBS = 'FETCHED_MY_CREATED_JOBS'

export const FETCH_JOBS_BY_JOBKEY = 'FETCH_JOBS_BY_JOBKEY'
export const FETCHED_JOBS_BY_JOBKEY = 'FETCHED_JOBS_BY_JOBKEY'

export const FETCH_JOBS_BY_WORKROOM_ID = 'FETCH_JOBS_BY_WORKROOM_ID'
export const FETCHED_JOBS_BY_WORKROOM_ID = 'FETCHED_JOBS_BY_WORKROOM_ID'
export const FETCH_JOBS_BY_TEAM_ID = 'FETCH_JOBS_BY_TEAM_ID'
export const FETCHED_JOBS_BY_TEAM_ID = 'FETCHED_JOBS_BY_TEAM_ID'


export const REMOVE_PRIORITY = 'REMOVE_PRIORITY'
export const REMOVED_PRIORITY = 'REMOVED_PRIORITY'