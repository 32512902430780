import TemplateSummary from '../components/templateSummary'
import TemplateApproveDev from '../components/templateApproveDev'
import TemplateTasksToDo from '../components/templateTasksToDo'
import TemplateNextIFrame from '../components/templateNextIFrame'
import TemplateIFrame from '../components/templateIFrame'
import TemplateTasksDynamicForm from '../components/templateTasksDynamicForm'

export const activitytemplates = [
   
    {
        'template' : "Template-Software-Ticket-Approval",
        'component' : "TemplateApproveDev",
        'text' : 'Software Ticket Approval'
    },
    {
        'template' : "Template-Todo-Copy-Start-Form",
        'component' : "TemplateTasksToDo",
        'text' : 'To-dos : Copy of Start Form'
    },

]

export const componentTemplates  = {
    "StartForm" : TemplateSummary,
    "TemplateApproveDev" : TemplateApproveDev,
    "TemplateTasksToDo" : TemplateTasksToDo,
    "TemplateIFrame" : TemplateIFrame,
    "TemplateNextIFrame" : TemplateNextIFrame,
    "TemplateTasksDynamicForm" : TemplateTasksDynamicForm
}


export const syncButtonForTemplates  = [
    "TemplateTasksToDo"
]


export const STARTFORM = 'StartForm'