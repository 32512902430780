import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a jobss schema
const jobsSchema = new schema.Entity('jobs',{}, { idAttribute: 'taskId' });
const jobsListSchema = [jobsSchema];


function byId(state = {}, action) {    
    if (action.type === 'FETCHED_QUEUE_JOBS') { 
        const normalizedJobs = normalize(action.payload, jobsListSchema);
        return merge({},state,normalizedJobs.entities.jobs)
    }     
    if (action.type === 'FETCHED_MY_CREATED_JOBS') { 
        const normalizedJobs = normalize(action.payload, jobsListSchema);
        return merge({},state,normalizedJobs.entities.jobs)
    }    
    if (action.type === 'UPDATED_TASK_REPORT') {
      let taskId = action.payload.taskId     
      return {...state, 
        [taskId]: action.payload
      }
    }     
    // if (action.type === 'DELETED_JOBS') {

    //   const {id} = action.payload
    //   const { [id]: idValue, ...restOfState } = state;    
    //   return restOfState
    // } 
    else {
      return state
    }
  }

function byQueue(state = {}, action) {
  if (action.type === 'FETCHED_QUEUE_JOBS') { 
    // console.log (' Action.payload  jobs is ...', action.payload)
    const normalizedJobs = normalize(action.payload, jobsListSchema);
    return merge({}, normalizedJobs.result)
    }
 else {
    return state
  }
}

function byMyCreated(state = {}, action) {
  if (action.type === 'FETCHED_MY_CREATED_JOBS') { 
    // console.log (' Action.payload  jobs is ...', action.payload)
    const normalizedJobs = normalize(action.payload, jobsListSchema);
    return merge({}, normalizedJobs.result)
    }
  if (action.type === 'INSERT_MY_CREATED') { 
    const taskId = action.payload["taskId"]
    console.log("taskId", taskId, action.payload)
      return { ...state, [Object.keys(state).length]: taskId}
      // return state
    }
 else {
    return state
  }
}



const mytasks = combineReducers({
    byQueue,
    byMyCreated,
    byId
});

export default mytasks;

