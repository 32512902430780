import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUsers,resetPassword } from '../data/actions'
import { searchColumns } from '../data/model'
import * as select from '../data/selectors'
import { Container,Label } from 'semantic-ui-react'
import { updateHeaderTitle } from '../../app/data/actions'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { TableList } from '../../../utilities/tableUtils'
import { Link } from 'react-router-dom';
import PasswordChangeModal from '../../app/components/passwordChangeModal'

const UsersList = (props) => {

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 
    

    const users = useSelector(state => select.getSortedUserList(state, props))
    const usersFetched = useSelector(state => select.getIsUsersFetched(state, props))
    const dispatch = useDispatch();
    const data = useMemo(() => users, [users])
    const columns = useMemo(() => searchColumns,[])

    useEffect(()=>{ if(!usersFetched){ dispatch(fetchUsers());}});        
    useEffect(() => {dispatch (updateHeaderTitle ({'title':'Users List'}))}, [])
    const UserActions = ({object: user}) => {
        return (
            <>
            <Link to={"/users/edit/"+user.id}>Edit</Link><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Link to={"/users/teampriority/"+user.id} style={{cursor: 'pointer', color: 'red'}}>Set Team Priority</Link>
            <PasswordChangeModal userId={user.id} pageFrom="userList"/> 
            
            </>
        )
    }
    
    // Loading Logic if tasks not fetched
    if(!usersFetched) {
        return <p>Loading ... </p>
    }else{
        return (
            <Container>
                <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: widths+"px"}}>
                    <div style={{height: (heights-20)+'px', width: "100%"}}>
                        <TableList columns={columns} data={data} Actions={UserActions} />
                    </div>
                </div>
            </Container>
        )
    }
}

export default UsersList;
