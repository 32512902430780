import { combineReducers } from 'redux';
import { merge } from 'lodash'
import { normalize, schema } from 'normalizr';

// Define a processfiles schema
const processfileSchema = new schema.Entity('processfiles',{}, { idAttribute: 'id' });
const processfileListSchema = [processfileSchema];

const defaultProcessfileParams = {
  processfileFetched: false,
  searchFetched: false,
  isDelFetched: false,
  createTitle: 'Create New Processfile',
  createSubmitButtonText: 'Create Processfile',
  editTitle: 'Edit Processfile',
  editSubmitButtonText: 'Edit Processfile' 
}


function byId(state = {}, action) {
    if (action.type === 'SAVED_PROCESSFILE') {
        const processfileID = action.payload._id;
      return {...state, 
        [processfileID]: action.payload
      }
    }
    if (action.type === 'FETCHED_PROCESSFILE') { 
        const normalizedProcessfile = normalize(action.payload, processfileListSchema);
        return merge({},state,normalizedProcessfile.entities.processfiles)
    }
    if (action.type === 'FETCHED_PROCESSFILE_BY_PROCESSID') {
      let data = action.payload
      if ( data.length > 0 ) {
          const key = data[0].key
        return {...state, 
          [key]: action.payload
        }
      } else return state 
  }    
    if (action.type === 'DELETED_PROCESSFILE') {
      if(action.payload.msg === "Success"){
        const processfileID = action.payload.id;
        return {...state, 
          [processfileID]: { ...state[processfileID],
            status :0
          }
        }
      }  else {
        return state
      }
    } else {
      return state
    }
  }

function searchResults(state = {}, action) {
  if (action.type === 'STORE_SEARCH_PROCESSFILE_RESULT') { 
    const normalizedProcessfile = normalize(action.payload, processfileListSchema);
    return merge({},normalizedProcessfile.entities.processfiles)
} else {
    return state
  }
}
    
function notifications ( state = {}, action ) {
  if (action.type === 'DELETED_PROCESSFILE') { 
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        delete: { 
          status : action.payload.msg === "Success" ? 'success' : 'error',
          msg : action.payload.msg
        }
      }
    }
  } 
  if(action.type === 'NOTIFICATION_DONE_PROCESSFILE'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}


function params(state = defaultProcessfileParams, action) {
  if (action.type === 'FETCHED_PROCESSFILE') { 
      return {...state, 
        processfileFetched: true,
        isFetching:false
      }
  }   if (action.type === 'SEARCH_PROCESSFILE') { 
    return {...state, 
      searchFetched: false,
      isSearching: true,
    }
  } 
  if (action.type === 'STORE_SEARCH_PROCESSFILE_RESULT') { 
    return {...state, 
      searchFetched: true,
      isSearching : false,
    }
  } 
  if (action.type === 'DELETED_PROCESSFILE') { 
    return {...state, 
      isDelFetched:true
    }
  }if(action.type === 'SET_PROCESSFILE_FETCH_OFF'){
    return {...state, 
      isDelFetched:false
    }
  } else {
    return state
  }
}

const processfile = combineReducers({
    byId,
    notifications,
    searchResults,
    params
});

export default processfile;

