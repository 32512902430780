import * as type from './types'


export function createWorkroom(workroom) {
    return {
        type : type.CREATE_WORKROOM,
        payload : workroom,
        txn : 'initiated',
    };
}


export function savedWorkroom(workroom) {
    return {
        type : type.SAVED_WORKROOM,
        payload : workroom,
        txn : 'success',
        diff : true
    };
}


export function editWorkroom(workroom) {
    return {
        type : type.EDIT_WORKROOM,
        payload : workroom,
        txn : 'initiated',
    };
}


export function fetchWorkroom() {
    return {
        type : type.FETCH_WORKROOM,
    };
}


export function fetchedWorkroom(workroom) {
    return {
        type : type.FETCHED_WORKROOM,
        payload : workroom
    };
}




export function fetchUsersInWorkroom(workroom) {
    return {
        type : type.FETCH_USERS_IN_WORKROOM,
        payload : workroom
    };
}


export function fetchedUsersInWorkroom(users) {
    return {
        type : type.FETCHED_USERS_IN_WORKROOM,
        payload : users
    };
}


export function fetchMyListWorkroom() {
    return {
        type : type.FETCH_MY_LIST_WORKROOM,
    };
}


export function fetchedMyListWorkroom(workroom) {
    return {
        type : type.FETCHED_MY_LIST_WORKROOM,
        payload : workroom
    };
}


export function searchWorkroom(workroom) {
    return {
        type : type.SEARCH_WORKROOM,
        payload : workroom
    };
}


export function storeSearchWorkroomResult(workroom) {
    return {
        type : type.STORE_SEARCH_WORKROOM_RESULT,
        payload : workroom
    };
}


export function deleteWorkroom(workroom) {
    return {
        type : type.DELETE_WORKROOM,
        payload: workroom
    };
}


export function deletedWorkroom(msg) {
    return {
        type : type.DELETED_WORKROOM,
        payload : msg
    };
}

export function archiveWorkroom(workroom) {
    console.log(workroom)
    return {
        type : type.ARCHIVE_WORKROOM,
        payload: workroom
    };
}

export function archivedWorkroom(msg) {
    return {
        type : type.ARCHIVED_WORKROOM,
        payload : msg
    };
}

export function restoreWorkroom(workroom) {
    console.log(workroom)
    return {
        type : type.RESTORE_WORKROOM,
        payload: workroom
    };
}

export function restoredWorkroom(msg) {
    return {
        type : type.RESTORED_WORKROOM,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_WORKROOM,
        payload: props
    };
}

export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}