import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as select from "../data/selectors";
import { Tab, Grid } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
} from "../../../utilities/heightForListing";
import { updateHeaderTitle } from "../../app/data/actions";
import JobsListByWorkroom from "../../jobs/components/jobsListByWorkroom";
import JobsCompletedListByWorkroom from "../../jobs/components/jobsListCompletedByWorkroom";
import JobsListOnHoldByWorkroom from "../../jobs/components/jobsListOnHoldByWorkroom";
import AttachmentsDisplayWorkroom from "../../comments/components/attachmentsDisplayWorkroom";
import CommentsList from "../../comments/components/commentsList";
import { editLastSeen, updateUserOnline } from "../../app/data/actions";
import { getCurrUserId } from "../../../utilities/pathUtils";
import { fetchComments } from "../../comments/data/actions";
import { isJobsFetchedByWorkroomId } from "../../jobs/data/selectors";
import { fetchJobsByWorkroomId } from "../../jobs/data/actions";
import WorkRoomModuleIFrame from "./workRoomModuleIFrame";

const WorkroomDispContainer = (props) => {
  const wsname = props.match.params.sname;
  const workroom = useSelector((state) =>
    select.getWorkroomFromShortName(state, wsname)
  );
  const workroomId = workroom ? workroom.id : undefined;
  const jobsFetched = useSelector((state) =>
    isJobsFetchedByWorkroomId(state, workroomId)
  );

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  const [currActiveIndex, setCurrActiveIndex] = useState(-1);
  const dispatch = useDispatch();
  const [panes, setPanes] = useState([]);

  useEffect(() => {
    heightSet(setHeight);
    widthSet(setWidth);
    innerHeightSet(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        heightSet(setHeight);
        widthSet(setWidth);
        innerHeightSet(setInnerHeight);
      },
      false
    );
  }, []);

  useEffect(() => {
    let path = props.match.path;
    let hash = props.location.hash;
    if (path == "/workroom/view/:sname" && hash == "") {
      setCurrActiveIndex(0);
    }
    if (path == "/workroom/view/:sname" && hash == "#archived") {
      setCurrActiveIndex(1);
    }
    if (path == "/workroom/view/:sname" && hash == "#jobs") {
      setCurrActiveIndex(2);
    }
  }, [props.location.pathname, props.location.hash, props.location.key]);

  useEffect(() => {
    if (workroom) {
      dispatch(fetchComments({ workroomId: workroom.id, dtCreated: 0 }));
      dispatch(updateHeaderTitle({ title: workroom.name }));
      dispatch(fetchJobsByWorkroomId({ workroomId: workroomId }));
      document.title = "Workroom: " + workroom.name;
    }
  }, [workroom]);

  useEffect(() => {
    const wroomId = workroomId;
    if (currActiveIndex == 0 && workroomId) {
      // that is it goes off from comments panel
      // dispatch last seen time
      console.log(
        "~~~~~~~~~~~~~ Setting the online status  ~~~~~~~~~~~~~~~",
        props.location.pathname
      );
      dispatch(
        updateUserOnline({ userId: getCurrUserId(), workroomId: wroomId })
      );
    }

    return () => {
      if (currActiveIndex == 0) {
        // that is it goes off from comments panel
        // dispatch last seen time
        console.log(
          "~~~~~~~~~~~~~ currActiveIndex workroom ~~~~~~~~~~~~~~~",
          props.location.pathname
        );
        let userid = getCurrUserId();
        if (userid && wroomId)
          dispatch(editLastSeen({ userId: userid, workroomId: wroomId }));
      }
    };
  }, [currActiveIndex, props.location.pathname]);

  // comments panel is index =0 here ( 1st tab) -
  // if any changes done to the sequence the useEffect above where we are dispatching last seen should be changed too
  const initialPanes = [
    {
      menuItem: "Chat",
      render: () => (
        <Tab.Pane className="borderNoneObj textAlignLeft">
          <CommentsList
            {...props}
            isWorkroomList={true}
            workroomId={workroomId}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Attachments",
      render: () => (
        <Tab.Pane className="borderNoneObj textAlignLeft">
          <AttachmentsDisplayWorkroom workroomId={workroomId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Current Jobs",
      render: () => (
        <Tab.Pane className="borderNoneObj textAlignLeft">
          <JobsListByWorkroom workroomId={workroomId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Archived Jobs",
      render: () => (
        <Tab.Pane className="borderNoneObj textAlignLeft">
          <JobsCompletedListByWorkroom workroomId={workroomId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Jobs on Hold",
      render: () => (
        <Tab.Pane className="borderNoneObj textAlignLeft">
          <JobsListOnHoldByWorkroom workroomId={workroomId} />
        </Tab.Pane>
      ),
    },
  ];

  let appItem = {
    menuItem: "App",
    render: () => (
      <Tab.Pane className="borderNoneObj">
        <WorkRoomModuleIFrame {...props} workroom={workroom} />
      </Tab.Pane>
    ),
  };

  useEffect(() => {
    if (
      workroom &&
      workroom.moduleUrl != null &&
      workroom.moduleUrl.startsWith("http")
    ) {
      initialPanes.push(appItem);
    }
    setPanes(initialPanes);
  }, [workroom]);

  return (
    <Grid divided columns="equal">
      <Grid.Column
        style={{ height: innerHeights - 120 + "px" }}
        className="borderNoneObj paddingTopZero"
      >
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            borderless: true,
            attached: true,
          }}
          defaultActiveIndex={0}
          activeIndex={currActiveIndex}
          onTabChange={(event, data) => {
            setCurrActiveIndex(data.activeIndex);
          }}
          panes={panes}
          className="borderNoneObj"
        />
      </Grid.Column>

      {/* <TeamRightMenu {...props}/> */}
    </Grid>
  );
};

export default WorkroomDispContainer;
