import React, { useEffect, useState }from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getProcess, getNotification, getProcessParams, getIsProcessFetched } from '../data/selectors';
import {getActivityByProcessId} from '../../activities/data/selectors'
import { createProcess, editProcess, setNotifyDone } from '../data/actions';
import { processSchema, duplicateCheckProcessName } from '../data/model'
import {getProcessFileByProcessKey} from '../../processfile/data/selectors'
import { Table, TableHeader, TableBody, Form, Button } from 'semantic-ui-react';
import { Formik, Field, Form as FormikForm, FieldArray } from 'formik';
import { FormikInputComponent } from '../../../utilities/formUtils';
import userACL from '../../../store/access'
import { heightSet } from '../../../utilities/heightForListing'
import Notification from '../../../utilities/notificationUtils'


const ProcessFormEdit = (props) => {
    const [heights, setHeight] = useState('')
    const [savedProcessId, setSavedProcessId] = useState(false)
    
    /* useEffect(() => {
        heightSet(setHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
        }, false);
    }) */
    // //console.log(savedProcessId, "==savedProcessId");
    
    // const processFetched = useSelector(state=> getIsProcessFetched(state, props))
    // const processFetched = useSelector(state => select.getIsProcessFetched(state, props))

    const dispatch = useDispatch()
    let processName = "";
    let processId = "";

    processName = props.location.processName === undefined ? "" : props.location.processName;
    processId = props.location.processId === undefined ? "" : props.location.processId;

    const process = useSelector(state => getProcess(state, props))
    const params = useSelector(state => getProcessParams(state, props))

    
    processName = process === undefined ?"" : process.processName

    const saveProcess = (values, resetForm) => {
        
        if (props.match.path === '/process/edit/:id') {
            userACL.atUpdate(values)
            dispatch(editProcess(values))
            setSavedProcessId(values.id)
            setTimeout(function(){ 
                props.history.push({pathname: "/process/list", processId:values.id, processName: values.processName})    
            }, 2000);
        }
    }
    
    return (
                    <>
                    <Formik id="processForm" size="large" width={5}
                        initialValues={process}
                        validationSchema={processSchema}
                        onSubmit={(values, { resetForm }) => saveProcess(values, resetForm)}
                        render={({ handleSubmit, onChange, values, handleChange }) => (
                            <Form as={FormikForm} size="large" className =  "CustomeForm" width={5} onSubmit={handleSubmit}>
                                <Form.Group widths={2}>
                                    <Field name="processKey" label = 'Process Key' component={FormikInputComponent}></Field>
                                    <Field name="processType" label = 'Process Type' component={FormikInputComponent}></Field>
                                </Form.Group>
                                <Field style={{width: "65.5%"}} name="processName" label = 'Process Name' component={FormikInputComponent}></Field>
                                <Field name="processObjective" label = 'Process Objective' component={FormikInputComponent}></Field>
                                <Field name="processNotes" label = 'Process Notes' component={FormikInputComponent}></Field>

                                <Button type="submit" floated="left" color="blue" size="medium" className="CustomeBTN"> {params.submitButtonText} </Button>
                                {savedProcessId ?
                                    <Notification id={savedProcessId} notifySelector={getNotification} notifyDoneAction={setNotifyDone} type='save'/>
                                    : 
                                null}
                            </Form>
                        )}
                    />
            </>
    )
}

export default ProcessFormEdit;