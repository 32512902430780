import * as Yup from 'yup';
import v4 from 'uuid';
import { parseDateFromFormats } from '../../../utilities/parseDate';
import moment from 'moment'
import axios from 'axios';
import { handlError, parseError } from '../../app/serverError'
import {URL} from '../../../store/path'
import { debounce } from 'lodash'


export const app = () => ({
    id: v4(),
    code: "",
    name: "",
    segid: "",
    cid: "",
    status: 1,
    dt_create: "",
    dt_update: "",
    user_create: "",
    user_update: ""
})


Yup.addMethod(Yup.date, 'format', parseDateFromFormats);
export const appSchema = Yup.object().shape({

    id: Yup.string()
                .required('This is a Required Field'),

    code: Yup.string()
                .required('This is a Required Field'),

    name: Yup.string()
                .required('This is a Required Field'),

    status: Yup.number().integer().nullable(),
    segid: Yup.string().nullable(),
    cid: Yup.string().nullable(),
    txnid : Yup.string().nullable(),
    dt_create: Yup.string().nullable(),
    dt_update: Yup.string().nullable(),
    user_create: Yup.string().nullable(),
    user_update: Yup.string().nullable()

  });



  