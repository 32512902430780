import { txns } from "./store/txnMiddleware";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import tasks from "./modules/tasks/data/reducers";
import taskdetails from "./modules/taskdetails/data/reducers";
import users from "./modules/users/data/reducers";
import login from "./modules/login/data/reducers";
import process from "./modules/process/data/reducers";

import processfile from "./modules/processfile/data/reducers";
import activity from "./modules/activities/data/reducers";
import workroom from "./modules/workRoom/data/reducers";
import client from "./modules/client/data/reducers";
import team from "./modules/team/data/reducers";
import app from "./modules/app/data/reducers";
import jobs from "./modules/jobs/data/reducers";
import mytasks from "./modules/mytasks/data/reducers";
import comments from "./modules/comments/data/reducers";
import scheduledprocess from "./modules/scheduledprocess/data/reducers";
import roles from "./modules/rolesAndPermission/data/reducers";
import module from "./modules/module/data/reducers";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    txns,
    activity,
    process,
    processfile,
    tasks,
    taskdetails,
    users,
    login,
    jobs,
    mytasks,
    scheduledprocess,
    workroom,
    client,
    team,
    app,
    comments,
    roles,
    module,
  });

export default rootReducer;
