import { combineReducers } from "redux";
import { normalize, schema } from "normalizr";
import { merge } from "lodash";

// Define a role and permission schema
const roleSchema = new schema.Entity("role", {}, { idAttribute: "id" });
const roleListSchema = [roleSchema];

const permissionSchema = new schema.Entity("permission",{},{ idAttribute: "id" });
const permissionListSchema = [permissionSchema];


function byId(state = {}, action) {
  if (action.type === "SAVED_ROLE") {
    const roleId = action.payload.id;
    return {
      ...state,
      [roleId]: action.payload,
    };
  }
  if (action.type === "FETCHED_ROLE") {
    const normalizedRole = normalize(action.payload, roleListSchema);
    return merge({}, state, normalizedRole.entities.role);
  } else {
    return state;
  }
}

function permissionById(state = {}, action) {
  if (action.type === "SAVED_PERMISSION") {
    const permissionId = action.payload.id;
    return {
      ...state,
      [permissionId]: action.payload,
    };
  }
  if (action.type === "FETCHED_PERMISSION") {
    const normalizedPermission = normalize(action.payload,permissionListSchema);
    return merge({}, state, normalizedPermission.entities.permission);
  }
  if (action.type === "REMOVED_PERMISSION") {
    const {[action.payload.id]:del,...rest} = state 
    return rest
  } else {
    return state;
  }
}


const roles = combineReducers({
  byId,
  permissionById,
});

export default roles;
