import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedTeam } from '../data/actions'
import * as select from '../data/selectors'
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'


const TeamSelect = ({ name, isTxn, required, isLabel, label, isSelection, multiple, getValue : callback }, props) => {

    const team = useSelector(state => state.team.byId)
    const teamFetched = useSelector(state => select.getIsTeamFetched(state, props))
    const options = useSelector(state => select.selectTeam(state, props))
    const dispatch = useDispatch()
    // console.log(team, "==team");
    
    useEffect(()=>{
        if (!teamFetched)
            dispatch(fetchedTeam(team));
    },[]);  
 

    const getValue = (values) => {
        //console.log("Team Selected", values, name)
    }


    if (!teamFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {        
        return (
            <Field name={name} 
                isLabel={isLabel}
                required = {required} 
                isTxn={isTxn} 
                label={label}
                multiple={multiple}
                component={FormikSelectComponent} 
                userProps={{ options, getValue }}> 
            </Field>
        )
    }

}


export default TeamSelect;
