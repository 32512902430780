import { combineReducers } from 'redux';
import { merge, groupBy} from 'lodash'
import { normalize, schema } from 'normalizr';

const defaultActivityParams = {
  activityFetched: false,
  activityDefinitionUpdated: false,
  createTitle: 'Create New Activity',
  createSubmitButtonText: 'Create Activity',
  editTitle: 'Edit Activity',
  editSubmitButtonText: 'Edit Activity' 
}

const activitySchema = new schema.Entity('activity',{}, { idAttribute: 'processId' });
const activityListSchema = [activitySchema];

function byId(state = {}, action) {
    if (action.type === 'SAVED_ACTIVITY') {
        const activityID = action.payload.id;
        const processId = action.payload.processId

        let existingarray = (state[processId]) ? state[processId] : []

        // this could already be there in the selector. Hence filtering out old object and putting in new
        existingarray = existingarray.filter(function( obj ) {
          return obj.id !== activityID;
      });
  
      return {...state, 
        [processId]: [...existingarray, action.payload]
      } 

    }
    if (action.type === 'UPDATED_ACTIVITY_DEFINITION') {
      let data = action.payload
      const processId = data[0].processId
    return {...state, 
      [processId]: action.payload
    }
  }
  if (action.type === 'FETCHED_ACTIVITY_BY_PROCESS') {
    let data = action.payload
    if (data.length > 0) {
        const processId = data[0].processId
      return {...state, 
        [processId]: action.payload
      }
    } else return state
}
    if (action.type === 'FETCHED_ACTIVITY') { 
      // console.log (' Action payload is  is ', action.payload)
      let groupedProcess = groupBy (action.payload, 'processId')
      // console.log (' Grouped by payload is  is ', groupedProcess)
      //  Object.keys(groupedProcess).map ( (process) => {

      //  })
      // const normalizedActivity = normalize(action.payload, activityListSchema);
      // console.log (' Normalised activities for activity is ', normalizedActivity)
      return merge({},state,groupedProcess)
    } else {
      return state
    }
  }


  
function params(state = defaultActivityParams, action) {
  if (action.type === 'FETCHED_ACTIVITY') { 
      return {...state, 
        activityFetched: true,
        isFetching:false
      }
  } else {
    if (action.type === 'UPDATED_ACTIVITY_DEFINITION') { 
      return {...state, 
        activityDefinitionUpdated: true,
        // isFetching:false
      }
  } if (action.type === 'RESET_ACTIVITY_DEFINITION_FLAG') { 
    return {...state, 
      activityDefinitionUpdated: false,
    }
  } else {
    return state
  }
}}

function notifications ( state = {}, action ) {

  if (action.type === 'SAVED_ACTIVITY') {
    return {...state, 
      [action.payload.id]: { ...state[action.payload.id],
        save: { 
          status : action.txn === "success" ? 'success' : 'error',
          msg : action.txn
        }
      }
    }    
  }
  if(action.type === 'NOTIFICATION_DONE_ACTIVITY'){
    const {id, type} = action.payload
    // Remove the 'id' element from state
    const { [id]: idValue, ...restOfState } = state;
    // Remove the 'type' from the 'id' element
    const { [type]: removedValue, ...restOfId } = idValue;
    // Merge back together
    const finalState = { ...restOfState, [id]: restOfId };     
    return finalState

  } else {
    return state
  }
}

const activity = combineReducers({
    byId,
    params,
    notifications
});

export default activity;

