import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsStepsFetched } from './selectors'
import {savedSteps,fetchedSteps, storeSearchStepsResult, deletedSteps } from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL }from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import axios from 'axios';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//#region Saga for Create Steps 

function* createSteps() {
    yield takeEvery('CREATE_STEPS',saveSteps)
  }

function* saveSteps(action){
  const { response, error } = yield call(saveStepsAPI, action.payload)
  //console.log("Saga",action.payload)
  if (response) yield put (savedSteps(action.payload))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveStepsAPI(data) {
    return axios.post(URL+'/steps/add', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Steps 

function* editSteps() {
  yield takeEvery('EDIT_STEPS',saveEditedSteps)
}

function* saveEditedSteps(action){
  const { response, error } = yield call(editStepsAPI, action.payload)  
  if (response) yield put (savedSteps(action.payload))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editStepsAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/steps/update/'+ user_id, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Steps 

function* deleteSteps() {
  yield takeEvery('DELETE_STEPS',saveDeleteSteps)
}

function* saveDeleteSteps(action){
  const { response, error } = yield call(deleteStepsAPI, action.payload)
  if (response) yield put (deletedSteps({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteStepsAPI(data) {
  let stepsId = data.id
  return axios.post(URL+'/steps/delete/'+ stepsId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Steps 

function* fetchSteps() { 
  yield takeEvery('FETCH_STEPS',requestSteps)
}
  

function* requestSteps(){
  const isStepsFetched = yield select(getIsStepsFetched);
  if(!isStepsFetched){
    let { data } = yield call(requestStepsAPI)    
    yield put (fetchedSteps(data))
  }
}


function requestStepsAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.post(URL+'/steps/list', data, getHeaderJWT())
}

//#endregion


//#region Saga for Search Steps 

function* searchSteps() {

  yield takeLatest('SEARCH_STEPS',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchStepsResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/steps/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    //console.log(error)
    console.groupEnd()
}

export default function* stepsSaga() {
    yield all([
        createSteps(),
        fetchSteps(),
        editSteps(),
        deleteSteps(),
        searchSteps()
    ])
  }  