import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {fetchScheduledprocess, deleteScheduledprocess, deletedScheduledprocess } from '../data/actions'
import * as select from '../data/selectors'
import * as workroomSelect from '../../workRoom/data/selectors'
import { searchColumns } from '../data/model'
import { Container,Modal,Icon,Button,Message } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
import { TableList } from '../../../utilities/tableUtils'
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

       

const ScheduledProcessList = (props) => {

    const [heights, setHeight] = useState('')
    const [innerHeights, setInnerHeight] = useState('')
    const [widths, setWidth] = useState('')

    useEffect(() => {
        heightSet(setHeight)
        widthSet(setWidth)
        innerHeightSet(setInnerHeight)
        window.addEventListener('resize', function(){
            heightSet(setHeight)
            widthSet(setWidth)
            innerHeightSet(setInnerHeight)
        }, false);
    }, []) 
    const dispatch = useDispatch();
    const scheduledprocess = useSelector(state => select.getScheduledprocessArray(state))
    console.log("scheduledprocess==",scheduledprocess)
    const scheduledprocessFetched = useSelector(state => select.getIsScheduledprocessFetched(state, props))
    const workRoomFetched = useSelector(state => workroomSelect.getIsWorkroomFetched(state, props))
    const getDelFetched =  useSelector(state => select.getDelFetched(state, props))
    setTimeout(function(){ 
        if(getDelFetched){
        dispatch(deletedScheduledprocess({msg : "error"}))
        }
    }, 1200);
    useEffect(()=>{
        if(!scheduledprocessFetched ){
            dispatch(fetchScheduledprocess());
        }
    }, [scheduledprocessFetched]);   
    const deleteScheduledProcessFromList = (values) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => dispatch(deleteScheduledprocess(values))
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
        // alert(values)
        // const obj = {id: values}
        // values.status = 0;
        // setOpen(false)
        // dispatch(deleteScheduledprocess(values))
    }

    useEffect(() => {
        dispatch (updateHeaderTitle ({'title':'Scheduled Process List'}))
    }, [])

    // Loading Logic if tasks not fetched
    if (!scheduledprocessFetched && !workRoomFetched){
        return <p>Loading ... </p>
    }  else {
        return (
            <Container>
                {scheduledprocessFetched === false ? 
                    <p>Loading ... </p> 
                : 
                    <div id="outerDiv" className='outerDivClass paddingRight20'  style={{width: (widths+70)+"px"}}>
                        {getDelFetched ?
                        <Message compact success style={{"right":"1%","position":"fixed","top":"0%"}}>
                        <Message.Header>Success !</Message.Header>
                            <p>
                            Scheduled process successfully deleted.
                            </p>
                            {}
                        </Message>
                        : 
                    null} 
                        <div style={{height: (heights-20)+'px', width: "100%"}}>
                            <TableList columns={searchColumns} data={scheduledprocess} Actions={ScheduledProcessActions} fn={{deleteScheduledProcessFromList}} />
                        </div>
                        
                    </div>
                }
            </Container>
        )
    }
}

const ScheduledProcessActions = ({object: scheduledProcess, fn,extra}) => {
    return (
        <>
            <Link to={{pathname: "/scheduledprocess/edit/"+scheduledProcess.id}}>Edit</Link><span>&nbsp;&nbsp;</span>
            <span style={{cursor: 'pointer', color: 'red'}} onClick={() => fn.deleteScheduledProcessFromList(scheduledProcess)}>Delete</span><span>&nbsp;&nbsp;</span>
            {/* <Modal
                // basic
                onClose={() => fn.setOpen(false)}
                onOpen={() => fn.setOpen(true)}
                open={extra.openstat}
                // size='small'
                trigger={<span style={{cursor: 'pointer', color: 'red'}}>Delete {scheduledProcess.id}</span>}
                >
            <Modal.Content>
                <p>
                Are you sure you want to delete? 
                </p>
            </Modal.Content>
            <Modal.Actions>
            <Button color='red' onClick={() => fn.setOpen(false)}>No</Button>
            <Button
                content="Yes"
                labelPosition='right'
                icon='checkmark'
                onClick={() => fn.deleteScheduledProcessFromList(scheduledProcess.id)}
                positive
                />
            </Modal.Actions>
                </Modal>  */}
        </>            
    )
} 

export default ScheduledProcessList;
