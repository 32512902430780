import { createSelector } from 'reselect'
import { startsWith, cloneDeep, orderBy } from 'lodash';
import { comments } from './model'
import { textFilter, numberFilter } from '../../../utilities/listUtils'

export const getIsFetchingComments = (state, props) => state.comments.params.isFetching;
export const getIsCommentsFetched = (state, props) => state.comments.params.commentsFetched;
export const getSignedUrl = (state, props) => state.comments.params.signedUrl;
export const getCommentsList = (state, prop) => state.comments.byId
export const getIsSearching = (state, props) => state.comments.params.isSearching;
export const getIsSearchFetched = (state, props) => state.comments.params.searchFetched;
export const getSearchResults = (state, prop) => state.comments.searchResults
export const filters = (state, prop) =>  state.comments.filterResults ;
export const getDeleteStatus = (state, prop) => state.comments.deleteStatus
export const getDelFetched = (state, prop) => state.comments.params.isDelFetched
export const getNotification = (state, id) => state.comments.notifications[id]

export const getComments = (state, props) => {
    if(props.match.path==='/comments/create'){
        return comments()
    }
    if(props.match.path==='/comments/edit/:id'){
        let _id = props.match.params.id
        let obj = cloneDeep(state.comments.byId[_id]);
        return obj        
    }
}


export const getCommentsByWorkroomId=(state, workroomid) =>{ 
    // if (!getIsCommentsFetched(state)) 
        // return undefined
    let all_comments = state.comments.byId
    if (!all_comments || all_comments.length == 0 ) 
        return undefined
    let comments = all_comments[workroomid]
    if ( !comments) return undefined
     comments = Object.values(comments)
    let sorteddata = orderBy(comments, [(item)=>item.dtCreate], ['asc']);
    return sorteddata

}

export const getCommentsByJobAndWorkroomId=(state, workroomid, jobid) =>{ 
    // if (!getIsCommentsFetched(state)) 
        // return undefined
    let all_comments = state.comments.byId
    if (!all_comments || all_comments.length == 0 ) 
        return undefined
        
    let comments = all_comments[workroomid]
    if ( !comments) return undefined

    comments = Object.values(comments)
    const jobcomments = comments.filter( (value) => { 
        if (value.jobId == jobid )
        {
            return value
        }})
    let sorteddata = orderBy(jobcomments, [(item)=>item.dtCreate], ['asc']);
    return sorteddata

}

export const getAttachmentsByWorkroomId=(state, workroomid) =>{ 
    // if (!getIsCommentsFetched(state)) 
        // return undefined
    let all_comments = state.comments.byId
    if (!all_comments || all_comments.length == 0 ) 
        return undefined
        
    let comments = all_comments[workroomid]
    if ( !comments) return undefined
    
    comments = Object.values(comments)
    
    const attachments = comments.filter( (value) => { 
        if (value.attachmentId != null  && value.attachmentId != '' )
        {
            return value
        }})
    let sorteddata = orderBy(attachments, [(item)=>item.dtCreate], ['asc']);
    return sorteddata

}


export const getAttachmentsByJobAndWorkroomId=(state, workroomid, jobid) =>{ 
    let attachments = getAttachmentsByWorkroomId(state, workroomid)
    if (!attachments) return undefined
    
    const jobattachments = attachments.filter( (value) => { 
        if (value.jobId == jobid )
        {
            return value
        }})
    let sorteddata = orderBy(jobattachments, [(item)=>item.dtCreate], ['asc']);
    return sorteddata

}

export const getCommentsParams = (state, props) => {
    const params =  {}

    if(startsWith(state.router.location.pathname,'/comments/create')){ 
        params.title = state.comments.params.createTitle
        params.submitButtonText = state.comments.params.createSubmitButtonText        
    };

    if(startsWith(state.router.location.pathname,'/comments/edit/')){ 
        params.title = state.comments.params.editTitle
        params.submitButtonText = state.comments.params.editSubmitButtonText        
    };

    return params
}

export const selectComments = createSelector(
    getCommentsList,
    comments => { 
        const keys = Object.keys(comments)
        const obj = keys.map( (key) => { return { key : key, value : key, text : comments[key].name }})
        return obj
    })


export const selectCommentsSearched = createSelector(
    getSearchResults,
    comments => { 
        const keys = Object.keys(comments)
        const obj = keys.map( (key) => { return { key : key, value : key, text : comments[key].name }})
        return obj
    })

