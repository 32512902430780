import { call, takeEvery, takeLatest, delay, all, put, select } from 'redux-saga/effects'
import { getIsOrganisationFetched } from './selectors'
import {savedOrganisation,fetchedOrganisation, storeSearchOrganisationResult, deletedOrganisation } from './actions'
import { handlError, parseError } from '../../app/serverError'
import { getHeaderJWT, URL } from '../../../store/path'
import userACL from '../../../store/access' 
import { merge } from 'lodash' 
import axios from 'axios';

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

//#region Saga for Create Organisation 

function* createOrganisation() {
    yield takeEvery('CREATE_ORGANISATION',saveOrganisation)
  }

function* saveOrganisation(action){
  const { response, error } = yield call(saveOrganisationAPI, action.payload)
  // //console.log("Saga",action.payload)
  if (response) yield put (savedOrganisation(action.payload))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function saveOrganisationAPI(data) {
    return axios.post(URL+'/organisations/add', data, getHeaderJWT())
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }

//#endregion


//#region Saga for Edited Organisation 

function* editOrganisation() {
  yield takeEvery('EDIT_ORGANISATION',saveEditedOrganisation)
}

function* saveEditedOrganisation(action){
  const { response, error } = yield call(editOrganisationAPI, action.payload)  
  if (response) yield put (savedOrganisation(action.payload))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function editOrganisationAPI(data) {
  let user_id = data.id
  return axios.post(URL+'/organisations/update/'+ user_id, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for Delete Organisation 

function* deleteOrganisation() {
  yield takeEvery('DELETE_ORGANISATION',saveDeleteOrganisation)
}

function* saveDeleteOrganisation(action){
  const { response, error } = yield call(deleteOrganisationAPI, action.payload)
  if (response) yield put (deletedOrganisation({id: action.payload.id, msg : response.data}))
  else {
    yield put (handlError(action, parseError(error)))
    sagaErrorMessage(error,action)  
  }
}

function deleteOrganisationAPI(data) {
  let organisationId = data.id
  return axios.post(URL+'/organisations/delete/'+ organisationId, data, getHeaderJWT())
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//#endregion


//#region Saga for List Organisation 

function* fetchOrganisation() {
  yield takeEvery('FETCH_ORGANISATION',requestOrganisation)
}
  

function* requestOrganisation(){
  const isOrganisationFetched = yield select(getIsOrganisationFetched);
  if(!isOrganisationFetched){
    let { data } = yield call(requestOrganisationAPI)
    yield put (fetchedOrganisation(data))
  }
}


function requestOrganisationAPI(data) {
  data = merge({}, data, userACL.atFetch())
  return axios.post(URL+'/organisations/list', data, getHeaderJWT())
}

//#endregion


//#region Saga for Search Organisation 

function* searchOrganisation() {

  yield takeLatest('SEARCH_ORGANISATION',initiateSearch)

}
  
function* initiateSearch(action){
  yield delay(350)
  let { data } = yield call(searchAPI, action.payload)
  yield put (storeSearchOrganisationResult(data))

}

function searchAPI(data) {
  return axios.post(URL+'/organisations/search/'+ data, data, getHeaderJWT())
}

//#endregion


const sagaErrorMessage = (error, action) => {
    console.group("Saga Error:"+action.type)
    // //console.log(error)
    console.groupEnd()
}

export default function* organisationSaga() {
    yield all([
        createOrganisation(),
        fetchOrganisation(),
        editOrganisation(),
        deleteOrganisation(),
        searchOrganisation()
    ])
  }  