import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchTasksByJob, setActiveTask } from '../data/actions'
import { fetchComments} from '../../comments/data/actions'
import { getCommentsByJobAndWorkroomId } from '../../comments/data/selectors'
import { fetchTaskdetailsByJob } from '../../taskdetails/data/actions'
import * as select from '../data/selectors'
import { Container, Tab, Grid } from 'semantic-ui-react'
import { heightSet, widthSet, innerHeightSet } from '../../../utilities/heightForListing'
import { updateHeaderTitle } from '../../app/data/actions'
import TemplateSummary from '../../taskdetails/components/templateSummary'
import TemplateSummaryDynamic from '../../taskdetails/components/templateSummaryDynamic'
import TasksRightMenu from './tasksRightMenu'
import TaskTemplate from './taskTemplate'
import { getProcessFromId } from '../../process/data/selectors'
import { getJobDisplayNameWithWorkroomById, getJobByJobKeyNumber, isJobDenied } from '../../jobs/data/selectors'
import { getWorkroomShortNameFromId } from '../../workRoom/data/selectors'
import CommentsList from '../../comments/components/commentsList'
import AttachmentsDisplay from '../../comments/components/attachmentsDisplay'
import { getActivityByProcessId } from '../../activities/data/selectors'
import { fetchActivityByProcess } from '../../activities/data/actions'
import { fetchJobsByJobKey } from '../../jobs/data/actions'

const TasksDispContainer = (props) => {

  const dispatch = useDispatch();

  const activityId = props.location.activityId

  const jobKey = (props.match.path == '/jobs/view/:key' )? props.match.params.key : (props.job ? props.job.jobKey : (props.location.job? props.location.job.jobKey : undefined))
  const [currActiveIndex, setCurrActiveIndex] = useState(0)
  const [permissionDenied, setPermissionDenied] = useState(false)

  const [heights, setHeight] = useState('')
  const [innerHeights, setInnerHeight] = useState('')
  const [widths, setWidth] = useState('')

  const job = useSelector ( state => getJobByJobKeyNumber ( state, jobKey))
  const jobId = job ? job.id : undefined

  const processId = job ? job.processId : undefined
  const process = useSelector(state => getProcessFromId(state, processId))
  const taskList = useSelector(state=> select.getTasksByJobId(state, jobId))
  const isjobdenied = useSelector (state => isJobDenied ( state, jobKey))
  const dispName = useSelector(state => getJobDisplayNameWithWorkroomById(state, jobId))
  const workroomId = job ? job.workroomId : undefined
  const commentsRecd = useSelector(state => getCommentsByJobAndWorkroomId(state, workroomId, jobId)) 
  const activitiesData = useSelector(state => getActivityByProcessId(state, processId))
  const wsname = useSelector(state => getWorkroomShortNameFromId(state, workroomId)) 
  const activeTaskFromSelector = useSelector (state => select.getCurrentTaskByJobId (state, jobId))
  const activeTask = useSelector (state => select.getActiveTask (state))

  useEffect(() => {
    if (job == undefined && jobKey && ( !isjobdenied))  {
        dispatch (fetchJobsByJobKey ( {'jobKey' : jobKey}))
    }
    else if (isjobdenied )
      setPermissionDenied(true)
    document.title = jobKey.toUpperCase()
  }, [jobKey, isjobdenied])


  useEffect(() => {
    if ((activitiesData == undefined || activitiesData.length == 0) && processId) {
        dispatch (fetchActivityByProcess ( {'processId' : processId}))
    }
  }, [processId])


  useEffect(() => {
      heightSet(setHeight)
      widthSet(setWidth)
      innerHeightSet(setInnerHeight)
  }) 


  useEffect(() => {
    if ((taskList == undefined || taskList.length == 0) && jobId) {
      dispatch(fetchTasksByJob({ 'jobId': jobId }));
      dispatch(fetchTaskdetailsByJob ({'jobId': jobId}))
    }
    if (jobId && taskList){
      const res = taskList.find(task => task.id == activeTask.id)
      if (!res || res.id !== activeTask.id) dispatch(setActiveTask(activeTaskFromSelector))
    }
  },[jobId, taskList]);


  useEffect(() => {
    if(!commentsRecd){
        console.log ( ' comments not recd is ', commentsRecd)
        dispatch(fetchComments({'workroomId': workroomId, 'dtCreated': 0}));
    }
  },[commentsRecd]);


  useEffect(() => {
    let hashlink = ""
    if (currActiveIndex == 0 || currActiveIndex == 1)
        hashlink = '#jobs'
     dispatch (updateHeaderTitle ({'title':dispName, 'linkForWorkroom' : wsname, 'linkAddress' : '/workroom/view/' + wsname.toLowerCase() + hashlink}))
  }, [dispName, currActiveIndex])


  useEffect(() => {

    let path = props.match.path
    let hash = props.location.hash
    console.log( ' the hash is *****', hash)
      if (path == '/jobs/view/:key' && (!activityId)) {
        if (hash == '#comments')
          setCurrActiveIndex(2)   
        else
          setCurrActiveIndex(0) 
      }
      if (path == '/jobs/view/:key' && activityId) {
          setCurrActiveIndex(1)
      }
      
  }, [props.location.pathname, activityId, props.location.hash])


    const panes = [
        {
          menuItem: 'Summary',
          render: () => 
                        process && process["startForm"]
                        ? <Tab.Pane className="borderNoneObj" >{console.clear()}{console.log(process)}<TemplateSummaryDynamic {...props} job = {job} process={process}/></Tab.Pane>
                        : <Tab.Pane className="borderNoneObj" >{console.clear()}{console.log(process)}<TemplateSummary {...props} job = {job}/></Tab.Pane>,
        },
        {
          menuItem: 'Tasks',
          render: () => <Tab.Pane className="borderNoneObj" ><TaskTemplate {...props} job = {job} /></Tab.Pane>,
        },
        {
          menuItem: 'Comments',
          render: () => <Tab.Pane className="borderNoneObj"><CommentsList isWorkroomList = {false} {...props} job = {job} /></Tab.Pane>,
        },
        {
            menuItem: 'Attachment',
            render: () => <Tab.Pane className="borderNoneObj"><AttachmentsDisplay {...props} job = {job} /></Tab.Pane>,
        },
          // {
          //     menuItem: 'Module',
          //     render: () => <Tab.Pane className="borderNoneObj"><TemplateIFrame {...props} job = {job} /></Tab.Pane>,
          // },
      ]
      

    // Loading Logic if tasks not fetched
    if (permissionDenied) {
      return (
          <Redirect
              to={{
                  pathname: "/app/denied",
                  // state: { from: props.location }
              }}
          />
      )
  } else {
    return (
      <Container>
            <Grid columns={8} divided columns='equal'>
              <Grid.Column style={{height:'85vh', overflowY: 'auto'}} className="borderNoneObj paddingTopZero">
                  <Tab 
                    menu={{ secondary: true, pointing: true, borderless:true, attached:true }} 
                    activeIndex= {currActiveIndex}
                    onTabChange = {((event, data) => {
                        setCurrActiveIndex(data.activeIndex)
                    })}
                    panes={panes} 
                    className='borderNoneObj'
                  />
              </Grid.Column>
              <TasksRightMenu {...props} job = {job} />
          </Grid>
      </Container>
    )
  }
}

export default TasksDispContainer;
