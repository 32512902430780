import * as type from './types'


export function createScheduledprocess(scheduledprocess) {
    return {
        type : type.CREATE_SCHEDULEDPROCESS,
        payload : scheduledprocess,
        txn : 'initiated',
    };
}


export function savedScheduledprocess(scheduledprocess) {
    return {
        type : type.SAVED_SCHEDULEDPROCESS,
        payload : scheduledprocess,
        txn : 'success',
        diff : true
    };
}


export function editScheduledprocess(scheduledprocess) {
    return {
        type : type.EDIT_SCHEDULEDPROCESS,
        payload : scheduledprocess,
        txn : 'initiated',
    };
}


export function fetchScheduledprocess() {
    return {
        type : type.FETCH_SCHEDULEDPROCESS,
    };
}


export function fetchedScheduledprocess(scheduledprocess) {
    return {
        type : type.FETCHED_SCHEDULEDPROCESS,
        payload : scheduledprocess
    };
}


export function searchScheduledprocess(scheduledprocess) {
    return {
        type : type.SEARCH_SCHEDULEDPROCESS,
        payload : scheduledprocess
    };
}


export function storeSearchScheduledprocessResult(scheduledprocess) {
    return {
        type : type.STORE_SEARCH_SCHEDULEDPROCESS_RESULT,
        payload : scheduledprocess
    };
}


export function deleteScheduledprocess(scheduledprocess) {
    return {
        type : type.DELETE_SCHEDULEDPROCESS,
        payload: scheduledprocess
    };
}


export function deletedScheduledprocess(msg) {
    // console.log("msg==action---",msg)
    return {
        type : type.DELETED_SCHEDULEDPROCESS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_SCHEDULEDPROCESS,
        payload: props
    };
}

export function filterItems(options) {
    return {
        type : type.FILTER_ITEMS,
        payload: options
    };
}