import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../data/actions";
import * as select from "../data/selectors";
import { Container, Icon, Input } from "semantic-ui-react";
import {
  heightSet,
  widthSet,
  innerHeightSet,
  setMenuHeight,
  menuAccordionHeight,
} from "../../../utilities/heightForListing";
import { fetchJobsByTeamId } from "../data/actions";
import moment from "moment";
import { JobTableList } from "../../../utilities/tableUtils";
import { getIconColour, getIconName } from "../../../utilities/iconUtils";

const JobsPriorityListByTeam = (props) => {
  const teamId = props.teamId;
  const ViewMenu = props.viewMenu;
  let jobs = useSelector((state) =>
    select.getInCompleteJobsByTeamIdforList(state, teamId)
  );
  const jobsFetched = useSelector((state) =>
    select.isJobsFetchedByTeamId(state, teamId)
  );
  const [maxPriority, setMaxPriority] = useState(0);

  const dispatch = useDispatch();

  const [heights, setHeight] = useState("");
  const [innerHeights, setInnerHeight] = useState("");
  const [widths, setWidth] = useState("");

  const redirectPath = "/team/view" + teamId;

  useEffect(() => {
    setMenuHeight(setHeight);
    widthSet(setWidth);
    menuAccordionHeight(setInnerHeight);
    window.addEventListener(
      "resize",
      function () {
        setMenuHeight(setHeight);
        widthSet(setWidth);
        menuAccordionHeight(setInnerHeight);
        // heightSetFotTaskForm(setInnerCommentHeight)
      },
      false
    );
  });

  useEffect(() => {
    if (jobs.length > 0 && maxPriority == 0)
      setMaxPriority(
        Math.max.apply(
          Math,
          jobs.map(function (o) {
            return o.priority;
          })
        ) + 1
      );
  }, [jobs]);

  useEffect(() => {
    if (!jobsFetched) dispatch(fetchJobsByTeamId({ teamId: teamId }));
    setMaxPriority(0);
  }, [teamId]);

  const setPriority = (jobId, priority) => {
    const obj = [{ id: jobId, priority: priority ? priority : maxPriority }];
    console.log(obj.priority, maxPriority);
    if (parseInt(obj[0].priority) >= maxPriority)
      setMaxPriority(parseInt(obj[0]["priority"]) + 1);
    dispatch(actions.updateJobPriority(obj));
  };

  const removePriority = (jobId, priority) => {
    const obj = { id: jobId };
    if (priority && parseInt(priority) + 1 == maxPriority)
      setMaxPriority(parseInt(priority));
    dispatch(actions.removePriority(obj));
  };

  // Loading Logic if tasks not fetched
  if (!jobs || jobs.length == 0) {
    return <p> No records </p>;
  } else {
    return (
      <Container>
        <JobTableList
          columns={searchColumns({ setPriority, removePriority, maxPriority })}
          data={jobs}
          HeaderLinks={ViewMenu}
        />
      </Container>
    );
  }
};

const PriorityActions = ({ object: job, fn }) => {
  const [priority, setPriority] = useState(null);
  return (
    <>
      {!job.priority ? (
        <>
          <span>
            <Input
              style={{ width: "60px", height: "25px", fontSize: "12px" }}
              placeholder={fn.maxPriority}
              onChange={(e) => setPriority(e.target.value)}
              value={priority}
            ></Input>
          </span>
          <span
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => fn.setPriority(job.id, priority)}
          >
            {" "}
            Set Priority
          </span>
        </>
      ) : (
        <>
          <span
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => fn.removePriority(job.id, job.priority)}
          >
            Remove Priority
          </span>
          <span>&nbsp;&nbsp;</span>
        </>
      )}
    </>
  );
};

export const searchColumns = (fn) => [
  {
    Header: "Priority",
    accessor: "priority",
    className: "tup1 ellipsisField",
    headerClassName: "tup1 tableHeaderCell",
  },
  {
    Header: "Job Status",
    accessor: "jobStatus",
    Cell: (row) => {
      return (
        <Icon
          name={getIconName(row.value)}
          color={getIconColour(row.value)}
          size="large"
        />
      );
    },
    className: "tup2 ellipsisField",
    headerClassName: "tup2 tableHeaderCell",
  },
  {
    Header: "Workroom",
    accessor: "workroomName",
    className: "tup3 ellipsisField",
    headerClassName: "tup3 tableHeaderCell",
  },
  {
    Header: "Job",
    accessor: "job",
    className: "tup4 ellipsisField",
    headerClassName: "tup4 tableHeaderCell",
  },
  {
    Header: "Deadline",
    accessor: "deadline",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "tup5",
    headerClassName: "tup5 tableHeaderCell",
  },
  {
    Header: "Date Start",
    accessor: "dtStart",
    Cell: (props) => {
      const custom_date = props.value
        ? moment(props.value).format("DD-MM-YYYY")
        : null;
      return <span className="ellipsisField">{custom_date}</span>;
    },
    className: "tup6",
    headerClassName: "tup6 tableHeaderCell",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    className: "tup7 ellipsisField",
    headerClassName: "tup7 tableHeaderCell",
  },
  {
    Header: "Action",
    Cell: (props) => {
      return <PriorityActions object={props.row.original} fn={fn} />;
    },
    className: "tup8 ellipsisField",
    headerClassName: "tup8 tableHeaderCell",
  },
];
export default JobsPriorityListByTeam;
