import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchedActivity, selectActivity } from '../data/actions'
import * as select from '../data/selectors'
import { Field } from 'formik'
import { FormikSelectComponent } from '../../../utilities/formUtils'
import { Dropdown } from 'semantic-ui-react';


const ActivitySelect =  ({ name, isTxn, isLabel, label, required, getValue : callback }, props) => {
    // ({ name, isTxn, isLabel, label, isSelection, placeholder, userProps, getValue : callback }, props) => {

    const activity = useSelector(state => state.activity.byId)
    const activityFetched = useSelector(state => select.getIsActivityFetched(state, props))
    // const options = useSelector(state => select.selectActivity(state, props))
    const options = useSelector(state => select.selectActivityFromJsonObject(state, props))
    // console.log(options, "==activity options");

    const dispatch = useDispatch()    
    useEffect(()=>{
        if (!activityFetched)
            dispatch(fetchedActivity(activity));
    },[]);
    

    const getValue = (values) => {}


    if (!activityFetched && options.length > 0 ){
        return <p>Loading</p>
    }  else {       
        return (
                <Field name={name} 
                    isLabel={isLabel}
                    required = {required} 
                    isTxn={isTxn} 
                    label={label}
                    component={FormikSelectComponent} 
                    userProps={{ options, getValue }}> 
                </Field>
            )
    }

}


export default ActivitySelect;
