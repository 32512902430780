import * as type from './types'


export function createSteps(steps) {
    return {
        type : type.CREATE_STEPS,
        payload : steps,
        txn : 'initiated',
    };
}


export function savedSteps(steps) {
    return {
        type : type.SAVED_STEPS,
        payload : steps,
        txn : 'success',
        diff : true
    };
}


export function editSteps(steps) {
    return {
        type : type.EDIT_STEPS,
        payload : steps,
        txn : 'initiated',
    };
}


export function fetchSteps() {
    return {
        type : type.FETCH_STEPS,
    };
}


export function fetchedSteps(steps) {
    return {
        type : type.FETCHED_STEPS,
        payload : steps
    };
}


export function searchSteps(steps) {
    return {
        type : type.SEARCH_STEPS,
        payload : steps
    };
}


export function storeSearchStepsResult(steps) {
    return {
        type : type.STORE_SEARCH_STEPS_RESULT,
        payload : steps
    };
}


export function deleteSteps(steps) {
    return {
        type : type.DELETE_STEPS,
        payload: steps
    };
}


export function deletedSteps(msg) {
    return {
        type : type.DELETED_STEPS,
        payload : msg
    };
}


export function setNotifyDone(props) {
    return {
        type : type.NOTIFICATION_DONE_STEPS,
        payload: props
    };
}